import { SettingsPanelItem } from '@sbs/bitmovin-player-ui';
import { ComponentConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/component';
import i18n from 'i18next';

import VideoQualityListBox from './VideoQualityListBox';

export default class VideoQualitySettingsPanelItem extends SettingsPanelItem {
  constructor(config: ComponentConfig = {}) {
    super(
      i18n.t('common:videoPlayer.settingsPanelVideoQuality'),
      new VideoQualityListBox(),
      {
        ...config,
        allowLabelFocus: true,
      },
    );
  }
}
