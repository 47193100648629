/**
 * Overrides Bitmovin's SubtitleUtils class.
 * Adds:
 * - loading of user preferences from the browser's localstorage
 * - automatically selects forced narratives track
 */
import { UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { ListItem, ListSelector, ListSelectorConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/listselector';
import { PlayerAPI, SubtitleEvent, SubtitleTrack } from 'bitmovin-player';
import * as ls from 'local-storage';
import { get, debounce } from 'lodash';

import i18n from '../../../../i18n';
import { PlayerUserPrefs } from '../../BitmovinClient';

/**
 * Helper class to handle all subtitle related events
 *
 * This class listens to player events as well as the `ListSelector` event if selection changed
 */
export default class SubtitleSwitchHandler {
  public static FORCED_SUBTITLES_LABEL: string = 'English (Forced)';

  private static SUBTITLES_OFF_KEY: string = 'null';
  private static IGNORE_SUBTITLE_LABELS: string[] = ['Captions (CC 1)'];

  private forcedSubtitlesId: string;
  private player: PlayerAPI;
  private listElement: ListSelector<ListSelectorConfig>;
  private uimanager: UIInstanceManager;
  private userPrefsLoaded: boolean = false;

  constructor(player: PlayerAPI, element: ListSelector<ListSelectorConfig>, uimanager: UIInstanceManager) {
    this.player = player;
    this.listElement = element;
    this.uimanager = uimanager;

    this.bindSelectionEvent();
    this.bindPlayerEvents();
    this.refreshSubtitles();
  }

  /**
   * Check if the current subtitle track is for forced narratives
   * @param subtitle
   */
  public static isSubtitleForced(subtitle: SubtitleTrack): boolean {
    return subtitle.forced === true || subtitle.label === SubtitleSwitchHandler.FORCED_SUBTITLES_LABEL;
  }

  public static shouldIgnoreSubtitle(subtitle: SubtitleTrack): boolean {
    const shouldIgnore = SubtitleSwitchHandler.IGNORE_SUBTITLE_LABELS.indexOf(subtitle.label) !== -1;
    return shouldIgnore;
  }

  /** Handles user selection of a subtitle from the player menu */
  private bindSelectionEvent(): void {
    this.listElement.onItemSelected.subscribe((_, value: string) => {
      if (
        !value
        || value === SubtitleSwitchHandler.SUBTITLES_OFF_KEY
      ) {
        if (this.forcedSubtitlesId) {
          this.player.subtitles.enable(this.forcedSubtitlesId, true);
        } else {
          const currentSubtitle = this.player.subtitles.list().filter((subtitle) => { return subtitle.enabled; }).pop();
          if (currentSubtitle) {
            this.player.subtitles.disable(currentSubtitle.id);
          }
        }
      } else {
        this.player.subtitles.enable(value, true);
      }
    });
  }

  /**
   * Setup required event handlers
   * @private
   */
  private bindPlayerEvents(): void {
    this.player.on(this.player.exports.PlayerEvent.SourceLoaded, this.sourceLoaded);
    this.player.on(this.player.exports.PlayerEvent.SubtitleAdded, this.addSubtitle);
    this.player.on(this.player.exports.PlayerEvent.SubtitleEnabled, this.selectCurrentSubtitle);
    this.player.on(this.player.exports.PlayerEvent.SubtitleDisabled, this.selectCurrentSubtitle);
    this.player.on(this.player.exports.PlayerEvent.SubtitleRemoved, this.removeSubtitle);
    // Update subtitles when source goes away
    this.player.on(this.player.exports.PlayerEvent.SourceUnloaded, this.clearSubtitles);
    // Update subtitles when the period within a source changes
    this.player.on(this.player.exports.PlayerEvent.PeriodSwitched, this.refreshSubtitles);
    this.uimanager.getConfig().events.onUpdated.subscribe(this.refreshSubtitles);
  }

  /**
   * Using debounce to prevent calling refreshSubtitles() multiple times to do special handling
   * @private
   */
  private debounceRefreshSubtitle = debounce(() => { this.refreshSubtitles(); }, 1000);

  /**
   * Handles SourceLoaded player event
   */
  private sourceLoaded = () => {
    this.forcedSubtitlesId = undefined;
  };

  /**
   * Handles SubtitleAdded player events
   * @param event
   */
  private addSubtitle = (event: SubtitleEvent) => {
    const { subtitle } = event;

    if (
      !SubtitleSwitchHandler.isSubtitleForced(subtitle)
      && !SubtitleSwitchHandler.shouldIgnoreSubtitle(subtitle)
      && !this.listElement.hasItem(subtitle.id)
    ) {
      this.debounceRefreshSubtitle();
    }
  };

  /**
   * Handles SubtitleRemoved player event
   * @param event
   */
  private removeSubtitle = (event: SubtitleEvent) => {
    const { subtitle } = event;
    if (this.listElement.hasItem(subtitle.id)) {
      this.listElement.removeItem(subtitle.id);
    }
  };

  /**
   * Highlights the currently enabled subtitle in the player menu
   */
  private selectCurrentSubtitle = () => {
    if (!this.player.subtitles) {
      // Subtitles API not available (yet)
      return;
    }

    const userPlayerPreferences: PlayerUserPrefs = ls.get('od.player.userPrefs');
    const stLang = get(userPlayerPreferences, 'stLang');

    if (this.userPrefsLoaded === false && stLang) {
      const availableSubtitles = this.player.subtitles.list();

      let userPreferredSubtitleId;
      for (let si = 0; si < availableSubtitles.length; si += 1) {
        const subtitle = availableSubtitles[si];
        if (
          subtitle.lang === stLang
          && !SubtitleSwitchHandler.isSubtitleForced(subtitle)
        ) {
          userPreferredSubtitleId = subtitle.id;
        }
      }

      // If we have found a user preferred subtitles, use it
      if (userPreferredSubtitleId) {
        this.listElement.selectItem(userPreferredSubtitleId);

        // Else use the forced subtitles if any
      } else {
        this.listElement.selectItem(SubtitleSwitchHandler.SUBTITLES_OFF_KEY);
      }
    } else {
      const currentSubtitle = this.player.subtitles.list().filter((subtitle) => { return subtitle.enabled; }).pop();
      if (currentSubtitle && !SubtitleSwitchHandler.isSubtitleForced(currentSubtitle)) {
        this.listElement.selectItem(currentSubtitle.id);
      } else {
        this.listElement.selectItem(SubtitleSwitchHandler.SUBTITLES_OFF_KEY);
      }
    }

    this.userPrefsLoaded = true;
  };

  /**
   * Removes the subtitles from the player menu
   */
  private clearSubtitles = () => {
    this.listElement.clearItems();

    // set userPrefsLoaded to false so that we will load the user's preferred subtitle on the next video
    this.userPrefsLoaded = false;
  };

  /**
   * Traverses the list of available subtitles and pre-processes/filters them for display in the player menu
   */
  private refreshSubtitles = () => {
    if (!this.player.subtitles) {
      // Subtitles API not available (yet)
      return;
    }

    const offListItem: ListItem = {
      key: SubtitleSwitchHandler.SUBTITLES_OFF_KEY,
      label: i18n.t('common:videoPlayer.settingsPanelSubtitlesOff'),
    };

    const subtitles = this.player.subtitles.list();
    if (subtitles.length === 0) {
      // No subtitles available
      // If audio tracks are available, the subtitle section will be hidden (CSS from BitmovinClients READY handler)
      return;
    }

    // Used to filter out Forced Narratives from the list
    const subtitleFilter = (subtitle: SubtitleTrack): boolean => {
      if (SubtitleSwitchHandler.isSubtitleForced(subtitle)) {
        this.forcedSubtitlesId = subtitle.id;
        return false;
      }

      return !SubtitleSwitchHandler.shouldIgnoreSubtitle(subtitle);
    };

    const subtitleToListItem = (subtitle: SubtitleTrack): ListItem => {
      return { key: subtitle.id, label: subtitle.label };
    };

    const newItems = [
      offListItem,
      ...subtitles
        .filter(subtitleFilter)
        .map(subtitleToListItem),
    ];
    this.listElement.synchronizeItems(newItems);

    this.selectCurrentSubtitle();
  };
}
