/**
 * Instantiates a custom Bitmovin UI
 */

import {
  BufferingOverlay,
  Container,
  CastToggleButton,
  ControlBar,
  CloseButton,
  ErrorMessageOverlay,
  FullscreenToggleButton,
  PlaybackTimeLabel,
  PlaybackTimeLabelMode,
  PlaybackToggleButton,
  PlaybackToggleOverlay,
  SeekBar,
  SeekBarLabel,
  SettingsPanelPage,
  SettingsPanelItem,
  SettingsPanel,
  SubtitleOverlay,
  TitleBar,
  UIContainer,
  VolumeToggleButton,
  VolumeSlider,
  UIManager,
  version,
} from '@sbs/bitmovin-player-ui';
import { UIConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/uiconfig';

import FastForwardButton from '@@src/lib/VideoPlayer/BitmovinPlayerUi/components/FastForwardButton';
import { PlayerMetadata } from '@@src/pages/WatchPage';
import VideoStream from '@@types/VideoStream';
import DataLayer from '@@utils/DataLayer';
import Logger from '@@utils/logger/Logger';

import i18n from '../../../i18n';
import BitmovinClient from '../BitmovinClient';
import AudioTrackListBox from './components/AudioTrackListBox';
import CastStatusOverlay from './components/CastStatusOverlay';
import ControlsToggleOverlay from './components/ControlsToggleOverlay';
import PictureInPictureToggleButton from './components/PictureInPictureToggleButton';
import RewindButton from './components/RewindButton';
import SettingsPanelWrapper from './components/SettingsPanelWrapper';
import SettingsToggleButton from './components/SettingsToggleButton';
import SubtitleListBox from './components/SubtitleListbox';
import VideoQualityListBox from './components/VideoQualityListBox';

const fastForwardRewindSeconds = 10;

interface OdUiConfig extends UIConfig {
  videoStreamMetadata: VideoStream.DaiStream;
  playerMetadata: PlayerMetadata;
}

function setupPlaySkipButtons(bitmovinClientInstance: BitmovinClient, config: OdUiConfig) {
  const { player } = bitmovinClientInstance;

  const performFastForward = () => {
    if (player.isLive()) {
      player.timeShift(Math.min(0, player.getTimeShift() + fastForwardRewindSeconds), 'ui-skip-backward');
    } else {
      player.seek(Math.min(player.getDuration(), player.getCurrentTime() + fastForwardRewindSeconds), 'ui-skip-forward');
    }

    DataLayer.events.videoTrackingEvent(
      'videoSkipForward',
      config.videoStreamMetadata.video,
      { currentChapterData: bitmovinClientInstance.getChapterData() },
    );
  };

  const performRewind = () => {
    if (player.isLive()) {
      player.timeShift(player.getTimeShift() - fastForwardRewindSeconds, 'ui-skip-backward');
    } else {
      player.seek(Math.max(0, player.getCurrentTime() - fastForwardRewindSeconds), 'ui-skip-backward');
    }

    DataLayer.events.videoTrackingEvent(
      'videoSkipBackward',
      config.videoStreamMetadata.video,
      { currentChapterData: bitmovinClientInstance.getChapterData() },
    );
  };

  const rewindButton = new RewindButton({
    ariaLabel: i18n.t('common:videoPlayer.rewind'),
  });
  rewindButton.onClick.subscribe(performRewind);

  const fastForwardButton = new FastForwardButton({
    ariaLabel: i18n.t('common:videoPlayer.fastForward'),
  });
  fastForwardButton.onClick.subscribe(performFastForward);

  return {
    rewindButton,
    fastForwardButton,
  };
}

function setupEpisodePickerButton(bitmovinClientInstance: BitmovinClient) {
  const episodePickerPanelWrapper = new SettingsPanelWrapper({
    rootElement: bitmovinClientInstance.getContainerElement(),
    customEvents: {
      onShow: { name: 'OdPlayerMoreEpisodesShown' },
      onHide: { name: 'OdPlayerMoreEpisodesHidden' },
      onHoverChanged: { name: 'OdPlayerMoreEpisodesHoverChanged' },
      visibilityToggle: { name: 'OdPlayerMoreEpisodesButtonClick' },
      show: { name: 'OdPlayerMoreEpisodesShow' },
      hide: { name: 'OdPlayerMoreEpisodesHide' },
    },
    hidden: true,
  });

  const moreEpisodesButton = new SettingsToggleButton({
    settingsPanel: episodePickerPanelWrapper,
    ariaLabel: 'View more episodes',
    cssClass: 'ui-moreepisodesbutton hidden',
    id: 'bmpui-ui-moreepisodesbutton',
  });

  return {
    moreEpisodesButton,
    episodePickerPanelWrapper,
  };
}

function setupControlBar(bitmovinClientInstance, config) {
  const settingsPanel = new SettingsPanel({
    components: [
      new SettingsPanelPage({
        components: [
          new SettingsPanelItem(i18n.t('common:videoPlayer.settingsPanelVideoQuality'), new VideoQualityListBox(), { allowLabelFocus: true }),
        ],
        autoFocusOn: 'label',
      }),
    ],
    hidden: true,
    cssClass: 'ui-settings-panel bmpui-ui-settings-panel-settings bmpui-ui-settings-panel-videoquality',
    hideDelay: -1,
  });
  settingsPanel.addComponent(new CloseButton({ target: settingsPanel }));

  const subtitleSettingsPanel = new SettingsPanel({
    components: [
      new SettingsPanelPage({
        components: [
          new SettingsPanelItem(
            i18n.t('common:videoPlayer.settingsPanelAudio'),
            new AudioTrackListBox(),
            { cssClass: 'ui-settings-panel-audio', allowLabelFocus: true },
          ),
          new SettingsPanelItem(
            i18n.t('common:videoPlayer.settingsPanelSubtitles'),
            new SubtitleListBox(),
            { cssClass: 'ui-settings-panel-subtitles', allowLabelFocus: true },
          ),
        ],
        autoFocusOn: 'label',
      }),
    ],
    hidden: true,
    cssClass: 'ui-settings-panel bmpui-ui-settings-panel-settings bmpui-ui-settings-panel-subtitleaudio',
    hideDelay: -1,
  });
  subtitleSettingsPanel.addComponent(new CloseButton({ target: subtitleSettingsPanel }));

  const { rewindButton, fastForwardButton } = setupPlaySkipButtons(bitmovinClientInstance, config);
  const { moreEpisodesButton, episodePickerPanelWrapper } = setupEpisodePickerButton(bitmovinClientInstance);

  const rightContainer = new Container({
    components: [
      ...(moreEpisodesButton ? [moreEpisodesButton] : []),
      new SettingsToggleButton({
        settingsPanel: subtitleSettingsPanel,
        cssClass: 'ui-subtitlesettingstogglebutton',
        ariaLabel: i18n.t('common:videoPlayer.subtitlesAndAudioDescription'),
        onHideOtherPanelsEvents: [{
          name: 'OdPlayerMoreEpisodesHide',
          payload: {
            reason: 'settingsMenuShow',
          },
          rootElement: bitmovinClientInstance.getContainerElement(),
        }],
      }),
      new SettingsToggleButton({
        settingsPanel,
        cssClass: 'ui-settingstogglebutton',
        onHideOtherPanelsEvents: [{
          name: 'OdPlayerMoreEpisodesHide',
          payload: {
            reason: 'settingsMenuShow',
          },
          rootElement: bitmovinClientInstance.getContainerElement(),
        }],
      }),
      new PictureInPictureToggleButton(),
      new FullscreenToggleButton(),
    ],
    cssClass: 'ui-paneliconsgroup',
  });

  return new ControlBar({
    components: [
      subtitleSettingsPanel,
      settingsPanel,

      // @ts-ignore emulating a Bitmovin SettingsPanel to wrap around our React component
      episodePickerPanelWrapper,

      new Container({
        components: [
          new PlaybackTimeLabel({ timeLabelMode: PlaybackTimeLabelMode.CurrentTime, hideInLivePlayback: true }),
          new SeekBar({ label: new SeekBarLabel() }),
          new PlaybackTimeLabel({ timeLabelMode: PlaybackTimeLabelMode.TotalTime, cssClasses: ['text-right'] }),
        ],
        cssClasses: ['controlbar-top'],
      }),
      new Container({
        components: [
          new Container({
            components: [
              new VolumeToggleButton(),
              new VolumeSlider(),
            ],
            cssClass: 'ui-volumegroup',
          }),
          new Container({
            components: [
              rewindButton,
              // Can't re-use their stoptoggle class because Bitmovin automatically removes it if it thinks there is no timeshift.
              new PlaybackToggleButton({
                cssClass: 'ui-playbacktogglebutton',
              }),
              fastForwardButton,
            ],
            cssClass: 'ui-playbackgroup',
          }),
          rightContainer,
        ],
        cssClasses: ['controlbar-bottom'],
      }),
    ],
  });
}

export function sbsOnDemandModernUI(bitmovinClientInstance: BitmovinClient, config: OdUiConfig): UIContainer {
  return new UIContainer({
    components: [
      new Container({
        components: [
          new CastToggleButton(),
          new CastStatusOverlay(),
        ],
        cssClass: 'ui-castgroup',
      }),
      new SubtitleOverlay(),
      new BufferingOverlay(),
      new ControlsToggleOverlay(),
      new PlaybackToggleOverlay(),
      setupControlBar(bitmovinClientInstance, config),
      new TitleBar(),
      new ErrorMessageOverlay(),
    ],
    hideDelay: 5000,
  });
}

export function buildSbsOnDemandUI(bitmovinClientInstance: BitmovinClient, config: OdUiConfig): UIManager {
  Logger.info(`Bitmovin Player UI v${version}`);

  return new UIManager(bitmovinClientInstance.player, [{
    ui: sbsOnDemandModernUI(bitmovinClientInstance, config),
  }], config);
}

export default {
  sbsOnDemandModernUI,
  buildSbsOnDemandUI,
};
