import { PlayerEvent, MetadataEvent } from 'bitmovin-player';
import { get } from 'lodash';

export interface AdTagParameters {
  iu?: string;
  // eslint-disable-next-line camelcase
  cust_params?: string;
  // eslint-disable-next-line camelcase
  description_url?: string;
  ppid?: string;
}

/**
 * Google DAI helper class
 */
class GoogleDai {
  public streamManager: any;
  public currentAdMetadata: any;
  public google: any;

  private cuepoints: any[];
  private bitmovinClientInstance: any;

  /**
   * Constructor
   * @param bitmovinClientInstance
   */
  public constructor(bitmovinClientInstance) {
    this.bitmovinClientInstance = bitmovinClientInstance;
  }

  /**
   * Reset when unloading the player
   */
  public unload(): void {
    this.cuepoints = [];
    if (this.streamManager) {
      this.streamManager.reset();
      delete this.streamManager;
    }
  }

  private loadStreamManager(): void {
    this.streamManager = new window.google.ima.dai.api.StreamManager(this.bitmovinClientInstance.getVideoElement());
  }

  /**
   * Loading Livestream by its Asset Key
   * @param assetKey
   * @param adTagParameters
   */
  public loadLivestreamByAssetKey(
    assetKey: string,
    adTagParameters: AdTagParameters | null = { iu: '/4117/video.web.sbs.com.au' },
  ): void {
    this.loadStreamManager();
    this.streamManager.setClickElement(this.bitmovinClientInstance.getAdClickElement());

    this.bitmovinClientInstance.player.on(PlayerEvent.Metadata, (data: MetadataEvent) => {
      if (this.streamManager && data && data.metadataType === 'ID3') {
        this.streamManager.onTimedMetadata(data.metadata);
      }
    });

    const adTag = { ...adTagParameters };
    // eslint-disable-next-line camelcase
    adTag.cust_params += `&tvid=${get(window, 'tvid_cookie_id', '')}`;
    adTag.cust_params += `&oztam=${this.bitmovinClientInstance.getOztamSessionId()}`;

    const streamRequest = new window.google.ima.dai.api.LiveStreamRequest();
    streamRequest.assetKey = assetKey;
    streamRequest.adTagParameters = adTag;
    this.streamManager.requestStream(streamRequest);
  }

  /**
   * Loading VOD video by its video ID and content source ID (cms ID)
   * @param videoId
   * @param providerAccountId
   * @param adTagParameters
   */
  public loadVodByVideoId(
    videoId: string,
    providerAccountId: string,
    adTagParameters: AdTagParameters | null = { iu: '/4117/video.web.sbs.com.au' },
  ): void {
    this.loadStreamManager();
    this.streamManager.setClickElement(this.bitmovinClientInstance.getAdClickElement());

    const adTag = { ...adTagParameters };
    // eslint-disable-next-line camelcase
    adTag.cust_params += `&tvid=${get(window, 'tvid_cookie_id', '')}`;
    adTag.cust_params += `&oztam=${this.bitmovinClientInstance.getOztamSessionId()}`;

    // Video Header Bidding processing
    const { videoHeaderBidding } = this.bitmovinClientInstance.state;
    const adpods = get(videoHeaderBidding, 'adpods', []);

    for (let ai = 0; ai < adpods.length; ai += 1) {
      const adpod = adpods[ai];
      const { targeting } = adpod;

      for (let ti = 0; ti < targeting.length; ti += 1) {
        const target = targeting[ti];
        const targetKeys = Object.keys(target);

        for (let ki = 0; ki < targetKeys.length; ki += 1) {
          const key = targetKeys[ki];
          if (key.indexOf('hb_cache_host_pod') === -1) {
            adTag.cust_params += `&${key}=${target[key]}`;
          }
        }
      }
    }

    const streamRequest = new window.google.ima.dai.api.VODStreamRequest();
    streamRequest.contentSourceId = providerAccountId;
    streamRequest.videoId = videoId;

    streamRequest.adTagParameters = adTag;
    this.streamManager.requestStream(streamRequest);
  }

  public contentTimeForStreamTime(streamTime: number): number {
    if (this.streamManager) {
      return this.streamManager.contentTimeForStreamTime(streamTime);
    }
    return streamTime;
  }

  public streamTimeForContentTime(contentTime: number): number {
    if (this.streamManager) {
      return this.streamManager.streamTimeForContentTime(contentTime);
    }
    return contentTime;
  }
}

export default GoogleDai;
