import { generatePathFromLinkProps } from '@@src/routes';
import OnDemand from '@@types/OnDemand';

/**
 * Finds and return the first image matching the types in order
 * @param images
 * @param types
 */
export const getImageByType = (images: OnDemand.VideoImages, types: string[]): string => {
  if (!images) {
    return '';
  }

  let imageUrl = '';

  types.every((type) => {
    Object.keys(images).every((key) => {
      if (key === type) {
        imageUrl = images[key];
      }

      return !imageUrl;
    });

    return !imageUrl;
  });

  return imageUrl;
};

// Helper function to determine the path for a video
export const getVideoPath = (video: OnDemand.Video): string => {
  let path = generatePathFromLinkProps(video.route);

  if (video.type === 'Episode' && video.episodeData) {
    // If the video is an episode, redirect to the series details page
    path = generatePathFromLinkProps(video.episodeData.seriesRoute);
  }

  return path;
};

export const videoHasDetailsPage = (video: OnDemand.Video): boolean => {
  return video?.route && video.route?.name !== 'watch';
};

export default {
  getImageByType,
  getVideoPath,
  videoHasDetailsPage,
};
