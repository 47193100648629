import { SettingsPanel, SettingsToggleButton, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { SettingsToggleButtonConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/settingstogglebutton';
import { Timeout } from '@sbs/bitmovin-player-ui/dist/js/framework/timeout';
import { PlayerAPI } from 'bitmovin-player';
import { isMobile, isIOS } from 'react-device-detect';

export interface SettingsHoverToggleButtonConfig extends SettingsToggleButtonConfig {
  /**
   * The delay in milliseconds after which the settings panel will be hidden when it's not hovered anymore.
   * Default: 200 milliseconds
   */
  hideDelay?: number;
  /**
   * Determines if the settings panel position should be adjusted (aligned with the hover button).
   */
  repositionSettingsPanel?: boolean
}

/**
 * A button that toggles visibility of a settings panel on hover.
 */
export default class SettingsHoverToggleButton extends SettingsToggleButton {
  private readonly hideTimeout: Timeout;
  private readonly repositionSettingsPanel: boolean;

  constructor(config: Optional<SettingsHoverToggleButtonConfig, 'hideDelay'>) {
    super(config);

    const hideDelay: number = config.hideDelay || 200;
    const { settingsPanel } = config;

    this.repositionSettingsPanel = Boolean(config.repositionSettingsPanel);
    this.hideTimeout = new Timeout(hideDelay, () => {
      settingsPanel.hide();
    });
  }

  /**
   * Display the Settings Panel.
   */
  private showSettingsPanel(settingsPanel: SettingsPanel) {
    // hide other settings panel if a new one will be opened,
    // check the implementation of this.visibleSettingsPanels in the parent class
    if (!settingsPanel.isShown()) {
      this.visibleSettingsPanels.slice().forEach((_settingsPanel: SettingsPanel) => {
        _settingsPanel.hide();
      });
    }

    settingsPanel.show();
    this.adjustSettingsPanelPosition(settingsPanel);
  }

  /**
   * Sets up the hover behavior for the component.
   */
  private setupHoverBehaviour(): void {
    const config = this.getConfig();
    const { settingsPanel } = config;

    // hover over the button will show the settings panel
    this.onHoverChanged.subscribe((_, { hovered }) => {
      this.hideTimeout.clear();

      if (hovered) {
        this.showSettingsPanel(settingsPanel);
      } else {
        this.hideTimeout.reset();
      }
    });

    // hover over the settings panel will prevent it from being hidden
    settingsPanel.onHoverChanged.subscribe((_, { hovered }) => {
      if (hovered) {
        this.hideTimeout.clear();
      } else {
        this.hideTimeout.reset();
      }
    });
  }

  /**
   * Sets up the keyboard interaction behaviour for the component.
   */
  private setupClickBehaviour(): void {
    const buttonElement = this.getDomElement();
    const config = this.getConfig();
    const { settingsPanel } = config;

    buttonElement.on('click', (() => {
      this.showSettingsPanel(settingsPanel);
    }) as EventListener);
  }

  /**
   * Adjusts the position of the settings panel menu based on the window size and device type.
   */
  private adjustSettingsPanelPosition(settingsPanel: SettingsPanel): void {
    if (this.repositionSettingsPanel === true) {
      let marginAdjustment = 20;

      if (isMobile) {
        marginAdjustment = 200;
      }

      const panelElement = settingsPanel.getDomElement().get(0);
      const toggleButtonElement = this.getDomElement().get(0);
      const buttonBoundaries = panelElement && toggleButtonElement?.getBoundingClientRect();

      if (panelElement && buttonBoundaries) {
        panelElement.style.right = `${(window.innerWidth - buttonBoundaries.right - marginAdjustment).toString()}px`;
      }
    }
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);

    // only apply our custom logic if it's not iOS as it seems to be breaking the button when VoiceOver is on
    if (!isIOS) {
      this.setupHoverBehaviour();
      this.setupClickBehaviour();
    }
  }
}
