import { IconButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@@src/hooks/store';
import { getUserId } from '@@stores/UserStore';
import OnDemand from '@@types/OnDemand';

import { ReactComponent as CloseIcon } from '../../../../images/icons/close.svg';
import Gpt from '../../../Advert/Gpt';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      opacity: 0,
      transition: 'opacity 0.2s',
    },
    adContainer: {
      position: 'relative',
    },
    show: {
      opacity: 1,
      transition: 'opacity 3s',
    },
    dismissButton: {
      position: 'absolute',
      top: 0,
      right: 30,
      padding: 0,
      fontSize: '2.5rem',
      '&:hover': {
        background: 'none',
      },
      '& .close_svg__circle': {
        fill: 'transparent',
      },
    },
    gradient: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      background: 'linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))',
    },
  });
});

interface AdOnPauseOverlayProps {
  video: OnDemand.Video;
  onDismissed?(resume?: boolean): void;
}

const AdOverlay: FunctionComponent<AdOnPauseOverlayProps> = ({ video, onDismissed }) => {
  const adUnitPath = '/4117/video.web.sbs.com.au/webpause';
  const slotSize = [[1, 1], [960, 540]];

  const classes = useStyles({});
  const [show, setShow] = useState<boolean>(false);
  const [dismissed, setDismissed] = useState<boolean>(false);
  const userId = useAppSelector(getUserId) || '';

  const { t } = useTranslation('common');

  const onSlotRenderEnded = useCallback((event) => {
    // we only want to show the ad if it's returning the correct size
    if (!event.isEmpty && isEqual(event.size, [960, 540])) {
      setShow(true);
    } else {
      setDismissed(true);
    }
  }, []);

  const handleClickDismiss = useCallback(() => {
    setShow(false);
    setTimeout(() => {
      setDismissed(true);
      if (onDismissed) {
        onDismissed(false);
      }
    }, 250);
  }, [onDismissed]);

  const handleClickDismissAndResume = useCallback(() => {
    setShow(false);
    setTimeout(() => {
      setDismissed(true);
      if (onDismissed) {
        onDismissed(true);
      }
    }, 250);
  }, [onDismissed]);

  const targeting = useMemo<Record<string, string>>(() => {
    const _targeting: Record<string, string> = {
      uid: userId,
      genre: video.genres.join(','),
      vid: video.id,
      programname: video.type === 'Episode' ? video.episodeData.programName : video.title,
      ratings: video.classification,
      collections: video.collections.join(','),
      ifaoptout: '0',
    };

    if (video.type === 'Episode' && video.episodeData.seasonNumber) {
      _targeting.season = video.episodeData.seasonNumber.toString();
    }

    return _targeting;
  }, [video, userId]);

  if (dismissed) {
    return null;
  }

  return (
    <div className={clsx(classes.root, { [classes.show]: show })}>
      <button type="button" className={classes.gradient} onClick={handleClickDismissAndResume} aria-label={t('button.dismissAd')}/>
      <div className={classes.adContainer}>
        <IconButton
          className={classes.dismissButton}
          onClick={handleClickDismiss}
          title={t('button.dismissAd')}
          aria-label={t('button.dismissAd')}
        >
          <CloseIcon/>
        </IconButton>
        <Gpt
          slotSize={slotSize}
          adUnitPath={adUnitPath}
          enableSingleRequest
          onSlotRenderEnded={onSlotRenderEnded}
          targeting={targeting}
          ppid={userId.replace(/-/g, '').toUpperCase()}
        />
      </div>
    </div>
  );
};

export default AdOverlay;
