import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    '@global': {
      // Hiding the <video> element will also hide the default PiP message the browser is injecting
      '.pip video': {
        display: 'none',
      },
      // Hides the subtitles in the main window
      '.pip .bmpui-ui-subtitle-overlay': {
        display: 'none',
      },
    },
    root: {
      display: 'none',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',

      // Moves the label up above the playback button groups in smaller screen sizes
      [theme.breakpoints.down('sm')]: {
        height: '80%',
      },

      '.pip &': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
      },
    },
    pipLabel: {
      fontSize: '2rem',

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
  });
});

/**
 * PictureInPictureOverlay to override the browser's built-in PiP message injected into the <video> element
 * @param props
 * @constructor
 */
const PictureInPictureOverlay: FunctionComponent = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation('common');

  return (
    <div className={classes.root}>
      <div className={classes.pipLabel}>
        {t('videoPlayer.playingInPiP')}
      </div>
    </div>
  );
};

export default PictureInPictureOverlay;
