import { Theme, Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import type { UIManager } from '@sbs/bitmovin-player-ui';
import clsx from 'clsx';
import { get } from 'lodash';
import { FunctionComponent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import BitmovinClient from '@@src/lib/VideoPlayer/BitmovinClient';

import fontFamily from '../../../../styles/typography/fontFamily';

const hideAnimationDuration = 1000;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      background: 'radial-gradient(farthest-side at 0px 0px, rgba(11,13,15,0.75) 0%, rgba(11,13,15,0.69) 35%, rgba(11,13,15,0) 100%)',
      position: 'absolute',
      top: 0,
      // Allow player title to disappear before applying the transition
      transitionDelay: '250ms !important',
      transition: `opacity ease-in-out ${hideAnimationDuration}ms`,
      opacity: 0,
      '&.show': {
        opacity: 1,
      },
    },
    container: {
      paddingTop: 40,
      paddingLeft: 40,
      // Allow player title to disappear before applying the transition
      transitionDelay: '250ms !important',
      transition: `clip-path ease-in-out  ${hideAnimationDuration}ms`,
      clipPath: 'polygon(0 0, 100% 0, 100% 0, 0 0)',
      [theme.breakpoints.down('xs')]: {
        paddingTop: 24,
        paddingLeft: 24,
      },
      '.show &': {
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
      },
    },
    classification: {
      padding: '2px 0px 2px 20px',
      fontSize: '1.5rem',

      fontFamily: fontFamily.secondary,
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
    classificationG: { borderLeft: '8px solid #15a749' },
    classificationPG: { borderLeft: '8px solid #fff100' },
    classificationM: { borderLeft: '8px solid #00adee' },
    'classificationMA15+': { borderLeft: '8px solid #ed1c24' },
    advice: {
      fontSize: '1rem',
      padding: '4px 0 4px 20px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.75rem',
      },
    },
    adviceG: { borderLeft: '8px solid #15a74950' },
    advicePG: { borderLeft: '8px solid #fff10050' },
    adviceM: { borderLeft: '8px solid #00adee50' },
    'adviceMA15+': { borderLeft: '8px solid #ed1c2450' },
  });
});

export interface ConsumerAdviceOverlayProps {
  bitmovinClient: BitmovinClient;
  /** Classification */
  classification: string;
  /** Consumer Advice */
  consumerAdvice: string[];
  /** Consumer Warnings */
  consumerWarnings: string[];
  /** Delay before fading out and is hidden */
  showTimeout?: number;
  /** The function to be executed when it goes hidden */
  onHide?(): void;
}

const ConsumerAdviceOverlay: FunctionComponent<ConsumerAdviceOverlayProps> = (props) => {
  const {
    classification, consumerAdvice, consumerWarnings, showTimeout = 5000,
    onHide, bitmovinClient,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation('common');
  const componentRef = useRef<HTMLDivElement>();

  useEffect(() => {
    let hideTimeoutId;

    const hide = () => {
      hideTimeoutId = clearTimeout(hideTimeoutId);
      if (componentRef.current) {
        componentRef.current.classList.remove('show');
      }
    };

    const show = () => {
      if (
        !hideTimeoutId
        && bitmovinClient.playerEvents.state.isAd === false
        && bitmovinClient.playerEvents.state.contentPlayStarted === true
        && bitmovinClient.state.areControlsShown === false
      ) {
        componentRef.current.classList.add('show');

        hideTimeoutId = setTimeout(() => {
          hide();
          if (componentRef.current) {
            componentRef.current.addEventListener('transitionend', () => {
              onHide();
            });
          }
        }, showTimeout);
      }
    };

    // when the main content starts playing for the first time, show the consumer advice
    bitmovinClient.on('ContentStarted', show);

    // when ad is started, hide the consumer advice
    bitmovinClient.on('AdStarted', hide);

    // when ad is finished, show the consumer advice
    bitmovinClient.on('AdBreakFinished', show);

    const uiManager: UIManager = get(bitmovinClient, 'uiManager');
    const currentUi = get(uiManager, 'currentUi');

    if (currentUi) {
      // when control is shown, hide the consumer advice
      currentUi.onControlsShow.subscribe(hide);

      // when control is hidden, show the consumer advice
      currentUi.onControlsHide.subscribe(show);
    }

    return () => {
      if (bitmovinClient.playerEvents) {
        bitmovinClient.off('AdStarted', hide);
        bitmovinClient.off('AdBreakFinished', show);
        bitmovinClient.off('ContentStarted', show);
      }

      if (currentUi) {
        currentUi.onControlsShow.unsubscribe(hide);
        currentUi.onControlsHide.unsubscribe(show);
      }
    };
  }, [bitmovinClient, onHide, showTimeout]);

  const genAdvice = (classificationCode: string, advice: string, label: string = undefined) => {
    const text = label || advice;
    return (
      <div
        key={advice}
        className={clsx(classes.advice, classes[`advice${classificationCode}`])}
      >
        {text}
      </div>
    );
  };

  return (
    <Box clone>
      <div ref={componentRef} className={clsx(classes.root)} data-testid="consumer-advice-card">
        <Box className={clsx(classes.container)}>
          <Box className={clsx(classes.classification, classes[`classification${classification}`])}>
            {classification}
          </Box>
          {consumerAdvice?.map((advice) => {
            return genAdvice(classification, advice);
          })}
          {consumerWarnings?.map((warning) => {
            return genAdvice(classification, warning);
          })}
          {consumerAdvice?.length === 0
            && consumerWarnings?.length === 0
            && genAdvice(classification, t(`classification.${classification}`))}
        </Box>
      </div>
    </Box>
  );
};

export default ConsumerAdviceOverlay;
