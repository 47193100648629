import {
  BufferingOverlay,
  Container,
  ErrorMessageOverlay,
  SeekBar,
  SubtitleOverlay,
  PlayerUtils,
  PlaybackTimeLabelMode,
  PlaybackTimeLabel,
  VolumeToggleButton,
  VolumeSlider,
  FullscreenToggleButton,
  CloseButton,
  SeekBarLabel,
} from '@sbs/bitmovin-player-ui';
import i18n from 'i18next';

import ControlsToggleOverlay from './components/ControlsToggleOverlay';
import EpisodePickerPanel from './components/EpisodePickerPanel';
import EpisodePickerToggleButton from './components/EpisodePickerToggleButton';
import PictureInPictureToggleButton from './components/PictureInPictureToggleButton';
import PlaybackButtonGroup from './components/PlaybackButtonGroup';
import PlaybackToggleOverlay from './components/PlaybackToggleOverlay';
import SbsControlBar from './components/SbsControlBar';
import SbsErrorContainer from './components/SbsErrorContainer';
import { SbsUIContainer } from './components/SbsUIContainer';
import SeekBackButton from './components/SeekBackButton';
import SeekForwardButton from './components/SeekForwardButton';
import SettingsHoverToggleButton from './components/SettingsHoverToggleButton';
import SubtitleAudioSettingsPanel from './components/SubtitleAudioSettingsPanel';
import VideoQualitySettingsPanel from './components/VideoQualitySettingsPanel';

const hideDelay = 5000;

/**
 * Builds a container with a seek bar and time labels for current and total playback time.
 * @returns {Container} - The container containing the seek bar and time labels.
 */
function buildSeekBarWithTimeLabel() {
  const totalTimeLabel: PlaybackTimeLabel = new PlaybackTimeLabel({
    timeLabelMode: PlaybackTimeLabelMode.TotalTime,
    cssClasses: ['text-right'],
  });
  // @ts-ignore, internal to Bitmovin UI
  totalTimeLabel.getDomElement().on('keydown', (event: KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      totalTimeLabel.getDomElement().get(0).click();
    }
  });

  return new Container({
    components: [
      new PlaybackTimeLabel({
        timeLabelMode: PlaybackTimeLabelMode.CurrentTime,
        hideInLivePlayback: true,
      }),
      new SeekBar({
        label: new SeekBarLabel(),
      }),
      totalTimeLabel,
    ],
    cssClasses: ['controlbar-top'],
  });
}

/**
 * Factory object for creating various UI components for video playback.
 * @type {Object}
 */
const UIFactory = {
  /**
   * Generates a UI container with playback controls and overlays suitable for displaying ads.
   * @returns {SbsUIContainer} - The UI container for ads playback.
   */
  sbsMinimalUI(): SbsUIContainer {
    const playbackButtonGroup = new PlaybackButtonGroup();

    /**
     * Disable seek buttons on ads ui
     */
    playbackButtonGroup.getComponents().forEach((component) => {
      if (component instanceof SeekBackButton || component instanceof SeekForwardButton) {
        component.disable();
      }
    });

    const controlBar = new SbsControlBar({
      components: [
        new Container({
          components: [
            new Container({
              components: [
                new VolumeToggleButton(),
                new VolumeSlider(),
              ],
              cssClasses: ['volumegroup'],
            }),
            playbackButtonGroup,
            new Container({
              components: [
                new PictureInPictureToggleButton(),
                new FullscreenToggleButton(),
              ],
              cssClasses: ['settingsgroup'],
            }),
          ],
          cssClasses: ['controlbar-bottom'],
        }),
      ],
    });

    return new SbsUIContainer({
      components: [
        new BufferingOverlay(),
        new PlaybackToggleOverlay({ tabIndex: -1 }),
        controlBar,
      ],
      cssClasses: ['ui-skin-minimal', 'ui-sbs-skin', 'ui-sbs-skin-largescreen'],
      hideDelay,
      hidePlayerStateExceptions: [
        PlayerUtils.PlayerState.Prepared,
        PlayerUtils.PlayerState.Paused,
        PlayerUtils.PlayerState.Finished,
      ],
    });
  },

  /**
   * Generates a UI container with simplified playback controls and overlays for ads playback on small screens.
   * @param {boolean} isMobile - Indicates whether the playback is on a mobile device.
   * @returns {SbsUIContainer} - The UI container for ads playback on small screens.
   */
  sbsMinimalSmallUI(isMobile: boolean): SbsUIContainer {
    const controlBar = new SbsControlBar({
      components: [
        new Container({
          components: [
            new VolumeToggleButton(),
            new PictureInPictureToggleButton(),
            new FullscreenToggleButton(),
          ],
          cssClasses: ['controlbar-bottom'],
        }),
      ],
    });

    const playbackButtonGroup = new PlaybackButtonGroup();

    /**
     * Disable seek buttons on ads ui
     */
    playbackButtonGroup.getComponents().forEach((component) => {
      if (component instanceof SeekBackButton || component instanceof SeekForwardButton) {
        component.disable();
      }
    });

    const playbackGroupOverlay = new Container({
      components: [
        playbackButtonGroup,
      ],
      cssClasses: ['playbackgroup-overlay'],
    });

    return new SbsUIContainer({
      components: [
        new BufferingOverlay(),
        new ControlsToggleOverlay(),
        controlBar,
        playbackGroupOverlay,
      ],
      cssClasses: [
        'ui-skin-minimal',
        'ui-sbs-skin-smallscreen',
        ...(isMobile ? ['ui-sbs-skin-mobile'] : []),
      ],
      hideDelay,
      hidePlayerStateExceptions: [
        PlayerUtils.PlayerState.Prepared,
        PlayerUtils.PlayerState.Paused,
        PlayerUtils.PlayerState.Finished,
      ],
    });
  },

  /**
   * Generates a UI container with controls and overlays suitable for small screens.
   * @param {boolean} isMobile - Indicates whether the playback is on a mobile device.
   * @returns {SbsUIContainer} - The UI container for small screens.
   */
  sbsSmallUI(isMobile: boolean): SbsUIContainer {
    const videoQualitySettingsPanel = new VideoQualitySettingsPanel();
    const subtitleAudioPanel = new SubtitleAudioSettingsPanel();

    if (isMobile) {
      videoQualitySettingsPanel.addComponent(new CloseButton({
        target: videoQualitySettingsPanel,
      }));

      subtitleAudioPanel.addComponent(new CloseButton({
        target: subtitleAudioPanel,
      }));
    }

    const videoQualityToggleButton = new SettingsHoverToggleButton(
      {
        settingsPanel: videoQualitySettingsPanel,
        repositionSettingsPanel: true,
      },
    );

    const subtitleAudioToggleButton = new SettingsHoverToggleButton(
      {
        settingsPanel: subtitleAudioPanel,
        cssClasses: ['ui-subtitleaudiosettingstogglebutton'],
        ariaLabel: i18n.t('common:videoPlayer.subtitlesAndAudioDescription'),
        repositionSettingsPanel: true,
      },
    );

    const controlBar = new SbsControlBar({
      components: [
        buildSeekBarWithTimeLabel(),
        new Container({
          components: [
            new VolumeToggleButton(),
            subtitleAudioToggleButton,
            videoQualityToggleButton,
            new PictureInPictureToggleButton(),
            new FullscreenToggleButton(),
          ],
          cssClasses: ['controlbar-bottom'],
        }),
      ],
    });

    const playbackGroupOverlay = new Container({
      components: [
        new PlaybackButtonGroup(),
      ],
      cssClasses: ['playbackgroup-overlay'],
    });

    return new SbsUIContainer({
      components: [
        videoQualitySettingsPanel,
        subtitleAudioPanel,
        new SubtitleOverlay(),
        new BufferingOverlay(),
        new ControlsToggleOverlay(),
        controlBar,
        playbackGroupOverlay,
        new ErrorMessageOverlay(),
      ],
      cssClasses: [
        'ui-sbs-skin-smallscreen',
        ...(isMobile ? ['ui-sbs-skin-mobile'] : []),
      ],
      hideDelay,
    });
  },

  /**
   * Generates a UI container with controls and overlays suitable for large screens.
   * @returns {SbsUIContainer} - The UI container for large screens.
   */
  sbsUI(): SbsUIContainer {
    const videoQualitySettingsPanel = new VideoQualitySettingsPanel();
    const videoQualityToggleButton = new SettingsHoverToggleButton(
      {
        settingsPanel: videoQualitySettingsPanel,
        repositionSettingsPanel: true,
      },
    );

    const subtitleAudioPanel = new SubtitleAudioSettingsPanel();
    const subtitleAudioToggleButton = new SettingsHoverToggleButton(
      {
        settingsPanel: subtitleAudioPanel,
        cssClasses: ['ui-subtitleaudiosettingstogglebutton'],
        ariaLabel: i18n.t('common:videoPlayer.subtitlesAndAudioDescription'),
        repositionSettingsPanel: true,
      },
    );

    const episodePickerPanel = new EpisodePickerPanel({
      hidden: true,
      hideDelay: -1,
    });

    const episodePickerToggleButton = new EpisodePickerToggleButton({
      settingsPanel: episodePickerPanel,
    });

    const controlBar = new SbsControlBar({
      components: [
        episodePickerPanel,
        subtitleAudioPanel,
        videoQualitySettingsPanel,
        buildSeekBarWithTimeLabel(),
        new Container({
          components: [
            new Container({
              components: [
                new VolumeToggleButton(),
                new VolumeSlider(),
              ],
              cssClasses: ['volumegroup'],
            }),
            new PlaybackButtonGroup(),
            new Container({
              components: [
                episodePickerToggleButton,
                subtitleAudioToggleButton,
                videoQualityToggleButton,
                new PictureInPictureToggleButton(),
                new FullscreenToggleButton(),
              ],
              cssClasses: ['settingsgroup'],
            }),
          ],
          cssClasses: ['controlbar-bottom'],
        }),
      ],
    });

    return new SbsUIContainer({
      components: [
        new SubtitleOverlay(),
        new BufferingOverlay(),
        new PlaybackToggleOverlay({ tabIndex: -1 }),
        controlBar,
        new ErrorMessageOverlay(),
      ],
      cssClasses: ['ui-sbs-skin', 'ui-sbs-skin-largescreen'],
      hideDelay,
    });
  },

  /**
   * Generates a UI container for displaying error messages.
   * @returns {SbsUIContainer} - The UI container for error messages.
   */
  sbsError(): SbsUIContainer {
    return new SbsUIContainer({
      cssClasses: ['ui-skin-error'],
      components: [
        new SbsErrorContainer(),
      ],
    });
  },
};

export default UIFactory;
