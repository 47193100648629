import { VideoPlayerApi } from '@@src/pages/WatchPage';
import DataLayer from '@@utils/DataLayer';

import i18n from '../../../../i18n';
import ShowAtPlayTime from '../Utils/ShowAtPlayTime';
import VideoPlayerPluginV2WithReactPortal from '../VideoPlayerPluginV2WithReactPortal';
import SeekButton from './SeekButton';

class SkipIntroPluginV2 extends VideoPlayerPluginV2WithReactPortal {
  protected videoCardPosition = 'top' as const;

  protected skipIntroStart: number;
  protected skipIntroEnd: number;

  public constructor(videoPlayer: VideoPlayerApi) {
    super(videoPlayer);
    this.skipIntroStart = this.videoPlayer.getTrackingTime('offer_skip_opening_titles');
    this.skipIntroEnd = this.videoPlayer.getTrackingTime('opening_titles_end');
  }

  public shouldLoad(): boolean {
    return this.skipIntroStart !== undefined && this.skipIntroEnd !== undefined;
  }

  protected component() {
    const handleShow = () => {
      DataLayer.events.videoNavigation(
        'skipIntro_display',
        this.videoPlayer.getVideo(),
        this.videoPlayer.getPlayerMetadata(),
        {
          videoNavigationEvent: {
            skipIntro: 'display',
          },
        },
      );
    };

    const skipForward = () => {
      DataLayer.events.videoNavigation(
        'skipIntro_tap',
        this.videoPlayer.getVideo(),
        this.videoPlayer.getPlayerMetadata(),
        {
          videoNavigationEvent: {
            skipIntro: 'tap',
          },
        },
      );
    };

    const seekFn = (seekTo: number): void => {
      this.bitmovinClient.player.seek(this.bitmovinClient.streamTimeForContentTime(seekTo));
    };

    return (
      <ShowAtPlayTime
        bitmovinClient={this.bitmovinClient}
        startTime={this.skipIntroStart}
        endTime={this.skipIntroEnd}
        onShow={handleShow}
        autoHideDelay={10}
        autoHideAfterUiHideDelay={5}
        hideOnPause
      >
        <SeekButton seekFn={seekFn} seekTo={this.skipIntroEnd} onClick={skipForward}>
          {i18n.t('videoPlayer.skipIntro')}
        </SeekButton>
      </ShowAtPlayTime>
    );
  }
}

export default SkipIntroPluginV2;
