import { ReactElement } from 'react';

import type BitmovinClient from '../../../lib/VideoPlayer/BitmovinClient';
import type PlayerEvents from '../../../lib/VideoPlayer/PlayerEvents';
import { VideoPlayerApi } from '../../../pages/WatchPage';

abstract class VideoPlayerPluginV2 {
  protected videoPlayer: VideoPlayerApi;
  protected bitmovinClient: BitmovinClient;
  protected playerEvents: PlayerEvents;

  public constructor(videoPlayer: VideoPlayerApi) {
    this.videoPlayer = videoPlayer;
    this.bitmovinClient = this.videoPlayer.getBitmovinClient();
    this.playerEvents = this.videoPlayer.getPlayerEvents();

    const onPlayerReady = () => {
      this.onPlayerReady();
      // make sure that the onPlayerReady is only called once on the player Ready event
      this.bitmovinClient.off('Ready', onPlayerReady);
    };
    this.bitmovinClient.on('Ready', onPlayerReady);
  }

  /**
   * Return true if the video player should load the plugin, or false if it should not load.
   */
  public shouldLoad(): boolean {
    return true;
  }

  /**
   * Implement the render variable if it needs to render a React element
   */
  public render: () => ReactElement = undefined;

  /**
   * Called on destroy. Use this function to do any clean up
   */
  protected onDestroy(): void {
    // Can be implemented by the subclass
  }

  /**
   * Call this function to clean up when the plugin is destroyed
   */
  public destroy(): void {
    this.onDestroy();
  }

  /**
   * It's called when player READY event is triggered
   * @protected
   */
  protected onPlayerReady(): void {
    // Can be implemented by the subclass
  }
}

export default VideoPlayerPluginV2;
