import { isMobile } from 'react-device-detect';

import VideoPlayerPluginV2WithReactPortal from '../VideoPlayerPluginV2WithReactPortal';
import NextEpisodeEndCardPluginComponent from './NextEpisodeEndCardPluginComponent';

class NextEpisodeEndCardPluginV2 extends VideoPlayerPluginV2WithReactPortal {
  public shouldLoad(): boolean {
    return !isMobile && !!this.videoPlayer.getNextVideo();
  }

  public component() {
    return (
      <NextEpisodeEndCardPluginComponent
        bitmovinClient={this.bitmovinClient}
        nextEpisode={this.videoPlayer.getNextVideo()}
        playerMetadata={this.videoPlayer.getPlayerMetadata()}
      />
    );
  }
}

export default NextEpisodeEndCardPluginV2;
