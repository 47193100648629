/**
 * Overrides Bitmovin's AudioTrackUtils class
 * This adds support for a "Default" audio track if none are available (the audio that's embedded in the video)
 */
import { UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { ListItem, ListSelector, ListSelectorConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/listselector';
import { i18n } from '@sbs/bitmovin-player-ui/dist/js/framework/localization/i18n';
import { PlayerAPI, AudioTrackEvent, AudioTrack } from 'bitmovin-player';

/**
 * Helper class to handle all audio tracks related events
 *
 * This class listens to player events as well as the `ListSelector` event if selection changed
 */
export default class OdAudioTrackSwitchHandler {
  public static DEFAULT_AUDIO_TRACK_LABEL: string = 'Default';

  private static DEFAULT_AUDIO_TRACK_ID: string = 'null';
  private player: PlayerAPI;
  private listElement: ListSelector<ListSelectorConfig>;
  private uimanager: UIInstanceManager;

  constructor(player: PlayerAPI, element: ListSelector<ListSelectorConfig>, uimanager: UIInstanceManager) {
    this.player = player;
    this.listElement = element;
    this.uimanager = uimanager;

    this.bindSelectionEvent();
    this.bindPlayerEvents();
    this.refreshAudioTracks();
  }

  private bindSelectionEvent(): void {
    this.listElement.onItemSelected.subscribe((_, value: string) => {
      this.player.setAudio(value);
    });
  }

  private bindPlayerEvents(): void {
    // Update selection when selected track has changed
    this.player.on(this.player.exports.PlayerEvent.AudioChanged, this.selectCurrentAudioTrack);
    // Update tracks when source goes away
    this.player.on(this.player.exports.PlayerEvent.SourceUnloaded, this.refreshAudioTracks);
    // Update tracks when the period within a source changes
    this.player.on(this.player.exports.PlayerEvent.PeriodSwitched, this.refreshAudioTracks);
    // Update tracks when a track is added or removed
    this.player.on(this.player.exports.PlayerEvent.AudioAdded, this.addAudioTrack);
    this.player.on(this.player.exports.PlayerEvent.AudioRemoved, this.removeAudioTrack);
    this.uimanager.getConfig().events.onUpdated.subscribe(this.refreshAudioTracks);
  }

  private addAudioTrack = (event: AudioTrackEvent) => {
    const audioTrack = event.track;

    // When an actual audio track is added, remove the "Default" item before adding the new track.
    if (this.listElement.hasItem(OdAudioTrackSwitchHandler.DEFAULT_AUDIO_TRACK_ID)) {
      this.listElement.removeItem(OdAudioTrackSwitchHandler.DEFAULT_AUDIO_TRACK_ID);
    }

    if (!this.listElement.hasItem(audioTrack.id)) {
      this.listElement.addItem(audioTrack.id, i18n.getLocalizer(audioTrack.label));

      const currentAudioTrack = this.player.getAudio();

      if (currentAudioTrack && audioTrack.id === currentAudioTrack.id) {
        this.listElement.selectItem(currentAudioTrack.id);
      }
    }
  };

  private removeAudioTrack = (event: AudioTrackEvent) => {
    const audioTrack = event.track;
    if (this.listElement.hasItem(audioTrack.id)) {
      this.listElement.removeItem(audioTrack.id);
    }
  };

  private selectCurrentAudioTrack = () => {
    const currentAudioTrack = this.player.getAudio();

    // HLS streams don't always provide this, so we have to check
    if (currentAudioTrack) {
      this.listElement.selectItem(currentAudioTrack.id);
    } else {
      this.listElement.selectItem(OdAudioTrackSwitchHandler.DEFAULT_AUDIO_TRACK_ID);
    }
  };

  private refreshAudioTracks = () => {
    const audioTracks = this.player.getAvailableAudio();
    if (audioTracks.length > 0) {
      const audioTrackToListItem = (audioTrack: AudioTrack): ListItem => {
        return { key: audioTrack.id, label: audioTrack.label };
      };

      this.listElement.synchronizeItems(audioTracks.map(audioTrackToListItem));
    } else if (!this.listElement.hasItem(OdAudioTrackSwitchHandler.DEFAULT_AUDIO_TRACK_ID)) {
      // If no audio tracks are available, add a "Default" item to the menu
      // At the moment, since we don't know the language of the default track, it's been decided to hide the whole
      // audio track menu by default with CSS and only show it when tracks are detected ('Ready' handler in BitmovinClient.ts)
      this.listElement.addItem(
        OdAudioTrackSwitchHandler.DEFAULT_AUDIO_TRACK_ID,
        OdAudioTrackSwitchHandler.DEFAULT_AUDIO_TRACK_LABEL,
      );
    }

    this.selectCurrentAudioTrack();
  };
}
