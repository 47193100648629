import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, ReactElement } from 'react';

import orange from '@@styles/colors/orange';
import fontFamily from '@@styles/typography/fontFamily';

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      bottom: 0,
      color: 'white',
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      fontSize: '1.125rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.875rem',
      },
      zIndex: 99,
      pointerEvents: 'none',
    },
    container: {
      position: 'absolute',
      bottom: 'calc(33% - 4vw - 3vh)',
      left: 'calc(100vw * 0.1234)',
      width: '60%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 900,
        left: 0,
        paddingLeft: 30,
        paddingRight: 30,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        left: 0,
        paddingLeft: 30,
        paddingRight: 30,
      },
      '@media only screen and (max-height: 600px)': {
        bottom: 80,
      },
      '@media only screen and (max-height: 400px)': {
        top: 0,
        bottom: 'auto',
        paddingTop: 20,
        paddingBottom: 10,
        height: '100%',
        overflow: 'auto',
      },
    },
    cardTitle: {
      fontSize: '1.25rem',
      fontWeight: 500,
      color: orange.darkTangerine,
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1em',
      },

    },
    imageDimmer: {
      width: '100%',
      height: '100%',
      background: 'linear-gradient(-90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%)',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    videoTitle: {
      fontFamily: fontFamily.ubuntu,
      fontWeight: 'bold',
      fontSize: '3.75rem',
      lineHeight: 1.1,
      margin: '8px 0 16px 0',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      [theme.breakpoints.down('lg')]: {
        fontSize: '3.25rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.5rem',
      },
      '@media only screen and (max-height: 400px)': {
        marginBottom: 8,
      },
    },
    videoDescription: {
      marginTop: '1em',
      fontWeight: 'normal',
      lineHeight: 1.5,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 4,
      '-webkit-box-orient': 'vertical',
      '@media only screen and (max-height: 780px)': {
        '-webkit-line-clamp': 3,
      },
      '@media only screen and (max-height: 400px)': {
        '-webkit-line-clamp': 2,
        marginTop: '0.5em',
      },
    },
    buttons: {
      pointerEvents: 'auto',
      marginTop: '1.5rem',
    },
  });
});

export interface EndCardProps {
  backgroundImageUrl: string;
  cardTitle: string;
  videoTitle: string;
  description: string;
  metadata?: ReactElement;
  buttons?: ReactElement;
  classes?: {
    container: string;
  };
}

const EndCard: FunctionComponent<EndCardProps> = (props) => {
  const {
    backgroundImageUrl,
    cardTitle,
    videoTitle,
    description,
    metadata,
    buttons,
  } = props;

  const classes = useStyles(props);

  return (
    <div className={classes.root} style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
      <div className={classes.imageDimmer}/>
      <div className={classes.container}>
        <div className={classes.cardTitle}>{cardTitle}</div>
        <div className={classes.videoTitle}>{videoTitle}</div>
        {metadata}
        <div className={classes.videoDescription}>{description}</div>
        <div className={classes.buttons}>
          {buttons}
        </div>
      </div>
    </div>
  );
};

export default EndCard;
