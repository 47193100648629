import { RecommendationData } from '@@stores/RecommendationStore';
import OnDemand from '@@types/OnDemand';
import { PersonalisationData } from '@@utils/DataLayer';

interface RecommendationTrackingData {
  personalisation: PersonalisationData | undefined;
  recommendationTargetId: string;
}

export function generateRecommendationTrackingData(recommendationData: RecommendationData | null, video: OnDemand.Video): RecommendationTrackingData {
  let personalisation: PersonalisationData | undefined;
  let recommendationTargetId: string | undefined;

  if (recommendationData) {
    if (video.type === 'Episode') {
      recommendationTargetId = video.episodeData?.seriesId || '';
    } else {
      recommendationTargetId = video.catalogueId;
    }

    if (recommendationData.target.id === recommendationTargetId) {
      const { recommendationId, recommendationVariantName } = recommendationData;
      personalisation = { recommendationId, recommendationVariantName };
    }
  }

  return {
    personalisation,
    recommendationTargetId,
  };
}

export default {
  generateRecommendationTrackingData,
};
