import { FunctionComponent, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { VideoPlayerPluginProps } from '@@src/lib/VideoPlayerV2/plugins/VideoPlayerPlugin';
import { getRecommendation, clearMatchingRecommendation } from '@@stores/RecommendationStore';
import OnDemand from '@@types/OnDemand';
import { PersonalisationData } from '@@utils/DataLayer';
import { generateRecommendationTrackingData } from '@@utils/Recommendations';

import VideoPlaybackTrackingCore from './VideoPlaybackTrackingCore';

const VideoPlaybackTracking: FunctionComponent<VideoPlayerPluginProps & { video: OnDemand.Movie | OnDemand.Episode | OnDemand.OneOff | null }> = (props) => {
  const { videoPlayer, playbackStreamData, video } = props;
  const dispatch = useAppDispatch();
  const recommendation = useAppSelector(getRecommendation);

  useEffect(() => {
    if (!video && !playbackStreamData) {
      return () => {};
    }

    let personalisation: PersonalisationData | undefined;
    let recommendationTargetId: string | undefined;

    if (video) {
      const td = generateRecommendationTrackingData(recommendation, video);
      personalisation = td.personalisation;
      recommendationTargetId = td.recommendationTargetId;
    }

    const tracker = new VideoPlaybackTrackingCore(videoPlayer, video, playbackStreamData, personalisation);

    return () => {
      if (recommendationTargetId) {
        dispatch(clearMatchingRecommendation({ targetId: recommendationTargetId }));
      }
      tracker.destroy();
    };
  }, [videoPlayer, playbackStreamData, video, recommendation, dispatch]);

  // This plugin does not have a UI
  return null;
};

export default VideoPlaybackTracking;
