import { get, isEmpty } from 'lodash';

import OnDemand from '@@types/OnDemand';
import VideoStream from '@@types/VideoStream';

import { FeedItem } from '../apis/FeedApi';
import { VideoStreamApi } from '../apis/VideoStreamApi';
import { transformToVideoItem } from './FeedTransformer';

export interface SideCar {
  name: string;
  language: string;
  url: string;
  isForced: boolean;
}

/**
 * Transform video config api response to our config
 * @TODO refactor this and related code for proper support of all stream providers and fallback progressively
 *
 * @param VideoStreamApiResponse
 */
function transform(VideoStreamApiResponse: VideoStreamApi.Response): VideoStream.DaiStream {
  const supportedStreamProviders = [];
  let daiProviderAccountId = '';
  let mainContentUrl = '';
  let fallbackContentUrl = '';
  const subtitleSidecarFiles:SideCar[] = [];

  for (let si = 0; si < VideoStreamApiResponse.streamProviders.length; si += 1) {
    const streamProvider = VideoStreamApiResponse.streamProviders[si];
    let textTracks;

    switch (streamProvider.type) {
      case 'GoogleDAIProvider':
        supportedStreamProviders.push(streamProvider);
        daiProviderAccountId = streamProvider.providerAccountId;
        break;

      case 'AkamaiHLSProvider':
        supportedStreamProviders.push(streamProvider);

        if (supportedStreamProviders.length > 1) {
          fallbackContentUrl = streamProvider.contentUrl;
        } else {
          mainContentUrl = streamProvider.contentUrl;
        }

        textTracks = get(streamProvider, 'textTracks', []);
        textTracks.forEach((textTrack) => {
          const sidecar = {
            name: textTrack.name,
            language: textTrack.language,
            url: textTrack.contentUrl,
            isForced: false,
          };

          const attributes = get(textTrack, 'attributes', []);
          attributes.forEach((attribute) => {
            if (attribute === 'FORCED=YES') {
              sidecar.isForced = true;
            }
          });

          subtitleSidecarFiles.push(sidecar);
        });

        break;

      default:
        // We currently don't support the other providers
        break;
    }
  }

  if (supportedStreamProviders.length === 0) {
    throw new Error('Cannot find any supported StreamProviders');
  }

  function formatAdTag({ customParameters, ...rest }) {
    const custParams = Object.keys(customParameters).map((key) => {
      return `${key}=${customParameters[key]}`;
    }).join('&');
    return {
      cust_params: custParams,
      ...rest,
      ipaddress: get(customParameters, 'ipaddress', ''),
    };
  }

  const trackingEvents = get(VideoStreamApiResponse, 'trackingEvents');
  const nextVideoItem = get(VideoStreamApiResponse, 'nextVideoItem');
  const nextVideo = nextVideoItem ? transformToVideoItem<FeedItem.Episode, OnDemand.Episode>(nextVideoItem) : null;
  const streamTrackingEvents: VideoStream.TrackingEvents = {};

  if (trackingEvents) {
    for (let ei = 0; ei < trackingEvents.length; ei += 1) {
      const event = trackingEvents[ei];

      if (!isEmpty(event)) {
        streamTrackingEvents[event.event_type] = event.event_seconds;
      }
    }
  }

  const video = transformToVideoItem(VideoStreamApiResponse.videoItem);
  const recommendationApi = get(VideoStreamApiResponse, 'recommendedContent', null);

  return {
    streamProviderType: supportedStreamProviders[0].type,
    videoStreamType: supportedStreamProviders[0].videoStreamType,
    providerAccountId: daiProviderAccountId,
    videoId: VideoStreamApiResponse.streamProviders[0].videoId,
    thumbnail: VideoStreamApiResponse.videoItem.thumbnailUrl,
    contentUrl: mainContentUrl,
    fallbackContentUrl,
    subtitleSidecarFiles,
    title: VideoStreamApiResponse.name,
    convivaAssetMetadata: VideoStreamApiResponse.conviva,
    oztamMetadata: VideoStreamApiResponse.oztam,
    cast: VideoStreamApiResponse.videoItem.actor.map((o) => { return o.name; }),
    languages: VideoStreamApiResponse.videoItem.inLanguage.map((o) => { return o.name; }),
    directors: VideoStreamApiResponse.videoItem.director.map((o) => { return o.name; }),
    genres: video.genres,
    advertisement: null,
    // @ts-ignore not sure how to type this
    adTag: formatAdTag(get(VideoStreamApiResponse, 'adTag')),
    video,
    nextVideo,
    recommendationApi,
    trackingEvents: streamTrackingEvents,
    rawVideo: VideoStreamApiResponse.videoItem,
    ...(nextVideoItem && { rawNextVideo: nextVideoItem }),
    ...(VideoStreamApiResponse.trackingEvents && { rawTrackingEvents: VideoStreamApiResponse.trackingEvents }),
    chromecast: {
      receiver: VideoStreamApiResponse.chromecast.receiver,
      controller: {
        backgroundImage: VideoStreamApiResponse.chromecast.controller.background_landscape,
      },
    },
    bidding: get(VideoStreamApiResponse, 'bidding', null),
  };
}

export default transform;
