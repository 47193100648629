import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@@src/components/Inputs/Button';
import GoogleDaiStreamHandler from '@@src/lib/VideoPlayerV2/StreamHandlers/GoogleDaiStreamHandler';
import {
  VideoPlayerEvent,
  VideoPlayerEventCallback,
  VideoPlayerEventType,
} from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import { VideoPlayerPluginProps } from '@@src/lib/VideoPlayerV2/plugins/VideoPlayerPlugin';
import blue from '@@styles/colors/blue';

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      display: 'flex',
      zIndex: 9999,
      left: 39,
      alignItems: 'center',
      transition: 'bottom 250ms ease-in-out',
      bottom: 30,
      '&.rise': {
        bottom: 120,
      },
      '&.riseHigher': {
        bottom: 180,
      },
      [theme.breakpoints.down('sm')]: {
        bottom: 15,
        '&.rise': {
          bottom: 15,
        },
        '&.riseHigher': {
          bottom: 120,
        },
      },
      [theme.breakpoints.down('xs')]: {
        bottom: 15,
        '&.rise': {
          bottom: 65,
        },
        '&.riseHigher': {
          bottom: 130,
        },
      },
    },
    adMessageTitle: {
      fontWeight: 'bold',
      textShadow: '2px 2px 4px black',
    },
    adMessage: {
      minWidth: 145,
      minHeight: 21,
      marginRight: 12,
      textShadow: '2px 2px 4px black',
      fontSize: '0.875em',
    },
    adButton: {
      marginLeft: 30,
      paddingTop: 3,
      paddingBottom: 3,
      fontSize: '0.75rem',
      opacity: 0.7,
      '&:focus-visible': {
        outline: `3px solid ${blue.navy}`,
      },
    },
  });
});

interface AdControlsProps extends VideoPlayerPluginProps {
  adClickElementId?: string;
}

const AdControls: FunctionComponent<AdControlsProps> = (props) => {
  const { videoPlayer, playbackStreamData, adClickElementId = 'adclick' } = props;
  const classes = useStyles(props);
  const clickElementRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('common');
  const [shouldHide, setShouldHide] = useState<boolean>(true);
  const [shouldRise, setShouldRise] = useState<boolean>(false);
  const [riseLevel, setRiseLevel] = useState<'low' | 'high'>('low');
  const [remainingTime, setRemainingTime] = useState<number>(0);

  useEffect(() => {
    const streamHandler = videoPlayer.getStreamHandler();
    if (
      clickElementRef.current
      && streamHandler instanceof GoogleDaiStreamHandler
    ) {
      streamHandler.setClickElement(clickElementRef.current);
    }

    const onAdProgress = (event: VideoPlayerEvent<VideoPlayerEventType.AD_PROGRESS>) => {
      if (event.remainingTime) {
        setRemainingTime(event.remainingTime);
        setShouldHide(false);
      } else {
        setShouldHide(true);
      }
    };

    const onAdBreakFinished = () => {
      setShouldHide(true);
      setRemainingTime(0);
    };

    const onControlsShow = () => {
      setShouldRise(true);
    };

    const onControlsHide = () => {
      setShouldRise(false);
    };

    const onPlayerError = () => {
      setShouldRise(false);
      setShouldHide(true);
    };

    const onAdHolidayStarted = () => {
      setRiseLevel('high');
    };

    const onAdHolidayFinished = () => {
      setRiseLevel('low');
    };

    videoPlayer.on(VideoPlayerEventType.AD_PROGRESS, onAdProgress as VideoPlayerEventCallback);
    videoPlayer.on(VideoPlayerEventType.AD_BREAK_FINISHED, onAdBreakFinished);
    videoPlayer.on(VideoPlayerEventType.UI_CONTROLS_SHOW, onControlsShow);
    videoPlayer.on(VideoPlayerEventType.UI_CONTROLS_HIDE, onControlsHide);
    videoPlayer.on(VideoPlayerEventType.PLAYER_ERROR, onPlayerError);
    videoPlayer.on(VideoPlayerEventType.AD_HOLIDAY_STARTED, onAdHolidayStarted);
    videoPlayer.on(VideoPlayerEventType.AD_HOLIDAY_FINISHED, onAdHolidayFinished);

    return () => {
      videoPlayer.off(VideoPlayerEventType.PLAYER_ERROR, onPlayerError);
      videoPlayer.off(VideoPlayerEventType.AD_PROGRESS, onAdProgress as VideoPlayerEventCallback);
      videoPlayer.off(VideoPlayerEventType.AD_BREAK_FINISHED, onAdBreakFinished);
      videoPlayer.off(VideoPlayerEventType.UI_CONTROLS_SHOW, onControlsShow);
      videoPlayer.off(VideoPlayerEventType.UI_CONTROLS_HIDE, onControlsHide);
      videoPlayer.off(VideoPlayerEventType.AD_HOLIDAY_STARTED, onAdHolidayStarted);
      videoPlayer.off(VideoPlayerEventType.AD_HOLIDAY_FINISHED, onAdHolidayFinished);

      setShouldHide(true);
      setShouldRise(false);
      setRemainingTime(0);
    };
  }, [videoPlayer, playbackStreamData]);

  const handleLearnMoreClick = useCallback(() => {
    videoPlayer.pause();
  }, [videoPlayer]);

  // We are hiding with a CSS class instead of returning null because the "Learn more" button needs to be rendered
  // in the DOM so that we can pass it to the DAI SDK.
  return (
    <div
      className={clsx(
        classes.root,
        { hide: shouldHide },
        { rise: shouldRise && riseLevel === 'low' },
        { riseHigher: shouldRise && riseLevel === 'high' },
      )}
    >
      <div>
        <div className={classes.adMessageTitle}>
          {t('videoPlayer.advertisement')}
        </div>
        <div
          className={clsx(classes.adMessage)}
          data-testid="ad-message"
        >
          {remainingTime > 0 && t('videoPlayer.adTimeRemaining', {
            count: remainingTime,
            formatParams: {
              count: {
                round: false,
                delimiter: ' ',
                spacer: remainingTime <= 60 ? ' ' : '',
                units: ['h', 'm', 's'],
                shortUnits: remainingTime > 60,
              },
            },
          })}
        </div>
      </div>
      <div className="adclick" id={adClickElementId}>
        <Button
          ref={clickElementRef}
          buttonType="secondary"
          size="small"
          classes={{ root: classes.adButton }}
          onClick={handleLearnMoreClick}
        >
          {t('videoPlayer.learnMore')}
        </Button>
      </div>
    </div>
  );
};

export default AdControls;
