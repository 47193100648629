import ReactSettingsPanel from './ReactSettingsPanel';

export default class EpisodePickerPanel extends ReactSettingsPanel {
  constructor(config = {}) {
    super({
      ...config,
      cssClasses: ['ui-episodepickerreactpanel'],
    });
  }
}
