import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import BitmovinClient from '@@src/lib/VideoPlayer/BitmovinClient';
import { PlayerMetadata } from '@@src/pages/WatchPage';
import OnDemand from '@@types/OnDemand';
import { PlaybackStreamData } from '@@types/PlaybackStreamData';
import DataLayer from '@@utils/DataLayer';

import NextEpisodeCard from './NextEpisodeCard';

interface NextEpisodeEndCardComponentProps {
  bitmovinClient: BitmovinClient;
  nextEpisode: OnDemand.Episode;
  playerMetadata: PlayerMetadata;
  playbackStreamData?: PlaybackStreamData;
}

const NextEpisodeEndCardPluginComponent: FunctionComponent<NextEpisodeEndCardComponentProps> = (props) => {
  const {
    bitmovinClient,
    nextEpisode,
    playerMetadata,
    playbackStreamData,
  } = props;

  const theme = useTheme();
  const mediaMatchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [show, setShow] = useState<boolean>(false);

  const showCard = useCallback(() => {
    setShow(true);
    DataLayer.events.playNextEpisode(
      'promoCardDisplay',
      { ...nextEpisode, entityType: playbackStreamData?.entityType },
      playerMetadata,
      {
        videoNavigationEvent: {
          playNextEpisode: 'promoCardDisplay',
        },
      },
    );
  }, [nextEpisode, playbackStreamData, playerMetadata]);

  useEffect(() => {
    const handleCastFinished = () => {
      if (show) {
        bitmovinClient.player.unload();
      }
    };

    if (bitmovinClient.playerEvents) {
      bitmovinClient.on('PlayFinished', showCard);
      bitmovinClient.on('CastFinished', handleCastFinished);
    }

    return () => {
      if (bitmovinClient.playerEvents) {
        bitmovinClient.off('PlayFinished', showCard);
        bitmovinClient.off('CastFinished', handleCastFinished);
      }
    };
  }, [bitmovinClient, showCard, show]);

  const handleClickPlay = useCallback(() => {
    setShow(false);

    DataLayer.events.playNextEpisode(
      'promoCardTap',
      { ...nextEpisode, entityType: playbackStreamData?.entityType },
      playerMetadata,
      {
        videoNavigationEvent: {
          playNextEpisode: 'promoCardTap',
        },
      },
    );
  }, [nextEpisode, playbackStreamData, playerMetadata]);

  if (!show || mediaMatchesSmDown) {
    return null;
  }

  return (
    <NextEpisodeCard episode={nextEpisode} onClickPlay={handleClickPlay}/>
  );
};

export default NextEpisodeEndCardPluginComponent;
