/**
 * Custom audio track list, see OdAudioTrackUtils.ts for the main code
 */
import { ListBox, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { PlayerAPI } from 'bitmovin-player';

import OdAudioTrackSwitchHandler from './AudioTrackUtils';

/**
 * A element that is similar to a select box where the user can select a subtitle
 */
export default class AudioTrackListBox extends ListBox {
  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    this.onItemRemoved.subscribe(this.handleItemRemoved);

    super.configure(player, uimanager);
    // eslint-disable-next-line no-new
    new OdAudioTrackSwitchHandler(player, this, uimanager);
  }

  private handleItemRemoved = (_, key) => {
    // if the item removed is in selected item, remove it
    if (key === this.selectedItem) {
      this.selectedItem = null;
    }
  };
}
