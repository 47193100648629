import { FunctionComponent, useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { VideoPlayerPluginProps } from '@@src/lib/VideoPlayerV2/plugins/VideoPlayerPlugin';
import { dispatchSetVideoProgress } from '@@src/utils/helpers';
import { setVideoProgress } from '@@stores/ProgressStore';
import { getIsLoggedIn } from '@@stores/UserStore';

import SaveProgressCore from './SaveProgressCore';

const SaveProgress: FunctionComponent<VideoPlayerPluginProps> = (props) => {
  const { videoPlayer, playbackStreamData, video } = props;
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const dispatch = useAppDispatch();

  const recordProgress = useCallback((duration: number, position: number, id: string) => {
    if (isLoggedIn && duration > 600) {
      dispatchSetVideoProgress(duration, position, id, dispatch, setVideoProgress);
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    let recorder: SaveProgressCore;

    if (video) {
      recorder = new SaveProgressCore(videoPlayer, video, recordProgress);
    }

    return () => {
      if (recorder instanceof SaveProgressCore) {
        recorder.destroy();
      }
    };
  }, [videoPlayer, playbackStreamData, video, recordProgress]);

  // This plugin does not have a UI
  return null;
};

export default SaveProgress;
