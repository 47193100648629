import { Box, BoxProps, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { Duration } from 'luxon';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Play from '@@icons/Play';
import PlayButton from '@@src/components/Video/PlayButton';
import DataLayer from '@@utils/DataLayer';
import { onButtonKeyListener } from '@@utils/helpers';

import { durationShortFormatParams } from '../../i18n';
import blue from '../../styles/colors/blue';
import grey from '../../styles/colors/grey';
import orange from '../../styles/colors/orange';
import fontFamily from '../../styles/typography/fontFamily';
import VideoProgressBar from '../ProgressBar/VideoProgressBar';

const useStyles = makeStyles<Theme, PlayerEpisodePickerTileProps>(() => {
  return createStyles({
    root: {
      width: '100%',
      display: 'block',
      transition: 'background-color 0.2s ease-in-out',
      '&:focus': {
        outline: `3px solid ${blue.navy}`,
      },
      '&.clickable': {
        cursor: 'pointer',
        '&:focus $playButtonOverlay': {
          opacity: 1,
        },
      },
    },
    watchingBadge: {
      position: 'absolute',
      top: 0,
      left: 0,
      fontWeight: 500,
      fontSize: '0.875rem',
      fontFamily: fontFamily.secondary,
      borderRadius: '0 0 16px 0',
      padding: '2px 12px 2px 4px',
      backgroundColor: alpha(grey.blackpearl, 0.9),
    },
    watchingPlayIcon: {
      fontSize: '0.875rem',
      position: 'relative',
      top: 2, // offset to center the icon relative to the font
      margin: '0 3px 0 0',
      color: orange.darkTangerine,
    },
    imageContainer: {
      position: 'relative',
      aspectRatio: '16/9',
      borderRadius: 4,
      overflow: 'hidden',
      '&:focus': {
        outline: 'none',
      },
    },
    image: {
      display: 'block',
      width: '100%',
    },
    content: {
      padding: 10,
    },
    title: {
      overflow: 'hidden',
      fontWeight: 500,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '1rem',
      lineHeight: 1.2,
    },
    metadata: {
      marginTop: 8,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      color: grey.chateau,
      display: 'flex',
      fontSize: '0.875rem',
      lineHeight: 1.1,
      minHeight: '1.2em',
    },
    playButton: {
      '$root:hover &': {
        opacity: 0.8,
      },
      '$root:hover &:hover &:focus': {
        opacity: 0.9,
      },
    },
    playButtonOverlay: {
      '$root:hover &': {
        opacity: 1,
      },
    },
  });
});

export interface PlayerEpisodePickerTileProps {
  /**
   * The mpx id of the episode.
   */
  mpxId: string;
  /**
   * The episode number of the episode.
   */
  episodeNumber: number;
  /**
   * The title of the episode.
   */
  title: string;
  /**
   * The duration of the episode.
   */
  duration: number;
  /**
   * The image url of the episode.
   */
  imageUrl: string;
  /**
   * The state whether user is currently watching this episode. If `true`, it will show the watching badge and the tile is not clickable. Default to `false`.
   */
  isWatching?: boolean;
}

const PlayerEpisodePickerTile: FunctionComponent<PlayerEpisodePickerTileProps> = (props) => {
  const {
    episodeNumber,
    title,
    duration,
    imageUrl,
    mpxId,
    isWatching = false,
  } = props;
  const classes = useStyles(props);

  const { t } = useTranslation('common');

  // get duration iso for date/timestamp
  const durationISO = Duration.fromObject({ hours: 0, minutes: 0, seconds: duration })
    .normalize();

  const boxProps: BoxProps = { component: 'div', tabIndex: isWatching ? -1 : 0 };

  // wrapper is created to get around typescript error on LinkPropsType which is incompatible with the BoxProps
  // although it is valid if the component is rendering a Link component
  const BoxWrapper = useCallback((containerProps) => {
    return <Box {...containerProps}/>;
  }, []);

  const handleBoxClick = useCallback(() => {
    if (!isWatching) {
      DataLayer.events.episodePickerSelectEpisode(title, mpxId);
      const event = new CustomEvent('OdPlayerVideoChange', {
        detail: {
          videoId: mpxId,
          allowResume: true,
        },
      });
      document.dispatchEvent(event);
    }
  }, [isWatching, title, mpxId]);

  return (
    <BoxWrapper
      className={clsx(classes.root, !isWatching && 'clickable')}
      {...boxProps}
      onClick={!isWatching ? handleBoxClick : undefined}
      onKeyDown={!isWatching ? onButtonKeyListener(handleBoxClick) : undefined}
      role="button"
      tabIndex={!isWatching ? 0 : -1}
    >
      <div className={classes.imageContainer}>
        {
          isWatching && (
            <div className={classes.watchingBadge}>
              <Play className={classes.watchingPlayIcon}/>
              Watching
            </div>
          )
        }
        <img className={classes.image} src={imageUrl} alt={title}/>
        {
          !isWatching && (
            <PlayButton overlay size={58} classes={{ playIcon: classes.playButton, overlay: classes.playButtonOverlay }}/>
          )
        }
        <VideoProgressBar videoId={mpxId} position="bottom" transparent/>
      </div>
      <div className={classes.content}>
        <Typography variant="body1" className={classes.title}>
          {`${episodeNumber}. ${title}`}
        </Typography>
        <time
          dateTime={durationISO.toISO()}
          aria-label={t('video.duration', {
            duration,
            formatParams: {
              duration: durationShortFormatParams,
            },
          })}
          className={classes.metadata}
        >
          <span>
            {t('video.duration', {
              duration,
              formatParams: {
                duration: durationShortFormatParams,
              },
            })}
          </span>
        </time>
      </div>
    </BoxWrapper>
  );
};

export default PlayerEpisodePickerTile;
