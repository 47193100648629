import { ButtonBase, FormControl, Grid, ListItemIcon, MenuItem, Select } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { get, find, round, findIndex, debounce } from 'lodash';
import { FunctionComponent, useEffect, useState, useCallback, useRef, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import SwipeListener from 'swipe-listener';

import ShelfTitle from '@@src/components/Shelf/ShelfTitle';
import { useAppDispatch } from '@@src/hooks/store';
import { getSeries } from '@@stores/SeriesStore';
import { getVideoAsyncThunk } from '@@stores/VideoStore';
import OnDemand from '@@types/OnDemand';
import DataLayer from '@@utils/DataLayer';
import Logger from '@@utils/logger/Logger';

import { ReactComponent as RightArrowIcon } from '../../images/icons/right-arrow.svg';
import { ReactComponent as TickIcon } from '../../images/icons/tick.svg';
import { ReactComponent as UpChevronIcon } from '../../images/icons/up-chevron.svg';
import blue from '../../styles/colors/blue';
import grey from '../../styles/colors/grey';
import orange from '../../styles/colors/orange';
import fontFamily from '../../styles/typography/fontFamily';
import { getResizedUrl } from '../Html/Image';
import PlayerEpisodePickerTile from './PlayerEpisodePickerTile';

const numOfTiles = 5;

const useStyles = makeStyles<Theme, PlayerEpisodePickerShelfProps>((theme) => {
  return createStyles({
    root: {
      background: alpha(grey.blackpearl, 0.8),
      '-webkit-backdrop-filter': 'blur(10px) saturate(2%)',
      backdropFilter: 'blur(10px) saturate(2%)',
      width: '100%',
      borderRadius: 8,
      overflow: 'hidden',
      padding: theme.spacing(3, 0),
      position: 'relative',
    },
    shelfHeader: {
      fontFamily: fontFamily.secondary,
      fontWeight: 700,
      fontSize: '1.25rem',
      marginRight: 16,
    },
    shelfContainer: {
      padding: '0 44px',
      '&:hover $handleIcon': {
        opacity: 1,
      },
    },
    slider: {
      position: 'relative',
      marginTop: theme.spacing(1.5),
    },
    sliderContent: {
      whiteSpace: 'nowrap',
      transform: 'translate(-0%, 0)',
      transition: 'transform 200ms',
    },
    sliderItemContainer: {
      width: `calc(${100 / numOfTiles}% - 8px)`,
      marginRight: theme.spacing(1),
      display: 'inline-block',
      verticalAlign: 'top',
    },
    handle: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: 44,
      display: 'flex',
      background: alpha(grey.darkBlackPearl, 0.7),
      fontSize: '3rem',
      zIndex: 1,
      '&:hover $handleIcon, &:focus $handleIcon': {
        opacity: 1,
      },
      '&[data-focus-visible-added]': {
        outline: `3px solid ${blue.navy}`,
      },
    },
    handlePrev: {
      left: 0,
      zIndex: 1,
      '& $handleIcon': {
        transform: 'rotate(180deg)',
      },
    },
    handleNext: {
      right: 0,
    },
    handleIcon: {
      margin: 'auto',
      opacity: 0,
      transition: '200ms opacity',
    },
    seasonFormControl: {
      '& .MuiInputBase-root': {
        fontFamily: fontFamily.secondary,
        fontWeight: 700,
      },
      '& .MuiFilledInput-root': {
        borderRadius: '50rem',
        backgroundColor: grey.darkBlackPearl,
        transition: 'background-color 0.2s ease-in-out',
      },
      '& .MuiFilledInput-root:hover': {
        backgroundColor: grey.codgrey,
      },
      '& .MuiSelect-filled': {
        padding: theme.spacing(1, 5, 1, 2),
      },
      '& .MuiSelect-filled.Mui-disabled': {
        padding: theme.spacing(1, 2, 1, 2),
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
      '& .MuiSelect-root.focus-visible': {
        outline: `3px solid ${blue.navy}`,
        borderRadius: '50rem',
      },
      '& $itemListIcon': {
        // don't show the check icon on the form control
        display: 'none',
      },
      '& .MuiInputBase-root.Mui-disabled': {
        color: grey.white,
        backgroundColor: alpha(grey.darkBlackPearl, 0.5),
      },
      '& input, & svg': {
        pointerEvents: 'none',
      },
    },
    selectIcon: {
      transform: 'rotate(180deg)',
      top: 'auto',
      fontSize: '0.7rem',
      right: 14,
      '&.MuiSelect-iconOpen': {
        transform: 'rotate(0)',
      },
      '&.Mui-disabled': {
        display: 'none',
      },
    },
    popoverRoot: {
      '& .MuiList-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiPaper-root': {
        borderRadius: 8,
        backgroundColor: grey.darkBlackPearl,
        marginTop: 4,
      },
      '& .MuiMenuItem-root': {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingRight: theme.spacing(5),
        fontFamily: fontFamily.secondary,
      },
      '& .MuiListItem-root.Mui-selected, & .MuiListItem-root.Mui-selected:hover': {
        backgroundColor: 'transparent',
        color: orange.darkTangerine,
        fontWeight: 500,
      },
      '& .MuiListItem-root.focus-visible': {
        outline: 'none',
      },
      '& .MuiListItem-button:hover': {
        backgroundColor: grey.codgrey,
      },
    },
    itemListIcon: {
      minWidth: 30,
      color: orange.darkTangerine,
    },
    viewAll: {
      fontFamily: fontFamily.secondary,
      color: grey.zumthor,
      fontSize: '0.875rem',
      transition: 'color 0.2s ease-in-out',
      '&:hover': {
        color: grey.white,
      },
    },
    viewAllIcon: {
      position: 'relative',
      top: 2,
      marginLeft: 2,
      '& path': {
        fill: grey.zumthor,
      },
      '$viewAll:hover & path': {
        fill: grey.white,
      },
    },
  });
});

export interface PlayerEpisodePickerShelfProps {
  /**
   * The current mpx id of the episode
   */
  mpxId: string;
  classes?: {
    root: string;
  };
  onIsAvailable?(): void;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
}

const PlayerEpisodePickerShelf: FunctionComponent<PlayerEpisodePickerShelfProps> = (props) => {
  const {
    mpxId,
    onIsAvailable,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const classes = useStyles(props);

  const [video, setVideo] = useState<OnDemand.Episode>();
  const [series, setSeries] = useState<OnDemand.TvSeries>();
  const [season, setSeason] = useState<OnDemand.SeriesItemSeason>();
  // this state is to store the paginations for each of the season so that it persists when switching between seasons
  const [pagination, setPagination] = useState<Record<number, { page: number; maxPage: number }>>();

  // to keep track whether we're moving to the previous page or next page. A number greater than 0 means next, otherwise its previous.
  const [updatePage, setUpdatePage] = useState<number>();

  // this will become available when the video is an episode and the episode's series has more than 1 episode available
  const [isAvailable, setIsAvailable] = useState<boolean>(false);

  const shelfRef = useRef<HTMLDivElement>();
  const sliderContentRef = useRef<HTMLDivElement>();

  const { t, i18n: { language } } = useTranslation('common');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (updatePage !== undefined) {
      const tiles = sliderContentRef.current.querySelectorAll<HTMLAnchorElement>('a[tabindex="0"]');
      if (tiles.length <= 0) return;

      const firstTile = tiles[0];
      const lastTile = tiles[tiles.length - 1];

      // if updatePage is positive, it went to the next page, so focus on the first tile if exists
      if (updatePage > 0 && firstTile) {
        firstTile.focus();
      }

      // if updatePage is 0 or negative, it went to the prev page, so focus on the last tile if exists
      if (updatePage <= 0 && lastTile) {
        lastTile.focus();
      }
    }
  }, [updatePage]);

  // reset is available
  useEffect(() => {
    setIsAvailable(false);
  }, [mpxId]);

  useEffect(() => {
    dispatch(getVideoAsyncThunk(mpxId, language)).then((v) => {
      if (v === null || 'error' in v) {
        setVideo(null);
        return;
      }

      if (v.type === 'Episode') {
        setVideo(v);
      } else {
        setVideo(null);
      }
    });
  }, [dispatch, language, mpxId]);

  useEffect(() => {
    // get the series object
    if (video && video.type === 'Episode' && video.episodeData.seriesSlug) {
      dispatch(getSeries(video.episodeData.seriesType, video.episodeData.seriesSlug, language))
        .then((_series) => {
          if (_series) {
            setSeries(_series);

            // set the season for the current episode
            const currentSeason = find(_series.seasons, { seasonSlug: video.episodeData.seasonSlug });
            setSeason(currentSeason);

            // set the max page for the current season
            const seasonMaxPage = Math.max(0, round(currentSeason.episodes.length / numOfTiles - 1, 2));

            // get position of the current episode in the season
            const index = findIndex(currentSeason.episodes, { id: video.id });

            // set the page for the current episode so that it's positioned in the middle when possible
            const seasonPage = Math.max(0, Math.min(seasonMaxPage, round((index - Math.floor(numOfTiles / 2)) / numOfTiles, 2)));

            setPagination({
              [currentSeason.seasonSlug]: {
                page: seasonPage,
                maxPage: seasonMaxPage,
              },
            });

            const { seasons } = _series;
            if (seasons.length > 0) {
              let totalNumberOfEpisodes = 0;
              seasons.forEach((o) => {
                const { episodes } = o;
                totalNumberOfEpisodes += episodes.length;
              });

              if (totalNumberOfEpisodes > 1) {
                setIsAvailable(true);
                if (typeof onIsAvailable === 'function') {
                  onIsAvailable();
                }
              }
            }
          }
        })
        .catch((e) => {
          Logger.error('Player episode picker: unable to get series', {
            mpxId: video.id,
            seriesSlug: video.episodeData.seriesSlug,
            error: {
              message: e.message,
            },
          });
        });
    }
  }, [dispatch, language, onIsAvailable, video]);

  const handleClickPrev = useCallback(() => {
    setPagination((_value) => {
      const value = {
        ..._value,
      };
      const newPage = Math.max(value[season.seasonSlug].page - 1, 0);

      // set updatePage to negative if going to prev page
      setUpdatePage(-newPage);

      value[season.seasonSlug].page = newPage;
      return value;
    });
  }, [season]);

  const handleClickNext = useCallback(() => {
    setPagination((_value) => {
      const value = {
        ..._value,
      };

      const newPage = Math.min(value[season.seasonSlug].page + 1, value[season.seasonSlug].maxPage);
      setUpdatePage(newPage);

      value[season.seasonSlug].page = newPage;
      return value;
    });
  }, [season]);

  useEffect(() => {
    const container = sliderContentRef.current;
    const swipeListener = container ? SwipeListener(container) : null;
    const wheelListener = debounce((event) => {
      event.preventDefault();

      if (event.deltaX < 0) {
        handleClickPrev();
      } else if (event.deltaX > 0) {
        handleClickNext();
      }
    }, 250, { leading: true, trailing: false });

    if (container) {
      container.addEventListener('swipe', (e: CustomEvent) => {
        const { directions } = e.detail;

        if (directions.left) {
          handleClickNext();
        }

        if (directions.right) {
          handleClickPrev();
        }
      });

      container.addEventListener('wheel', wheelListener);
    }

    return () => {
      if (swipeListener) {
        swipeListener.off();
      }

      if (container) {
        container.removeEventListener('wheel', wheelListener);
      }
    };
  }, [handleClickNext, handleClickPrev]);

  const seasonSlug = get(season, 'seasonSlug');
  const paginationItem = get(pagination, seasonSlug, {});
  const page = get(paginationItem, 'page', 0);
  const maxPage = get(paginationItem, 'maxPage', 0);

  const getTranslateX = useCallback(() => {
    return -page * 100;
  }, [page]);

  const handleChangeSeason = useCallback((event) => {
    // update the season
    const selectedSeason = find(series.seasons, { seasonSlug: event.target.value });
    setSeason(selectedSeason);

    DataLayer.events.episodePickerSelectSeason(selectedSeason.name);

    if (!pagination[selectedSeason.seasonSlug]) {
      setPagination((value) => {
        return {
          ...value,
          [selectedSeason.seasonSlug]: {
            page: 0,
            maxPage: round(selectedSeason.episodes.length / numOfTiles - 1, 2),
          },
        };
      });
    }
  }, [series, pagination]);

  const handleShelfTitleClick = useCallback(() => {
    DataLayer.events.episodePickerViewAll();
  }, []);

  const handleMouseEnter = useCallback((e) => {
    if (onMouseEnter) {
      onMouseEnter(e);
    }
  }, [onMouseEnter]);

  const handleMouseLeave = useCallback((e) => {
    const targetElement = e.target;
    const seasonSelectorElement = (targetElement as HTMLDivElement).closest(classes.seasonFormControl);

    if (!seasonSelectorElement && onMouseLeave) {
      onMouseLeave(e);
    }
  }, [classes.seasonFormControl, onMouseLeave]);

  const SelectIcon = useCallback(({ className }) => {
    return <UpChevronIcon className={clsx(className, classes.selectIcon)}/>;
  }, [classes.selectIcon]);

  if (!isAvailable) {
    return null;
  }

  return (
    <div
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={shelfRef}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.shelfContainer}
      >
        <Grid item>
          <ShelfTitle
            name={t('playerEpisodePicker.shelfTitle', { seriesTitle: series.title })}
            route={series.route}
            onClick={handleShelfTitleClick}
          />
        </Grid>
        <Grid item>
          <FormControl className={classes.seasonFormControl}>
            <Select
              value={season.seasonSlug}
              onChange={handleChangeSeason}
              inputProps={{ 'aria-label': t('playerEpisodePicker.selectSeason') }}
              variant="filled"
              disableUnderline
              disabled={series.seasons.length === 1}
              IconComponent={SelectIcon}
              MenuProps={{
                container: () => {
                  return shelfRef.current.parentElement;
                },
                elevation: 0,
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                PopoverClasses: {
                  root: classes.popoverRoot,
                },
              }}
            >
              {
                series.seasons.map((s) => {
                  return (
                    <MenuItem key={s.seasonSlug} value={s.seasonSlug}>
                      {
                        s.seasonSlug === season.seasonSlug ? (
                          <ListItemIcon classes={{ root: classes.itemListIcon }}>
                            <TickIcon/>
                          </ListItemIcon>
                        ) : (
                          <div className={classes.itemListIcon}/>
                        )
                      }
                      <span>{s.name}</span>
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <div className={clsx(classes.slider, classes.shelfContainer)} data-slider-container="">
        {
          page > 0 && (
            <ButtonBase
              onClick={handleClickPrev}
              className={`${classes.handle} ${classes.handlePrev}`}
              aria-label={t('navigation.prevItemHandleLabel')}
            >
              <RightArrowIcon className={classes.handleIcon}/>
            </ButtonBase>
          )
        }
        <div ref={sliderContentRef} className={classes.sliderContent} style={{ transform: `translateX(${getTranslateX()}%)` }} data-slider-content="">
          {
            season.episodes
              .map((episode, index) => {
                const imageUrl = getResizedUrl({ imageId: episode.odImageId }, { width: 340, crop: false });

                const min = page * numOfTiles - 1;
                const max = (page + 1) * numOfTiles;

                if (index < min || index > max) {
                  // conserve memory by not rendering the tiles if they're out of window
                  return <div key={episode.id} className={classes.sliderItemContainer}/>;
                }

                return (
                  <div key={episode.id} className={classes.sliderItemContainer}>
                    <PlayerEpisodePickerTile
                      mpxId={episode.id}
                      episodeNumber={episode.episodeNumber}
                      title={episode.title}
                      duration={episode.duration}
                      imageUrl={imageUrl}
                      isWatching={episode.id === mpxId}
                    />
                  </div>
                );
              })
          }
        </div>
        {
          page < maxPage && (
            <ButtonBase
              aria-label={t('navigation.nextItemHandleLabel')}
              onClick={handleClickNext}
              className={`${classes.handle} ${classes.handleNext}`}
            >
              <RightArrowIcon className={classes.handleIcon}/>
            </ButtonBase>
          )
        }
      </div>
    </div>
  );
};

export default PlayerEpisodePickerShelf;
