/**
 * Overrides Bitmovin's VideoQualityListBox.
 * Allows processing and filtering video qualities returned by the stream.
 */
import { ListBox, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { PlayerAPI, PlayerEvent, VideoQuality } from 'bitmovin-player';
import i18n from 'i18next';

import Logger from '@@utils/logger/Logger';

type Quality = 'high' | 'medium' | 'low';

/**
 * A element that is similar to a select box where the user can select a subtitle
 */
export default class VideoQualityListBox extends ListBox {
  private static VIDEO_QUALITY_AUTO_KEY: string = 'auto';
  private static MIN_HIGH_BITRATE: number = 1800000;
  private static MIN_MED_BITRATE: number = 600000;

  private player!: PlayerAPI;

  constructor(config = {}) {
    super(config);

    this.config = this.mergeConfig(config, {
      cssClasses: ['ui-videoqualitylistbox'],
    }, this.config);
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);

    this.player = player;

    this.onItemSelected.subscribe((sender, value: string) => {
      player.setVideoQuality(value);
    });

    // Update qualities when source goes away
    player.on(PlayerEvent.SourceUnloaded, this.updateVideoQualities);
    // Update qualities when the period within a source changes
    player.on(PlayerEvent.PeriodSwitched, this.updateVideoQualities);
    // Update quality selection when quality is changed (from outside)
    player.on(PlayerEvent.VideoQualityChanged, this.selectCurrentVideoQuality);

    // Update qualities when their availability changed
    player.on(PlayerEvent.VideoQualityAdded, this.updateVideoQualities);
    player.on(PlayerEvent.VideoQualityRemoved, this.updateVideoQualities);

    uimanager.getConfig().events.onUpdated.subscribe(this.updateVideoQualities);
  }

  private getQualityLabel(quality: Quality): string {
    if (quality === 'high') {
      return i18n.t('common:videoPlayer.videoQualityHigh');
    }

    if (quality === 'medium') {
      return i18n.t('common:videoPlayer.videoQualityMedium');
    }

    if (quality === 'low') {
      return i18n.t('common:videoPlayer.videoQualityLow');
    }

    return '';
  }

  private selectCurrentVideoQuality = () => {
    const currentVideoQuality = this.player.getVideoQuality();
    if (!currentVideoQuality || currentVideoQuality.id === 'not available') {
      this.selectItem(VideoQualityListBox.VIDEO_QUALITY_AUTO_KEY);
    } else {
      this.selectItem(this.player.getVideoQuality().id);
    }
  };

  private updateVideoQualities = () => {
    const { player } = this;
    const videoQualities = player.getAvailableVideoQualities();

    if (videoQualities?.length > 0) {
      this.clearItems();

      // Progressive streams do not support automatic quality selection
      if (player.getStreamType() !== 'progressive') {
        // Add entry for automatic quality switching (default setting)
        this.addItem(VideoQualityListBox.VIDEO_QUALITY_AUTO_KEY, i18n.t('common:videoPlayer.videoQualityAuto'));
      }

      /**
       Auto = Retains Bitmovin's current logic
       High - Only plays the highest available stream that is >= 1800kbps
       Medium - Only plays the highest available stream that is >= 600kbps that is not already marked as "High"
       Low = Only plays the highest available stream of the remaining quality that is not already marked as "High" or "Medium"
       */
      const customQualities: {
        [key in string]: VideoQuality;
      } = {};

      // Sorting with lowest bitrate first
      videoQualities.sort(({ bitrate: a }, { bitrate: b }) => { return a - b; });

      ['High', 'Medium', 'Low'].forEach((quality) => {
        const videoQuality = videoQualities.pop();
        let selectedQuality;

        if (videoQuality) {
          switch (quality) {
            // Considering 'High' the first quality with bitrate at 1800Kbps or more
            case 'High':
              if (videoQuality.bitrate >= 1800000) {
                selectedQuality = videoQuality;
              }
              break;

            // Considering 'Medium' the first quality with bitrate at 600Kbps or more
            case 'Medium':
              if (videoQuality.bitrate >= 600000) {
                selectedQuality = videoQuality;
              }
              break;

            // Considering 'Low' the first of the remaining qualities
            case 'Low':
            default:
              selectedQuality = videoQuality;
              break;
          }
        }

        if (selectedQuality) {
          customQualities[quality.toLowerCase()] = { ...selectedQuality };
        }
      });

      (Object.keys(customQualities) as Quality[]).forEach((quality) => {
        const {
          id,
          bitrate,
          height,
        } = customQualities[quality];

        const label = this.getQualityLabel(quality);
        this.addItem(id, `${label} (${height}p)`);
        Logger.info(`[VideoPlayer] video quality added: ${label} (${bitrate / 1000} kbps)`);
      });

      // Select initial quality
      this.selectCurrentVideoQuality();
    }
  };
}
