import { hasFeature } from '@@utils/config';

import VideoPlayerPluginV2WithReactPortal from '../VideoPlayerPluginV2WithReactPortal';
import EpisodePickerComponent from './EpisodePickerPluginComponent';

class EpisodePickerPluginV2 extends VideoPlayerPluginV2WithReactPortal {
  public shouldLoad(): boolean {
    const video = this.videoPlayer.getVideo();
    return video.type === 'Episode' && hasFeature('episodePicker');
  }

  protected component() {
    const video = this.videoPlayer.getVideo();
    const videoPlayerContainer = this.videoPlayer.getContainer();

    if (video.type === 'Episode') {
      return (
        <EpisodePickerComponent
          videoPlayerContainer={videoPlayerContainer}
          video={video}
        />
      );
    }

    return null;
  }
}

export default EpisodePickerPluginV2;
