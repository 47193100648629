import { ConvivaConstants } from '@convivainc/conviva-js-coresdk';

export interface CustomContentMetadata {
  [key: string]: string;
}

export default class ContentMetadata {
  public applicationName: string = '';
  public assetName: string = '';
  public custom: CustomContentMetadata = {};
  public duration: number = 0;
  public streamType: ConvivaConstants['StreamType']['LIVE'] | ConvivaConstants['StreamType']['UNKNOWN'] | ConvivaConstants['StreamType']['VOD'] = 'VOD';
  public streamUrl: string = '';
  public viewerId: string = '';
}
