import {
  BrowserUtils, Component, Container, SettingsPanel, UIInstanceManager, UIUtils, Spacer,
} from '@sbs/bitmovin-player-ui';
import { ComponentConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/component';
import { ContainerConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/container';
import { PlayerAPI, PlayerEvent } from 'bitmovin-player';

interface HoveredComponent {
  component: Component<ComponentConfig>;
  isHovered: boolean;
}

export default class SbsControlBar extends Container<ContainerConfig> {
  private isSettingsPanelShown: boolean = false;
  private lastHoveredComponent: HoveredComponent | undefined = undefined;

  constructor(config: ContainerConfig = {}) {
    super({
      ...config,
      cssClass: 'ui-controlbar',
      role: 'region',
      ariaLabel: 'Control bar',
      hidden: true, // This is to hide the controls until the player is ready
    });
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);

    // Counts how many components are hovered and block hiding of the control bar
    let hoverStackCount = 0;

    // only enabling this for non-mobile platforms without touch input. enabling this
    // for touch devices causes the UI to not disappear after hideDelay seconds.
    // Instead, it will stay visible until another manual interaction is performed.
    /* istanbul ignore if */
    if (uimanager.getConfig().disableAutoHideWhenHovered && !BrowserUtils.isMobile) {
      // Track hover status of child components
      UIUtils.traverseTree(this, (component) => {
        // Do not track hover status of child containers or spacers, only of 'real' controls
        if (component instanceof Container || component instanceof Spacer) {
          return;
        }

        // Subscribe hover event and keep a count of the number of hovered children
        component.onHoverChanged.subscribe((_, args) => {
          // Clicking in and out of episode picker's season selector triggers onmouse over on the episode picker shelf.
          // This will prevent handling the hovering on a component that is already hovered on.
          if (
            !this.lastHoveredComponent
            || component !== this.lastHoveredComponent.component
            || args.hovered !== this.lastHoveredComponent.isHovered
          ) {
            if (args.hovered) {
              hoverStackCount += 1;
              this.lastHoveredComponent = { component, isHovered: true };
            } else {
              hoverStackCount -= 1;
              this.lastHoveredComponent = { component, isHovered: false };
            }
          }

          if (hoverStackCount === 0) {
            this.lastHoveredComponent = undefined;
          }
        });
      });
    }

    if (BrowserUtils.isMobile) {
      uimanager.onComponentShow.subscribe((component: Component<ComponentConfig>) => {
        if (component instanceof SettingsPanel) {
          this.isSettingsPanelShown = true;
        }
      });

      uimanager.onComponentHide.subscribe((component: Component<ComponentConfig>) => {
        if (component instanceof SettingsPanel) {
          this.isSettingsPanelShown = false;
        }
      });
    }

    uimanager.onControlsShow.subscribe(() => {
      if (player.getSource()?.hls || player.isPlaying() || player.isPaused()) {
        this.show();
      }
    });

    /* istanbul ignore if */
    uimanager.onPreviewControlsHide.subscribe((sender, args) => {
      // Cancel the hide event if hovered child components block hiding or if the settings panel is active on mobile.
      // eslint-disable-next-line no-param-reassign
      args.cancel = args.cancel || (hoverStackCount > 0 || this.isSettingsPanelShown);
    });

    uimanager.onControlsHide.subscribe(() => {
      this.hide();
    });

    player.on(PlayerEvent.SourceLoaded, () => {
      this.show();
      if (player.isLive()) {
        this.getDomElement().addClass('livestream');
      } else {
        this.getDomElement().removeClass('livestream');
      }
    });

    player.on(PlayerEvent.SourceUnloaded, () => {
      this.hide();
    });
  }
}
