import { FunctionComponent, useEffect } from 'react';

import AdSnapBackCore from '@@src/lib/VideoPlayerV2/plugins/AdSnapBack/AdSnapBackCore';
import { VideoPlayerPluginProps } from '@@src/lib/VideoPlayerV2/plugins/VideoPlayerPlugin';

const AdSnapback: FunctionComponent<VideoPlayerPluginProps> = (props) => {
  const { videoPlayer, playbackStreamData } = props;

  useEffect(() => {
    const snapBackPlugin = new AdSnapBackCore(videoPlayer);

    return () => {
      snapBackPlugin.destroy();
    };
  }, [videoPlayer, playbackStreamData]);

  // This plugin does not have a UI
  return null;
};

export default AdSnapback;
