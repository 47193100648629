import { ReactElement, ReactNode } from 'react';
import ReactDOM from 'react-dom';

import { VideoCardPosition } from '../../../lib/VideoPlayer/BitmovinClient';
import { VideoPlayerApi } from '../../../pages/WatchPage';
import VideoPlayerPluginV2 from './VideoPlayerPluginV2';

abstract class VideoPlayerPluginV2WithReactPortal extends VideoPlayerPluginV2 {
  protected portalElement: HTMLDivElement;
  protected videoCardPosition: VideoCardPosition = 'top';

  public constructor(videoPlayer: VideoPlayerApi) {
    super(videoPlayer);
    this.portalElement = document.createElement('div');
  }

  protected onPlayerReady() {
    // append the portal element to the bitmovin video card container
    this.bitmovinClient.addVideoCardV2(this.portalElement, this.videoCardPosition);
  }

  protected onDestroy() {
    // remove the portal element
    if (this.portalElement.parentNode) {
      this.portalElement.parentNode.removeChild(this.portalElement);
    }
  }

  /**
   * Component to be rendered in the ReactPortal
   * @protected
   */
  protected component(): ReactNode {
    return null;
  }

  /**
   * Render the component using ReactPortal
   */
  public render = (): ReactElement => {
    if (this.shouldLoad() && this.component()) {
      return ReactDOM.createPortal(this.component(), this.portalElement);
    }
    return null;
  };
}

export default VideoPlayerPluginV2WithReactPortal;
