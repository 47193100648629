import { ClickOverlay, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { PlayerAPI, ViewMode } from 'bitmovin-player';
import { find } from 'lodash';

import SbsControlBar from '@@src/lib/VideoPlayerV2/BitmovinPlayerUI/components/SbsControlBar';

/**
 * Click overlay to toggle the display of the control bar on tap for mobile/tablets
 */
export default class ControlsToggleOverlay extends ClickOverlay {
  constructor(config = {}) {
    super(config);

    this.config = this.mergeConfig(config, {
      cssClasses: ['ui-controlstoggleoverlay'],
      id: 'bmpui-ui-controlstoggle',
    }, this.config);
  }

  configure(player: PlayerAPI, uiInstanceManager: UIInstanceManager): void {
    // Copying double-click login from Bitmovin's hugeplaybackbuttontogglebutton.ts
    let clickTime = 0;
    let doubleClickTime = 0;

    this.onClick.subscribe(() => {
      const activeUi = uiInstanceManager.getUI();
      const uiComponents = activeUi.getComponents();

      const toggleFullscreen = () => {
        if (player.getViewMode() === ViewMode.Fullscreen) {
          player.setViewMode(ViewMode.Inline);
        } else {
          player.setViewMode(ViewMode.Fullscreen);
        }
      };

      const toggleControlBar = () => {
        const controlBar = find(uiComponents, (component) => {
          return component instanceof SbsControlBar;
        });
        if (controlBar) {
          if (controlBar.isShown()) {
            uiInstanceManager.onControlsHide.dispatch(uiInstanceManager.getUI());
          } else {
            uiInstanceManager.onControlsShow.dispatch(uiInstanceManager.getUI());
          }
        }
      };

      const now = Date.now();

      if (now - clickTime < 500) {
        // We have a double click inside the 200ms interval, just toggle fullscreen mode
        toggleFullscreen();
        doubleClickTime = now;
        return;
      }

      clickTime = now;

      setTimeout(() => {
        if (Date.now() - doubleClickTime > 200) {
          // No double click detected, so we toggle playback and wait what happens next
          toggleControlBar();
        }
      }, 200);
    });
  }
}
