/**
 * Overrides Bitmovin's SubtitleUtils class.
 * Automatically selects forced narratives track
 */
import { UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { ListItem, ListSelector, ListSelectorConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/listselector';
import { PlayerAPI, PlayerEvent, PlayerEventCallback, SubtitleEvent, SubtitleTrack } from 'bitmovin-player';
import { debounce } from 'lodash';

import { getUserPreferences } from '@@src/lib/VideoPlayerV2/plugins/SaveUserPreferences/SaveUserPreferences';

import i18n from '../../../../i18n';

/**
 * Helper class to handle all subtitle related events
 *
 * This class listens to player events as well as the `ListSelector` event if selection changed
 */
// following BitmovinUI repo structure
// eslint-disable-next-line import/prefer-default-export
export class SubtitleSwitchHandler {
  public static FORCED_SUBTITLES_LABEL: string = 'English (Forced)';

  private static SUBTITLES_OFF_KEY: string = 'null';
  private static IGNORE_SUBTITLE_LABELS: string[] = ['Captions (CC 1)'];

  private forcedSubtitlesId: string | undefined;
  private player: PlayerAPI;
  private listElement: ListSelector<ListSelectorConfig>;
  private uimanager: UIInstanceManager;
  private offListItem: ListItem = {
    key: SubtitleSwitchHandler.SUBTITLES_OFF_KEY,
    label: i18n.t('common:videoPlayer.settingsPanelSubtitlesOff'),
  };

  constructor(player: PlayerAPI, element: ListSelector<ListSelectorConfig>, uimanager: UIInstanceManager) {
    this.player = player;
    this.listElement = element;
    this.uimanager = uimanager;

    this.bindSelectionEvent();
    this.bindPlayerEvents();
    this.refreshSubtitles();
  }

  /**
   * Check if the current subtitle track is for forced narratives
   * @param subtitle
   */
  private isSubtitleForced(subtitle: SubtitleTrack): boolean {
    return subtitle.forced === true || subtitle.label === SubtitleSwitchHandler.FORCED_SUBTITLES_LABEL;
  }

  private shouldIgnoreSubtitle(subtitle: SubtitleTrack): boolean {
    const shouldIgnore = SubtitleSwitchHandler.IGNORE_SUBTITLE_LABELS.indexOf(subtitle.label) !== -1;
    return shouldIgnore;
  }

  /** Handles user selection of a subtitle from the player menu */
  private bindSelectionEvent(): void {
    this.listElement.onItemSelected.subscribe((_, value: string) => {
      if (
        !value
        || value === SubtitleSwitchHandler.SUBTITLES_OFF_KEY
      ) {
        if (this.forcedSubtitlesId) {
          this.player.subtitles.enable(this.forcedSubtitlesId, true);
        } else {
          const currentSubtitle = this.player.subtitles.list().filter((subtitle) => { return subtitle.enabled; }).pop();
          if (currentSubtitle) {
            this.player.subtitles.disable(currentSubtitle.id);
          }
        }
      } else {
        this.player.subtitles.enable(value, true);
      }
    });
  }

  /**
   * Setup required event handlers
   * @private
   */
  private bindPlayerEvents(): void {
    this.player.on(PlayerEvent.SourceLoaded, this.sourceLoaded);
    this.player.on(PlayerEvent.SubtitleAdded, this.addSubtitle as PlayerEventCallback);
    this.player.on(PlayerEvent.SubtitleEnabled, this.selectCurrentSubtitle);
    this.player.on(PlayerEvent.SubtitleDisabled, this.selectCurrentSubtitle);
    this.player.on(PlayerEvent.SubtitleRemoved, this.removeSubtitle as PlayerEventCallback);
    // Update subtitles when source goes away
    this.player.on(PlayerEvent.SourceUnloaded, this.clearSubtitles);
    // Update subtitles when the period within a source changes
    this.player.on(PlayerEvent.PeriodSwitched, this.refreshSubtitles);
    this.uimanager.getConfig().events.onUpdated.subscribe(this.refreshSubtitles);
  }

  /**
   * Handles SourceLoaded player event
   */
  private sourceLoaded = () => {
    this.forcedSubtitlesId = undefined;
  };

  /**
   * Handles SubtitleAdded player events
   * @param event
   */
  private addSubtitle = (event: SubtitleEvent) => {
    const { subtitle } = event;

    // add off list item
    if (!this.listElement.hasItem(this.offListItem.key)) {
      this.listElement.addItem(this.offListItem.key, this.offListItem.label);
    }

    if (
      !this.isSubtitleForced(subtitle)
      && !this.shouldIgnoreSubtitle(subtitle)
      && !this.listElement.hasItem(subtitle.id)
    ) {
      this.listElement.addItem(subtitle.id, subtitle.label);
    }
  };

  /**
   * Handles SubtitleRemoved player event
   * @param event
   */
  private removeSubtitle = (event: SubtitleEvent) => {
    const { subtitle } = event;
    if (this.listElement.hasItem(subtitle.id)) {
      this.listElement.removeItem(subtitle.id);
    }

    // if last item is the off list item, remove it
    const items = this.listElement.getItems();
    if (items.length === 1 && items[0].key === this.offListItem.key) {
      this.listElement.removeItem(this.offListItem.key);
    }
  };

  /**
   * Highlights the currently enabled subtitle in the player menu
   */
  private selectCurrentSubtitle = () => {
    if (!this.player.subtitles) {
      // Subtitles API not available (yet)
      return;
    }

    const currentSubtitle = this.player.subtitles.list().filter((subtitle) => { return subtitle.enabled; }).pop();

    if (currentSubtitle && !this.isSubtitleForced(currentSubtitle)) {
      this.listElement.selectItem(currentSubtitle.id);
    } else {
      this.listElement.selectItem(SubtitleSwitchHandler.SUBTITLES_OFF_KEY);
    }
  };

  /**
   * Removes the subtitles from the player menu
   */
  private clearSubtitles = () => {
    this.listElement.clearItems();
  };

  /**
   * Traverses the list of available subtitles and pre-processes/filters them for display in the player menu
   */
  private refreshSubtitles = () => {
    if (!this.player.subtitles) {
      // Subtitles API not available (yet)
      return;
    }

    const subtitles = this.player.subtitles.list();
    if (subtitles.length === 0) {
      // No subtitles available
      this.listElement.synchronizeItems([]);
      return;
    }

    // Used to filter out Forced Narratives from the list
    const subtitleFilter = (subtitle: SubtitleTrack): boolean => {
      if (this.isSubtitleForced(subtitle)) {
        this.forcedSubtitlesId = subtitle.id;
        return false;
      }

      return !this.shouldIgnoreSubtitle(subtitle);
    };

    const subtitleToListItem = (subtitle: SubtitleTrack): ListItem => {
      return { key: subtitle.id, label: subtitle.label };
    };

    const newItems = [
      this.offListItem,
      ...subtitles
        .filter(subtitleFilter)
        .map(subtitleToListItem),
    ];

    this.listElement.synchronizeItems(newItems);
    this.selectCurrentSubtitle();
  };
}
