import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import PlayerEpisodePickerShelfV2 from '@@src/components/PlayerEpisodePicker/PlayerEpisodePickerShelfV2';
import VideoPlayer from '@@src/lib/VideoPlayerV2/VideoPlayer';
import {
  VideoPlayerEventCallback,
  VideoPlayerEventType,
  VideoPlayerUiVariantResolveEvent,
} from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import type OnDemand from '@@types/OnDemand';

import EpisodePickerPanel from '../../BitmovinPlayerUI/components/EpisodePickerPanel';
import EpisodePickerToggleButton from '../../BitmovinPlayerUI/components/EpisodePickerToggleButton';
import { SbsUIContainer } from '../../BitmovinPlayerUI/components/SbsUIContainer';

export interface EpisodePickerProps {
  videoPlayer: VideoPlayer;
  video: OnDemand.Episode;
  series: OnDemand.TvSeries;
}

const EpisodePicker: FunctionComponent<EpisodePickerProps> = (props) => {
  const {
    video, series, videoPlayer,
  } = props;

  const [currentUi, setCurrentUi] = useState<SbsUIContainer>();
  const [available, setAvailable] = useState<boolean>(false);

  useEffect(() => {
    const uiChangeHandler = (event: VideoPlayerUiVariantResolveEvent) => {
      const { ui: activeUi } = event;
      const ui = activeUi.getUI();

      if (ui instanceof SbsUIContainer) {
        if (ui.findComponents(EpisodePickerToggleButton)?.length > 0) {
          setCurrentUi(ui);
        }
      }
    };

    videoPlayer.on(VideoPlayerEventType.UI_VARIANT_CHANGED, uiChangeHandler as VideoPlayerEventCallback);

    return () => {
      videoPlayer.off(VideoPlayerEventType.UI_VARIANT_CHANGED, uiChangeHandler as VideoPlayerEventCallback);
    };
  }, [videoPlayer]);

  useEffect(() => {
    if (currentUi) {
      const episodePickerToggleButtons = currentUi.findComponents(EpisodePickerToggleButton);
      episodePickerToggleButtons.forEach((button) => {
        if (available) {
          button.show();
        } else {
          button.hide();
        }
      });
    }

    return () => {
      if (currentUi) {
        const episodePickerToggleButtons = currentUi.findComponents(EpisodePickerToggleButton);

        episodePickerToggleButtons.forEach((button) => {
          button.hide();
        });
      }
    };
  }, [available, currentUi]);

  const handleOnIsAvailable = useCallback(() => {
    setAvailable(true);
  }, []);

  const component = (
    <PlayerEpisodePickerShelfV2
      onIsAvailable={handleOnIsAvailable}
      video={video}
      series={series}
    />
  );

  if (!currentUi) {
    return null;
  }

  const episodePickerPanels = currentUi.findComponents(EpisodePickerPanel);

  return (
    <>
      {
        episodePickerPanels.map((panel) => {
          const panelItem = panel.getDomElement().find('.bmpui-ui-component').get(0);
          return panelItem ? createPortal(component, panelItem) : null;
        })
      }
    </>
  );
};

export default EpisodePicker;
