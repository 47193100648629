import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { useAppDispatch } from '@@src/hooks/store';
import VideoPlayer from '@@src/lib/VideoPlayerV2/VideoPlayer';
import { VideoPlayerEventType } from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import { setProgress } from '@@stores/ProgressStore';
import OnDemand from '@@types/OnDemand';
import { PlaybackStreamData } from '@@types/PlaybackStreamData';
import DataLayer from '@@utils/DataLayer';
import { getNextEpisodeInSeries } from '@@utils/helpers';

import ShowAtPlayTime from '../Utils/ShowAtPlayTime';
import AutoPlayNextEpisodeButtons from './AutoPlayNextEpisodeButtons';

interface AutoPlayNextEpisodeProps {
  videoPlayer: VideoPlayer;
  video: OnDemand.Episode;
  series: OnDemand.TvSeries;
  displayTime: number;
  playbackStreamData: PlaybackStreamData;
}

const AutoPlayNextEpisode: FunctionComponent<AutoPlayNextEpisodeProps> = (props) => {
  const {
    videoPlayer,
    displayTime,
    video,
    series,
    playbackStreamData,
  } = props;

  const episode = video as OnDemand.Episode;

  // show the component
  const [show, setShow] = useState<boolean>(true);

  const [hidePlayButtonGroupOnXs, setHidePlayButtonGroupOnXs] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const theme = useTheme();
  const mediaXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const nextEpisode = getNextEpisodeInSeries(episode.id, series);

  // when content is finished, we should hide this component
  // so it does not interfere with the next episode endcard
  const cancelOnPlayFinished = useCallback(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    // reset state when episode changes
    setShow(true);
  }, [episode]);

  useEffect(() => {
    videoPlayer.on(VideoPlayerEventType.PLAYBACK_FINISHED, cancelOnPlayFinished);

    return () => {
      videoPlayer.off(VideoPlayerEventType.PLAYBACK_FINISHED, cancelOnPlayFinished);
    };
  }, [cancelOnPlayFinished, videoPlayer]);

  const handlePause = useCallback(() => {
    setHidePlayButtonGroupOnXs(false);
  }, []);

  useEffect(() => {
    videoPlayer.on(VideoPlayerEventType.PAUSED, handlePause);

    return () => {
      videoPlayer.off(VideoPlayerEventType.PAUSED, handlePause);
    };
  }, [handlePause, videoPlayer]);

  // hide the playbackgroup on xs breakpoint when we're showing the buttons because they can overlap
  useEffect(() => {
    if (mediaXsDown && hidePlayButtonGroupOnXs) {
      const els = document.getElementsByClassName('bmpui-playbackgroup-overlay');

      Array.prototype.forEach.call(els, (el) => {
        // eslint-disable-next-line no-param-reassign
        el.style.display = 'none';
      });
    } else {
      const els = document.getElementsByClassName('bmpui-playbackgroup-overlay');

      Array.prototype.forEach.call(els, (el) => {
        // eslint-disable-next-line no-param-reassign
        el.style.display = null;
      });
    }
  }, [hidePlayButtonGroupOnXs, mediaXsDown]);

  // reset progress so that the video is played from the beginning
  const resetProgress = useCallback((videoId) => {
    dispatch(setProgress({
      videoId,
      seconds: 0,
      percent: 0,
    }));
  }, [dispatch]);

  const handleActionClick = useCallback(() => {
    setShow(false);

    if (nextEpisode) {
      resetProgress(nextEpisode.id);

      DataLayer.events.playNextEpisode(
        'tap',
        { ...nextEpisode, entityType: playbackStreamData.entityType } as OnDemand.Episode,
        videoPlayer.getPlayerMetadata(),
        {
          videoNavigationEvent: {
            playNextEpisode: 'tap',
          },
        },
      );
    }
  }, [nextEpisode, playbackStreamData.entityType, resetProgress, videoPlayer]);

  const handleActionTimeout = useCallback(() => {
    if (nextEpisode) {
      resetProgress(nextEpisode.id);

      DataLayer.events.playNextEpisode(
        'lapse',
        { ...nextEpisode, entityType: playbackStreamData.entityType } as OnDemand.Episode,
        videoPlayer.getPlayerMetadata(),
        {
          videoNavigationEvent: {
            playNextEpisode: 'lapse',
          },
        },
      );
    }
  }, [nextEpisode, playbackStreamData.entityType, resetProgress, videoPlayer]);

  const handleCancelClick = useCallback(() => {
    setHidePlayButtonGroupOnXs(false);

    DataLayer.events.playNextEpisode(
      'keepWatching',
      { ...nextEpisode, entityType: playbackStreamData.entityType } as OnDemand.Episode,
      videoPlayer.getPlayerMetadata(),
      {
        videoNavigationEvent: {
          playNextEpisode: 'keepWatching',
        },
      },
    );
  }, [nextEpisode, playbackStreamData.entityType, videoPlayer]);

  const handleShow = useCallback(() => {
    setHidePlayButtonGroupOnXs(true);

    DataLayer.events.playNextEpisode(
      'display',
      { ...nextEpisode, entityType: playbackStreamData.entityType } as OnDemand.Episode,
      videoPlayer.getPlayerMetadata(),
      {
        videoNavigationEvent: {
          playNextEpisode: 'display',
        },
      },
    );
  }, [nextEpisode, playbackStreamData.entityType, videoPlayer]);

  if (!show || !nextEpisode) return null;

  const autoplayNextEpDelay = 10;

  return (
    <ShowAtPlayTime
      key={episode.id}
      videoPlayer={videoPlayer}
      startTime={displayTime}
      endTime={episode.duration}
      onShow={handleShow}
      minDurationBeforeEndTime={autoplayNextEpDelay}
      hideOnPause
    >
      <AutoPlayNextEpisodeButtons
        key={episode.id}
        delay={autoplayNextEpDelay}
        onCancelClick={handleCancelClick}
        onActionTimeout={handleActionTimeout}
        onActionClick={handleActionClick}
        nextEpisodeId={nextEpisode.id}
      />
    </ShowAtPlayTime>
  );
};

export default AutoPlayNextEpisode;
