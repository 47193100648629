import { VideoPlayerApi } from '@@src/pages/WatchPage';
import DataLayer from '@@utils/DataLayer';

import i18n from '../../../../i18n';
import ShowAtPlayTime from '../Utils/ShowAtPlayTime';
import VideoPlayerPluginV2WithReactPortal from '../VideoPlayerPluginV2WithReactPortal';
import SeekButton from './SeekButton';

class SkipRecapPluginV2 extends VideoPlayerPluginV2WithReactPortal {
  protected videoCardPosition = 'top' as const;

  protected skipRecapStart: number;
  protected skipRecapEnd: number;

  public constructor(videoPlayer: VideoPlayerApi) {
    super(videoPlayer);
    this.skipRecapStart = this.videoPlayer.getTrackingTime('offer_skip_recap');
    this.skipRecapEnd = this.videoPlayer.getTrackingTime('recap_end');
  }

  public shouldLoad(): boolean {
    return this.skipRecapStart !== undefined && this.skipRecapEnd !== undefined;
  }

  protected component() {
    const handleShow = () => {
      DataLayer.events.videoNavigation(
        'skipRecap_display',
        this.videoPlayer.getVideo(),
        this.videoPlayer.getPlayerMetadata(),
        {
          videoNavigationEvent: {
            skipRecap: 'display',
          },
        },
      );
    };

    const skipForward = () => {
      DataLayer.events.videoNavigation(
        'skipRecap_tap',
        this.videoPlayer.getVideo(),
        this.videoPlayer.getPlayerMetadata(),
        {
          videoNavigationEvent: {
            skipRecap: 'tap',
          },
        },
      );
    };

    const seekFn = (seekTo: number): void => {
      this.bitmovinClient.player.seek(this.bitmovinClient.streamTimeForContentTime(seekTo));
    };

    return (
      <ShowAtPlayTime
        bitmovinClient={this.bitmovinClient}
        startTime={this.skipRecapStart}
        endTime={this.skipRecapEnd}
        onShow={handleShow}
        autoHideDelay={10}
        autoHideAfterUiHideDelay={5}
        hideOnPause
      >
        <SeekButton seekFn={seekFn} seekTo={this.skipRecapEnd} onClick={skipForward}>
          {i18n.t('videoPlayer.skipRecap')}
        </SeekButton>
      </ShowAtPlayTime>
    );
  }
}

export default SkipRecapPluginV2;
