import { PlayerAPI } from 'bitmovin-player';

import VideoPlayerEventManager from '../VideoPlayerEventManager';
import GoogleDaiStreamHandler from './GoogleDaiStreamHandler';
import loadImaDaiSdk from './GoogleImaDaiApi';
import HLSStreamHandler from './HLSStreamHandler';
import { StreamHandler, StreamHandlerConfig } from './StreamHandler';

const StreamHandlerFactory = {
  async create(
    streamHandlerConfig: StreamHandlerConfig,
    eventManager: VideoPlayerEventManager,
    player: PlayerAPI,
    videoElement: HTMLVideoElement,
    adClickElementId?: string,
  ): Promise<StreamHandler> {
    let streamHandler;
    if (streamHandlerConfig.type === 'GoogleDAI') {
      const imaDaiApi = await loadImaDaiSdk();
      streamHandler = new GoogleDaiStreamHandler(streamHandlerConfig, eventManager, player, videoElement, imaDaiApi, adClickElementId);
    } else if (streamHandlerConfig.type === 'HLS') {
      streamHandler = new HLSStreamHandler(streamHandlerConfig, eventManager, player);
    } else {
      throw new Error('Unexpected stream handler config');
    }

    return streamHandler;
  },
};

export default StreamHandlerFactory;
