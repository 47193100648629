import { AdEvent, PlayerAPI, PlayerEvent, PlayerEventBase, PlayerEventCallback } from 'bitmovin-player';

import Logger from '@@utils/logger/Logger';

import VideoPlayerEventManager, { VideoPlayerEventType } from '../VideoPlayerEventManager';
import type { StreamData, StreamHandler } from './StreamHandler';
import { CuePoints } from './StreamHandler';

export interface TextTrack {
  language: string;
  label: string;
  url: string;
}

export interface HLSStreamHandlerConfig {
  type: 'HLS';
  url: string;
  textTracks?: TextTrack[];
}

export default class HLSStreamHandler implements StreamHandler {
  private config: HLSStreamHandlerConfig;
  private eventManager: VideoPlayerEventManager;
  private player: PlayerAPI;

  private onAdPlaying: ((event: AdEvent) => void) | undefined;
  private cuepoints: CuePoints[] = [];

  constructor(
    config: HLSStreamHandlerConfig,
    eventManager: VideoPlayerEventManager,
    player: PlayerAPI,
  ) {
    this.config = config;
    this.eventManager = eventManager;
    this.player = player;

    this.registerPlayerEvents();
  }

  private registerPlayerEvents = (): void => {
    this.player.on(PlayerEvent.AdBreakStarted, this.onAdBreakStarted as PlayerEventCallback);
    this.player.on(PlayerEvent.AdBreakFinished, this.onAdBreakFinished as PlayerEventCallback);
    this.player.on(PlayerEvent.AdStarted, this.onAdStarted as PlayerEventCallback);
    this.player.on(PlayerEvent.AdFinished, this.onAdFinished as PlayerEventCallback);
  };

  private deregisterPlayerEvents = (): void => {
    this.player.off(PlayerEvent.AdBreakStarted, this.onAdBreakStarted as PlayerEventCallback);
    this.player.off(PlayerEvent.AdBreakFinished, this.onAdBreakFinished as PlayerEventCallback);
    this.player.off(PlayerEvent.AdStarted, this.onAdStarted as PlayerEventCallback);
    this.player.off(PlayerEvent.AdFinished, this.onAdFinished as PlayerEventCallback);

    // onAdPlaying may not be cleaned up if video is changed in the middle of ad playback and onAdFinished is not called
    if (this.onAdPlaying) {
      this.player.off(PlayerEvent.TimeChanged, this.onAdPlaying as PlayerEventCallback);
    }
  };

  public unload = () => {
    // on source unloaded, we want to clean up the player events
    this.deregisterPlayerEvents();
  };

  public load() {
    Logger.debug('AkamaiStreamHandler: load', {
      contentUrl: this.config.url,
    });

    return Promise.resolve<StreamData>({
      hlsUrl: this.config.url,
      textTracks: this.config.textTracks,
    });
  }

  public getStreamId = () => {
    return null;
  };

  public getStreamTime() {
    return this.player.getCurrentTime();
  }

  public streamTimeForContentTime(contentTime: number): number {
    return contentTime;
  }

  public contentTimeForStreamTime(streamTime: number): number {
    return streamTime;
  }

  private onAdBreakStarted = (event: AdEvent) => {
    this.eventManager.updateState('isAd', true);
    this.eventManager.dispatch(VideoPlayerEventType.AD_BREAK_STARTED, { timestamp: event.timestamp });
  };

  private onAdStarted = (event: AdEvent) => {
    this.eventManager.updateState('isAd', true);

    // onAdPlaying may not be cleaned up if onAdFinished is not called which may happen under certain circumstances
    if (this.onAdPlaying) {
      this.player.off(PlayerEvent.TimeChanged, this.onAdPlaying as PlayerEventCallback);
    }

    /**
     * Since bitmovin doesn't have an equivalent AD_PROGRESS,
     * we will listen to TimeChanged event when the ad is started
     * and dispatch the AD_PROGRESS event when we are in the ad
     */
    this.onAdPlaying = (_event: AdEvent) => {
      const remainingTime = Math.round(Math.max(0, this.player.getDuration() - this.player.getCurrentTime()));

      this.eventManager.dispatch(VideoPlayerEventType.AD_PROGRESS, {
        timestamp: _event.timestamp,
        remainingTime,
      });
    };

    this.player.on(PlayerEvent.TimeChanged, this.onAdPlaying as PlayerEventCallback);

    this.eventManager.dispatch(VideoPlayerEventType.AD_STARTED, { timestamp: event.timestamp, adMetadata: null });
  };

  private onAdFinished = (event: AdEvent) => {
    if (this.onAdPlaying) {
      // Remove the onAdPlaying listener from TimeChanged event when ad is finished
      this.player.off(PlayerEvent.TimeChanged, this.onAdPlaying as PlayerEventCallback);
      this.onAdPlaying = undefined;
    }

    this.eventManager.dispatch(VideoPlayerEventType.AD_FINISHED, { timestamp: event.timestamp, adMetadata: null });
  };

  private onAdBreakFinished = (event: PlayerEventBase) => {
    this.eventManager.updateState('isAd', false);
    this.eventManager.dispatch(VideoPlayerEventType.AD_BREAK_FINISHED, { timestamp: event.timestamp });
  };

  public getCuePoints = () => {
    return this.cuepoints;
  };

  public getPreviousCuePointForStreamTime = (): CuePoints | null => {
    return null;
  };
}
