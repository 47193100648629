/**
 * Overrides Bitmovin's SettingsToggleButton
 * Adds hovering behaviour to the settings toggle button:
 *  - show the settings panel on hover on the button
 *  - prevent display then hide of the settings panel when the user hovers then immediately clicks on the button
 */
import { ToggleButton, Component, SettingsPanel, UIInstanceManager, ArrayUtils } from '@sbs/bitmovin-player-ui';
import { ComponentConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/component';
import { ToggleButtonConfig } from '@sbs/bitmovin-player-ui/src/ts/components/togglebutton';
import { PlayerAPI } from 'bitmovin-player';

import SettingsPanelWrapper from './SettingsPanelWrapper';

export interface OdCustomEvent {
  name: string;
  payload: Record<string, string>;
  rootElement: HTMLElement;
}

/**
 * Configuration interface for the {@link SettingsToggleButton}.
 */
export interface OdSettingsToggleButtonConfig extends ToggleButtonConfig {
  /**
   * The settings panel whose visibility the button should toggle.
   */
  settingsPanel: SettingsPanel | SettingsPanelWrapper;

  /**
   * Decides if the button should be automatically hidden when the settings panel does not contain any active settings.
   * Default: true
   */
  autoHideWhenNoActiveSettings?: boolean;

  onHideOtherPanelsEvents?: OdCustomEvent[];
}

export default class SettingsToggleButton extends ToggleButton<OdSettingsToggleButtonConfig> {
  private visibleSettingsPanels: SettingsPanel[] = [];
  private hideSettingsPanelTimeoutId: ReturnType<typeof setTimeout>;
  private lastHoverTime: number = 0;

  constructor(config: OdSettingsToggleButtonConfig) {
    super(config);

    if (!config.settingsPanel) {
      throw new Error('Required SettingsPanel or settingsPanelWrapper is missing');
    }

    const defaultConfig: OdSettingsToggleButtonConfig = {
      cssClass: 'ui-settingstogglebutton',
      text: 'Settings',
      settingsPanel: null,
      autoHideWhenNoActiveSettings: true,
      role: 'pop-up button',
      onHideOtherPanelsEvents: [],
    };

    this.config = this.mergeConfig(config, defaultConfig as OdSettingsToggleButtonConfig, this.config);

    if (this.config.settingsPanel instanceof SettingsPanel) {
      /**
       * WCAG20 standard defines which popup menu (element id) is owned by the button
       */
      this.getDomElement().attr('aria-owns', this.config.settingsPanel.getActivePage().getConfig().id);
    } else {
      this.getDomElement().attr('aria-owns', this.config.settingsPanel.getContainerId());
    }

    /**
     * WCAG20 standard defines that a button has a popup menu bound to it
     */
    this.getDomElement().attr('aria-haspopup', 'true');
  }

  hideOtherSettingsPanels(): void {
    const config = this.getConfig();

    // (We need to iterate a copy because hiding them will automatically remove themselves from the array
    // due to the subscribeOnce above)
    this.visibleSettingsPanels.slice().forEach((_settingsPanel) => {
      _settingsPanel.hide();
    });

    config.onHideOtherPanelsEvents.forEach((customEvent) => {
      const event = new CustomEvent(customEvent.name, {
        ...(customEvent.payload && ({ detail: customEvent.payload })),
      });
      customEvent.rootElement.dispatchEvent(event);
    });
  }

  setupHideOtherSettingsPanelsOnShow(uimanager: UIInstanceManager): void {
    const config = this.getConfig();
    const { settingsPanel } = config;

    this.onClick.subscribe(() => {
      const now = new Date().getTime();
      const lastHoverTimeDelta = now - this.lastHoverTime;

      // Mouse click will be ignored if hovered less than a second ago
      if (lastHoverTimeDelta > 1000) {
        // only hide other `SettingsPanel`s if a new one will be opened
        if (!settingsPanel.isShown()) {
          // Hide all open SettingsPanels before opening this button's panel
          this.hideOtherSettingsPanels();
        }

        settingsPanel.toggleHidden();
      } else {
        // Second click goes through
        this.lastHoverTime = 0;
      }
    });

    if (settingsPanel instanceof SettingsPanel) {
      settingsPanel.onShow.subscribe(() => {
        // Set toggle status to on when the settings panel shows
        this.on();
      });

      settingsPanel.onHide.subscribe(() => {
        // Set toggle status to off when the settings panel hides
        this.off();
      });
    }

    // Ensure that only one `SettingPanel` is visible at once
    // Keep track of shown SettingsPanels
    uimanager.onComponentShow.subscribe((sender: Component<ComponentConfig>) => {
      if (sender instanceof SettingsPanel) {
        this.visibleSettingsPanels.push(sender);
        sender.onHide.subscribeOnce(() => {
          ArrayUtils.remove(this.visibleSettingsPanels, sender);
        });
      }
    });
  }

  setupHoverBehaviour(): void {
    const config = this.getConfig();
    const { settingsPanel } = config;

    const hideSettingsPanel = () => {
      if (
        !settingsPanel.isShown()
        || this.isHovered()
        || settingsPanel.isHovered()
      ) {
        return;
      }

      if (this.hideSettingsPanelTimeoutId) {
        clearTimeout(this.hideSettingsPanelTimeoutId);
      }

      this.hideSettingsPanelTimeoutId = setTimeout(() => {
        if (
          !this.isHovered()
          && !settingsPanel.isHovered()
        ) {
          settingsPanel.hide();
        }
      }, 200);
    };

    this.onHoverChanged.subscribe(() => {
      if (this.isHovered() && !settingsPanel.isShown()) {
        this.lastHoverTime = new Date().getTime();

        if (this.hideSettingsPanelTimeoutId) {
          clearTimeout(this.hideSettingsPanelTimeoutId);
        }

        this.hideOtherSettingsPanels();
        settingsPanel.show();
      } else {
        hideSettingsPanel();
      }
    });

    settingsPanel.onHoverChanged.subscribe(() => {
      if (settingsPanel.isHovered()) {
        if (this.hideSettingsPanelTimeoutId) {
          clearTimeout(this.hideSettingsPanelTimeoutId);
        }
      } else {
        hideSettingsPanel();
      }
    });
  }

  setupAutoHideWhenNoActiveSettings(): void {
    const config = this.getConfig();
    const { settingsPanel } = config;

    if (settingsPanel instanceof SettingsPanel) {
      // Handle automatic hiding of the button if there are no settings for the user to interact with
      if (config.autoHideWhenNoActiveSettings) {
        // Setup handler to show/hide button when the settings change
        const settingsPanelItemsChangedHandler = () => {
          if (settingsPanel.rootPageHasActiveSettings()) {
            if (this.isHidden()) {
              this.show();
            }
          } else if (this.isShown()) {
            this.hide();
          }
        };

        // Wire the handler to the event
        settingsPanel.onSettingsStateChanged.subscribe(settingsPanelItemsChangedHandler);
        // Call handler for first init at startup
        settingsPanelItemsChangedHandler();
      }
    }
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);
    this.setupHideOtherSettingsPanelsOnShow(uimanager);
    this.setupHoverBehaviour();
    this.setupAutoHideWhenNoActiveSettings();
  }
}
