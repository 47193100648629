import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PlayIcon from '@@icons/Play';

import Button from '../../../Inputs/Button';
import TimedButton from '../../../Inputs/TimedButton';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      position: 'absolute',
      width: 260,
      bottom: 194,
      right: 57,
    },
  });
});

export interface AutoplayNextEpisodeButtonsProps {
  onActionTimeout?(): void,
  onActionClick?(): void,
  onCancelClick?(): void,
  delay: number;
}

const AutoplayNextEpisodeButtons: FunctionComponent<AutoplayNextEpisodeButtonsProps> = (props) => {
  const {
    onActionTimeout,
    onActionClick,
    onCancelClick,
    delay,
  } = props;

  const classes = useStyles(props);
  const { t } = useTranslation('common');

  // when user click 'keep watching', we'll hide the buttons forever
  const [cancelled, setCancelled] = useState(false);

  const handleActionClick = useCallback(() => {
    if (onActionClick) {
      onActionClick();
    }
  }, [onActionClick]);

  const handleActionTimeout = useCallback(() => {
    if (onActionTimeout) {
      onActionTimeout();
    }
  }, [onActionTimeout]);

  const handleCancelClick = useCallback(() => {
    setCancelled(true);

    if (onCancelClick) {
      onCancelClick();
    }
  }, [onCancelClick]);

  if (cancelled) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={1} alignItems="stretch">
        <Grid item>
          <TimedButton
            fullWidth
            buttonType="primary"
            size="large"
            onTimeout={handleActionTimeout}
            delay={delay}
            onClick={handleActionClick}
            startIcon={<PlayIcon/>}
          >
            {t('videoPlayer.playNextEpisode')}
          </TimedButton>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            buttonType="secondary"
            size="large"
            onClick={handleCancelClick}
          >
            {t('videoPlayer.keepWatching')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AutoplayNextEpisodeButtons;
