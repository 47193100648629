import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { recommendedEndCard } from '@@src/apis/RecommendationApi';
import { getSingleRecommendation } from '@@src/apis/VideoRecommendationsApi';
import { PlayerMetadata } from '@@src/pages/WatchPage';
import { canUseSmartRecommendation } from '@@src/utils/helpers';
import OnDemand from '@@types/OnDemand';
import DataLayer from '@@utils/DataLayer';
import Browser from '@@utils/newrelicAgent/Browser';

import BitmovinClient from '../../../../lib/VideoPlayer/BitmovinClient';
import RecommendationCard from './RecommendationCard';

interface RecommendationEndCardPluginComponentProps {
  bitmovinClient: BitmovinClient;
  video: OnDemand.Video;
  playerMetadata: PlayerMetadata;
  displayTime?: number;
}
const RecommendationEndCardPluginComponent: FunctionComponent<RecommendationEndCardPluginComponentProps> = (props) => {
  const {
    video,
    bitmovinClient,
    displayTime = null,
    playerMetadata,
  } = props;

  // show the recommendation card after displayTime
  const [show, setShow] = useState<boolean>(false);

  // disable the recommendation card temporarily
  // it may be re-enabled on a certain condition
  const [disabled, setDisabled] = useState<boolean>(false);

  // the rec end card is dismissed
  const [dismissed, setDismissed] = useState<boolean>(false);

  const theme = useTheme();
  const mediaMatchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (mediaMatchesSmDown) {
      setDisabled(true);
    } else if (!dismissed) {
      setDisabled(false);
    }
  }, [mediaMatchesSmDown, dismissed]);

  const [recommendedVideo, setRecommendedVideo] = useState<OnDemand.Video>();

  // new recommendation POC
  const getRecommendation = useCallback(() => {
    if (video) {
      const useSmartRecommendation = canUseSmartRecommendation(video.entityType);

      Browser.addPageAction('od-feature-personalised', {
        recName: 'Rec End Card',
        displayed: useSmartRecommendation ? 'yes' : 'no',
      });

      if (useSmartRecommendation) {
        recommendedEndCard(video.catalogueId).then((_recommendedVideo) => {
          if (_recommendedVideo) {
            if (_recommendedVideo.entityType === 'MOVIE' || _recommendedVideo.entityType === 'TV_PROGRAM' || _recommendedVideo.entityType === 'TV_EPISODE') {
              setRecommendedVideo(_recommendedVideo);
            }
          }
        });
      } else {
        getSingleRecommendation(video.id).then((_recommendedVideo) => {
          if (_recommendedVideo) {
            setRecommendedVideo(_recommendedVideo);
          }
        });
      }
    }
  }, [video]);

  // when player is started, get the recommendation
  useEffect(() => {
    if (!disabled && bitmovinClient.playerEvents) {
      bitmovinClient.on('ContentStarted', getRecommendation);
    }
    return () => {
      if (bitmovinClient.playerEvents) {
        bitmovinClient.off('ContentStarted', getRecommendation);
      }
    };
  }, [bitmovinClient, disabled, getRecommendation]);

  const shrinkPlayer = useCallback(() => {
    const videoElement = bitmovinClient.getVideoElement();
    // show the mini player
    videoElement.classList.add('endcardFloat');
    videoElement.addEventListener('click', () => {
      videoElement.classList.remove('endcardFloat');

      // We need this for the player to grow back to its previous position
      // without it, it will grow from top/right corner instead of reversing the animation
      videoElement.classList.add('endcardFloatEnd');

      setTimeout(() => {
        if (videoElement.classList.contains('endcardFloatEnd')) {
          videoElement.classList.remove('endcardFloatEnd');
        }
      }, 350);

      // set dismiss state when the mini player is clicked
      setDismissed(true);

      DataLayer.events.recEndCard(
        'recommendedEndCard_exit',
        video,
        recommendedVideo,
        playerMetadata,
        {
          videoNavigationEvent: {
            recommendedEndCard: 'exit',
          },
        },
      );
    });
  }, [bitmovinClient, playerMetadata, recommendedVideo, video]);

  const growPlayer = useCallback((dismiss: boolean = true) => {
    const videoElement = bitmovinClient.getVideoElement();
    videoElement.classList.remove('endcardFloat');

    // We need this for the player to grow back to its previous position
    // without it, it will grow from top/right corner instead of reversing the animation
    videoElement.classList.add('endcardFloatEnd');

    setTimeout(() => {
      if (videoElement.classList.contains('endcardFloatEnd')) {
        videoElement.classList.remove('endcardFloatEnd');
      }
    }, 350);

    if (dismiss) {
      // set dismiss state when the mini player is clicked
      setDismissed(true);
    }
  }, [bitmovinClient]);

  const hideMiniPlayer = useCallback(() => {
    const videoElement = bitmovinClient.getVideoElement();
    videoElement.style.display = 'none';
    videoElement.classList.remove('endcardFloat');
  }, [bitmovinClient]);

  const displayRecommendation = useCallback(() => {
    setShow(true);
    DataLayer.events.recEndCard(
      'recommendedEndCard_display',
      video,
      recommendedVideo,
      playerMetadata,
      {
        videoNavigationEvent: {
          recommendedEndCard: 'display',
        },
      },
    );
  }, [playerMetadata, recommendedVideo, video]);

  const checkShouldDisplayRecommendation = useCallback((event) => {
    const { time } = event;

    const displayStreamTime = bitmovinClient.streamTimeForContentTime(displayTime);

    if (!show && time >= displayStreamTime) {
      shrinkPlayer();
      displayRecommendation();
    }
  }, [bitmovinClient, displayTime, show, displayRecommendation, shrinkPlayer]);

  // when playing, check if we should display the recommendation
  useEffect(() => {
    if (displayTime && !disabled && bitmovinClient.playerEvents) {
      bitmovinClient.on('Playing', checkShouldDisplayRecommendation);
    }

    return () => {
      if (displayTime && bitmovinClient.playerEvents) {
        bitmovinClient.off('Playing', checkShouldDisplayRecommendation);
      }
    };
  }, [bitmovinClient, checkShouldDisplayRecommendation, disabled, displayTime]);

  const displayRecommendationOnPlayFinished = useCallback(() => {
    setDismissed(false);
    displayRecommendation();
    hideMiniPlayer();
  }, [displayRecommendation, hideMiniPlayer]);

  // when content is finished, display the recommendation without the mini player
  useEffect(() => {
    const handleCastFinished = () => {
      if (show) {
        bitmovinClient.player.unload();
      }
    };

    if (!disabled && bitmovinClient.playerEvents) {
      bitmovinClient.on('PlayFinished', displayRecommendationOnPlayFinished);
      bitmovinClient.on('CastFinished', handleCastFinished);
    }

    return () => {
      if (bitmovinClient.playerEvents) {
        bitmovinClient.off('PlayFinished', displayRecommendationOnPlayFinished);
        bitmovinClient.off('CastFinished', handleCastFinished);
      }
    };
  }, [bitmovinClient, disabled, displayRecommendationOnPlayFinished, growPlayer, hideMiniPlayer, show]);

  const handleClickPlay = useCallback(() => {
    DataLayer.events.recEndCard(
      'recommendedEndCard_tap',
      video,
      recommendedVideo,
      playerMetadata,
      {
        videoNavigationEvent: {
          recommendedEndCard: 'tap',
        },
      },
    );

    hideMiniPlayer();

    // re-grow the player in the background in preparation for next video
    growPlayer();
  }, [playerMetadata, recommendedVideo, video, growPlayer, hideMiniPlayer]);

  const handleClickMoreInfo = useCallback(() => {
    DataLayer.events.recEndCard(
      'recommendedEndCard_info',
      video,
      recommendedVideo,
      playerMetadata,
      {
        videoNavigationEvent: {
          recommendedEndCard: 'info',
        },
      },
    );
  }, [playerMetadata, recommendedVideo, video]);

  if (disabled || dismissed || !show || !recommendedVideo) {
    return null;
  }

  return (
    <RecommendationCard
      video={recommendedVideo}
      onClickPlay={handleClickPlay}
      onClickMoreInfo={handleClickMoreInfo}
    />
  );
};

export default RecommendationEndCardPluginComponent;
