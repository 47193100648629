import VideoPlayer from '@@src/lib/VideoPlayerV2/VideoPlayer';
import {
  VideoPlayerEventCallback,
  VideoPlayerEventType, VideoPlayerPlaybackEvent,
} from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import OnDemand from '@@types/OnDemand';
import { PlaybackStreamData } from '@@types/PlaybackStreamData';
import DataLayer, { PersonalisationData } from '@@utils/DataLayer';

interface PlaybackEvents {
  type: VideoPlayerEventType;
  handler: VideoPlayerEventCallback;
}

const actionMap = {
  videoLoaded: 'load',
  videoStarted: 'start',
  videoChapterBreak: 'chapterBreak',
  videoMilestone25: 'milestone25',
  videoMilestone50: 'milestone50',
  videoMilestone75: 'milestone75',
  videoMilestone95: 'milestone95',
  videoCompleted: 'complete',
  videoSkipBackward: 'skipBackward',
  videoSkipForward: 'skipForward',
  videoPause: 'pause',
  // unsupported events have a false value
  videoSubtitle_On: false,
  videoSubtitle_Off: false,
  videoAudioTrack_On: false,
} as const;

type PlaybackEventName = keyof typeof actionMap;

class VideoPlaybackTrackingCore {
  private video: OnDemand.Movie | OnDemand.Episode | OnDemand.OneOff | null;
  private playbackStreamData: PlaybackStreamData;
  private videoPlayer: VideoPlayer;
  private events: PlaybackEvents[] = [];
  private supportedMilestones = [25, 50, 75, 95];
  private supportedMilestonesPositions: number[] = [];
  private currentMilestone = 0;
  private personalisation: PersonalisationData | undefined;

  public constructor(
    videoPlayer: VideoPlayer,
    video: OnDemand.Movie | OnDemand.Episode | OnDemand.OneOff | null,
    playbackStreamData: PlaybackStreamData,
    personalisation?: PersonalisationData,
  ) {
    this.videoPlayer = videoPlayer;
    this.video = video;
    this.playbackStreamData = playbackStreamData;
    this.personalisation = personalisation;
    this.events = [
      { type: VideoPlayerEventType.SOURCE_LOADED, handler: this.onSourceLoaded },
      { type: VideoPlayerEventType.CONTENT_STARTED, handler: this.onContentStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_BREAK_STARTED, handler: this.onAdBreakStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AD_BREAK_FINISHED, handler: this.onAdBreakFinished as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.TIME_CHANGED, handler: this.onTimeChanged as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.SUBTITLE_ENABLE, handler: this.onSubtitleEnabled as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.SUBTITLE_DISABLE, handler: this.onSubtitleDisabled as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.AUDIO_CHANGED, handler: this.onAudioChanged as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.PAUSED, handler: this.onPaused as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.PLAYBACK_FINISHED, handler: this.onPlaybackFinished as VideoPlayerEventCallback },
    ];
    this.registerEvents();
  }

  private sendDataLayerVideoTracking = (actionName: PlaybackEventName, additionalAttributes: any = {}) => {
    DataLayer.updatePlayerInfo(this.videoPlayer.getPlayerMetadata(), this.videoPlayer.getUserSettings());
    const currentChapterData = this.videoPlayer.getCurrentChapterData();

    let mediaObject: any = {
      mpxId: '',
      pilatId: '',
      mediaType: 'video',
      entityId: '',
      parentEntityId: '',
      videoDetails: {
        title: '',
        classification: '',
        collection: [],
        contentType: '',
        mediaPart: '',
        channel: [],
        country: [],
        genre: [],
        subgenre: [],
        language: [],
        entityType: '',
        parentEntityType: '',
        episodeDetails: {
          seriesTitle: '',
          episodeNumber: '',
          seasonNumber: '',
        },
      },
    };

    if (this.video) {
      mediaObject.entityId = this.video.catalogueId || '';
      mediaObject.videoDetails.entityType = this.video.entityType || '';
      mediaObject.videoDetails.title = this.video.cdpTitle ?? this.video.title;
      mediaObject.mpxId = this.video.id;
      mediaObject.pilatId = this.video.pilatId ?? '';

      mediaObject.videoDetails.contentType = (this.video.entityType || '').toLowerCase();
      mediaObject.videoDetails.channel = this.video.channels ?? [];

      if (this.video.type === 'Episode') {
        mediaObject.parentEntityId = this.video.episodeData?.seriesId ?? '';
        mediaObject.videoDetails.parentEntityType = this.video.episodeData?.seriesEntityType ?? '';
        mediaObject.videoDetails.episodeDetails.seriesTitle = this.video.episodeData?.programName ?? '';
        mediaObject.videoDetails.episodeDetails.episodeNumber = this.video.episodeData?.episodeNumber.toString() ?? '';
        mediaObject.videoDetails.episodeDetails.seasonNumber = this.video.episodeData?.seasonNumber.toString() ?? '';
      }

      mediaObject.videoDetails.language = this.video.languages ?? [];
      mediaObject.videoDetails.country = this.video.countries ?? [];
      mediaObject.videoDetails.classification = this.video.classification ?? '';
      mediaObject.videoDetails.genre = this.video.genres ?? [];
      mediaObject.videoDetails.subgenre = this.video.subgenres ? this.video.subgenres.map((subGenre) => {
        return subGenre.replace(/^.*?\//, '');
      }) : [];

      mediaObject.videoDetails.collection = this.video.collections ?? [];
    }

    if (this.playbackStreamData) {
      mediaObject.mpxId = this.playbackStreamData.mpxId;
      mediaObject.videoDetails.title = this.playbackStreamData.cdpTitle;
      mediaObject.videoDetails.title = this.playbackStreamData.cdpTitle;
      mediaObject.videoDetails.classification = this.playbackStreamData.classification;
    }

    if (additionalAttributes.videoPlayerEvent) {
      mediaObject = {
        ...mediaObject,
        videoPlayerEvent: additionalAttributes.videoPlayerEvent,
      };
    }

    if (additionalAttributes.videoNavigationEvent) {
      mediaObject = {
        ...mediaObject,
        videoNavigationEvent: additionalAttributes.videoNavigationEvent,
      };
    }

    if (actionMap[actionName]) {
      mediaObject = {
        ...mediaObject,
        progress: actionMap[actionName],
      };
    }

    if (currentChapterData.current !== undefined) {
      if (actionName === 'videoCompleted') {
        mediaObject.videoDetails.mediaPart = `${currentChapterData.current + 1}.${currentChapterData.total}`;
      } else {
        mediaObject.videoDetails.mediaPart = `${currentChapterData.current}.${currentChapterData.total}`;
      }
    }

    DataLayer.events.videoPlaybackTrackingEvent(actionName, {
      media: mediaObject,
      personalisation: this.personalisation ?? { recommendationId: '', recommendationVariantName: '' },
    });
  };

  private onSourceLoaded = () => {
    this.sendDataLayerVideoTracking('videoLoaded');

    const videoDuration = this.video?.duration ?? this.playbackStreamData.duration;
    this.supportedMilestones.forEach((milestone) => {
      const milestoneInSeconds = (milestone / 100) * videoDuration;
      this.supportedMilestonesPositions.push(milestoneInSeconds);
    });
  };

  private onAdBreakStarted = () => {
    this.checkMilestone();
  };

  private onAdBreakFinished = () => {
    const currentChapterData = this.videoPlayer.getCurrentChapterData();
    if (currentChapterData.current > 1) {
      this.sendDataLayerVideoTracking('videoChapterBreak');
    }
  };

  private getMilestone(contentTime: number): { value: number, delta: number } {
    let milestoneReached = 0;
    let milestoneDelta = 0;

    this.supportedMilestonesPositions.forEach((milestonePosition, index) => {
      if (contentTime >= milestonePosition) {
        milestoneReached = this.supportedMilestones[index];
        milestoneDelta = Math.abs(milestonePosition - contentTime);
      }
    });

    return {
      value: milestoneReached,
      delta: milestoneDelta,
    };
  }

  private checkMilestone = () => {
    const contentTime = this.videoPlayer.getCurrentContentTime();
    const milestoneData = this.getMilestone(contentTime);

    if (milestoneData.value > this.currentMilestone) {
      this.currentMilestone = milestoneData.value;

      if (milestoneData.delta <= 1) {
        this.sendDataLayerVideoTracking(`videoMilestone${milestoneData.value}` as PlaybackEventName);
      }
    }
  };

  private onTimeChanged = () => {
    this.checkMilestone();
  };

  private onContentStarted = () => {
    this.checkMilestone();
    this.sendDataLayerVideoTracking('videoStarted');
  };

  private onSubtitleEnabled = () => {
    this.sendDataLayerVideoTracking(
      'videoSubtitle_On',
      {
        videoPlayerEvent: {
          videoSubtitle: 'On',
        },
      },
    );
  };

  private onSubtitleDisabled = () => {
    this.sendDataLayerVideoTracking(
      'videoSubtitle_Off',
      {
        videoPlayerEvent: {
          videoSubtitle: 'Off',
        },
      },
    );
  };

  private onAudioChanged = () => {
    this.sendDataLayerVideoTracking(
      'videoAudioTrack_On',
      {
        videoPlayerEvent: {
          videoAudioTrack: 'On',
        },
      },
    );
  };

  private onPaused = (event: VideoPlayerPlaybackEvent) => {
    if (event.issuer !== 'ui-seek') {
      this.sendDataLayerVideoTracking('videoPause');
    }
  };

  private onPlaybackFinished = () => {
    this.sendDataLayerVideoTracking('videoCompleted');
  };

  private registerEvents = () => {
    this.events.forEach((event) => {
      this.videoPlayer.on(event.type, event.handler);
    });
  };

  private unRegisterEvents = () => {
    this.events.forEach((event) => {
      this.videoPlayer.off(event.type, event.handler);
    });
  };

  public destroy = () => {
    this.unRegisterEvents();
  };
}

export default VideoPlaybackTrackingCore;
