import { get } from 'lodash';

import { VideoPlayerApi } from '@@src/pages/WatchPage';
import DataLayer, { ODEventData } from '@@utils/DataLayer';

import VideoPlayerPluginV2 from '../VideoPlayerPluginV2';

class DataLayerTrackingPluginV2 extends VideoPlayerPluginV2 {
  public constructor(videoPlayer: VideoPlayerApi) {
    super(videoPlayer);
    this.registerEventHandlers();
  }

  protected registerEventHandlers() {
    this.playerEvents.on('SourceLoaded', this.onSourceLoaded);
    this.playerEvents.on('AdStarted', this.onAdStarted);
    this.playerEvents.on('AdFinished', this.onAdFinished);
    this.playerEvents.on('AdBreakFinished', this.onAdBreakFinished);
    this.playerEvents.on('Milestone', this.onMilestone);
    this.playerEvents.on('ContentStarted', this.onContentStarted);
    this.playerEvents.on('Paused', this.onPaused);
    this.playerEvents.on('PlayFinished', this.onPlayFinished);
    this.playerEvents.on('AudioChanged', this.onAudioChanged);
    this.playerEvents.on('SubtitleDisabled', this.onSubtitleDisabled);
    this.playerEvents.on('SubtitleEnabled', this.onSubtitleEnabled);

    this.bitmovinClient.getContainerElement().addEventListener('adHolidaySkipSnapback', this.reportAdHoliday);
  }

  protected onDestroy() {
    if (this.playerEvents) {
      this.playerEvents.off('SourceLoaded', this.onSourceLoaded);
      this.playerEvents.off('AdStarted', this.onAdStarted);
      this.playerEvents.off('AdFinished', this.onAdFinished);
      this.playerEvents.off('AdBreakFinished', this.onAdBreakFinished);
      this.playerEvents.off('Milestone', this.onMilestone);
      this.playerEvents.off('ContentStarted', this.onContentStarted);
      this.playerEvents.off('Paused', this.onPaused);
      this.playerEvents.off('PlayFinished', this.onPlayFinished);
      this.playerEvents.off('AudioChanged', this.onAudioChanged);
      this.playerEvents.off('SubtitleDisabled', this.onSubtitleDisabled);
      this.playerEvents.off('SubtitleEnabled', this.onSubtitleEnabled);
    }
  }

  private onMilestone = (event: ODEventData) => {
    if (!this.bitmovinClient.state.isLivestream) {
      const supportedMilestones = [25, 50, 75, 95];

      // Other tracking plugins might require different milestones
      // but we only send the one that DL supports
      if (supportedMilestones.indexOf(event.milestone) !== -1) {
        this.sendDataLayerVideoTracking(`videoMilestone${event.milestone}`, event, {
          milestone: event.milestone,
        });
      }
    }
  };

  private onContentStarted = (event: ODEventData) => {
    this.sendDataLayerVideoTracking('videoStarted', event);
  };

  private onAdBreakFinished = (event: ODEventData) => {
    const { currentChapterData } = event;

    // do not send chapter information for Livestreams as it's not needed
    if (this.bitmovinClient.state.isLivestream) {
      this.sendDataLayerVideoTracking('videoChapterBreak');
    } else if (currentChapterData.current > 1) {
      this.sendDataLayerVideoTracking('videoChapterBreak', event);
    }
  };

  private onPaused = (event: ODEventData) => {
    this.sendDataLayerVideoTracking('videoPause', event);
  };

  private onPlayFinished = (event: ODEventData) => {
    this.sendDataLayerVideoTracking('videoCompleted', event);
  };

  private onAudioChanged = (event: ODEventData) => {
    DataLayer.updatePlayerInfo(this.videoPlayer.getPlayerMetadata(), this.bitmovinClient.getUserSettings());
    this.sendDataLayerVideoTracking('videoAudioTrack_On', event, {
      videoPlayerEvent: {
        videoAudioTrack: 'On',
      },
    });
  };

  private onSubtitleEnabled = (event: ODEventData) => {
    DataLayer.updatePlayerInfo(this.videoPlayer.getPlayerMetadata(), this.bitmovinClient.getUserSettings());
    this.sendDataLayerVideoTracking('videoSubtitle_On', event, {
      videoPlayerEvent: {
        videoSubtitle: 'On',
      },
    });
  };

  private onSubtitleDisabled = (event: ODEventData) => {
    DataLayer.updatePlayerInfo(this.videoPlayer.getPlayerMetadata(), this.bitmovinClient.getUserSettings());
    this.sendDataLayerVideoTracking('videoSubtitle_Off', event, {
      videoPlayerEvent: {
        videoSubtitle: 'Off',
      },
    });
  };

  private reportAdHoliday = (event: any) => {
    this.sendDataLayerVideoTracking('videoAdHoliday', event.detail);
  };

  private onSourceLoaded = () => {
    DataLayer.updatePlayerInfo(this.videoPlayer.getPlayerMetadata(), this.bitmovinClient.getUserSettings());
    this.sendDataLayerVideoTracking('videoLoaded');
  };

  private onAdStarted = (event: ODEventData) => {
    this.sendDataLayerVideoTracking('videoAdStart', event);
  };

  private onAdFinished = (event: ODEventData) => {
    this.sendDataLayerVideoTracking('videoAdComplete', event);
  };

  public sendDataLayerVideoTracking = (actionName, event = {}, additionalAttributes = {}) => {
    const milestone = get(event, 'milestone');
    const adMetadata = get(event, 'adMetadata');
    const currentChapterData = get(event, 'currentChapterData');
    const _additionalAttributes = {
      ...additionalAttributes,
      ...(currentChapterData && { currentChapterData }),
      ...(adMetadata && { adMetadata }),
      ...(milestone && { milestone }),
    };

    if (!this.bitmovinClient.state.isCasting) {
      DataLayer.events.videoTrackingEvent(
        actionName,
        this.videoPlayer.getVideo(),
        _additionalAttributes,
      );
    }
  };
}

export default DataLayerTrackingPluginV2;
