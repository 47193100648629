import { UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { Button, ButtonConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/button';
import { PlayerAPI, PlayerEvent } from 'bitmovin-player';
import i18n from 'i18next';

interface SeekBackButtonConfig extends ButtonConfig {
  seekDuration: number;
}

/**
 * A button that toggles between playback and pause.
 */
export default class SeekBackButton extends Button<SeekBackButtonConfig> {
  constructor(config: Optional<SeekBackButtonConfig, 'seekDuration'> = {}) {
    const seekDuration: number = config.seekDuration || 10;
    super({
      ...config,
      ariaLabel: i18n.t('common:videoPlayer.seekBack', { seekDuration }),
      cssClasses: ['ui-seekbackbutton', 'ui-button'],
      seekDuration,
    });
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);
    const config = this.getConfig();

    const checkTimeShift = () => {
      if (!player.isLive()) {
        return;
      }

      const isTimeshiftAvailable = player.getMaxTimeShift() < -10;
      if (!isTimeshiftAvailable) {
        this.disable();
      } else {
        this.enable();
      }
    };

    checkTimeShift();

    player.on(PlayerEvent.TimeShifted, checkTimeShift);
    player.on(PlayerEvent.Ready, checkTimeShift);
    player.on(PlayerEvent.Play, checkTimeShift);

    this.onClick.subscribe(() => {
      if (player.isLive()) {
        player.timeShift(player.getTimeShift() - config.seekDuration, 'ui-seekbackbutton');
      } else {
        player.seek(Math.max(0, player.getCurrentTime() - config.seekDuration), 'ui-seekbackbutton');
      }
    });
  }
}
