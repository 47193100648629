import { IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ArrowBackIcon } from '@@src/images/icons/arrow-back.svg';
import blue from '@@styles/colors/blue';

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    arrowBackButton: {
      pointerEvents: 'auto',
      position: 'absolute',
      padding: 0,
      top: 61,
      left: 38,
      borderRadius: 0,
      opacity: 0.8,
      transition: 'left 0.3s ease-in-out, top 0.3s ease-in-out',
      '&:focus-visible': {
        outline: `3px solid ${blue.navy}`,
        borderRadius: 4,
      },
      '.show &:hover': {
        background: 'transparent',
        opacity: 1,
      },
      zIndex: 9999,
      [theme.breakpoints.down('sm')]: {
        top: 18,
        left: 16,
      },
    },
    arrowBackIcon: {
      width: 42,
      height: 42,
      transition: 'left 0.3s ease-in-out, top 0.3s ease-in-out',
      [theme.breakpoints.up('md')]: {
        width: 48,
        height: 48,
      },
    },
  });
});

interface GoBackButtonProps {
  backUrl: string | undefined;
  onClick?: () => void;
}

const GoBackButton: FunctionComponent<GoBackButtonProps> = (props) => {
  const { backUrl, onClick = undefined } = props;
  const { t } = useTranslation('common');
  const history = useHistory();
  const classes = useStyles(props);

  const handleArrowBackClick = useCallback(() => {
    window.onbeforeunload = null;
    if (onClick) { onClick(); }

    // Give a bit of time for the pause above to trigger a progress record
    setTimeout(() => {
      if (!backUrl) {
        history.goBack();
      } else {
        history.replace(backUrl);
      }
    }, 500);
  }, [history, backUrl, onClick]);

  return (
    <IconButton
      aria-label={t('videoPlayer.close')}
      onClick={handleArrowBackClick}
      className={classes.arrowBackButton}
      data-testid="close-player"
    >
      <ArrowBackIcon className={classes.arrowBackIcon}/>
    </IconButton>
  );
};

export default GoBackButton;
