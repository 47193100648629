import { get } from 'lodash';

import VideoPlayer from '@@src/lib/VideoPlayerV2/VideoPlayer';
import {
  VideoPlayerEventCallback,
  VideoPlayerEventType,
  VideoPlayerSeekEvent,
} from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import Logger from '@@utils/logger/Logger';

export enum AdSnapBackEventType {
  AD_SNAP_BACK_STARTED = 'adSnapBackStarted', // custom event sent when we snap the user to the closest unwatched midroll slot
  AD_SNAP_BACK_FINISHED = 'adSnapBackFinished', // custom event sent when snapback process has finished
}

interface PlaybackEvents {
  type: VideoPlayerEventType;
  handler: VideoPlayerEventCallback;
}

class AdSnapBackCore {
  private videoPlayer: VideoPlayer;
  private events: PlaybackEvents[] = [];

  private readonly issuerIgnoreList = ['snapBack', 'snapForward'];
  private readonly seekTimeErrorMargin: number = 0.2;

  private isSnappingBack: boolean = false;
  private isSnappingForward: boolean = false;
  private positionAtSeekTime: number = 0;
  private seekTargetTime: number = 0;
  private enabled: boolean = true;

  public constructor(videoPlayer: VideoPlayer) {
    this.videoPlayer = videoPlayer;
    this.events = [
      { type: VideoPlayerEventType.SEEK_STARTED, handler: this.onSeekStarted as VideoPlayerEventCallback },
      { type: VideoPlayerEventType.SEEK_FINISHED, handler: this.onSeekFinished },
      { type: VideoPlayerEventType.AD_HOLIDAY_STARTED, handler: this.onAdHolidayStarted },
      { type: VideoPlayerEventType.AD_HOLIDAY_FINISHED, handler: this.onAdHolidayFinished },
      { type: VideoPlayerEventType.SOURCE_UNLOADED, handler: this.onSourceUnloaded },
      { type: VideoPlayerEventType.TIME_CHANGED, handler: this.onTimeChanged as VideoPlayerEventCallback },
    ];
    this.registerEvents();
  }

  private getSnapbackCuePoint = () => {
    if (!this.enabled) {
      Logger.info('AdSnapback: feature disabled');
      return null;
    }

    const currentChapter = this.videoPlayer.getCurrentChapterData(this.positionAtSeekTime);
    const seekChapter = this.videoPlayer.getCurrentChapterData(this.seekTargetTime);
    if (currentChapter.current === seekChapter.current) {
      Logger.info('AdSnapback: Not snapping back when seeking within the same chapter');
      return null;
    }

    if (this.seekTargetTime <= this.positionAtSeekTime) {
      Logger.info('AdSnapback: Not snapping back when seeking backward');
      return null;
    }

    const nextCuePoint = this.videoPlayer.getNextCuePointForStreamTime(this.seekTargetTime);
    let shouldScrubToMarker = false;
    if (nextCuePoint) {
      shouldScrubToMarker = Math.abs(nextCuePoint.start - this.seekTargetTime) < this.seekTimeErrorMargin;
    }

    if (shouldScrubToMarker) {
      Logger.info('AdSnapback: Not snapping back as scrubbed into the start of an ad break');
      return null;
    }

    const previousCuePoint = this.videoPlayer.getPreviousCuePointForStreamTime(this.seekTargetTime);

    const previousAdPlayed = get(previousCuePoint, 'played', true);
    if (previousAdPlayed) {
      Logger.info('AdSnapback: Not snapping back as previous ads already played');
      return null;
    }

    return previousCuePoint;
  };

  private checkSnapback = () => {
    // If snapback progress in progress
    if (this.isSnappingBack) {
      // If coming from Seek_Finished event from a snap forward
      if (this.isSnappingForward) {
        Logger.info('AdSnapback: snapback process completed');
        this.isSnappingBack = false;
        this.isSnappingForward = false;
        this.videoPlayer.dispatchCustomEvent(AdSnapBackEventType.AD_SNAP_BACK_FINISHED, { timestamp: new Date().getTime() });
      } else {
        Logger.info('AdSnapback: a snapback process is already in progress');
      }
    } else {
      const cuePoint = this.getSnapbackCuePoint();

      if (cuePoint) {
        this.videoPlayer.dispatchCustomEvent(AdSnapBackEventType.AD_SNAP_BACK_STARTED, {
          timestamp: new Date().getTime(),
          position: this.videoPlayer.getStreamTime(),
          positionContentTime: this.videoPlayer.getCurrentContentTime(),
        });

        if (this.seekTargetTime > cuePoint.end) {
          Logger.info(`AdSnapback: setting snap forward time to ${this.seekTargetTime}`);

          const snapForward = () => {
            const streamTime = this.videoPlayer.getStreamTime();
            if (this.seekTargetTime > streamTime) {
              Logger.info(`AdSnapback: snapping forward to stream time ${this.seekTargetTime}`);
              this.isSnappingForward = true;
              this.videoPlayer.seekToStreamTime(this.seekTargetTime, 'snapForward');
            }

            this.videoPlayer.off(VideoPlayerEventType.AD_BREAK_FINISHED, snapForward);
          };

          this.videoPlayer.on(VideoPlayerEventType.AD_BREAK_FINISHED, snapForward);
        }

        Logger.info(`AdSnapback: snapping back to ${cuePoint.start}`);
        this.isSnappingBack = true;
        this.videoPlayer.seekToStreamTime(cuePoint.start - this.seekTimeErrorMargin, 'snapBack');
      } else {
        this.isSnappingBack = false;
        this.isSnappingForward = false;
      }
    }
  };

  // Reset snapback if the content is playing.
  // This should not happen but it's for handling spam clicking on seek forward button or right key.
  private onTimeChanged = (event: VideoPlayerSeekEvent) => {
    const { isAd } = event;

    if (!isAd) {
      this.isSnappingBack = false;
      this.isSnappingForward = false;
    }
  };

  private onSeekStarted = (event: VideoPlayerSeekEvent) => {
    const { issuer } = event;

    // Ignoring seek events triggered by ourselves.
    if (!issuer || this.issuerIgnoreList.indexOf(issuer) === -1) {
      this.positionAtSeekTime = event.position;
      this.seekTargetTime = event.seekTarget;
    }
  };

  private onSeekFinished = () => {
    this.checkSnapback();
  };

  private onAdHolidayStarted = () => {
    Logger.info('AdSnapback: disabling feature due to ad holiday started');
    this.enabled = false;
  };

  private onAdHolidayFinished = () => {
    Logger.info('AdSnapback: enabling feature due to ad holiday ended');
    this.enabled = true;
  };

  private onSourceUnloaded = () => {
    this.isSnappingBack = false;
    this.isSnappingForward = false;
    this.enabled = true;
    this.seekTargetTime = 0;
    this.positionAtSeekTime = 0;
  };

  private registerEvents = () => {
    this.events.forEach((event) => {
      this.videoPlayer.on(event.type, event.handler);
    });
  };

  private unRegisterEvents = () => {
    this.events.forEach((event) => {
      this.videoPlayer.off(event.type, event.handler);
    });
  };

  public destroy = () => {
    this.unRegisterEvents();
  };
}

export default AdSnapBackCore;
