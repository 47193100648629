/**
 * Custom subtitles list, see OdSubtitleUtils.ts for the main code
 */
import { ListBox, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { PlayerAPI } from 'bitmovin-player';

import { SubtitleSwitchHandler } from '../utils/subtitleutils';

/**
 * A element that is similar to a select box where the user can select a subtitle
 */
export default class SubtitleListBox extends ListBox {
  private subtitleSwitchHandler: SubtitleSwitchHandler | undefined;
  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);
    // eslint-disable-next-line no-new
    this.subtitleSwitchHandler = new SubtitleSwitchHandler(player, this, uimanager);
  }
}
