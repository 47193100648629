import VideoPlayerPluginV2WithReactPortal from '../VideoPlayerPluginV2WithReactPortal';
import AdOnPausePluginComponent from './AdOnPausePluginComponent';

class AdOnPausePluginV2 extends VideoPlayerPluginV2WithReactPortal {
  protected videoCardPosition: 'above-control-bar' = 'above-control-bar';

  public shouldLoad(): boolean {
    // ad on pause should only be loaded on vod
    return !this.videoPlayer.getVideo().isLiveStream;
  }

  protected component() {
    return (
      <AdOnPausePluginComponent
        bitmovinClient={this.bitmovinClient}
        video={this.videoPlayer.getVideo()}
        creditsBegin={this.videoPlayer.getTrackingTime('credits_begin')}
      />
    );
  }
}

export default AdOnPausePluginV2;
