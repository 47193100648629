import { SettingsPanel, SettingsPanelItem, SettingsPanelPage } from '@sbs/bitmovin-player-ui';
import { Component, ComponentConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/component';

export default class ReactSettingsPanel extends SettingsPanel {
  constructor(config: ComponentConfig = {}) {
    super({
      components: [
        new SettingsPanelPage({
          components: [
            new SettingsPanelItem('', new Component(), {}),
          ],
        }),
      ],
      pageTransitionAnimation: false,
      ...config,
    });
  }
}
