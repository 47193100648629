import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import OnDemand from '@@types/OnDemand';
import DataLayer from '@@utils/DataLayer';

import BitmovinClient from '../../../../lib/VideoPlayer/BitmovinClient';
import AdOverlay from './AdOverlay';

interface AdOnPausePluginComponentProps {
  bitmovinClient: BitmovinClient;
  video: OnDemand.Video;
  creditsBegin: null | number;
}

const AdOnPausePluginComponent: FunctionComponent<AdOnPausePluginComponentProps> = (props) => {
  const { bitmovinClient, video, creditsBegin } = props;

  const [show, setShow] = useState<boolean>(false);
  const [dismissed, setDismissed] = useState<boolean>(false);

  const showTimeout = useRef<number>();

  const { width, height } = useWindowSize();

  const minWidth = 1280;
  const minHeight = 800;

  const sizeRequirementMet = width >= minWidth && height >= minHeight;

  const showOnPaused = useCallback((event) => {
    const { time } = event;

    const isAd = bitmovinClient.streamTimeIsAd(time);

    let shouldShow: boolean = isAd === false
      && !bitmovinClient.state.isCasting
      && sizeRequirementMet
      // don't show ad when autoplay doesn't work. time is 0 when autoplay doesn't work regardless of resume position
      && time > 0;

    if (creditsBegin) {
      const creditsBeginStreamTime = bitmovinClient.streamTimeForContentTime(creditsBegin);
      shouldShow = shouldShow && time < creditsBeginStreamTime;
    }

    if (shouldShow) {
      // add a second delay to show the ad
      showTimeout.current = setTimeout(() => {
        setShow(true);
      }, 1000);
    }

    return () => {
      if (showTimeout.current) {
        clearTimeout(showTimeout.current);
      }
    };
  }, [bitmovinClient, creditsBegin, sizeRequirementMet]);

  const hideOnPlay = useCallback(() => {
    if (showTimeout.current) {
      clearTimeout(showTimeout.current);
    }
    setShow(false);
    // when playing, we clear the dismissed flag so that we can display another ad when the user pauses again.
    setDismissed(false);
  }, []);

  useEffect(() => {
    if (bitmovinClient.playerEvents) {
      bitmovinClient.playerEvents.on('Paused', showOnPaused);
    }
    return () => {
      if (bitmovinClient.playerEvents) {
        bitmovinClient.playerEvents.off('Paused', showOnPaused);
      }
    };
  }, [bitmovinClient, showOnPaused]);

  useEffect(() => {
    if (bitmovinClient.playerEvents) {
      bitmovinClient.playerEvents.on('Play', hideOnPlay);
    }
    return () => {
      if (bitmovinClient.playerEvents) {
        bitmovinClient.playerEvents.off('Play', hideOnPlay);
      }
    };
  }, [bitmovinClient, hideOnPlay]);

  const handleDismissed = useCallback((resume: boolean = false) => {
    setDismissed(true);
    DataLayer.events.adOnPauseDismiss(
      'userInteraction',
      {
        userInteractionDetails: {
          interactionName: 'ondemand:web:video-pause-ad-overlay:ad-dismiss',
        },
      },
    );

    if (resume) {
      bitmovinClient.player.play();
    }
  }, [bitmovinClient.player]);

  if (!show || !sizeRequirementMet || dismissed) return null;

  return <AdOverlay video={video} onDismissed={handleDismissed}/>;
};

export default AdOnPausePluginComponent;
