import { Button, PlayerUtils, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { ButtonConfig } from '@sbs/bitmovin-player-ui/src/ts/components/button';
import { PlayerAPI, PlayerEvent } from 'bitmovin-player';

class RewindButton extends Button<ButtonConfig> {
  constructor(config: ButtonConfig = {}) {
    super(config);

    this.config = this.mergeConfig(config, {
      ariaLabel: 'Rewind',
      cssClass: 'ui-rewindbutton',
    } as ButtonConfig, this.config);
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);

    const checkTimeShift = () => {
      if (!player.isLive()) {
        return;
      }

      const buttonElement = this.getDomElement();

      if (!PlayerUtils.isTimeShiftAvailable(player)) {
        buttonElement.addClass('disabled');
      } else {
        buttonElement.removeClass('disabled');
      }
    };

    player.on(PlayerEvent.TimeShifted, checkTimeShift);
    player.on(PlayerEvent.Ready, checkTimeShift);
  }
}

export default RewindButton;
