/**
 * Overrides Bitmovin's VideoQualityListBox.
 * Allows processing and filtering video qualities returned by the stream.
 */
import { ListBox, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { ListSelectorConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/listselector';
import { PlayerAPI } from 'bitmovin-player';

import Logger from '@@utils/logger/Logger';

/**
 * A element that is similar to a select box where the user can select a subtitle
 */
export default class VideoQualityListBox extends ListBox {
  private static VIDEO_QUALITY_AUTO_KEY: string = 'auto';
  private hasAuto: boolean;

  constructor(config: ListSelectorConfig = {}) {
    super(config);

    this.config = this.mergeConfig(config, {
      cssClasses: ['ui-videoqualitylistbox'],
    }, this.config);
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);

    const selectCurrentVideoQuality = () => {
      const currentVideoQuality = player.getVideoQuality();
      if (!currentVideoQuality || currentVideoQuality.id === 'not available') {
        this.selectItem(VideoQualityListBox.VIDEO_QUALITY_AUTO_KEY);
      } else {
        this.selectItem(player.getVideoQuality().id);
      }
    };

    const updateVideoQualities = () => {
      const videoQualities = player.getAvailableVideoQualities();

      if (videoQualities?.length > 0) {
        this.clearItems();

        // Progressive streams do not support automatic quality selection
        this.hasAuto = player.getStreamType() !== 'progressive';

        if (this.hasAuto) {
          // Add entry for automatic quality switching (default setting)
          this.addItem(VideoQualityListBox.VIDEO_QUALITY_AUTO_KEY, 'Auto');
        }

        /**
         * Auto = Retains Bitmovin's current logic
         High - Only plays the highest available stream that is >= 1800kbps
         Medium - Only plays the highest available stream that is in between >= 600kbps and <= 1799kbps
         Low = Only plays the highest available stream that is <= 599kbps
         */
        const customQualities = {
          high: {
            bitrate: 0,
            qualityObject: {},
          },
          medium: {
            bitrate: 0,
            qualityObject: {},
          },
          low: {
            bitrate: 0,
            qualityObject: {},
          },
        };

        // Sorting with lowest bitrate first
        videoQualities.sort(({ bitrate: a }, { bitrate: b }) => { return a - b; });

        ['High', 'Medium', 'Low'].forEach((quality) => {
          const videoQuality = videoQualities.pop();
          let selectedQuality;

          switch (quality) {
            // Considering 'High' the first quality with bitrate at 1800Kbps or more
            case 'High':
              if (videoQuality.bitrate >= 1800000) {
                selectedQuality = videoQuality;
              }
              break;

            // Considering 'Medium' the first quality with bitrate at 600Kbps or more
            case 'Medium':
              if (videoQuality.bitrate > 600000) {
                selectedQuality = videoQuality;
              }
              break;

            // Considering 'Low' the first of the remaining qualities
            case 'Low':
            default:
              selectedQuality = videoQuality;
              break;
          }

          if (selectedQuality) {
            customQualities[quality.toLowerCase()].bitrate = selectedQuality.bitrate;
            customQualities[quality.toLowerCase()].qualityObject = {
              ...selectedQuality,
              label: quality,
            };
          } else {
            delete customQualities[quality.toLowerCase()];
          }
        });

        Object.keys(customQualities).forEach((quality) => {
          const {
            bitrate,
            qualityObject: {
              id,
              label,
              height,
            },
          } = customQualities[quality];

          if (bitrate > 0) {
            this.addItem(id, `${label} (${height}p)`);
            Logger.info(`[VideoPlayer] video quality added: ${label} (${bitrate / 1000} kbps)`);
          }
        });

        // Select initial quality
        selectCurrentVideoQuality();
      }
    };

    this.onItemSelected.subscribe((sender: VideoQualityListBox, value: string) => {
      player.setVideoQuality(value);
    });

    // Update qualities when source goes away
    player.on(player.exports.PlayerEvent.SourceUnloaded, updateVideoQualities);
    // Update qualities when the period within a source changes
    player.on(player.exports.PlayerEvent.PeriodSwitched, updateVideoQualities);
    // Update quality selection when quality is changed (from outside)
    player.on(player.exports.PlayerEvent.VideoQualityChanged, selectCurrentVideoQuality);

    if ((player.exports.PlayerEvent as any).VideoQualityAdded) {
      // Update qualities when their availability changed
      // TODO: remove any cast after next player release
      player.on((player.exports.PlayerEvent as any).VideoQualityAdded, updateVideoQualities);
      player.on((player.exports.PlayerEvent as any).VideoQualityRemoved, updateVideoQualities);
    }

    uimanager.getConfig().events.onUpdated.subscribe(updateVideoQualities);
  }

  /**
   * Returns true if the select box contains an 'auto' item for automatic quality selection mode.
   * @return {boolean}
   */
  hasAutoItem(): boolean {
    return this.hasAuto;
  }
}
