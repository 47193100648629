import { HttpRequestType } from 'bitmovin-player';
import { isMobile, isSafari } from 'react-device-detect';

import { BITMOVIN_ANALYTICS_LICENSE_KEY } from '@@utils/constants';

import VideoStream from '../@types/VideoStream';

const VideoPlayerUtils = {
  maxRetryCount: 3,
  retryLimitCount: 0,

  bitmovinRetryHttpRequest: (type, response) => {
    if (type === HttpRequestType.MEDIA_VIDEO) {
      VideoPlayerUtils.retryLimitCount += 1;

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (VideoPlayerUtils.retryLimitCount >= VideoPlayerUtils.maxRetryCount) {
            reject(response.request);
          }
          resolve(response.request);
        }, 1500);
      });
    }

    return null;
  },

  resetBitmovinRetryLimitCount: () => {
    VideoPlayerUtils.retryLimitCount = 0;
  },

  generateConvivaPluginsOptions: (useTouchStone: boolean, convivaEnv: string = 'test') => {
    let env = convivaEnv;

    const convivaConfigs = {
      test: {
        customerKey: 'd793177943f56c9e4795b0ba62b873c61132016f',
        gatewayUrl: 'https://sbs-test.testonly.conviva.com',
      },
      touchstone: {
        customerKey: 'b800410803a4659ffcd7b9b6577a907110b7aa21',
        gatewayUrl: 'https://sbs.testonly.conviva.com',
      },
      prod: {
        customerKey: 'b800410803a4659ffcd7b9b6577a907110b7aa21',
        gatewayUrl: 'https://cws.conviva.com',
      },
    };

    let enableConvivaDebugging = false;
    if (useTouchStone === true) {
      env = 'touchstone';
      enableConvivaDebugging = true;
    }

    return {
      customerKey: convivaConfigs[env].customerKey,
      pluginConfig: {
        debugLoggingEnabled: enableConvivaDebugging,
        gatewayUrl: convivaConfigs[env].gatewayUrl,
      },
    };
  },

  generateLivestreamAdConfig: (adConfig: VideoStream.AdvertisementObject) => {
    return {
      adBreaks: [
        {
          tag: {
            url: adConfig.playlistUrl,
            type: 'vast',
          },
          id: 'preRollAd',
          position: 'pre',
        },
      ],
    };
  },

  generateBitmovinPlayerConfig: (
    playerLicenseKey: string,
    videoStreamMetadata: VideoStream.DaiStream,
  ) => {
    const { videoStreamType, streamProviderType, advertisement } = videoStreamMetadata;

    let adConfig;
    if (
      videoStreamType === 'live'
      && streamProviderType === 'AkamaiHLSProvider'
      && advertisement && advertisement.playlistUrl
    ) {
      adConfig = VideoPlayerUtils.generateLivestreamAdConfig(advertisement);
    }

    const shouldAddStartupBitrates = videoStreamType !== 'live';
    const playerTech = isSafari ? { player: 'native', streaming: 'hls' } : { player: 'html5', streaming: 'hls' };

    return {
      key: playerLicenseKey,
      ...(isSafari && {
        // This config is required for preprocessHttpResponse() below to work with Safari.
        tweaks: {
          native_hls_parsing: true,
        },
      }),
      network: {
        retryHttpRequest: VideoPlayerUtils.bitmovinRetryHttpRequest,
        preprocessHttpResponse: (type, response) => {
          if (type === 'manifest/hls/master') {
            if (isSafari) {
              // Until Bitmovin fixes on their side, this is a work around to turn CCs into Subtitles to prevent them
              // from showing with large fonts and at odd locations on the screen.
              response.body = response.body.replace(/,CHARACTERISTICS="public.accessibility.transcribes-spoken-dialog,public.accessibility.describes-music-and-sound"/g, '');
            }
            response.body = response.body.replace(/,FORCED=YES/g, '');
          }
          return response;
        },
      },
      playback: {
        autoplay: !isMobile && !isSafari,
        preferredTech: [playerTech],
      },
      ...(shouldAddStartupBitrates && {
        adaptation: {
          desktop: {
            startupBitrate: '1000kbps',
          },
          mobile: {
            startupBitrate: '500kbps',
          },
        },
      }),
      ...(adConfig !== null && { advertising: adConfig }),
      // 'ui' should always stay false
      // For custom config, edit bitmovin-google-dai.ts to pass it to buildDefaultUI()
      ui: false,
      ...(BITMOVIN_ANALYTICS_LICENSE_KEY && {
        analytics: {
          key: BITMOVIN_ANALYTICS_LICENSE_KEY,
        },
      }),
    };
  },
};

export default VideoPlayerUtils;
