import { SettingsToggleButtonConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/settingstogglebutton';
import i18n from 'i18next';

import SettingsHoverToggleButton from './SettingsHoverToggleButton';

/**
 * A toggle button to show / hide episode picker panel
 */
export default class EpisodePickerToggleButton extends SettingsHoverToggleButton {
  constructor(config: SettingsToggleButtonConfig) {
    super({
      ...config,
      ariaLabel: i18n.t('common:videoPlayer.viewMoreEpisodes'),
      cssClasses: ['ui-episodepickertogglebutton', 'ui-settingstogglebutton'],
      autoHideWhenNoActiveSettings: false,
      hidden: true,
    });
  }
}
