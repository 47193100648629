import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import fontFamily from '@@styles/typography/fontFamily';

const useStyles = makeStyles(() => {
  return createStyles({
    title: {
      fontFamily: fontFamily.secondary,
      fontSize: '1.75rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
    },
    description: {
      fontFamily: fontFamily.primary,
      textWrap: 'wrap',
      marginBottom: '1.5rem',
      '& a': {
        textDecoration: 'underline',
      },
    },
    reason: {
      fontFamily: 'monospace',
      fontSize: '0.75rem',
      opacity: 0.5,
    },
  });
});

interface VideoLoadingErrorCardProps {
  reason?: string;
}

const PlaybackErrorCard: FunctionComponent<VideoLoadingErrorCardProps> = (props) => {
  const { reason } = props;
  const classes = useStyles(props);
  const { t } = useTranslation('common');

  return (
    <>
      <div className={classes.title}>
        {t('videoPlayer.videoPlaybackErrorTitle')}
      </div>
      <p className={classes.description}>
        {t('videoPlayer.videoPlaybackErrorBody')}
      </p>
      {reason && <div className={classes.reason}>{reason}</div>}
    </>
  );
};

export default PlaybackErrorCard;
