import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { useAppSelector } from '@@src/hooks/store';
import { PlayerMetadata } from '@@src/pages/WatchPage';
import { getAutoplayNextEpDelay } from '@@stores/ConfigStore';
import OnDemand from '@@types/OnDemand';
import DataLayer from '@@utils/DataLayer';

import BitmovinClient from '../../../../lib/VideoPlayer/BitmovinClient';
import ShowAtPlayTime from '../Utils/ShowAtPlayTime';
import AutoplayNextEpisodeButtons from './AutoplayNextEpisodeButtons';

interface AutoplayNextEpisodeComponentProps {
  bitmovinClient: BitmovinClient;
  video: OnDemand.Video;
  nextEpisode: OnDemand.Episode;
  displayTime: number;
  playerMetadata: PlayerMetadata;
}

const AutoplayNextEpisodeComponent: FunctionComponent<AutoplayNextEpisodeComponentProps> = (props) => {
  const {
    video,
    nextEpisode,
    bitmovinClient,
    displayTime,
    playerMetadata,
  } = props;

  // show the component
  const [show, setShow] = useState<boolean>(true);

  // duration before the button is triggered
  const autoplayNextEpDelay = useAppSelector((state) => {
    return getAutoplayNextEpDelay(state);
  });

  // when content is finished, we should hide this component
  // so it does not interfere with the next episode endcard
  const cancelOnPlayFinished = useCallback(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    if (bitmovinClient.playerEvents) {
      bitmovinClient.on('PlayFinished', cancelOnPlayFinished);
    }

    return () => {
      if (bitmovinClient.playerEvents) {
        bitmovinClient.off('PlayFinished', cancelOnPlayFinished);
      }
    };
  }, [bitmovinClient, cancelOnPlayFinished]);

  const handleActionClick = useCallback(() => {
    const event = new CustomEvent('OdPlayerVideoChange', { detail: { videoId: nextEpisode.id } });
    document.dispatchEvent(event);

    DataLayer.events.playNextEpisode(
      'tap',
      nextEpisode,
      playerMetadata,
      {
        videoNavigationEvent: {
          playNextEpisode: 'tap',
        },
      },
    );

    setShow(false);
  }, [nextEpisode, playerMetadata]);

  const handleActionTimeout = useCallback(() => {
    const event = new CustomEvent('OdPlayerVideoChange', { detail: { videoId: nextEpisode.id } });
    document.dispatchEvent(event);

    DataLayer.events.playNextEpisode(
      'lapse',
      nextEpisode,
      playerMetadata,
      {
        videoNavigationEvent: {
          playNextEpisode: 'lapse',
        },
      },
    );
  }, [nextEpisode, playerMetadata]);

  const handleCancelClick = useCallback(() => {
    DataLayer.events.playNextEpisode(
      'keepWatching',
      nextEpisode,
      playerMetadata,
      {
        videoNavigationEvent: {
          playNextEpisode: 'keepWatching',
        },
      },
    );
  }, [nextEpisode, playerMetadata]);

  const handleShow = useCallback(() => {
    DataLayer.events.playNextEpisode(
      'display',
      nextEpisode,
      playerMetadata,
      {
        videoNavigationEvent: {
          playNextEpisode: 'display',
        },
      },
    );
  }, [nextEpisode, playerMetadata]);

  if (!show) return null;

  return (
    <ShowAtPlayTime
      bitmovinClient={bitmovinClient}
      startTime={displayTime}
      endTime={video.duration}
      onShow={handleShow}
      minDurationBeforeEndTime={autoplayNextEpDelay}
      hideOnPause
    >
      <AutoplayNextEpisodeButtons
        delay={autoplayNextEpDelay}
        onCancelClick={handleCancelClick}
        onActionTimeout={handleActionTimeout}
        onActionClick={handleActionClick}
      />
    </ShowAtPlayTime>
  );
};

export default AutoplayNextEpisodeComponent;
