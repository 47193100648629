import { SettingsPanel, SettingsPanelItem, SettingsPanelPage } from '@sbs/bitmovin-player-ui';
import { ComponentConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/component';
import i18n from 'i18next';

import AudioTrackListBox from './AudioTrackListBox';
import SubtitleListBox from './SubtitleListBox';

/**
 * Represents a settings panel item for configuring audio settings.
 */
export class AudioSettingsPanelItem extends SettingsPanelItem {
  constructor(config: ComponentConfig = {}) {
    super(
      i18n.t('common:videoPlayer.settingsPanelAudio'),
      new AudioTrackListBox(),
      {
        ...config,
        cssClasses: [
          'ui-settings-panel-audio',
          'ui-settings-panel-item',
        ],
        allowLabelFocus: true,
      },
    );
  }
}

/**
 * Represents a settings panel item for configuring subtitle settings.
 */
export class SubtitleSettingsPanelItem extends SettingsPanelItem {
  constructor(config: ComponentConfig = {}) {
    super(
      i18n.t('common:videoPlayer.settingsPanelSubtitles'),
      new SubtitleListBox(),
      {
        ...config,
        cssClasses: [
          'ui-settings-panel-subtitles',
          'ui-settings-panel-item',
        ],
        allowLabelFocus: true,
      },
    );
  }
}

/**
 * Represents a settings panel for configuring subtitle and audio settings.
 */
export default class SubtitleAudioSettingsPanel extends SettingsPanel {
  constructor(config: ComponentConfig = {}) {
    super({
      ...config,
      hidden: true,
      hideDelay: -1,
      cssClasses: ['subtitle-audio-settings'],
      components: [
        new SettingsPanelPage({
          autoFocusOn: 'label',
          components: [
            new AudioSettingsPanelItem(),
            new SubtitleSettingsPanelItem(),
          ],
        }),
      ],
    });
  }
}
