import { get } from 'lodash';
import { FunctionComponent, ReactElement } from 'react';

import VideoPlayer from '@@src/lib/VideoPlayerV2/VideoPlayer';
import AutoPlayNextEpisode from '@@src/lib/VideoPlayerV2/plugins/AutoPlayNextEpisode/AutoPlayNextEpisode';
import EpisodePicker from '@@src/lib/VideoPlayerV2/plugins/EpisodePicker/EpisodePicker';
import PlaybackEndCard from '@@src/lib/VideoPlayerV2/plugins/PlaybackEndCard/PlaybackEndCard';
import OnDemand from '@@types/OnDemand';
import { PlaybackStreamData } from '@@types/PlaybackStreamData';

interface WatchPageV2PluginsProps {
  videoPlayer: VideoPlayer;
  playbackStreamData: PlaybackStreamData;
  video?: OnDemand.Video | null;
  series?: OnDemand.TvSeries | null;
}

/**
 * This component is responsible for rendering the plugins for the WatchPageV2.
 * It contains all the business logic whether a plugin should be rendered or not.
 * @param props
 * @constructor
 */
const WatchPageV2Plugins: FunctionComponent<WatchPageV2PluginsProps> = (props) => {
  const {
    videoPlayer,
    playbackStreamData,
    video,
    series,
  } = props;

  const plugins: Record<string, ReactElement> = {};

  if (video) {
    plugins.playbackEndCard = <PlaybackEndCard key="playbackEndCard" videoPlayer={videoPlayer} playbackStreamData={playbackStreamData} video={video} series={series}/>;
  }

  // load plugins for an episode
  if (video?.type === 'Episode' && series) {
    const offerNextEpisode = get(playbackStreamData, 'playbackEvents.offerNextEpisode', null);

    if (offerNextEpisode) {
      plugins.autoPlayNextEpisode = (
        <AutoPlayNextEpisode
          key="autoPlayNextEpisode"
          video={video}
          series={series}
          displayTime={offerNextEpisode}
          videoPlayer={videoPlayer}
          playbackStreamData={playbackStreamData}
        />
      );
    }

    plugins.episodePicker = <EpisodePicker key="episodePicker" video={video} series={series} videoPlayer={videoPlayer}/>;
  }

  return (
    <>
      {Object.values(plugins)}
    </>
  );
};

export default WatchPageV2Plugins;
