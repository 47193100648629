import i18n from 'i18next';
import { FunctionComponent, useCallback } from 'react';

import DataLayer from '@@utils/DataLayer';

import ShowAtPlayTime from '../Utils/ShowAtPlayTime';
import { VideoPlayerPluginProps } from '../VideoPlayerPlugin';
import SeekButton from './SeekButton';

const SkipRecap: FunctionComponent<VideoPlayerPluginProps> = (props) => {
  const { videoPlayer, playbackStreamData, video } = props;

  const skipRecapStart = playbackStreamData.playbackEvents.offerSkipRecap;
  const skipRecapEnd = playbackStreamData.playbackEvents.recapEnd;

  const handleShow = useCallback(() => {
    if (video) {
      DataLayer.events.videoNavigation(
        'skipRecap_display',
        video,
        videoPlayer.getPlayerMetadata(),
        {
          videoNavigationEvent: {
            skipRecap: 'display',
          },
        },
      );
    }
  }, [video, videoPlayer]);

  const handleClick = useCallback(() => {
    if (video) {
      DataLayer.events.videoNavigation(
        'skipRecap_tap',
        video,
        videoPlayer.getPlayerMetadata(),
        {
          videoNavigationEvent: {
            skipRecap: 'tap',
          },
        },
      );
    }
  }, [video, videoPlayer]);

  const seekFn = useCallback((seekTo: number): void => {
    videoPlayer.seek(seekTo);
  }, [videoPlayer]);

  if (typeof skipRecapStart !== 'number' || typeof skipRecapEnd !== 'number') {
    return null;
  }

  return (
    <ShowAtPlayTime
      key={video?.id}
      videoPlayer={videoPlayer}
      startTime={skipRecapStart}
      endTime={skipRecapEnd}
      onShow={handleShow}
      autoHideDelay={10}
      autoHideAfterUiHideDelay={5}
      hideOnPause
    >
      <SeekButton seekFn={seekFn} seekTo={skipRecapEnd} onClick={handleClick}>
        {i18n.t('videoPlayer.skipRecap')}
      </SeekButton>
    </ShowAtPlayTime>
  );
};

export default SkipRecap;
