import { UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { Button, ButtonConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/button';
import { PlayerAPI, PlayerEvent } from 'bitmovin-player';
import i18n from 'i18next';

interface SeekForwardButtonConfig extends ButtonConfig {
  seekDuration: number,
}

/**
 * A button that toggles between playback and pause.
 */
export default class SeekForwardButton extends Button<SeekForwardButtonConfig> {
  private pausedTimeshiftUpdater: ReturnType<typeof setInterval> | undefined = undefined;

  constructor(config: Optional<SeekForwardButtonConfig, 'seekDuration'> = {}) {
    const seekDuration: number = config.seekDuration || 10;
    super({
      ...config,
      ariaLabel: i18n.t('common:videoPlayer.seekForward', { seekDuration }),
      cssClasses: ['ui-seekforwardbutton', 'ui-button'],
      seekDuration,
    });
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);
    const config = this.getConfig();

    const checkTimeShift = () => {
      if (!player.isLive()) {
        return;
      }

      const isAtLivePosition = player.isLive() && player.getTimeShift() === 0;
      const isTimeshiftAvailable = player.getMaxTimeShift() < -10;
      if (!isTimeshiftAvailable || isAtLivePosition) {
        this.disable();
      } else {
        this.enable();
      }
    };

    checkTimeShift();

    player.on(PlayerEvent.Paused, () => {
      if (player.isLive() && player.getMaxTimeShift() < 0) {
        this.pausedTimeshiftUpdater = setInterval(checkTimeShift, 1000);
      }
    });
    player.on(PlayerEvent.Play, () => {
      clearInterval(this.pausedTimeshiftUpdater);
      checkTimeShift();
    });
    player.on(PlayerEvent.Ready, checkTimeShift);
    player.on(PlayerEvent.TimeShifted, checkTimeShift);

    this.onClick.subscribe(() => {
      if (player.isLive()) {
        player.timeShift(player.getTimeShift() + config.seekDuration, 'ui-seekforwardbutton');
      } else {
        player.seek(Math.max(0, player.getCurrentTime() + config.seekDuration), 'ui-seekforwardbutton');
      }
    });
  }
}
