import { AxiosResponse } from 'axios';

import { adnxsHttpClient, handleHttpError } from '@@utils/HttpClient';
import { ADNXS_API_HEADER_BIDDING_PATH } from '@@utils/constants';

interface VideoHeaderBiddingAdPod {
  podid: number;
  targetting: Record<string, string>[];
}

export interface VideoHeaderBiddingResponse {
  adpods: VideoHeaderBiddingAdPod[];
}

interface PrebidLfvResponse extends AxiosResponse {
  data: VideoHeaderBiddingResponse;
}

export function getVideoHeaderBidding(payload): Promise<VideoHeaderBiddingResponse> {
  // It takes between 600 - 700ms to get a response back, setting to 1000ms to give some margin.
  return adnxsHttpClient.post(ADNXS_API_HEADER_BIDDING_PATH, payload, { timeout: 1500 })
    .then(({ data }: PrebidLfvResponse) => {
      return data;
    })
    .catch((e) => {
      return handleHttpError(e, new Error('Error on AdnxsApi.getVideoHeaderBidding()'), {}, [], null, 'warn');
    });
}

export default {
  getVideoHeaderBidding,
};
