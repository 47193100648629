/**
 * Copyright (C) 2017, Bitmovin, Inc., All Rights Reserved
 *
 * This source code and its use and distribution, is subject to the terms
 * and conditions of the applicable license agreement.
 *
 * space: play/pause
 * m: mute/unmute
 * f: enter fullscreen
 * esc: exit fullscreen
 * right: seek +10 seconds
 * left: seek -10 seconds
 * shift + right: seek +20 seconds
 * shift + left: seek -20 seconds
 * up: volume +5
 * down: volume -5
 * shift + up: volume +10
 * shift + down: volume -10
 * c: activate and cycle through available subtitles
 * a: activate and cycle through available audio tracks
 */

// eslint-disable-next-line max-classes-per-file
import { ControlBar, SettingsPanel, UIManager } from '@sbs/bitmovin-player-ui';
import { PlayerEvent, ViewMode, PlayerAPI } from 'bitmovin-player';
import { findIndex, debounce, get, find } from 'lodash';

import Browser from '@@utils/newrelicAgent/Browser';

/* eslint-disable */
declare global {
  interface Window {
    bitmovin: any;
  }
}

let bitmovin;

/**
 * Class to handle mappings from KeyboardEvent.keyCode to a string representation
 */
class KeyboardEventMapper {
  public static KeyBindingSeparator = ' / ';
  public static KeyCommandSeparator = '+';

  /**
   * keys which will represented as a modifier
   */
  public static ModifyerKeys = {
    16: 'shift',
    17: 'ctrl',
    18: 'alt',
    20: 'capslock',
    91: 'meta',
    93: 'meta',
    224: 'meta',
  };

  /**
   * Special keys on the keyboard which are not modifiers
   */
  public static ControlKeys = {
    8: 'backspace',
    9: 'tab',
    13: 'enter',
    19: 'pause',
    27: 'esc',
    32: 'space',
    33: 'pageup',
    34: 'pagedown',
    35: 'end',
    36: 'home',
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down',
    44: 'print',
    45: 'ins',
    46: 'del',
    145: 'scrolllock',
    186: ';',
    187: '=',
    188: ',',
    189: '-',
    190: '.',
    191: '/',
    192: '`',
    219: '[',
    220: '\\',
    221: ']',
    222: '\'',
  };

  /**
   * Keys wich normally move the page
   */
  public static ScrollingKeys = {
    32: 'space',
    33: 'pageup',
    34: 'pagedown',
    35: 'end',
    36: 'home',
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down',
  };

  /**
   * All number on the numblock an the keys surrounding it
   */
  public static NumblockKeys = {
    96: '0',
    97: '1',
    98: '2',
    99: '3',
    100: '4',
    101: '5',
    102: '6',
    103: '7',
    104: '8',
    105: '9',
    106: '*',
    107: '+',
    109: '-',
    110: '.',
    111: '/',
    144: 'numlock',
  };

  /**
   * F1 - F19
   */
  public static F_Keys = {
    112: 'F1',
    113: 'F2',
    114: 'F3',
    115: 'F4',
    116: 'F5',
    117: 'F6',
    118: 'F7',
    119: 'F8',
    120: 'F9',
    121: 'F10',
    122: 'F11',
    123: 'F12',
    124: 'F13',
    125: 'F14',
    126: 'F15',
    127: 'F16',
    128: 'F17',
    129: 'F18',
    130: 'F19',
  };

  /**
   * Converts a Keyboard Event to something like shit+alt+g depending on the character code of the event and the modifiers
   * @param event the event to be converted into a string
   * @returns {string} the representation of the all keys which were pressed
   */
  public static convertKeyboardEventToString(event: KeyboardEvent): string {
    let retVal = '';
    let needsConcat = false;

    if (event.shiftKey) {
      retVal += 'shift';
      needsConcat = true;
    }
    if (event.altKey) {
      if (needsConcat) {
        retVal += KeyboardEventMapper.KeyCommandSeparator;
      } else {
        needsConcat = true;
      }
      retVal += 'alt';
    }
    if (event.ctrlKey || event.metaKey) {
      if (needsConcat) {
        retVal += KeyboardEventMapper.KeyCommandSeparator;
      } else {
        needsConcat = true;
      }
      retVal += 'ctrl';
    }

    const convertedCode = KeyboardEventMapper.convertKeyCodeToString(event);
    if (convertedCode) {
      if (needsConcat) {
        retVal += KeyboardEventMapper.KeyCommandSeparator;
      }
      retVal += convertedCode;
    } else {
      // eslint-disable-next-line no-console
      console.log(`No conversion for the code: ${event.keyCode}`);
    }

    return retVal;
  }

  /**
   * Tries to convert a given keyCode to a string representation of the key
   * @param event the event which contains the keyCode
   * @returns {string} the string representation of the keyCode (eg.: 'left', 'esc', 'space', 'a', '1' ...)
   */
  public static convertKeyCodeToString(event: KeyboardEvent): string {
    const code = event.which || event.keyCode;

    let retVal: string;
    if (KeyboardEventMapper.isModifierKey(code)) {
      retVal = KeyboardEventMapper.ModifyerKeys[code];
    } else if (KeyboardEventMapper.isControlKey(code)) {
      retVal = KeyboardEventMapper.ControlKeys[code];
    } else if (KeyboardEventMapper.isNumblockKey(code)) {
      retVal = KeyboardEventMapper.NumblockKeys[code];
    } else if (KeyboardEventMapper.isFKey(code)) {
      retVal = KeyboardEventMapper.F_Keys[code];
    } else {
      // try and convert a unicode character
      retVal = String.fromCharCode(code).toLowerCase();
    }

    return retVal;
  }

  public static isModifierKey(keyCode: number): boolean {
    return Object.prototype.hasOwnProperty.call(KeyboardEventMapper.ModifyerKeys, keyCode.toString());
  }

  public static isControlKey(keyCode: number): boolean {
    return Object.prototype.hasOwnProperty.call(KeyboardEventMapper.ControlKeys, keyCode.toString());
  }

  public static isNumblockKey(keyCode: number): boolean {
    return Object.prototype.hasOwnProperty.call(KeyboardEventMapper.NumblockKeys, keyCode.toString());
  }

  public static isFKey(keyCode: number): boolean {
    return Object.prototype.hasOwnProperty.call(KeyboardEventMapper.F_Keys, keyCode.toString());
  }

  public static isScrollKey(keyCode: number): boolean {
    return Object.prototype.hasOwnProperty.call(KeyboardEventMapper.ScrollingKeys, keyCode.toString());
  }
}

let hideSubtitlePanelTimeoutHandler;
const hideSubtitlePanel = debounce((controlBar, subtitlePanel, activeUi) => {
  clearTimeout(hideSubtitlePanelTimeoutHandler);
  hideSubtitlePanelTimeoutHandler = setTimeout(() => {
    subtitlePanel.hide();
    controlBar.hide();
    activeUi.hideUi();
  }, 2000);
}, 250, { leading: false });

const showSubtitlePanel = (uiManager: UIManager) => {
  const currentUi = get(uiManager, 'currentUi');
  const activeUi = currentUi.getUI();
  const uiComponents = activeUi.getComponents();
  const controlBar = find(uiComponents, (component) => {
    return component instanceof ControlBar;
  });
  const controlBarComponents = controlBar.getComponents();
  const subtitlePanel = find(controlBarComponents, (component) => {
    return component instanceof SettingsPanel;
  });

  if (subtitlePanel && controlBar) {
    activeUi.showUi();
    subtitlePanel.show();
    controlBar.show();
    hideSubtitlePanel(controlBar, subtitlePanel, activeUi);
  }
};

/**
 * Default implementation of the KeyMap to Control the player
 */
class DefaultPlayerKeymap implements PlayerKeyMap {
  public toggle_play: KeyToFunctionBinding = {
    bindingId: 'toggle_play',
    keyBinding: 'space',
    callback: (player: SupportedPlayerTypes, uiManager: UIManager, event: KeyboardEvent) => {
      // @ts-ignore
      if (event.target.tagName === 'BODY')
        if (player.isPlaying()) {
          player.pause();
        } else {
          player.play();
        }
    },
  };
  public toggle_mute: KeyToFunctionBinding = {
    bindingId: 'toggle_mute',
    keyBinding: 'm',
    callback: (player: SupportedPlayerTypes) => {
      if (player.isMuted()) {
        player.unmute();
      } else {
        player.mute();
      }
    },
  };
  public cycle_subtitles: KeyToFunctionBinding = {
    bindingId: 'cycle_subtitles',
    keyBinding: 'c',
    callback: (player: SupportedPlayerTypes, uiManager: UIManager) => {
      const availableSubtitles = player.subtitles.list();
      if (availableSubtitles.length > 0) {
        const currentSubtitlesIndex = findIndex(availableSubtitles, (subtitle) => {
          return subtitle.enabled;
        });

        let subtitleIndexToEnable = 0;
        if (currentSubtitlesIndex !== -1) {
          subtitleIndexToEnable = currentSubtitlesIndex + 1;

          if (subtitleIndexToEnable >= availableSubtitles.length) {
            subtitleIndexToEnable = -1;
          }
        }

        showSubtitlePanel(uiManager);

        if (subtitleIndexToEnable >= 0) {
          player.subtitles.enable(availableSubtitles[subtitleIndexToEnable].id);
          Browser.addPageAction('VideoPlayerKeyPressed', {
            keyName: 'c',
            subtitleLanguage: availableSubtitles[subtitleIndexToEnable].lang,
            video: player.getSource().title,
          });
        } else {
          player.subtitles.disable(availableSubtitles[currentSubtitlesIndex].id)
          Browser.addPageAction('VideoPlayerKeyPressed', {
            keyName: 'c',
            subtitleLanguage: 'off',
            video: player.getSource().title,
          });
        }
      }
    },
  };
  public cycle_audio: KeyToFunctionBinding = {
    bindingId: 'cycle_audio',
    keyBinding: 'a',
    callback: (player: SupportedPlayerTypes, uiManager: UIManager) => {
      const availableAudio = player.getAvailableAudio();
      if (availableAudio.length > 0) {
        const currentAudio = player.getAudio();
        const currentAudioIndex = findIndex(availableAudio, (audio) => {
          return audio.id === currentAudio.id;
        });

        let audioIndexToEnable = 0;
        if (currentAudioIndex !== -1 && (currentAudioIndex + 1) < availableAudio.length) {
          audioIndexToEnable = currentAudioIndex + 1;
        }

        showSubtitlePanel(uiManager);

        player.setAudio(availableAudio[audioIndexToEnable].id);
        Browser.addPageAction('VideoPlayerKeyPressed', {
          keyName: 'a',
          audioLanguage: availableAudio[audioIndexToEnable].lang,
          video: player.getSource().title,
        });
      }
    },
  };
  public enter_fullscreen: KeyToFunctionBinding = {
    bindingId: 'enter_fullscreen',
    keyBinding: 'f',
    callback: (player: SupportedPlayerTypes) => {
      if (player.getViewMode() !== ViewMode.Fullscreen) {
        player.setViewMode(ViewMode.Fullscreen);
      }
    },
  };
  public exit_fullscreen: KeyToFunctionBinding = {
    bindingId: 'exit_fullscreen',
    keyBinding: 'esc',
    callback: (player: SupportedPlayerTypes) => {
      if (player.getViewMode() === ViewMode.Fullscreen) {
        player.setViewMode(ViewMode.Inline);
      }
    },
  };
  public seekForward = (player, time) => {
    if (player.isLive()) {
      player.timeShift(Math.min(0, player.getTimeShift() + time), 'keyboard');
    } else {
      player.seek(Math.min(player.getDuration(), player.getCurrentTime() + time), 'keyboard');
    }
  };
  public seekBackward = (player, time) => {
    if (player.isLive()) {
      player.timeShift(player.getTimeShift() - time, 'keyboard');
    } else {
      player.seek(Math.max(0, player.getCurrentTime() - time), 'keyboard');
    }
  };
  public seek_plus10_sec: KeyToFunctionBinding = {
    bindingId: 'seek_plus10_sec',
    keyBinding: 'right',
    callback: (player: SupportedPlayerTypes) => {
      this.seekForward(player, 10);
    },
  };
  public seek_plus20_sec: KeyToFunctionBinding = {
    bindingId: 'seek_plus20_sec',
    keyBinding: 'shift+right',
    callback: (player: SupportedPlayerTypes) => {
      this.seekForward(player, 20);
    },
  };
  public seek_minus10_sec: KeyToFunctionBinding = {
    bindingId: 'seek_minus10_sec',
    keyBinding: 'left',
    callback: (player: SupportedPlayerTypes) => {
      this.seekBackward(player, 10);
    },
  };
  public seek_minus20_sec: KeyToFunctionBinding = {
    bindingId: 'seek_minus20_sec',
    keyBinding: 'shift+left',
    callback: (player: SupportedPlayerTypes) => {
      this.seekBackward(player, 20);
    },
  };
  public volume_plus5: KeyToFunctionBinding = {
    bindingId: 'volume_plus5',
    keyBinding: 'up',
    callback: (player: SupportedPlayerTypes) => {
      if (!document.activeElement.classList.contains('bmpui-ui-seekbar')) {
        player.setVolume(Math.min(100, player.getVolume() + 5));
      }
    },
  };
  public volume_plus10: KeyToFunctionBinding = {
    bindingId: 'volume_plus10',
    keyBinding: 'shift+up',
    callback: (player: SupportedPlayerTypes) => {
      if (!document.activeElement.classList.contains('bmpui-ui-seekbar')) {
        player.setVolume(Math.min(100, player.getVolume() + 10));
      }
    },
  };
  public volume_minus5: KeyToFunctionBinding = {
    bindingId: 'volume_minus5',
    keyBinding: 'down',
    callback: (player: SupportedPlayerTypes) => {
      if (!document.activeElement.classList.contains('bmpui-ui-seekbar')) {
        player.setVolume(Math.max(0, player.getVolume() - 5));
      }
    },
  };
  public volume_minus10: KeyToFunctionBinding = {
    bindingId: 'volume_minus10',
    keyBinding: 'shift+down',
    callback: (player: SupportedPlayerTypes) => {
      if (!document.activeElement.classList.contains('bmpui-ui-seekbar')) {
        player.setVolume(Math.max(0, player.getVolume() - 10));
      }
    },
  };

  public getAllBindings(): KeyToFunctionBinding[] {
    const retVal = [];

    // collect all objects of this keymap
    // Do not use a static approach here as everything can be overwritten / extended
    for (let ai = 0; ai < Object.keys(this).length; ai += 1) {
      const attr = Object.keys(this)[ai];

      if (typeof this[attr] === 'object') {
        retVal.push(this[attr]);
      }
    }

    return retVal;
  }

  public getAllBindingsForKey(keyRepresentation: string): KeyToFunctionBinding[] {
    const retVal = [];
    const allBindings = this.getAllBindings();
    // split the key command by + and check all parts seperatly so we have the same behavior with ctrl+alt as with alt+ctrl
    const allNeededKeys = keyRepresentation.split(KeyboardEventMapper.KeyCommandSeparator);
    allBindings.forEach((element: KeyToFunctionBinding) => {
      element.keyBinding.split(KeyboardEventMapper.KeyBindingSeparator).forEach((singleBinding: string) => {
        let containsAllParts = true;
        // make sure that the same amount of keys is needed and then make sure that all keys are contained
        const singleBindingParts = singleBinding.split(KeyboardEventMapper.KeyCommandSeparator);
        if (allNeededKeys.length === singleBindingParts.length) {
          allNeededKeys.forEach((keyCommandPart: string) => {
            if (singleBindingParts.indexOf(keyCommandPart) < 0) {
              containsAllParts = false;
            }
          });
          if (containsAllParts) {
            retVal.push(element);
          }
        }
      });
    });
    return retVal;
  }
}

/**
 * Definition of a Keyboard -> PlayerControl binding.
 * the player Method signals what should happen on the player, but the actual behaviour is completely controlled by
 * the callback.
 * If you wish to overwrite the default behavior you can overwrite the default listeners in the config. Any Binding defined
 * there will overwrite the default config.
 */
interface KeyToFunctionBinding {
  /**
   * Identifier for the binding
   */
  bindingId: string;
  /**
   * the actual functionality of the binding, gets the player as a parameter
   */
  callback: Function;
  /**
   * The keycode to listen to. Multiple bindings can listen to the same key.
   */
  keyBinding: string;
}

/**
 * Definition of all player functions which are bound to a keystroke by default.
 * It is possible to configure any number of unknown KeyToFunctionBindings via the player configuration
 */
interface PlayerKeyMap {
  toggle_play: KeyToFunctionBinding;
  toggle_mute: KeyToFunctionBinding;
  enter_fullscreen: KeyToFunctionBinding;
  exit_fullscreen: KeyToFunctionBinding;
  seek_plus10_sec: KeyToFunctionBinding;
  seek_plus20_sec: KeyToFunctionBinding;
  seek_minus10_sec: KeyToFunctionBinding;
  seek_minus20_sec: KeyToFunctionBinding;
  volume_plus5: KeyToFunctionBinding;
  volume_plus10: KeyToFunctionBinding;
  volume_minus5: KeyToFunctionBinding;
  volume_minus10: KeyToFunctionBinding;
  cycle_subtitles: KeyToFunctionBinding;
  cycle_audio: KeyToFunctionBinding;

  /**
   * Retrieves a collection of all bindings of this KeyMap
   */
  getAllBindings(): KeyToFunctionBinding[];

  /**
   * Filters all bindings of this KeyMap to find the bindings which have a matching keyBinding
   * @param keyRepresentation the string representation of the desired keyStroke
   */
  getAllBindingsForKey(keyRepresentation: string): KeyToFunctionBinding[];
}

/**
 * All Player types which are supported by this class
 */
type SupportedPlayerTypes = PlayerAPI;

/**
 * Class to control a given player instance via the keyboard
 */
class PlayerKeyboardControl {
  private keyMap: PlayerKeyMap;
  private isEnabled: boolean;
  private player: SupportedPlayerTypes;
  private uiManager: UIManager;
  private shouldPreventScrolling: boolean;

  // Handling disable of seeking from keyboard when an ad is playing
  private allowSeeking: boolean;

  public constructor(
    wrappedPlayer: SupportedPlayerTypes,
    uiManager: UIManager,
    preventPageScroll = true,
    config?: any
  ) {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line prefer-destructuring
      bitmovin = window.bitmovin;
    }
    this.player = wrappedPlayer;
    this.uiManager = uiManager;
    this.shouldPreventScrolling = preventPageScroll;
    let paramKeyMap = {};
    if (config) {
      paramKeyMap = config;
    }

    this.keyMap = PlayerKeyboardControl.mergeConfigWithDefault(paramKeyMap);
    // default to enabled
    // this also registers the event listeners
    this.enable(true);

    // destroy this together with the player
    this.player.on(PlayerEvent.Destroy, () => {
      this.destroy();
    });
  }

  // SBS custom function
  public setAllowSeeking(allow = true) {
    this.allowSeeking = allow;
  }

  public enable(shouldBeEnabled: boolean = true) {
    this.isEnabled = shouldBeEnabled;
    // depending if we are enabled register or remove the keyListener
    // we cannot use the keypress event as that event does not work with modifiers
    // only add the keyUp listener as we do not expect users holding buttons to control the player
    if (this.isEnabled) {
      // in order to stop the browser from scrolling we have to add an additional onKeyDown listener
      // because the browser would scroll on that one already
      if (this.shouldPreventScrolling) {
        document.addEventListener('keydown', this.preventScrolling, false);
      }
      document.addEventListener('keyup', this.handleKeyEvent, false);
    } else {
      // document.addEventListener('keypress', this.handleKeyEvent, false);
      document.removeEventListener('keydown', this.preventScrolling, false);
      document.removeEventListener('keyup', this.handleKeyEvent, false);
    }
  }

  public disable(shouldBeDisabled: boolean = true) {
    this.enable(!shouldBeDisabled);
  }

  /**
   * Use this method to prevent the browser from scrolling via keyboard
   * @param preventScrolling true if keyboard scrolling should be prevented, false if nots
   */
  public setPreventScrolling(preventScrolling: boolean): void {
    this.shouldPreventScrolling = preventScrolling;

    // set up or remove the listener if necessary
    if (this.isEnabled) {
      if (preventScrolling) {
        document.addEventListener('keydown', this.preventScrolling, false);
      } else {
        document.removeEventListener('keydown', this.preventScrolling, false);
      }
    }
  }

  public destroy() {
    // removes the listener
    this.disable(true);
  }

  protected static mergeConfigWithDefault(paramKeyMap: object): PlayerKeyMap {
    const retVal = new DefaultPlayerKeymap();
    // allow overwrites to the default player keymap as well as new listeners
    for (let ai = 0; ai < Object.keys(paramKeyMap).length; ai += 1) {
      const attr = Object.keys(paramKeyMap)[ai];
      if (attr && paramKeyMap[attr]) {
        const toCheck = paramKeyMap[attr];
        // avoid wrong configs and check for elements being real keyListeners
        if (toCheck.keyBinding && toCheck.callback) {
          retVal[attr] = paramKeyMap[attr];
        } else {
          // eslint-disable-next-line no-console
          console.log(`Invalid Key Listener at params[${attr}]`);
        }
      }
    }
    return retVal;
  }

  public preventScrolling = (event: KeyboardEvent) => {
    const keyCode = event.which || event.keyCode;
    // prevent scrolling with arrow keys, space, pageUp and pageDown
    if (
      !(event.ctrlKey || event.shiftKey || event.altKey || event.metaKey)
      && KeyboardEventMapper.isScrollKey(keyCode)

      // Don't prevent the default behaviour if the event was received on a button
      && (event.target as HTMLElement).tagName !== 'BUTTON'
    ) {
      // maybe we should check here if we actually have a keybinding for the keyCode and only prevent
      // the scrolling if we actually handle the event
      event.preventDefault();
    }
  };

  public handleKeyEvent = (event: KeyboardEvent) => {
    if (this.isEnabled) {
      const keyStringRepresentation = KeyboardEventMapper.convertKeyboardEventToString(event);

      const bindings = this.keyMap.getAllBindingsForKey(keyStringRepresentation);
      bindings.forEach((singleBinding: KeyToFunctionBinding) => {
        if (
          this.allowSeeking === true
          || singleBinding.bindingId.indexOf('seek_') === -1
        ) {
          singleBinding.callback(this.player, this.uiManager, event);
        }
      });
    }
  };
}

export default PlayerKeyboardControl;
