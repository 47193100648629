import { UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { MetadataType } from 'bitmovin-player/types/core/Events';
import { remove, throttle } from 'lodash';

import Logger from '@@utils/logger/Logger';

import { AdMetadata } from './StreamHandlers/GoogleDaiStreamHandler';

export interface EventState {
  hasContentStarted: boolean;
  hasPlaybackStarted: boolean;
  isAd: boolean;
  isAdHoliday: boolean | undefined; // undefined when not started, true when started and still going, false when ended
  timelineMarkers: number[];
}

interface BaseDispatchPayload {
  timestamp: number;
  issuer?: string;
}

export type VideoPlayerEventBase = BaseDispatchPayload & EventState;

export type VideoPlayerSeekEvent = VideoPlayerEventBase & SeekDispatchPayload;

export type VideoPlayerPlaybackQualityChangedEvent = VideoPlayerEventBase & PlaybackQualityChangedPayload;

export type VideoPlayerErrorEvent = VideoPlayerEventBase & PlayerErrorPayload;

export type VideoPlayerAdSnapbackEvent = VideoPlayerEventBase & AdSnapBackPayload;

export type VideoPlayerPlaybackEvent = VideoPlayerEventBase & PlaybackDispatchPayload;

export type VideoPlayerCustomEvent = VideoPlayerEventBase & CustomEventPayload;

export type VideoPlayerEventCallback = (event: VideoPlayerEventBase) => void;

export enum VideoPlayerEventType {
  READY = 'ready', // the video player is ready
  SOURCE_LOADED = 'sourceLoaded', // the source has been loaded
  SOURCE_UNLOADED = 'sourceUnloaded', // the source has been unloaded

  STREAM_HANDLER_INITIALISED = 'streamHandlerInitialised', // the stream handler is successfully initialised

  PLAY = 'play',
  PLAYING = 'playing',
  PAUSED = 'paused',
  CONTENT_STARTED = 'contentStarted', // fired once when content is first played, after pre-roll

  TIME_CHANGED = 'timeChanged', // fired when the current playback time has changed

  SEEK_STARTED = 'seekStarted',
  SEEK = 'seek',
  SEEK_FINISHED = 'seekFinished',

  PLAYBACK_STARTED = 'playbackStarted',
  PLAYBACK_FINISHED = 'playbackFinished',

  BUFFERING_STARTED = 'bufferingStarted',
  BUFFERING_FINISHED = 'bufferingFinished',

  CUSTOM_EVENT = 'customEvent',

  AD_BREAK_STARTED = 'adBreakStarted', // fired the first time each ad break begins playback
  AD_BREAK_FINISHED = 'adBreakFinished', // fired the first time each ad break finishes
  AD_PROGRESS = 'adProgress', // fired when there is an update to an ad's progress
  AD_STARTED = 'adStarted', // fired when an ad playback has been started
  AD_FINISHED = 'adFinished', // fired when an ad playback has been finished
  AD_HOLIDAY_STARTED = 'adHolidayStarted',
  AD_HOLIDAY_FINISHED = 'adHolidayFinished',

  VOLUME_CHANGED = 'volumeChanged',
  MUTED = 'muted',
  UNMUTED = 'unmuted',

  SUBTITLE_ENABLE = 'subtitleEnable', // fired when a subtitle is being enabled
  SUBTITLE_DISABLE = 'subtitleDisable', // fired when a subtitle is being disabled

  AUDIO_CHANGED = 'audioChanged', // fired when audio track is changed

  PLAYER_RESIZED = 'playerResized',
  VIEW_MODE_CHANGED = 'viewModeChanged',
  VIDEO_PLAYBACK_QUALITY_CHANGED = 'videoPlaybackQualityChanged',

  UI_LOADED = 'uiLoaded',
  UI_CONTROLS_SHOW = 'uiControlsShow', // fired when the ui controls are shown
  UI_CONTROLS_HIDE = 'uiControlsHide', // fired when the ui controls are hidden
  UI_VARIANT_RESOLVE = 'uiVariantResolve', // fired when uimanager.resolveUiVariant() is called
  UI_VARIANT_CHANGED = 'uiVariantChanged', // fired when uimanager has resolved and changed the player UI

  UI_SEEK_BACK = 'uiSeekBack', // fired when the ui seek back button is clicked
  UI_SEEK_FORWARD = 'uiSeekForward', // fired when the ui seek forward button is clicked

  PLAYER_ERROR = 'playerError', // error event fired by bitmovin
  PLAYER_WARNING = 'playerWarning', // error event fired by bitmovin

  METADATA = 'metadata', // event when encountering ID3 metadata tags in HLS streams, needed for ad insertion in livestreams
}

export type VideoPlayerUiVariantResolveEvent = VideoPlayerEventBase & UiVariantResolvePayload;

interface AdProgressDispatchPayload extends BaseDispatchPayload {
  remainingTime: number | null;
}

interface SeekDispatchPayload extends BaseDispatchPayload {
  position: number;
  positionContentTime: number;
  seekTarget: number;
}

interface PlaybackQualityChangedPayload extends BaseDispatchPayload {
  bitrate: number;
}

interface UiVariantResolvePayload extends BaseDispatchPayload {
  ui: UIInstanceManager;
}

interface AdEventPayload extends BaseDispatchPayload {
  adMetadata: AdMetadata | null;
}

interface PlayerErrorPayload extends BaseDispatchPayload {
  name: string;
  code: string;
  message: string;
  data?: Record<string, any>;
}

interface PlaybackDispatchPayload extends BaseDispatchPayload {
  time: number;
}

interface AdSnapBackPayload extends BaseDispatchPayload {
  position: number;
  positionContentTime: number;
}

interface CustomEventPayload extends BaseDispatchPayload {
  eventName?: string;
  customData?: Record<string, string | number>;
  position?: number;
  positionContentTime?: number;
}

interface VolumeChangedPayload extends BaseDispatchPayload {
  targetVolume: number;
}

interface SubtitleEventPayload extends BaseDispatchPayload {
  subtitle: {
    lang: string;
    label: string;
  };
}

interface AudioTrack {
  lang: string;
  label: string;
}

interface AudioChangedEventPayload extends BaseDispatchPayload {
  sourceAudio:AudioTrack | null;
  targetAudio: AudioTrack;
}

interface MetadataEventPayload extends BaseDispatchPayload {
  metadataType: MetadataType;
  metadata: Record<string, unknown>;
  start?: number;
  end?: number;
}

export interface EventHandlerPayloadMap {
  [VideoPlayerEventType.READY]: BaseDispatchPayload
  [VideoPlayerEventType.SOURCE_LOADED]: BaseDispatchPayload
  [VideoPlayerEventType.SOURCE_UNLOADED]: BaseDispatchPayload

  [VideoPlayerEventType.STREAM_HANDLER_INITIALISED]: BaseDispatchPayload

  [VideoPlayerEventType.PLAY]: BaseDispatchPayload
  [VideoPlayerEventType.PLAYING]: PlaybackDispatchPayload
  [VideoPlayerEventType.CONTENT_STARTED]: BaseDispatchPayload
  [VideoPlayerEventType.PAUSED]: PlaybackDispatchPayload

  [VideoPlayerEventType.TIME_CHANGED]: PlaybackDispatchPayload

  [VideoPlayerEventType.SEEK_STARTED]: SeekDispatchPayload
  [VideoPlayerEventType.SEEK]: BaseDispatchPayload
  [VideoPlayerEventType.SEEK_FINISHED]: BaseDispatchPayload

  [VideoPlayerEventType.PLAYBACK_STARTED]: BaseDispatchPayload
  [VideoPlayerEventType.PLAYBACK_FINISHED]: BaseDispatchPayload

  [VideoPlayerEventType.BUFFERING_STARTED]: BaseDispatchPayload
  [VideoPlayerEventType.BUFFERING_FINISHED]: BaseDispatchPayload

  [VideoPlayerEventType.CUSTOM_EVENT]: CustomEventPayload

  [VideoPlayerEventType.AD_BREAK_STARTED]: BaseDispatchPayload
  [VideoPlayerEventType.AD_BREAK_FINISHED]: BaseDispatchPayload
  [VideoPlayerEventType.AD_PROGRESS]: AdProgressDispatchPayload
  [VideoPlayerEventType.AD_STARTED]: AdEventPayload
  [VideoPlayerEventType.AD_FINISHED]: AdEventPayload
  [VideoPlayerEventType.AD_HOLIDAY_STARTED]: BaseDispatchPayload
  [VideoPlayerEventType.AD_HOLIDAY_FINISHED]: BaseDispatchPayload

  [VideoPlayerEventType.VOLUME_CHANGED]: VolumeChangedPayload
  [VideoPlayerEventType.MUTED]: BaseDispatchPayload
  [VideoPlayerEventType.UNMUTED]: BaseDispatchPayload

  [VideoPlayerEventType.SUBTITLE_ENABLE]: SubtitleEventPayload
  [VideoPlayerEventType.SUBTITLE_DISABLE]: SubtitleEventPayload
  [VideoPlayerEventType.AUDIO_CHANGED]: AudioChangedEventPayload

  [VideoPlayerEventType.PLAYER_RESIZED]: BaseDispatchPayload
  [VideoPlayerEventType.VIEW_MODE_CHANGED]: BaseDispatchPayload
  [VideoPlayerEventType.VIDEO_PLAYBACK_QUALITY_CHANGED]: PlaybackQualityChangedPayload

  [VideoPlayerEventType.UI_LOADED]: UiVariantResolvePayload
  [VideoPlayerEventType.UI_CONTROLS_SHOW]: BaseDispatchPayload
  [VideoPlayerEventType.UI_CONTROLS_HIDE]: BaseDispatchPayload
  [VideoPlayerEventType.UI_VARIANT_RESOLVE]: UiVariantResolvePayload
  [VideoPlayerEventType.UI_VARIANT_CHANGED]: UiVariantResolvePayload

  [VideoPlayerEventType.UI_SEEK_BACK]: BaseDispatchPayload
  [VideoPlayerEventType.UI_SEEK_FORWARD]: BaseDispatchPayload

  [VideoPlayerEventType.PLAYER_ERROR]: PlayerErrorPayload
  [VideoPlayerEventType.PLAYER_WARNING]: PlayerErrorPayload

  [VideoPlayerEventType.METADATA]: MetadataEventPayload
}

export type VideoPlayerEvent<T extends VideoPlayerEventType> = EventState & EventHandlerPayloadMap[T];

export type VideoEventHandler<T extends VideoPlayerEventType> = (event: VideoPlayerEvent<T>) => void;

export default class VideoPlayerEventManager {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private eventHandlers: { [eventType: string]: ((event: any) => void)[]; } = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private throttledLogDispatchFunctions: Partial<Record<VideoPlayerEventType, any>> = {};

  private readonly defaultState: EventState = {
    hasContentStarted: false,
    hasPlaybackStarted: false,
    isAd: false,
    isAdHoliday: undefined,
    timelineMarkers: [],
  };

  private state: EventState = {
    ...this.defaultState,
  };

  public addEventHandler<T extends VideoPlayerEventType>(eventType: T, handler: VideoEventHandler<T>) {
    if (!this.eventHandlers[eventType]) {
      this.eventHandlers[eventType] = [];
    }
    this.eventHandlers[eventType].push(handler);
  }

  public removeEventHandler<T extends VideoPlayerEventType>(eventType: T, handler: VideoEventHandler<T>) {
    if (this.eventHandlers[eventType]) {
      remove(this.eventHandlers[eventType], (o) => {
        return o === handler;
      });
    }
  }

  /**
   * Log the dispatch every 1s per event type
   * @param eventType
   * @param cbPayload
   * @private
   */
  private logDispatch<T extends VideoPlayerEventType>(eventType: T, cbPayload: EventState & EventHandlerPayloadMap[T]) {
    if (!this.throttledLogDispatchFunctions[eventType]) {
      this.throttledLogDispatchFunctions[eventType] = throttle((_cbPayload) => {
        Logger.debug(`VideoPlayerEventManager: dispatch ${eventType}`, _cbPayload);
      }, 1000);
    }

    this.throttledLogDispatchFunctions[eventType](cbPayload);
  }

  public dispatch<T extends VideoPlayerEventType>(eventType: T, payload: EventHandlerPayloadMap[T]) {
    const cbPayload: EventState & EventHandlerPayloadMap[T] = {
      ...this.state,
      ...payload,
    };

    this.logDispatch<T>(eventType, cbPayload);

    if (this.eventHandlers[eventType]) {
      this.eventHandlers[eventType].forEach((cb) => {
        cb(cbPayload);
      });
    }
  }

  public dispatchCustomEvent<T extends VideoPlayerEventType>(name: string, payload: EventHandlerPayloadMap[T]) {
    this.dispatch(VideoPlayerEventType.CUSTOM_EVENT, {
      eventName: name,
      ...payload,
    });
  }

  /**
   * Remove all event handlers and reset state
   */
  public clear(): void {
    this.eventHandlers = {};
    this.state = { ...this.defaultState };
  }

  /**
   * Update event state
   * @param key
   * @param value
   */
  public updateState<K extends keyof EventState>(key: K, value: EventState[K]) {
    this.state[key] = value;
  }
}
