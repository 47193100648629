/**
 * Emulates Bitmovin's SettingsPanel in order to have a React component acts as a video player settings panel.
 * Also allows interaction with other Bitmovin's UI elements.
 */
import { Component, ComponentConfig, ComponentHoverChangedEventArgs } from '@sbs/bitmovin-player-ui/dist/js/framework/components/component';
import { EventListener } from '@sbs/bitmovin-player-ui/src/ts/eventdispatcher';

interface OdSettingsPanelWrapperCustomEvent {
  name: string;
}

interface OdSettingsPanelWrapperCustomEvents {
  onShow: OdSettingsPanelWrapperCustomEvent,
  onHide: OdSettingsPanelWrapperCustomEvent,
  onHoverChanged: OdSettingsPanelWrapperCustomEvent,
  visibilityToggle: OdSettingsPanelWrapperCustomEvent,
  show: OdSettingsPanelWrapperCustomEvent,
  hide: OdSettingsPanelWrapperCustomEvent,
}

/**
 * Configuration interface for a {@link SettingsPanelWrapper}.
 */
interface OdSettingsPanelWrapperConfig extends ComponentConfig {
  rootElement: HTMLElement;
  customEvents: OdSettingsPanelWrapperCustomEvents;
}

interface OdSettingsPanelWrapperEvent<WrapperEventListener> {
  subscribe: (listener: WrapperEventListener) => void;
}

export default class SettingsPanelWrapper extends Component<OdSettingsPanelWrapperConfig> {
  protected config: OdSettingsPanelWrapperConfig;
  protected panelElement: HTMLElement;

  // Useless constructor to prevent eslint warning about wrong config type when instantiating this class
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(config: OdSettingsPanelWrapperConfig) {
    super(config);
  }

  // @ts-ignore: Overriding Bitmovin UI Component 's event behaviour
  get onShow(): OdSettingsPanelWrapperEvent<EventListenerOrEventListenerObject> {
    const config = this.getConfig();

    return {
      subscribe: (listener) => {
        config.rootElement.addEventListener(this.config.customEvents.onShow.name, listener);
      },
    };
  }

  // @ts-ignore: Overriding Bitmovin UI Component 's event behaviour
  get onHide(): OdSettingsPanelWrapperEvent<EventListenerOrEventListenerObject> {
    const config = this.getConfig();

    return {
      subscribe: (listener) => {
        config.rootElement.addEventListener(this.config.customEvents.onHide.name, listener);
      },
    };
  }

  // @ts-ignore: Overriding Bitmovin UI Component 's event behaviour
  get onHoverChanged(): OdSettingsPanelWrapperEvent<EventListener<typeof Component, ComponentHoverChangedEventArgs>> {
    const config = this.getConfig();

    return {
      subscribe: (listener: EventListener<typeof Component, ComponentHoverChangedEventArgs>) => {
        config.rootElement.addEventListener(this.config.customEvents.onHoverChanged.name, (event: CustomEvent) => {
          const { detail } = event;
          listener(null, detail);
        });
      },
    };
  }

  getConfig(): OdSettingsPanelWrapperConfig {
    return this.config;
  }

  getContainerId(): string {
    const config = this.getConfig();
    return config.id;
  }

  show(): void {
    super.show();
    const config = this.getConfig();
    const event = new CustomEvent(this.config.customEvents.show.name, { detail: { reason: 'panelShow' } });
    config.rootElement.dispatchEvent(event);
  }

  hide(): void {
    super.hide();
    const config = this.getConfig();
    const event = new CustomEvent(this.config.customEvents.hide.name, { detail: { reason: 'panelHide' } });
    config.rootElement.dispatchEvent(event);
  }

  configure(): void {
    this.onHoverChanged.subscribe((_, args) => {
      const { hovered } = args;
      this.onHoverChangedEvent(hovered);
    });

    this.onShow.subscribe((args: CustomEvent) => {
      const { detail: { target } } = args;
      if (target && !this.panelElement) {
        this.panelElement = target;
        this.panelElement.addEventListener('keydown', (event: KeyboardEvent) => {
          const { key } = event;
          if (key === 'Escape') {
            this.hide();
          }
        });
      }
    });
  }
}
