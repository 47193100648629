import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import {
  VideoEventHandler,
  VideoPlayerEventBase,
  VideoPlayerEventType,
} from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import type { VideoPlayerPluginProps } from '@@src/lib/VideoPlayerV2/plugins/VideoPlayerPlugin';
import DataLayer from '@@utils/DataLayer';

import AdOverlay from './AdOverlay';

const AdOnPause: FunctionComponent<VideoPlayerPluginProps> = (props) => {
  const {
    videoPlayer,
    video,
    playbackStreamData,
  } = props;

  const [show, setShow] = useState<boolean>(false);
  const [dismissed, setDismissed] = useState<boolean>(false);

  const showTimeout = useRef<number>();

  const { width, height } = useWindowSize();

  const minWidth = 1280;
  const minHeight = 800;

  const sizeRequirementMet = width >= minWidth && height >= minHeight;

  const creditsBegin = playbackStreamData.playbackEvents.closingCreditsBegin;

  const showOnPaused = useCallback<VideoEventHandler<VideoPlayerEventType.PAUSED>>((event) => {
    const { time, isAd, issuer } = event;

    let shouldShow: boolean = isAd === false
      // don't show when the video pause was triggered by a change of video (episode picker, rec endcard)
      && issuer !== 'videochange'
      && sizeRequirementMet
      // don't show ad when autoplay doesn't work. time is 0 when autoplay doesn't work regardless of resume position
      && time > 0;

    if (creditsBegin) {
      const creditsBeginStreamTime = videoPlayer.streamTimeForContentTime(creditsBegin);
      shouldShow = shouldShow && time < creditsBeginStreamTime;
    }

    if (shouldShow) {
      // add a second delay to show the ad
      showTimeout.current = setTimeout(() => {
        setShow(true);
      }, 1000);
    }

    return () => {
      if (showTimeout.current) {
        clearTimeout(showTimeout.current);
      }
    };
  }, [videoPlayer, creditsBegin, sizeRequirementMet]);

  const hideOnPlay = useCallback(() => {
    if (showTimeout.current) {
      clearTimeout(showTimeout.current);
    }
    setShow(false);
    // when playing, we clear the dismissed flag so that we can display another ad when the user pauses again.
    setDismissed(false);
  }, []);

  useEffect(() => {
    videoPlayer.on(VideoPlayerEventType.PAUSED, showOnPaused as (event: VideoPlayerEventBase) => void);
    return () => {
      videoPlayer.off(VideoPlayerEventType.PAUSED, showOnPaused as (event: VideoPlayerEventBase) => void);
    };
  }, [videoPlayer, showOnPaused]);

  useEffect(() => {
    videoPlayer.on(VideoPlayerEventType.PLAY, hideOnPlay);
    return () => {
      videoPlayer.off(VideoPlayerEventType.PLAY, hideOnPlay);
    };
  }, [videoPlayer, hideOnPlay]);

  const handleDismissed = useCallback((resume: boolean = false) => {
    setDismissed(true);
    DataLayer.events.adOnPauseDismiss(
      'userInteraction',
      {
        userInteractionDetails: {
          interactionName: 'ondemand:web:video-pause-ad-overlay:ad-dismiss',
        },
      },
    );

    if (resume) {
      videoPlayer.play();
    }
  }, [videoPlayer]);

  if (!show || !sizeRequirementMet || dismissed || !video) return null;

  return <AdOverlay video={video} onDismissed={handleDismissed}/>;
};

export default AdOnPause;
