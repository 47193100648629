import { useHistory } from 'react-router-dom';

import { BASENAME, WEB_HOST } from '@@utils/constants';

export default function useIsDirectAccess(): boolean {
  const history = useHistory();

  return history.length === 1
  || (history.length === 2 && (!document?.referrer.startsWith(`${WEB_HOST}${BASENAME}`)));
}
