import { FunctionComponent, useState } from 'react';

import BitmovinClient from '../../../../lib/VideoPlayer/BitmovinClient';
import ConsumerAdviceOverlay from './ConsumerAdviceOverlay';

export interface ConsumerAdvicePluginComponentProps {
  /** Classification */
  classification: string;
  /** Consumer Advice */
  consumerAdvice: string[];
  /** Consumer Warnings */
  consumerWarnings: string[];
  bitmovinClient: BitmovinClient;
}

const ConsumerAdvicePluginComponent: FunctionComponent<ConsumerAdvicePluginComponentProps> = (props) => {
  const {
    classification, consumerAdvice, consumerWarnings,
    bitmovinClient,
  } = props;

  // once this is set, it will stop showing regardless of the show state above
  // this will be set to true after the showTimeout
  const [stopShowing, setStopShowing] = useState<boolean>(false);

  if (stopShowing) return null;

  return (
    <ConsumerAdviceOverlay
      bitmovinClient={bitmovinClient}
      classification={classification}
      consumerAdvice={consumerAdvice}
      consumerWarnings={consumerWarnings}
      onHide={() => {
        setStopShowing(true);
      }}
    />
  );
};

export default ConsumerAdvicePluginComponent;
