import { Component, Container, UIContainer, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { EventDispatcher, NoArgs } from '@sbs/bitmovin-player-ui/dist/js/framework/eventdispatcher';
import { UIVariant } from '@sbs/bitmovin-player-ui/dist/js/framework/uimanager';
import { PlayerAPI } from 'bitmovin-player';

export interface SbsUIVariant extends UIVariant {
  ui: SbsUIContainer;
}

export class SbsUIContainer extends UIContainer {
  private events = {
    onControlsShow: new EventDispatcher<SbsUIContainer, NoArgs>(),
    onControlsHide: new EventDispatcher<SbsUIContainer, NoArgs>(),
  };

  configure(player: PlayerAPI, uimanager: UIInstanceManager) {
    super.configure(player, uimanager);

    uimanager.onControlsShow.subscribe(() => {
      this.events.onControlsShow.dispatch(this);
    });

    uimanager.onControlsHide.subscribe(() => {
      this.events.onControlsHide.dispatch(this);
    });
  }

  // expose uimanager onControlsShow events so that we can subscribe to them
  get onControlsShow(): EventDispatcher<SbsUIContainer, NoArgs> {
    return this.events.onControlsShow;
  }

  // expose uimanager onControlsHide events so that we can subscribe to them
  get onControlsHide(): EventDispatcher<SbsUIContainer, NoArgs> {
    return this.events.onControlsHide;
  }

  /**
   * Find all components of a given type in the container and its children
   * @param type
   */
  findComponents<T extends Component<any>>(type: new (config: any) => T): T[] {
    const c: T[] = [];

    function findRecursive(el: Container<any>) {
      el.getComponents().forEach((component) => {
        if (component instanceof type) {
          c.push(component);
        }

        if (component instanceof Container) {
          findRecursive(component);
        }
      });
    }

    findRecursive(this);

    return c;
  }
}

export default { SbsUIContainer };
