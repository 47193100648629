import { FunctionComponent, useEffect, useCallback } from 'react';

import { useAppSelector } from '@@src/hooks/store';
import { VideoPlayerEventType } from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import { VideoPlayerPluginProps } from '@@src/lib/VideoPlayerV2/plugins/VideoPlayerPlugin';
import { getRecommendation } from '@@stores/RecommendationStore';
import DataLayer from '@@utils/DataLayer';
import { generateRecommendationTrackingData } from '@@utils/Recommendations';

const SeekButtonsTracker: FunctionComponent<VideoPlayerPluginProps> = (props) => {
  const { videoPlayer, video } = props;
  const recommendation = useAppSelector(getRecommendation);

  const onSeekForward = useCallback(() => {
    if (video) {
      const { personalisation } = generateRecommendationTrackingData(recommendation, video);
      const chapterData = videoPlayer.getCurrentChapterData();

      DataLayer.events.videoTrackingEvent(
        'videoSkipForward',
        video,
        {
          ...(chapterData && { currentChapterData: chapterData }),
          personalisation,
        },
      );
    }
  }, [recommendation, video, videoPlayer]);

  const onSeekBack = useCallback(() => {
    if (video) {
      const { personalisation } = generateRecommendationTrackingData(recommendation, video);
      const chapterData = videoPlayer.getCurrentChapterData();

      DataLayer.events.videoTrackingEvent(
        'videoSkipBackward',
        video,
        {
          ...(chapterData && { currentChapterData: chapterData }),
          personalisation,
        },
      );
    }
  }, [recommendation, video, videoPlayer]);

  useEffect(() => {
    if (video) {
      videoPlayer.on(VideoPlayerEventType.UI_SEEK_FORWARD, onSeekForward);
      videoPlayer.on(VideoPlayerEventType.UI_SEEK_BACK, onSeekBack);

      return () => {
        videoPlayer.off(VideoPlayerEventType.UI_SEEK_FORWARD, onSeekForward);
        videoPlayer.off(VideoPlayerEventType.UI_SEEK_BACK, onSeekBack);
      };
    }

    return undefined;
  }, [videoPlayer, onSeekBack, onSeekForward, video]);

  // This plugin does not have a UI
  return null;
};

export default SeekButtonsTracker;
