import { get } from 'lodash';
import { FunctionComponent, useEffect, useRef, useState, useCallback } from 'react';

import Logger from '@@utils/logger/Logger';

import BitmovinClient from '../../../../lib/VideoPlayer/BitmovinClient';

interface ShowAtPlayTimeProps {
  bitmovinClient: BitmovinClient;

  /**
   * The content time, in seconds after which the element will be shown.
   */
  startTime: number;

  /**
   * The content time, in seconds after which the element will not be shown.
   */
  endTime?: number;

  /**
   * The minimum duration, in seconds before the end time to show the content.
   */
  minDurationBeforeEndTime?: number;

  /**
   * The function to be executed when the show event is triggered.
   */
  onShow?(): void;

  /**
   * The time, in seconds that the button would auto hide after it is displayed.
   */
  autoHideDelay?: number;

  /**
   * The time, in seconds that the button would auto hide after the bitmovin ui is hidden.
   */
  autoHideAfterUiHideDelay?: number;

  /**
   * If true, hide the component when the player is paused
   */
  hideOnPause?: boolean;
}

/**
 * Generic component which will show the children element between start time and end time.
 * There are optional props to auto hide after it's shown or after the bitmovin ui is hidden.
 * @param props
 * @constructor
 */
const ShowAtPlayTime: FunctionComponent<ShowAtPlayTimeProps> = (props) => {
  const {
    bitmovinClient,
    children,
    startTime,
    endTime,
    onShow,
    autoHideDelay,
    autoHideAfterUiHideDelay,
    minDurationBeforeEndTime,
    hideOnPause = false,
  } = props;

  // show the skip intro button
  const [show, setShow] = useState<boolean>(false);

  // when autohide is set to true, the element will not be shown until it's set back to false
  const [autoHide, setAutoHide] = useState<boolean>(false);

  const autoHideTimeout = useRef(null);
  const autoHideAfterUiHideTimeout = useRef(null);

  const checkShouldShow = useCallback((event) => {
    // dont need to check anything if auto hide is true
    if (autoHide) return;

    // When casting the DAI stream is different on the receiver so content to stream time conversion will be incorrect
    // For now we won't use any plugins that rely on time codes.
    if (bitmovinClient.state.isStreamDai && bitmovinClient.state.isCasting) {
      return;
    }

    const { time } = event;
    const startStreamTime = bitmovinClient.streamTimeForContentTime(startTime);
    const endStreamTime = bitmovinClient.streamTimeForContentTime(endTime);
    const timeLeftToEndTime = endStreamTime - time;

    let haveEnoughTimeToShow = true;
    if (!show && minDurationBeforeEndTime) {
      haveEnoughTimeToShow = minDurationBeforeEndTime <= timeLeftToEndTime;
    }

    const shouldShow = time >= startStreamTime
      && (!endTime || time <= endStreamTime)
      && haveEnoughTimeToShow;

    if (shouldShow && !show) {
      setShow(true);
    } else if (!shouldShow && show) {
      setShow(false);
    }
  }, [autoHide, bitmovinClient, endTime, minDurationBeforeEndTime, show, startTime]);

  // When playing, check if it should show the element
  useEffect(() => {
    const handlePaused = () => {
      setShow(false);
    };

    if (bitmovinClient.playerEvents) {
      bitmovinClient.on('Playing', checkShouldShow);
      if (hideOnPause === true) {
        bitmovinClient.on('Paused', handlePaused);
      }
    }

    return () => {
      if (bitmovinClient.playerEvents) {
        bitmovinClient.off('Playing', checkShouldShow);
        if (hideOnPause === true) {
          bitmovinClient.off('Paused', handlePaused);
        }
      }
    };
  }, [bitmovinClient, checkShouldShow, hideOnPause]);

  // when the show is set to true, create a timer to auto hide the element after a delay
  useEffect(() => {
    if (show) {
      if (onShow) {
        onShow();
      }

      if (autoHideDelay) {
        autoHideTimeout.current = setTimeout(() => {
          Logger.debug(`Auto hide element ${autoHideDelay}s after it's displayed.`);
          setShow(false);
          setAutoHide(true);
        }, autoHideDelay * 1000);
      }
    }

    return () => {
      clearTimeout(autoHideTimeout.current);
    };
  }, [autoHideDelay, onShow, show]);

  // when the ui control is hidden, create a timer to hide the element after a delay
  useEffect(() => {
    const autoHideWhenControlIsHidden = () => {
      autoHideAfterUiHideTimeout.current = setTimeout(() => {
        Logger.debug(`Auto hide element ${autoHideAfterUiHideDelay}s after ui is hidden.`);

        setShow(false);
        setAutoHide(true);
      }, autoHideAfterUiHideDelay * 1000);
    };

    const currentUi = get(bitmovinClient.uiManager, 'currentUi');
    if (currentUi && autoHideAfterUiHideDelay) {
      currentUi.onControlsHide.subscribe(autoHideWhenControlIsHidden);
    }

    return () => {
      if (currentUi) {
        currentUi.onControlsHide.unsubscribe(autoHideWhenControlIsHidden);
      }
      clearTimeout(autoHideAfterUiHideTimeout.current);
    };
  }, [autoHideAfterUiHideDelay, bitmovinClient]);

  // when the ui control is shown, cancel any timer created to hide the element after a delay
  useEffect(() => {
    function cancelAutoHideWhenControlIsHidden() {
      Logger.debug(`Cancel Auto hide element ${autoHideAfterUiHideDelay}s after ui is shown.`);
      clearTimeout(autoHideAfterUiHideTimeout.current);
      setAutoHide(false);
    }

    const currentUi = get(bitmovinClient.uiManager, 'currentUi');
    if (currentUi && autoHideAfterUiHideDelay) {
      currentUi.onControlsShow.subscribe(cancelAutoHideWhenControlIsHidden);
    }

    return () => {
      if (currentUi) {
        currentUi.onControlsShow.unsubscribe(cancelAutoHideWhenControlIsHidden);
      }
      clearTimeout(autoHideAfterUiHideTimeout.current);
    };
  }, [autoHideAfterUiHideDelay, bitmovinClient]);

  // When 'Play' ie: from resume, clear auto hide
  useEffect(() => {
    const clearAutoHide = () => {
      setAutoHide(false);
    };

    if (bitmovinClient.playerEvents) {
      bitmovinClient.on('Play', clearAutoHide);
    }
    return () => {
      if (bitmovinClient.playerEvents) {
        bitmovinClient.off('Play', clearAutoHide);
      }
    };
  }, [bitmovinClient]);

  if (!show) {
    return null;
  }

  return <>{children}</>;
};

export default ShowAtPlayTime;
