import { createStyles, makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import PlayIcon from '@@icons/Play';
import { durationShortFormatParams } from '@@src/i18n';
import { getImageByType } from '@@utils/ApiV3ItemUtils';

import OnDemand from '../../../../@types/OnDemand';
import orange from '../../../../styles/colors/orange';
import fontFamily from '../../../../styles/typography/fontFamily';
import Button from '../../../Inputs/Button';
import Metadata from '../../../Tiles/Metadata';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      position: 'absolute',
      bottom: 0,
      color: 'white',
      fontFamily: fontFamily.primary,
      background: (props) => {
        const backgroundImage = get(props, 'backgroundImage');
        if (backgroundImage) {
          return `linear-gradient(-90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%), url(${backgroundImage}) top left / cover no-repeat`;
        }
        return 'black';
      },
      width: '100%',
      height: '100%',
      '.casting &': {
        display: 'none',
      },
    },
    container: {
      position: 'absolute',
      top: 'calc(100vh * 0.2236)',
      left: 'calc(100vw * 0.1242)',
      width: 469,
    },
    button: {
      width: 234,
      margin: 0,
      textTransform: 'none',
      '&.MuiButton-outlined': {
        color: 'white',
      },
      pointerEvents: 'auto',
    },
    cardTitle: {
      fontSize: '1.25rem',
      color: orange.darkTangerine,
      marginBottom: 8,
      textTransform: 'uppercase',
    },
    seriesData: {
      fontSize: '2rem',
      fontWeight: 'bold',
      fontFamily: fontFamily.secondary,
    },
    nextEpTitle: {
      fontSize: '3.25rem',
      fontWeight: 'bold',
      fontFamily: fontFamily.secondary,
    },
    nextEpMetadata: {
      display: 'flex',
      lineHeight: 1,
      fontSize: '1.125rem',
      fontWeight: 500,
      fontFamily: fontFamily.secondary,
      '& span': {
        marginRight: 15,
      },
    },
    nextEpDescription: {
      fontSize: '1.125rem',
      marginBottom: 40,
    },
    metadataBadges: {
      display: 'flex',
      fontSize: '0.9375em',
      lineHeight: 1.2,
      '& span': {
        marginRight: 2,
      },
      marginRight: 13,
    },
  });
});

export interface NextEpisodeCardProps {
  /** The next episode that will be displayed. */
  episode: OnDemand.Episode;

  /** The function that will be called when the play button is clicked */
  onClickPlay?(): void;
}

const NextEpisodeCard: FunctionComponent<NextEpisodeCardProps> = (props) => {
  const {
    episode,
    onClickPlay,
  } = props;
  const images = get(episode, 'images');
  const backgroundImage = getImageByType(images, ['hd720', 'thumbnailLarge', 'thumbnail']);
  const classes = useStyles({
    backgroundImage,
    ...props,
  });
  const { t } = useTranslation('common');

  const handleClick = useCallback(() => {
    if (onClickPlay) {
      onClickPlay();
    }

    const event = new CustomEvent('OdPlayerVideoChange', { detail: { videoId: episode.id } });
    document.dispatchEvent(event);
  }, [onClickPlay, episode]);

  return (
    <div className={classes.root} data-testid="next-episode-card">
      <div className={classes.container}>
        <div className={classes.cardTitle}>{t('videoPlayer.nextEpisode')}</div>
        <div className={classes.seriesData}>
          {episode.episodeData.programName}
        </div>
        <div className={classes.seriesData}>
          {' '}
          {t('videoPlayer.season')}
          {' '}
          {episode.episodeData.seasonNumber}
          {' '}
          {t('videoPlayer.episode')}
          {' '}
          {episode.episodeData.episodeNumber}
        </div>
        <div className={classes.nextEpTitle}>{episode.title}</div>
        <div className={classes.nextEpMetadata}>
          <span>
            {t('common:video.duration', {
              duration: episode.duration,
              formatParams: {
                duration: durationShortFormatParams,
              },
            })}
          </span>
          <Metadata
            isActive
            classification={get(episode, 'classification')}
            classes={{ root: classes.metadataBadges }}
          />
        </div>
        <p className={classes.nextEpDescription}>{episode.shortDescription}</p>
        <Button
          size="large"
          onClick={handleClick}
          startIcon={<PlayIcon/>}
        >
          {t('videoPlayer.playNextEpisode')}
        </Button>
      </div>
    </div>
  );
};

export default NextEpisodeCard;
