import { ToggleButton, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { ToggleButtonConfig } from '@sbs/bitmovin-player-ui/src/ts/components/togglebutton';
import { PlayerAPI } from 'bitmovin-player';

import DataLayer from '@@utils/DataLayer';
import Logger from '@@utils/logger/Logger';

declare global {
  interface Document {
    pictureInPictureElement: HTMLElement;
    requestPictureInPicture: () => Promise<void>;
    exitPictureInPicture: () => Promise<void>;
  }
}

/**
 * A button that toggles Apple macOS picture-in-picture mode.
 */
export default class PictureInPictureToggleButton extends ToggleButton<ToggleButtonConfig> {
  private isInitialized: boolean;
  private player: PlayerAPI;

  constructor(config: ToggleButtonConfig = {}) {
    super(config);

    this.isInitialized = false;
    this.config = this.mergeConfig(config, {
      cssClass: 'ui-piptogglebutton',
      text: 'Picture in Picture',
    }, this.config);
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);
    this.player = player;

    const pictureInPictureAvailabilityChangedHandler = () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.isPictureInPictureAvailable() ? this.show() : this.hide();
    };

    uimanager.getConfig().events.onUpdated.subscribe(pictureInPictureAvailabilityChangedHandler);
    this.player.on(this.player.exports.PlayerEvent.Ready, this.onPlayerReady);
    this.player.on(this.player.exports.PlayerEvent.AdBreakStarted, this.onAdBreakStarted);
    this.player.on(this.player.exports.PlayerEvent.AdBreakFinished, this.onAdBreakFinished);

    this.onClick.subscribe(() => {
      if (!this.isPictureInPictureAvailable()) {
        if (console) {
          Logger.warn('PIP: unavailable');
        }
        return;
      }

      if (!document.pictureInPictureElement) {
        // @ts-ignore not defined
        player.getVideoElement().requestPictureInPicture()
          .then(() => {
            DataLayer.events.pipActivated();
          })
          .catch((error) => {
            Logger.error(`PIP: ${error.message}`);
          });
      } else {
        document.exitPictureInPicture()
          .catch((error) => {
            Logger.error(`PIP: ${error.message}`);
          });
      }
    });

    // Startup init
    pictureInPictureAvailabilityChangedHandler(); // Hide button if PIP not available
  }

  onPlayerReady = () => {
    if (!this.isInitialized && this.isPictureInPictureAvailable()) {
      this.player.getVideoElement().addEventListener('enterpictureinpicture', this.onEnterPip, false);
      this.player.getVideoElement().addEventListener('leavepictureinpicture', this.onLeavePip, false);
      this.isInitialized = true;
    }
  };

  // This is a Bitmovin event not the DAI one, so it only applies to VAST ads (non-DAI streams)
  onAdBreakStarted = () => {
    this.hide();
  };

  // This is a Bitmovin event not the DAI one, so it only applies to VAST ads (non-DAI streams)
  onAdBreakFinished = () => {
    if (this.isPictureInPictureAvailable()) {
      this.show();
    }
  };

  onEnterPip = () => {
    this.player.getContainer().classList.add('pip');
  };

  onLeavePip = () => {
    this.player.getContainer().classList.remove('pip');
  };

  isPictureInPictureAvailable = () => {
    return document.pictureInPictureEnabled;
  };

  release(): void {
    if (
      this.isInitialized
      && this.isPictureInPictureAvailable()
    ) {
      this.player.getVideoElement().removeEventListener('enterpictureinpicture', this.onEnterPip);
      this.player.getVideoElement().removeEventListener('leavepictureinpicture', this.onLeavePip);
    }
  }
}
