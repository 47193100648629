import { Box, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { get } from 'lodash';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import PlayIcon from '@@icons/Play';
import { getResizedUrl } from '@@src/components/Html/Image';
import Button from '@@src/components/Inputs/Button';
import Metadata from '@@src/components/Tiles/Metadata';
import { durationShortFormatParams } from '@@src/i18n';
import fontFamily from '@@styles/typography/fontFamily';
import OnDemand from '@@types/OnDemand';

import EndCard from '../EndCard';

const useStyles = makeStyles(() => {
  return createStyles({
    metadata: {
      marginTop: '0.8em',
      fontFamily: fontFamily.secondary,
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: '1em',
      fontWeight: 500,
      alignItems: 'center',
      rowGap: 5,
      '& > span': {
        marginRight: 15,
      },
      lineHeight: 1,
    },
    metadataBadges: {
      display: 'flex',
      fontSize: '1.5rem',
      lineHeight: 1.2,
      '& span': {
        marginRight: 2,
      },
      marginRight: 13,
      '& svg': {
        height: '0.8em',
      },
      '& > *:not(:last-child)': {
        marginRight: 8,
      },
    },
  });
});

export interface NextEpisodeCardProps {
  /** The next episode that will be displayed. */
  episode: OnDemand.SlimEpisode;

  /** The function that will be called when the play button is clicked */
  onClickPlay?(): void;
}

const NextEpisodeCard: FunctionComponent<NextEpisodeCardProps> = (props) => {
  const {
    episode,
    onClickPlay,
  } = props;

  const backgroundImage = episode.odImageId ? getResizedUrl({ imageId: episode.odImageId }) : '';

  const classes = useStyles(props);

  const { t } = useTranslation('common');
  const theme = useTheme();
  const mediaMatchesMd = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = useCallback(() => {
    const event = new CustomEvent('OdPlayerVideoChange', {
      detail: {
        videoId: episode.id,
        allowResume: false,
      },
    });
    document.dispatchEvent(event);

    if (onClickPlay) {
      onClickPlay();
    }
  }, [episode.id, onClickPlay]);

  let title: string;

  const seasonNumber = episode.episodeData?.seasonNumber || 0;
  const episodeNumber = episode.episodeData?.episodeNumber || 0;

  if (episode.episodeData?.seriesType === 'news' || episode.episodeData?.seriesType === 'sports') {
    title = t('videoPlayer.seriesAndEpisodeTitle', {
      seriesTitle: episode.episodeData?.programName,
      episodeTitle: episode.title,
    });
  } else {
    title = t('videoPlayer.seriesTitleWithSeasonAndEpisode', {
      seriesTitle: episode.episodeData?.programName,
      seasonNumber,
      episodeNumber,
    });
  }

  const metadata = (
    <div className={classes.metadata}>
      <span>
        {t('common:video.duration', {
          duration: episode.duration,
          formatParams: {
            duration: durationShortFormatParams,
          },
        })}
      </span>
      <Metadata
        isActive
        classification={get(episode, 'classification')}
        classes={{ root: classes.metadataBadges }}
      />
    </div>
  );

  const buttons = (
    <Box>
      <Button
        size={mediaMatchesMd ? 'medium' : 'large'}
        onClick={handleClick}
        startIcon={<PlayIcon/>}
      >
        {t('videoPlayer.playNextEpisode')}
      </Button>
    </Box>
  );

  return (
    <EndCard
      backgroundImageUrl={backgroundImage}
      cardTitle={t('videoPlayer.nextEpisode')}
      videoTitle={title}
      description={episode.shortDescription || ''}
      metadata={metadata}
      buttons={buttons}
    />
  );
};

export default NextEpisodeCard;
