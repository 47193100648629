import { get } from 'lodash';

import VideoStream from '../@types/VideoStream';
import fetch from '../apis/VideoStreamApi';
import transform from '../transformers/VideoStreamTransformer';

/**
 * Fetch config from video config API and transform it to our config
 */
export function getStream(videoId, params): Promise<VideoStream.DaiStream> {
  return fetch(videoId, params)
    .then((result) => {
      const error = get(result, 'error');
      if (error) {
        throw new Error(error);
      }

      if (result) {
        return transform(result);
      }

      throw new Error('Failed fetching stream');
    });
}

export default getStream;
