/*** IMPORTS FROM imports-loader ***/

(function() {
/* eslint-disable */
/*! (C) 2016 Conviva, Inc. All rights reserved. Confidential and proprietary. */
!(function (a, b) {
  if (typeof define === 'function' && define.amd ? define(b) : typeof exports === 'object' && (module.exports = b()), typeof a !== 'undefined') {
    if (void 0 !== a.Conviva) return; if (a.ConvivaLoading) return; a.ConvivaLoading = !0,
    a.Conviva = b(), delete a.ConvivaLoading;
  }
}(this, () => {
  const a = {}; return (function () {
    const b = {}; !(function () {
      var c = a.Client = function (b, g) {
        function i(a, b) {
          if (!(a instanceof d)) throw new Error('clientSettings parameter should be an instance of ClientSettings.');
          if (!(b instanceof f)) throw new Error('systemFactory parameter should be an instance of SystemFactory.'); (a.gatewayUrl == d._httpsProtocol + d.defaultProductionGatewayDNS || a.gatewayUrl == d._httpProtocol + d.defaultProductionGatewayDNS) && (this.defaultGatewayURLError = !0),
          this._settings = a.clone(), this._systemFactory = b, this._systemFactory.configure('SDK', this._settings), this._exceptionCatcher = this._systemFactory.buildExceptionCatcher(), this._exceptionCatcher.runProtected('Client.init', () => {
            j._id = F.uinteger32(), j._logger = j._systemFactory.buildLogger(), j._logger.setModuleName('Client'), j.defaultGatewayURLError && j._logger.info('Gateway URL should not be set to https://cws.conviva.com or http://cws.conviva.com, therefore this call is ignored'),
            j._logger.info(`init(): url=${j._settings.gatewayUrl} customerKey=${j._settings.customerKey}`), j._config = j._systemFactory.buildConfig(), j._config.load(), j._sessionFactory = j._systemFactory.buildSessionFactory(j, j._settings, j._config),
            j._logger.info('init(): done.');
          }, (a) => { throw new Error(`Client constructor failed: ${a.message}`); });
        } var j = this; this.defaultGatewayURLError = !1, this._logger = null, this._sessionFactory = null, this._systemFactory = null,
        this._globalSessionKey = -1, this._settings = null, this._systemSettings = null, this._exceptionCatcher = null, this._config = null, this._id = -1, this._released = !1, i.apply(this, arguments), this.release = function () {
          this._released || this._exceptionCatcher.runProtected('Client.release', () => {
            j._logger.info('release()'), j._sessionFactory.cleanup(), j._sessionFactory = null, j._globalSessionKey = -1, j._logger = null, j._id = -1, j._exceptionCatcher = null, j._settings = null, j._systemSettings = null, j._systemFactory = null,
            j._released = !0;
          });
        }, this.createSession = function (a) {
          let b = c.NO_SESSION_KEY; return this._released ? b : a == null || a instanceof e ? (this._exceptionCatcher.runProtected('Client.createSession', () => {
            b = j._sessionFactory.makeVideoSession(a, u.SESSION_TYPE.VIDEO);
          }), b) : (j._logger.error('createSession(): expecting an instance of ContentMetadata for contentMetadata parameter'), b);
        }, this.createAdSession = function (a, b) {
          let d = c.NO_SESSION_KEY; return this._released ? d : b == null || b instanceof e ? (this._exceptionCatcher.runProtected('Client.createAdSession', () => {
            const c = j._sessionFactory.getVideoSession(a); c != null && (b == null && (b = new e()), (b.applicationName == null || b.viewerId == null) && (b.viewerId == null && c._contentMetadata.viewerId != null && (b.viewerId = c._contentMetadata.viewerId),
            b.applicationName == null && c._contentMetadata.applicationName != null && (b.applicationName = c._contentMetadata.applicationName)), b.custom['c3.csid'] = C.ToString(c._id)), d = j._sessionFactory.makeVideoSession(b, u.SESSION_TYPE.AD);
          }), d) : (j._logger.error('createAdSession(): expecting an instance of ContentMetadata for adMetadata parameter'), d);
        }, this.reportError = function (b, c, d) {
          return this._released ? void 0 : C.isValidString(c) ? d !== a.Client.ErrorSeverity.FATAL && d !== a.Client.ErrorSeverity.WARNING ? void this._logger.error(`reportError(): invalid error severity: ${d}`) : void this._exceptionCatcher.runProtected('Client.reportError', () => {
            const a = j._sessionFactory.getVideoSession(b); a != null && a.reportError(c, d);
          }) : void this._logger.error(`reportError(): invalid error message string: ${c}`);
        }, this.updateContentMetadata = function (a, b) {
          return this._released ? void 0 : b instanceof e ? void this._exceptionCatcher.runProtected('Client.updateContentMetadata', () => {
            const c = j._sessionFactory.getVideoSession(a); if (c != null) { const d = b.clone(); c.updateContentMetadata(d); }
          }) : void j._logger.error('updateContentMetadata(): expecting an instance of ContentMetadata for contentMetadata parameter');
        }, this.detachPlayer = function (a) { this._released || this._exceptionCatcher.runProtected('Client.detachPlayer', () => { const b = j._sessionFactory.getVideoSession(a); b != null && b.detachPlayer(); }); }, this.attachPlayer = function (a, b) {
          return this._released ? void 0 : b instanceof h ? void this._exceptionCatcher.runProtected('Client.attachPlayer', () => { const c = j._sessionFactory.getVideoSession(a); c != null && c.attachPlayer(b); }) : void j._logger.error('attachPlayer(): expecting an instance of PlayerStateManager for playerStateManager parameter');
        }, this.contentPreload = function (a) { this._released || this._exceptionCatcher.runProtected('Client.contentPreload', () => { const b = j._sessionFactory.getVideoSession(a); b != null && b.contentPreload(); }); }, this.contentStart = function (a) {
          this._released || this._exceptionCatcher.runProtected('Client.contentStart', () => { const b = j._sessionFactory.getVideoSession(a); b != null && b.contentStart(); });
        }, this.sendCustomEvent = function (a, b, d) {
          return this._released ? void 0 : C.isValidString(b) ? void this._exceptionCatcher.runProtected('Client.sendCustomEvent', () => {
            if (a == c.NO_SESSION_KEY) { if (j._globalSessionKey < 0) { const f = new e(); j._globalSessionKey = j._sessionFactory.makeGlobalSession(f, null); }a = j._globalSessionKey; } const g = C.sanitizeStringToStringObject(d); const h = j._sessionFactory.getSession(a);
            h != null && h.sendCustomEvent(b, g);
          }) : void j._logger.error('sendCustomEvent(): eventName parameter must be a valid string.');
        }, this.adStart = function (a, b, d, e) {
          return this._released ? void 0 : b !== c.AdStream.CONTENT && b !== c.AdStream.SEPARATE ? void this._logger.error(`adStart(): ignored, invalid value for adStream parameter: ${b}`) : d !== c.AdPlayer.CONTENT && d !== c.AdPlayer.SEPARATE ? void this._logger.error(`adStart(): ignored, invalid value for adPlayer parameter: ${d}`) : e !== c.AdPosition.BUMPER && e !== c.AdPosition.PREROLL && e !== c.AdPosition.MIDROLL && e !== c.AdPosition.POSTROLL ? void this._logger.error(`adStart(): ignored, invalid value for adPosition parameter: ${e}`) : void this._exceptionCatcher.runProtected('Client.adStart', () => {
            const c = j._sessionFactory.getVideoSession(a); c != null && c.adStart(b, d, e);
          });
        }, this.adEnd = function (a) {
          this._released || this._exceptionCatcher.runProtected('Client.adEnd', () => {
            const b = j._sessionFactory.getVideoSession(a);
            b != null && b.adEnd();
          });
        }, this.cleanupSession = function (a) {
          return this._released ? void 0 : a === c.NO_SESSION_KEY ? void this._logger.warning("cleanupSession(): ignored, can't cleanup Client.NO_SESSION_KEY") : void this._exceptionCatcher.runProtected('Client.cleanupSession', () => {
            const b = j._sessionFactory.getVideoSession(a); b != null && j._sessionFactory.cleanupSession(a);
          });
        }, this.getAttachedPlayer = function (a) {
          let b = null; return this._released ? b : a === c.NO_SESSION_KEY ? (this._logger.warning('getAttachedPlayer(): ignored for Client.NO_SESSION_KEY'),
          b) : (this._exceptionCatcher.runProtected('Client.getAttachedPlayer', () => { const c = j._sessionFactory.getVideoSession(a); c != null && (b = c.getPlayerStateManager()); }), b);
        }, this.isPlayerAttached = function (a) {
          return this._released ? !1 : this.getAttachedPlayer(a) !== null ? !0 : !1;
        }, this.getPlayerStateManager = function () { if (this._released) throw new Error('This instance of Conviva.Client has been released.'); return new h(this._systemFactory); }, this.releasePlayerStateManager = function (a) {
          if (this._released) throw new Error('This instance of Conviva.Client has been released.'); this._exceptionCatcher.runProtected('Client.releasePlayerStateManager', () => { a instanceof h && a.release(); });
        },
        this.getSettings = function () { return this._settings; }, this.getId = function () { return this._id; };
      }; c.version = '2.151.0.36995', c.NO_SESSION_KEY = -2, c.AdPosition = {
        BUMPER: 'BUMPER',
        PREROLL: 'PREROLL',
        MIDROLL: 'MIDROLL',
        POSTROLL: 'POSTROLL',
      }, c.AdStream = { CONTENT: 'CONTENT', SEPARATE: 'SEPARATE' }, c.AdPlayer = { CONTENT: 'CONTENT', SEPARATE: 'SEPARATE' }, c.ErrorSeverity = { FATAL: 0, WARNING: 1 }, c.DeviceType = {
        DESKTOP: 'DESKTOP',
        CONSOLE: 'Console',
        SETTOP: 'Settop',
        MOBILE: 'Mobile',
        TABLET: 'Tablet',
        SMARTTV: 'SmartTV',
      }, c.AdTechnology = { CLIENT_SIDE: 'Client Side', SERVER_SIDE: 'Server Side' }, c.AdType = {
        BLACKOUT_SLATE: 'Blackout slate',
        TECHNICAL_DIFFICULTIES_SLATE: 'Technical Difficulties slate',
        COMMERCIAL_SLATE: 'Commercial Break slate',
        OTHER_SLATE: 'Other slate',
        VPAID: 'VPAID',
        REGULAR: 'Regular Ad',
      }, c.AdServingType = { INLINE: 'Inline', WRAPPER: 'Wrapper' }, c.DeviceCategory = {
        ANDROID_DEVICE: 'AND',
        APPLE_DEVICE: 'APL',
        CHROMECAST: 'CHR',
        DESKTOP_APP: 'DSKAPP',
        DEVICE_SIMULATOR: 'SIMULATOR',
        LG_TV: 'LGTV',
        NINTENDO: 'NINTENDO',
        PLAYSTATION: 'PS',
        ROKU: 'RK',
        SAMSUNG_TV: 'SAMSUNGTV',
        SMART_TV: 'TV',
        SET_TOP_BOX: 'STB',
        TIVO: 'TIVO',
        WEB: 'WEB',
        WINDOWS_DEVICE: 'WIN',
        XBOX: 'XB',
        KAIOS_DEVICE: 'KAIOS',
      }; var d = a.ClientSettings = function (a) {
        function b(a) { if (!C.isValidString(a)) throw new Error('customerKey must be valid'); this.customerKey = a; } function c(a) {
          if (C.isValidString(a)) { const b = a.split('://'); if (b[1] != 'cws.conviva.com' && (b[0] == 'https' || b[0] == 'http')) return a; } return `https://${e.customerKey}.${d.defaultProductionGatewayDNS}`;
        } var e = this; this._customerKey = null,
        C.defGet(this, 'customerKey', function () { return this._customerKey; }), C.defSet(this, 'customerKey', function (a) { C.isValidString(a) && (this._customerKey = a); }), this._heartbeatInterval = d.defaultProductionHeartbeatInterval,
        C.defGet(this, 'heartbeatInterval', function () { return this._heartbeatInterval; }), C.defSet(this, 'heartbeatInterval', function (a) {
          if (typeof a === 'number') {
            const b = C.NumberToUnsignedInt(a); b === a && (this._heartbeatInterval = b);
          }
        }), this._gatewayUrl = null, C.defGet(this, 'gatewayUrl', function () { return this._gatewayUrl; }), C.defSet(this, 'gatewayUrl', function (a) { C.isValidString(a) && (this._gatewayUrl = a); }), b.apply(this, arguments), this.equals = function (a) {
          return this.customerKey === a.customerKey && this.gatewayUrl === a.gatewayUrl && this.heartbeatInterval === a.heartbeatInterval;
        }, this.clone = function () {
          const a = new d(this.customerKey); return a.gatewayUrl = c(this.gatewayUrl),
          a.heartbeatInterval = this.heartbeatInterval, a;
        };
      }; d.defaultDevelopmentGatewayUrl = 'https://conviva.testonly.conviva.com', d.defaultProductionGatewayUrl = 'https://cws.conviva.com', d.defaultProductionGatewayDNS = 'cws.conviva.com',
      d.defaultDevelopmentGatewayDNS = 'conviva.testonly.conviva.com', d._httpsProtocol = 'https://', d._httpProtocol = 'http://', d.defaultDevelopmentHeartbeatInterval = 5, d.defaultProductionHeartbeatInterval = 20; var e = a.ContentMetadata = function () {
        function a() { return this._assetName; } function b(a) { typeof a === 'string' && (this._assetName = a); } function c() { return this._custom; } function d(a) {
          typeof a === 'object' && (this._custom = C.sanitizeStringToStringObject(a));
        } function f() { return this._defaultResource; } function g(a) { typeof a === 'string' && (this._defaultResource = a); } function h() { return this._viewerId; } function i(a) { typeof a === 'string' && (this._viewerId = a); } function j() {
          return this._applicationName;
        } function k(a) { typeof a === 'string' && (this._applicationName = a); } function l() { return this._streamUrl; } function m(a) { typeof a === 'string' && (this._streamUrl = a); } function n() {
          return this._streamType;
        } function o(a) { (a === e.StreamType.UNKNOWN || a === e.StreamType.VOD || a === e.StreamType.LIVE) && (this._streamType = a); } function p() { return this._duration; } function q(a) {
          this._duration = G.sanitizeSpecialIntegerValue(a);
        } function r() { return this._encodedFrameRate; } function s(a) { this._encodedFrameRate = G.sanitizeSpecialIntegerValue(a); } function t() {} this._assetName = null, C.defGet(this, 'assetName', a), C.defSet(this, 'assetName', b),
        this._custom = {}, C.defGet(this, 'custom', c), C.defSet(this, 'custom', d), this._defaultResource = null, C.defGet(this, 'defaultResource', f), C.defSet(this, 'defaultResource', g), this._viewerId = null, C.defGet(this, 'viewerId', h),
        C.defSet(this, 'viewerId', i), this._applicationName = null, C.defGet(this, 'applicationName', j), C.defSet(this, 'applicationName', k), this._streamUrl = null, C.defGet(this, 'streamUrl', l), C.defSet(this, 'streamUrl', m),
        this._streamType = e.StreamType.UNKNOWN, C.defGet(this, 'streamType', n), C.defSet(this, 'streamType', o), this._duration = -1, C.defGet(this, 'duration', p), C.defSet(this, 'duration', q), this._encodedFrameRate = -1, C.defGet(this, 'encodedFrameRate', r),
        C.defSet(this, 'encodedFrameRate', s), t.apply(this, arguments), this.clone = function () {
          const a = new e(); a.assetName = this.assetName, a.applicationName = this.applicationName, a.streamUrl = this.streamUrl, a.viewerId = this.viewerId,
          a.defaultResource = this.defaultResource, a.streamType = this.streamType, a.duration = this.duration, a.encodedFrameRate = this.encodedFrameRate; for (const b in this.custom)a.custom[b] = this.custom[b]; return a;
        };
      }; e.StreamType = { UNKNOWN: 'UNKNOWN', LIVE: 'LIVE', VOD: 'VOD' }; var f = (a.ErrorType = {
        ERROR_UNKNOWN: 'ERROR_UNKNOWN',
        ERROR_IO: 'ERROR_IO',
        ERROR_TIMEOUT: 'ERROR_TIMEOUT',
        ERROR_NULL_ASSET: 'ERROR_NULL_ASSET',
        ERROR_MISSING_PARAMETER: 'ERROR_MISSING_PARAMETER',
        ERROR_NO_AD_AVAILABLE: 'ERROR_NO_AD_AVAILABLE',
        ERROR_PARSE: 'ERROR_PARSE',
        ERROR_INVALID_VALUE: 'ERROR_INVALID_VALUE',
        ERROR_INVALID_SLOT: 'ERROR_INVALID_SLOT',
        ERROR_3P_COMPONENT: 'ERROR_3P_COMPONENT',
        ERROR_UNSUPPORTED_3P_FEATURE: 'ERROR_UNSUPPORTED_3P_FEATURE',
        ERROR_DEVICE_LIMIT: 'ERROR_DEVICE_LIMIT',
        ERROR_UNMATCHED_SLOT_SIZE: 'ERROR_UNMATCHED_SLOT_SIZE',
      }, a.Events = {
        AD_REQUESTED: 'Conviva.AdRequested',
        AD_RESPONSE: 'Conviva.AdResponse',
        AD_SLOT_STARTED: 'Conviva.SlotStarted',
        AD_ATTEMPTED: 'Conviva.AdAttempted',
        AD_SLOT_ENDED: 'Conviva.SlotEnded',
        AD_IMPRESSION_START: 'Conviva.AdImpression',
        AD_START: 'Conviva.AdStart',
        AD_FIRST_QUARTILE: 'Conviva.AdFirstQuartile',
        AD_MID_QUARTILE: 'Conviva.AdMidQuartile',
        AD_THIRD_QUARTILE: 'Conviva.AdThirdQuartile',
        AD_COMPLETE: 'Conviva.AdComplete',
        AD_END: 'Conviva.AdEnd',
        AD_IMPRESSION_END: 'Conviva.AdImpressionEnd',
        AD_SKIPPED: 'Conviva.AdSkipped',
        AD_ERROR: 'Conviva.AdError',
        AD_PROGRESS: 'Conviva.AdProgress',
        AD_CLOSE: 'Conviva.AdClose',
        CONTENT_PAUSED: 'Conviva.PauseContent',
        CONTENT_RESUMED: 'Conviva.ResumeContent',
        POD_START: 'Conviva.PodStart',
        POD_END: 'Conviva.PodEnd',
      }, a.SystemFactory = function (a, b) {
        function c(a, b) {
          if (!(a instanceof m)) throw new Error('systemInterface parameter should be an instance of SystemInterface.'); this._systemInterface = a, this._timeInterface = this._systemInterface._timeInterface,
          this._timerInterface = this._systemInterface._timerInterface, this._httpInterface = this._systemInterface._httpInterface, this._storageInterface = this._systemInterface._storageInterface, this._metadataInterface = this._systemInterface._metadataInterface,
          this._loggingInterface = this._systemInterface._loggingInterface, b instanceof g ? this._settings = b.clone() : this._settings = new g();
        } const d = this; c.apply(this, arguments), this.configure = function (a, b) {
          this._packageName = a,
          this._clientSettings = b;
        }, this.release = function () { this._systemInterface.release(), this._systemInterface = null, this._packageName = null, this._settings = null, this._logBuffer = null; }; const e = {
          build() {
            return new I(d._loggingInterface, d._timeInterface, d.getSettings(), d._logBuffer, d._packageName);
          },
        }; this.buildLogger = function () { return e.build(); }, this.buildSessionFactory = function (a, b, c) { return new w(a, b, c, this); }, this.buildPing = function () {
          return new E(this.buildLogger(), this.buildHttpClient(), this._clientSettings);
        }, this.buildCallbackWithTimeout = function () { return new x(this.buildTimer()); }, this.buildCallbackWithTimeoutNoPing = function () {
          const a = null; const b = new A(this.buildLogger(), a, this.getSettings()); const c = new M(this.buildLogger(), this._timerInterface, b);
          return new x(c);
        }, this.buildHttpClient = function () { return new H(this.buildLogger(), this._httpInterface, this.buildCallbackWithTimeoutNoPing(), this.getSettings()); }, this.buildExceptionCatcher = function () {
          return new A(this.buildLogger(), this.buildPing(), this.getSettings());
        }, this.buildTime = function () { return new L(this._timeInterface, this.buildLogger()); }, this.buildTimer = function () { return new M(this.buildLogger(), this._timerInterface, this.buildExceptionCatcher()); }, this.buildStorage = function () {
          return new J(this.buildLogger(), this._storageInterface, this.buildCallbackWithTimeout(), this.getSettings());
        }, this.buildConfig = function () {
          return new y(this.buildLogger(), this.buildStorage(), this.buildJsonInterface());
        }, this.buildSystemMetadata = function () { return new K(this.buildLogger(), this._metadataInterface, this.buildExceptionCatcher()); }, this.buildCwsProtocol = function () { return new r(); }, this.buildGatewayControl = function (a) {
          return new B(a, this.buildLogger(), this.buildHttpClient(), this.buildJsonInterface());
        }, this.buildCwsSession = function (a, b, c) {
          const d = this.buildGatewayControl(b); return new u(this.buildEventQueue(), a, b, this.buildLogger(), this.buildExceptionCatcher(), this.buildTimer(), d, this.buildCwsProtocol(), this.buildTime(), this.getLogBuffer(), c);
        }, this.buildSession = function (a, b, c, d, e, f) { const g = this.buildExceptionCatcher(); const h = this.buildLogger(); const i = b.clone(); const j = this.buildCwsSession(a, i, c); return new v(d, e, f, c, this.buildSystemMetadata(), j, g, h); }, this.buildEventQueue = function () {
          return new z();
        }, this.buildJsonInterface = function () { return new s(); }, this.getLogBuffer = function () { return this._logBuffer; }, this.getSettings = function () { return this._settings; }, this._logBuffer = new D();
      }); var g = a.SystemSettings = function () {
        function a() {} this._logLevel = g.defaultProductionLogLevel, C.defGet(this, 'logLevel', function () { return this._logLevel; }), C.defSet(this, 'logLevel', function (a) {
          if (typeof a === 'number') {
            const b = C.NumberToUnsignedInt(a);
            b === a && b >= g.LogLevel.DEBUG && b <= g.LogLevel.ERROR && (this._logLevel = b);
          }
        }), this._allowUncaughtExceptions = g.defaultProductionAllowUncaughtExceptions, C.defGet(this, 'allowUncaughtExceptions', function () {
          return this._allowUncaughtExceptions;
        }), C.defSet(this, 'allowUncaughtExceptions', function (a) { C.isBoolean(a) && (this._allowUncaughtExceptions = a); }), this._storageTimeout = g.defaultStorageTimeout, C.defGet(this, 'storageTimeout', function () {
          return this._storageTimeout;
        }), C.defSet(this, 'storageTimeout', function (a) { if (typeof a === 'number') { const b = C.NumberToUnsignedInt(a); b === a && (this._storageTimeout = b); } }), this._httpTimeout = g.defaultHttpTimeout, C.defGet(this, 'httpTimeout', function () {
          return this._httpTimeout;
        }), C.defSet(this, 'httpTimeout', function (a) { if (typeof a === 'number') { const b = C.NumberToUnsignedInt(a); b === a && (this._httpTimeout = b); } }), a.apply(this, arguments), this.equals = function (a) {
          return this.logLevel === a.logLevel && this.allowUncaughtExceptions === a.allowUncaughtExceptions && this.storageTimeout === a.storageTimeout && this.httpTimeout === a.httpTimeout;
        }, this.clone = function () {
          const a = new g(); return a.logLevel = this.logLevel,
          a.allowUncaughtExceptions = this.allowUncaughtExceptions, a.storageTimeout = this.storageTimeout, a.httpTimeout = this.httpTimeout, a;
        };
      }; g.LogLevel = {
        DEBUG: 0, INFO: 1, WARNING: 2, ERROR: 3, NONE: 4,
      }, g.defaultDevelopmentLogLevel = g.LogLevel.DEBUG,
      g.defaultProductionLogLevel = g.LogLevel.ERROR, g.defaultDevelopmentAllowUncaughtExceptions = !0, g.defaultProductionAllowUncaughtExceptions = !1, g.defaultStorageTimeout = 10, g.defaultHttpTimeout = 10; var h = a.PlayerStateManager = function (c) {
        function d(a) { e._systemFactory = a, e._logger = e._systemFactory.buildLogger(), e._logger.setModuleName('PlayerStateManager'), e._exceptionCatcher = e._systemFactory.buildExceptionCatcher(); } var e = this; e._monitorNotifier = null,
        e._bitrateKbps = -2, e._playerState = h.PlayerState.UNKNOWN, e._currentMetadata = {}, e._renderedFrameRate = -2, e._encodedFrameRate = -2, e._duration = -2, e._playerVersion = null, e._playerType = null, e._streamUrl = null, e._moduleName = null,
        e._moduleVersion = null, e._width = -1, e._height = -1, e._connectionType = null, e._linkEncryption = null, e._lastError = null, e._pendingErrors = [], e._released = !1, this.release = function () {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.release', () => {
            e._monitorNotifier != null && e._monitorNotifier.onRelease(), e.removeMonitoringNotifier(), e._systemFactory = null, e._logger = null, e._exceptionCatcher = null, e._released = !0;
          });
        }, this.setPlayheadTime = function (a) {},
        this.setBufferLength = function (a) {}, this.setRenderedFrameRate = function (a) {}, this.getEncodedFrameRate = function () { return e._encodedFrameRate; }, this.setEncodedFrameRate = function (a) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setEncodedFrameRate', () => {
            const b = G.sanitizeSpecialIntegerValue(a); b >= -1 && (e._encodedFrameRate = b, e._monitorNotifier != null && e._monitorNotifier.onEncodedFrameRateChange(e._encodedFrameRate));
          });
        }, this.getDuration = function () {
          return e._duration;
        }, this.setClientMeasureInterface = function (b) { C.validateInterface(b, new a.ClientMeasureInterface(), 'ClientMeasureInterface'), this._playerInterface = b; }, this.getPHT = function () {
          return this._playerInterface && typeof this._playerInterface.getPHT === 'function' ? this._playerInterface.getPHT() : h.DEFAULT_PHT;
        }, this.getBufferLength = function () { return this._playerInterface && typeof this._playerInterface.getBufferLength === 'function' ? this._playerInterface.getBufferLength() : h.DEFAULT_BUFFER_LENGTH; }, this.getSignalStrength = function () {
          return this._playerInterface && typeof this._playerInterface.getSignalStrength === 'function' ? this._playerInterface.getSignalStrength() : h.DEFAULT_SIGNAL_STRENGTH;
        }, this.getRenderedFrameRate = function () {
          return this._playerInterface && typeof this._playerInterface.getRenderedFrameRate === 'function' ? this._playerInterface.getRenderedFrameRate() : h.DEFAULT_RENDERED_FRAME_RATE;
        }, this.setDuration = function (a) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setDuration', () => {
            const b = G.sanitizeSpecialIntegerValue(a); b >= -1 && (e._duration = b, e._monitorNotifier != null && e._monitorNotifier.onContentLengthChange(e._duration));
          });
        }, this.getStreamUrl = function () { return e._streamUrl; }, this.setStreamUrl = function (a) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setStreamUrl', () => {
            a != null && (e._streamUrl = a, e._monitorNotifier != null && e._monitorNotifier.onStreamUrlChange(e._streamUrl));
          });
        }, this.getModuleName = function () { return e._moduleName; }, this.getModuleVersion = function () { return e._moduleVersion; }, this.setModuleNameAndVersion = function (a, b) { e._moduleName = a, e._moduleVersion = b; }, this.getPlayerType = function () {
          return e._playerType;
        }, this.setPlayerType = function (a) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setPlayerType', () => {
            e._playerType = a, e._monitorNotifier != null && e._monitorNotifier.onPlayerTypeChange(e._playerType);
          });
        }, this.getPlayerVersion = function () { return e._playerVersion; }, this.setPlayerVersion = function (a) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setPlayerVersion', () => {
            e._playerVersion = a,
            e._monitorNotifier != null && e._monitorNotifier.onPlayerVersionChange(e._playerVersion);
          });
        }, this.setMonitoringNotifier = function (a, b) {
          return e._released ? void 0 : e._monitorNotifier != null ? !1 : (e._monitorNotifier = a,
          e._logger.setSessionId(b), !0);
        }, this.removeMonitoringNotifier = function () { e._released || (e._monitorNotifier = null, e._logger.setSessionId(null)); }, this.pushCurrentState = function () {
          e.setPlayerState(e.getPlayerState()),
          e.setBitrateKbps(e.getBitrateKbps()), e.setDuration(e.getDuration()), e.setEncodedFrameRate(e.getEncodedFrameRate()), e.setStreamUrl(e.getStreamUrl()), e.setPlayerType(e.getPlayerType()), e.setPlayerVersion(e.getPlayerVersion());
          for (let a = 0; a < e._pendingErrors.length; a++) { const b = e._pendingErrors[a]; e.setError(b); }e._pendingErrors = [];
        }, this.getPlayerState = function () { return e._playerState; }, this.setPlayerState = function (a) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setPlayerState', () => {
            h.isValidPlayerState(a) ? (e._playerState = a, e._monitorNotifier != null && e._monitorNotifier.onPlayerStateChange(e._playerState)) : e._logger.error(`PlayerStateManager.SetPlayerState(): invalid state: ${a}`);
          });
        },
        this.getBitrateKbps = function () { return e._bitrateKbps; }, this.setBitrateKbps = function (a) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setBitrateKbps', () => {
            const b = G.sanitizeSpecialIntegerValue(a);
            b >= -1 && (e._bitrateKbps = b, e._monitorNotifier != null && e._monitorNotifier.onBitrateChange(e._bitrateKbps));
          });
        }, this.setPlayerSeekStart = function (a) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setPlayerSeekStart()', () => {
            e._monitorNotifier != null && e._monitorNotifier.onSeekEvent(h.SEEK_ACTIONS_TYPE.SEEK_START, a);
          });
        }, this.setPlayerSeekEnd = function () {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setPlayerSeekEnd()', () => {
            e._monitorNotifier != null && e._monitorNotifier.onSeekEvent(h.SEEK_ACTIONS_TYPE.SEEK_END, -1);
          });
        }, this.setUserSeekButtonUp = function () {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setUserSeekButtonUp()', () => {
            e._monitorNotifier != null && e._monitorNotifier.onSeekEvent(h.SEEK_ACTIONS_TYPE.BUTTON_UP, -1);
          });
        }, this.setUserSeekButtonDown = function () {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setUserSeekButtonDown()', () => {
            e._monitorNotifier != null && e._monitorNotifier.onSeekEvent(h.SEEK_ACTIONS_TYPE.BUTTON_DOWN, -1);
          });
        }, this.setVideoResolutionWidth = function (a) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setVideoResolutionWidth()', () => {
            const b = G.sanitizeSpecialIntegerValue(a); b > 0 && (e._width = b), e._monitorNotifier != null && e._monitorNotifier.onStreamResolutionWidthChange(e._width);
          });
        }, this.setVideoResolutionHeight = function (a) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setVideoResolutionHeight()', () => {
            const b = G.sanitizeSpecialIntegerValue(a); b > 0 && (e._height = b), e._monitorNotifier != null && e._monitorNotifier.onStreamResolutionHeightChange(e._height);
          });
        }, this.setConnectionType = function (a) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setConnectionType()', () => {
            e._connectionType = a, e._monitorNotifier != null && e._monitorNotifier.onConnectionTypeChange(e._connectionType);
          });
        }, this.setLinkEncryption = function (a) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setLinkEncryption()', () => {
            e._linkEncryption = a, e._monitorNotifier != null && e._monitorNotifier.onLinkEncryptionChange(e._linkEncryption);
          });
        }, this.setSignalStrength = function (a) {}, this.setError = function (a) {
          if (e._lastError = a, e._monitorNotifier != null) {
            const b = a.errorCode; const c = a.severity; e._monitorNotifier.onError(b, c);
          } else e._pendingErrors.push(a);
        }, this.sendError = function (a, c) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.sendError', () => {
            const d = new b.StreamerError(a, c); e.setError(d);
          });
        }, this.reset = function () {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.reset', () => {
            e._bitrateKbps = -2, e._playerState = h.PlayerState.UNKNOWN,
            e._currentMetadata = {}, e._renderedFrameRate = -1, e._encodedFrameRate = -1, e._duration = -1, e._playerVersion = null, e._playerType = null, e._streamUrl = null, e._lastError = null, e._pendingErrors = [];
          });
        }, this.setCDNServerIP = function (a) {
          e._released || e._exceptionCatcher.runProtected('PlayerStateManager.setCDNServerIP', () => { e._monitorNotifier != null && e._monitorNotifier.onCDNServerIPChange(a); });
        }, this.getError = function () {
          return e._lastError;
        }, d.apply(e, arguments), h.DEFAULT_SIGNAL_STRENGTH = 1e3, h.DEFAULT_RENDERED_FRAME_RATE = -1, h.DEFAULT_BUFFER_LENGTH = -1, h.DEFAULT_PHT = -1;
      }; h.PlayerState = {
        STOPPED: 'STOPPED',
        PLAYING: 'PLAYING',
        BUFFERING: 'BUFFERING',
        PAUSED: 'PAUSED',
        UNKNOWN: 'UNKNOWN',
        NOT_MONITORED: 'NOT_MONITORED',
      }, h.isValidPlayerState = function (a) {
        return a === h.PlayerState.STOPPED || a === h.PlayerState.PLAYING || a === h.PlayerState.BUFFERING || a === h.PlayerState.PAUSED || a === h.PlayerState.UNKNOWN || a === h.PlayerState.NOT_MONITORED ? !0 : !1;
      }, h.SEEK_ACTIONS_TYPE = {
        SEEK_START: 'pss', SEEK_END: 'pse', BUTTON_UP: 'bu', BUTTON_DOWN: 'bd',
      }; const i = (a.ClientMeasureInterface = function () {
        this.getPHT = function () {}, this.getBufferLength = function () {}, this.getSignalStrength = function () {},
        this.getRenderedFrameRate = function () {};
      }, a.HttpInterface = function () { this.makeRequest = function (a, b, c, d, e, f) {}, this.release = function () {}; }); const j = a.LoggingInterface = function () {
        this.consoleLog = function (a, b) {}, this.release = function () {};
      }; const k = a.MetadataInterface = function () {
        this.getBrowserName = function () {}, this.getBrowserVersion = function () {}, this.getDeviceBrand = function () {}, this.getDeviceManufacturer = function () {}, this.getDeviceModel = function () {},
        this.getDeviceType = function () {}, this.getDeviceVersion = function () {}, this.getFrameworkName = function () {}, this.getFrameworkVersion = function () {}, this.getOperatingSystemName = function () {}, this.getOperatingSystemVersion = function () {},
        this.getDeviceCategory = function () {}, this.release = function () {};
      }; const l = a.StorageInterface = function () { this.saveData = function (a, b, c, d) {}, this.loadData = function (a, b, c) {}, this.release = function () {}; }; var m = a.SystemInterface = function (a, b, c, d, e, f) {
        function g(a, b, c, d, e, f) {
          C.validateInterface(a, new n(), 'TimeInterface'), C.validateInterface(b, new o(), 'TimerInterface'), C.validateInterface(c, new i(), 'HttpInterface'), C.validateInterface(d, new l(), 'StorageInterface'),
          C.validateInterface(e, new k(), 'MetadataInterface'), C.validateInterface(f, new j(), 'LoggingInterface'), this._timeInterface = a, this._timerInterface = b, this._httpInterface = c, this._storageInterface = d, this._metadataInterface = e,
          this._loggingInterface = f;
        }g.apply(this, arguments), this.release = function () {
          this._timeInterface.release(), this._timeInterface = null, this._timerInterface.release(), this._timerInterface = null, this._httpInterface.release(),
          this._httpInterface = null, this._storageInterface.release(), this._storageInterface = null, this._metadataInterface.release(), this._metadataInterface = null, this._loggingInterface.release(), this._loggingInterface = null;
        };
      }; var n = a.TimeInterface = function () { this.getEpochTimeMs = function () {}, this.release = function () {}; }; var o = a.TimerInterface = function () { this.createTimer = function (a, b, c) {}, this.release = function () {}; }; const p = b.JSON2 = {}; !(function () {
        function a(a) { return a < 10 ? `0${a}` : a; } function b(a) {
          return d.lastIndex = 0, d.test(a) ? `"${a.replace(d, (a) => { const b = g[a]; return typeof b==='string' ? b : `\\u${  (`0000${a.charCodeAt(0).toString(16)}`).slice(-4)}`; })}"` : `"${a}"`;
        } function c(a, d) {
          let g; let i; let j; let k; let l; const m = e; let n = d[a]; switch (n && typeof n === 'object' && typeof n.toJSON === 'function' && (n = n.toJSON(a)), typeof h === 'function' && (n = h.call(d, a, n)), typeof n) {
            case 'string': return b(n); case 'number':
              return isFinite(n) ? String(n) : 'null'; case 'boolean': case 'null': return String(n); case 'object': if (!n) return 'null'; if (e += f, l = [], Object.prototype.toString.apply(n) === Object.prototype.toString.apply([])) {
              for (k = n.length,
              g = 0; k > g; g += 1)l[g] = c(g, n) || 'null'; return j = l.length === 0 ? '[]' : e ? `[\n${e}${l.join(`,\n${e}`)}\n${m}]` : `[${l.join(',')}]`, e = m, j;
            } if (h && typeof h === 'object') {
                for (k = h.length, g = 0; k > g; g += 1) {typeof h[g] == 'string' && (i = h[g],
                j = c(i, n), j && l.push(b(i) + (e ? ': ' : ':') + j));}
              } else for (i in n)Object.prototype.hasOwnProperty.call(n, i) && (j = c(i, n), j && l.push(b(i) + (e ? ': ' : ':') + j)); return j = l.length === 0 ? '{}' : e ? `{\n${e}${l.join(`,\n${e}`)}\n${m}}` : `{${l.join(',')}}`,
              e = m, j;
          }
        } typeof Date.prototype.toJSON !== 'function' && (Date.prototype.toJSON = function () {
          return isFinite(this.valueOf()) ? `${this.getUTCFullYear()}-${a(this.getUTCMonth() + 1)}-${a(this.getUTCDate())}T${a(this.getUTCHours())}:${a(this.getUTCMinutes())}:${a(this.getUTCSeconds())}Z` : null;
        }, String.prototype.toJSON = Number.prototype.toJSON = Boolean.prototype.toJSON = function () { return this.valueOf(); }); let d; let e; let f; let g; let h; typeof p.stringify !== 'function' && (d = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
        g = {
          '\b': '\\b', '	': '\\t', '\n': '\\n', '\f': '\\f', '\r': '\\r', '"': '\\"', '\\': '\\\\',
        }, p.stringify = function (a, b, d) {
          let g; if (e = '', f = '', typeof d === 'number') for (g = 0; d > g; g += 1)f += ' '; else typeof d === 'string' && (f = d); if (h = b,
          b && typeof b !== 'function' && (typeof b !== 'object' || typeof b.length !== 'number')) throw new Error('JSON2.stringify'); return c('', { '': a });
        });
      }()); const q = b.JsonParse = (function () {
        let a; let b; let c; let d; const e = {
          '"': '"',
          '\\': '\\',
          '/': '/',
          b: '\b',
          f: '\f',
          n: '\n',
          r: '\r',
          t: '	',
        }; const f = function (b) {
          throw {
            name: 'SyntaxError', message: b, at: a, text: c,
          };
        }; const g = function (d) { return d && d !== b && f(`Expected '${d}' instead of '${b}'`), b = c.charAt(a), a += 1, b; }; const h = function () {
          let a; let c = ''; for (b === '-' && (c = '-', g('-')); b >= '0' && b <= '9';)c += b, g(); if (b === '.') for (c += '.'; g() && b >= '0' && b <= '9';)c += b; if (b === 'e' || b === 'E') for (c += b, g(), (b === '-' || b === '+') && (c += b, g()); b >= '0' && b <= '9';)c += b, g(); return a = +c,
          isNaN(a) ? void f('Bad number') : a;
        }; const i = function () {
          let a; let c; let d; let h = ''; if (b === '"') {
            for (;g();) {
              if (b === '"') return g(), h; if (b === '\\') { if (g(), 'u' === b) {
 for (d = 0, c = 0; c < 4 && (a = parseInt(g(), 16), isFinite(a)); c += 1)d = 16 * d + a; h += String.fromCharCode(d);
              } else { if (typeof e[b]!='string') break; h += e[b]; }
} else h += b;
            } 
}f('Bad string');
        }; const j = function () { for (;b && b <= ' ';)g(); }; const k = function () {
          switch (b) {
            case 't': return g('t'), g('r'), g('u'), g('e'), !0; case 'f': return g('f'), g('a'),
            g('l'), g('s'), g('e'), !1; case 'n': return g('n'), g('u'), g('l'), g('l'), null;
          }f(`Unexpected '${b}'`);
        }; const l = function () {
          const a = []; if (b === '[') {
            if (g('['), j(), b === ']') return g(']'), a; for (;b;) {
              if (a.push(d()), j(), b === ']') {
                return g(']'),
                a; 
} g(','), j();
            }
          }f('Bad array');
        }; const m = function () {
          let a; const c = {}; if (b === '{') {
            if (g('{'), j(), b === '}') return g('}'), c; for (;b;) {
              if (a = i(), j(), g(':'), Object.hasOwnProperty.call(c, a) && f(`Duplicate key "${a}"`), c[a] = d(),
              j(), b === '}') return g('}'), c; g(','), j();
            }
          }f('Bad object');
        }; return d = function () {
          switch (j(), b) {
            case '{': return m(); case '[': return l(); case '"': return i(); case '-': return h(); default: return b >= '0' && b <= '9' ? h() : k();
          }
        }, function (e, g) {
          let h; return c = e, a = 0, b = ' ', h = d(), j(), b && f('Syntax error'), typeof g === 'function' ? (function i(a, b) {
            let c; let d; const e = a[b]; if (e && typeof e === 'object') {
              for (c in e) {
                Object.hasOwnProperty.call(e, c) && (d = i(e, c),
                void 0 !== d ? e[c] = d : delete e[c]);
              }
            } return g.call(a, b, e);
          }({ '': h }, '')) : h;
        };
      }()); var r = b.CwsProtocol = function () {
        this.generateEvent = function (a, b, c, d) { return d.seq = a, d.st = b, d.t = c, d; }, this.generateSessionEndEvent = function (a, b) {
          const c = r.EVENT_TYPE_SESSION_END; const d = {}; return this.generateEvent(a, b, c, d);
        };
      }; r.version = '2.4', r.gatewayPath = '/0/wsg', r.DEFAULT_CLIENT_ID = '0', r.DEFAULT_MAX_HEARTBEAT_INFOS = 0, r.DEFAULT_PLAYHEAD_TIME = -1, r.DEFAULT_BUFFER_LENGTH = -1,
      r.DEFAULT_SIGNAL_STRENGTH = 1e3, r.SDK_METADATA_SCHEMA = 'sdk.js.1', r.EVENT_TYPE_STATE_CHANGE = 'CwsStateChangeEvent', r.EVENT_TYPE_ERROR = 'CwsErrorEvent', r.EVENT_TYPE_SESSION_END = 'CwsSessionEndEvent', r.EVENT_TYPE_CUSTOM = 'CwsCustomEvent',
      r.EVENT_TYPE_SEEK = 'CwsSeekEvent', r.EVENT_TYPE_DATA_SAMPLE = 'CwsDataSamplesEvent', r.RESPONSE_STATUS_NO_ERRORS = 'ok', r.RESPONSE_KEY_STATUS = 'err', r.RESPONSE_KEY_CLIENT_ID = 'clid', r.RESPONSE_KEY_CONFIG = 'cfg',
      r.RESPONSE_KEY_EVENTS = 'evs', r.RESPONSE_CONFIG_KEY_MAX_HEARTBEAT_INFOS = 'maxhbinfos', r.RESPONSE_CONFIG_KEY_SEND_LOGS = 'slg', r.RESPONSE_CONFIG_KEY_HEARTBEAT_INTERVAL = 'hbi', r.RESPONSE_CONFIG_KEY_GATEWAY_URL = 'gw',
      r.MESSAGE_TYPE_SESSION_HEARTBEAT = 'CwsSessionHb', r.REQUEST_KEY_MESSAGE_TYPE = 't', r.REQUEST_KEY_SESSION_TIME = 'st', r.REQUEST_KEY_SESSION_START_TIME = 'sst', r.REQUEST_KEY_IS_LIVE = 'lv', r.REQUEST_KEY_SEQUENCE_NUMBER = 'seq',
      r.REQUEST_KEY_CUSTOMER_KEY = 'cid', r.REQUEST_KEY_CLIENT_ID = 'clid', r.REQUEST_KEY_CLIENT_VERSION = 'clv', r.REQUEST_KEY_PROTOCOL_VERSION = 'pver', r.REQUEST_KEY_INSTANCE_ID = 'iid', r.REQUEST_KEY_SESSION_ID = 'sid', r.REQUEST_KEY_VIEWER_ID = 'vid',
      r.REQUEST_KEY_ASSET_NAME = 'an', r.REQUEST_KEY_PLAYER_NAME = 'pn', r.REQUEST_KEY_TAGS = 'tags', r.REQUEST_KEY_SESSION_FLAGS = 'sf', r.REQUEST_KEY_EVENTS = 'evs', r.REQUEST_KEY_LOGS = 'lg', r.REQUEST_KEY_HEARTBEAT_INFOS = 'hbinfos',
      r.REQUEST_KEY_IS_SDK_CLIENT = 'sdk', r.REQUEST_KEY_PAUSE_JOIN = 'pj', r.REQUEST_KEY_PLAYER_STATE = 'ps', r.REQUEST_KEY_BITRATE = 'br', r.REQUEST_KEY_CONTENT_LENGTH = 'cl', r.REQUEST_KEY_ENCODED_FRAME_RATE = 'efps', r.REQUEST_KEY_AVERAGE_FRAME_RATE = 'afps',
      r.REQUEST_KEY_RFPS_TOTAL = 'rfpstot', r.REQUEST_KEY_RFPS_COUNT = 'rfpscnt', r.REQUEST_KEY_RESOURCE = 'rs', r.REQUEST_KEY_PLAYHEAD_TIME = 'pht', r.REQUEST_KEY_BUFFER_LENGTH = 'bl', r.REQUEST_KEY_STREAM_URL = 'url', r.REQUEST_KEY_CAPABILITIES = 'caps',
      r.REQUEST_KEY_PLATFORM_METADATA = 'pm', r.REQUEST_KEY_VIDEO_WIDTH = 'w', r.REQUEST_KEY_VIDEO_HEIGHT = 'h', r.REQUEST_KEY_CONNECTION_TYPE = 'ct', r.REQUEST_KEY_LINK_ENCRYPTION = 'le', r.REQUEST_KEY_SIGNAL_STRENGTH = 'ss',
      r.REQUEST_KEY_STREAM_METADATA_CHANGE = 'strmetadata', r.REQUEST_PLATFORM_METADATA_KEY_SCHEMA = 'sch', r.REQUEST_PLATFORM_METADATA_KEY_BROWSER_NAME = 'br', r.REQUEST_PLATFORM_METADATA_KEY_BROWSER_VERSION = 'brv', r.REQUEST_PLATFORM_METADATA_KEY_DEVICE_BRAND = 'dvb',
      r.REQUEST_PLATFORM_METADATA_KEY_DEVICE_MANUFACTURER = 'dvma', r.REQUEST_PLATFORM_METADATA_KEY_DEVICE_MODEL = 'dvm', r.REQUEST_PLATFORM_METADATA_KEY_DEVICE_TYPE = 'dvt', r.REQUEST_PLATFORM_METADATA_KEY_DEVICE_VERSION = 'dvv',
      r.REQUEST_PLATFORM_METADATA_KEY_FRAMEWORK_NAME = 'fw', r.REQUEST_PLATFORM_METADATA_KEY_FRAMEWORK_VERSION = 'fwv', r.REQUEST_PLATFORM_METADATA_KEY_CLIENT_CONFIGURATION = 'cc', r.REQUEST_PLATFORM_METADATA_KEY_MODULE_NAME = 'mn',
      r.REQUEST_PLATFORM_METADATA_KEY_MODULE_VERSION = 'mv', r.REQUEST_PLATFORM_METADATA_KEY_OPERATING_SYSTEM_NAME = 'os', r.REQUEST_PLATFORM_METADATA_KEY_OPERATING_SYSTEM_VERSION = 'osv', r.REQUEST_PLATFORM_METADATA_KEY_DEVICE_CATEGORY = 'cat',
      r.REQUEST_KEY_PROTOCOL_CAPP = 'caps', r.REQUEST_EVENT_KEY_TYPE = 't', r.REQUEST_EVENT_KEY_SEQUENCE_NUMBER = 'seq', r.REQUEST_EVENT_KEY_SESSION_TIME = 'st', r.REQUEST_EVENT_KEY_SEEK_ACT_TYPE = 'act', r.REQUEST_EVENT_KEY_SEEK_TO_POS = 'skto',
      r.REQUEST_ERROR_EVENT_KEY_MESSAGE = 'err', r.REQUEST_ERROR_EVENT_KEY_FATAL = 'ft', r.REQUEST_STATE_CHANGE_EVENT_KEY_NEW = 'new', r.REQUEST_STATE_CHANGE_EVENT_KEY_OLD = 'old', r.REQUEST_CUSTOM_EVENT_KEY_NAME = 'name',
      r.REQUEST_CUSTOM_EVENT_KEY_ATTRIBUTES = 'attr', r.REQUEST_HEARTBEAT_INFO_KEY_SEQUENCE_NUMBER = 'seq', r.REQUEST_HEARTBEAT_INFO_KEY_STATUS = 'err', r.REQUEST_HEARTBEAT_INFO_KEY_ROUNDTRIP_TIME = 'rtt', r.REQUEST_KEY_IS_AD_SESSION = 'ad',
      r.REQUEST_CDN_SERVER_IP = 'csi', r.convertPlayerState = function (a) {
        switch (a) {
          case h.PlayerState.STOPPED: return r.eStopped; case h.PlayerState.PLAYING: return r.ePlaying; case h.PlayerState.BUFFERING: return r.eBuffering;
          case h.PlayerState.PAUSED: return r.ePaused; case h.PlayerState.NOT_MONITORED: return r.eNotMonitored; default: return r.eUnknown;
        }
      }, r.eStopped = 1, r.ePlaying = 3, r.eBuffering = 6, r.ePaused = 12, r.eNotMonitored = 98, r.eUnknown = 100,
      r.SessionFlags = {
        GLOBAL: 0, VIDEO: 1, QUALITY_METRICS: 2, BITRATE_METRICS: 4,
      }, r.Capabilities = { INSIGHTS: 0 }; var s = (b.JSONInterface = function () { this.encode = function (a) {}, this.decode = function (a) {}; }, b.LibJSONInterface = function () {
        this.encode = function (a) { let b = null; try { b = p.stringify(a); } catch (c) {} return b; }, this.decode = function (a) { let b = null; try { b = q(a); } catch (c) {} return C.isObject(b) || (b = null), b; };
      }); const t = b.CwsHeartbeat = function () {
        function a() {}
        this._data = {}, a.apply(this, arguments), this.get = function () { return this._data; }, this.setField = function (a, b) { this._data[a] = b; }, this.setInnerField = function (a, b, c) {
          this._data[a] || (this._data[a] = {}), this._data[a][b] = c;
        }, this.setMessageType = function (a) { this.setField(r.REQUEST_KEY_MESSAGE_TYPE, a); }, this.setSessionTime = function (a) { this.setField(r.REQUEST_KEY_SESSION_TIME, a); }, this.setSessionStartTime = function (a) {
          this.setField(r.REQUEST_KEY_SESSION_START_TIME, a);
        }, this.setIsLive = function (a) { this.setField(r.REQUEST_KEY_IS_LIVE, a); }, this.setSequenceNumber = function (a) { this.setField(r.REQUEST_KEY_SEQUENCE_NUMBER, a); }, this.setCustomerKey = function (a) {
          this.setField(r.REQUEST_KEY_CUSTOMER_KEY, a);
        }, this.setClientId = function (a) { this.setField(r.REQUEST_KEY_CLIENT_ID, a); }, this.setClientVersion = function (a) { this.setField(r.REQUEST_KEY_CLIENT_VERSION, a); }, this.setProtocolVersion = function (a) {
          this.setField(r.REQUEST_KEY_PROTOCOL_VERSION, a);
        }, this.setCapfield = function (a) { this.setField(r.REQUEST_KEY_PROTOCOL_CAPP, a); }, this.setSessionId = function (a) { this.setField(r.REQUEST_KEY_SESSION_ID, a); }, this.setInstanceId = function (a) {
          this.setField(r.REQUEST_KEY_INSTANCE_ID, a);
        }, this.setViewerId = function (a) { this.setField(r.REQUEST_KEY_VIEWER_ID, a); }, this.setAssetName = function (a) { this.setField(r.REQUEST_KEY_ASSET_NAME, a); }, this.setPlayerName = function (a) {
          this.setField(r.REQUEST_KEY_PLAYER_NAME, a);
        }, this.setTags = function (a) { this.setField(r.REQUEST_KEY_TAGS, a); }, this.setSessionFlags = function (a) { this.setField(r.REQUEST_KEY_SESSION_FLAGS, a); }, this.setCapabilities = function (a) {
          this.setField(r.REQUEST_KEY_CAPABILITIES, a);
        }, this.setEvents = function (a) { this.setField(r.REQUEST_KEY_EVENTS, a); }, this.setIsSdkClient = function (a) { this.setField(r.REQUEST_KEY_IS_SDK_CLIENT, a); }, this.setLogs = function (a) {
          this.setField(r.REQUEST_KEY_LOGS, a);
        }, this.setHeartbeatInfos = function (a) { this.setField(r.REQUEST_KEY_HEARTBEAT_INFOS, a); }, this.setPlayerState = function (a) { this.setField(r.REQUEST_KEY_PLAYER_STATE, a); }, this.setPauseJoin = function (a) {
          this.setField(r.REQUEST_KEY_PAUSE_JOIN, a);
        }, this.setContentLength = function (a) { this.setField(r.REQUEST_KEY_CONTENT_LENGTH, a); }, this.setBitrate = function (a) { this.setField(r.REQUEST_KEY_BITRATE, a); }, this.setResource = function (a) {
          this.setField(r.REQUEST_KEY_RESOURCE, a);
        }, this.setEncodedFrameRate = function (a) { this.setField(r.REQUEST_KEY_ENCODED_FRAME_RATE, a); }, this.setAverageFrameRate = function (a) { this.setField(r.REQUEST_KEY_AVERAGE_FRAME_RATE, a); }, this.setRfpsObservationCount = function (a) {
          this.setField(r.REQUEST_KEY_RFPS_COUNT, a);
        }, this.setRfpsTotal = function (a) { this.setField(r.REQUEST_KEY_RFPS_TOTAL, a); }, this.setPlatformMetadataSchema = function (a) {
          this.setInnerField(r.REQUEST_KEY_PLATFORM_METADATA, r.REQUEST_PLATFORM_METADATA_KEY_SCHEMA, a);
        }, this.setBrowserName = function (a) { this.setInnerField(r.REQUEST_KEY_PLATFORM_METADATA, r.REQUEST_PLATFORM_METADATA_KEY_BROWSER_NAME, a); }, this.setBrowserVersion = function (a) {
          this.setInnerField(r.REQUEST_KEY_PLATFORM_METADATA, r.REQUEST_PLATFORM_METADATA_KEY_BROWSER_VERSION, a);
        }, this.setDeviceBrand = function (a) { this.setInnerField(r.REQUEST_KEY_PLATFORM_METADATA, r.REQUEST_PLATFORM_METADATA_KEY_DEVICE_BRAND, a); }, this.setDeviceManufacturer = function (a) {
          this.setInnerField(r.REQUEST_KEY_PLATFORM_METADATA, r.REQUEST_PLATFORM_METADATA_KEY_DEVICE_MANUFACTURER, a);
        }, this.setDeviceModel = function (a) { this.setInnerField(r.REQUEST_KEY_PLATFORM_METADATA, r.REQUEST_PLATFORM_METADATA_KEY_DEVICE_MODEL, a); }, this.setDeviceType = function (a) {
          this.setInnerField(r.REQUEST_KEY_PLATFORM_METADATA, r.REQUEST_PLATFORM_METADATA_KEY_DEVICE_TYPE, a);
        }, this.setDeviceVersion = function (a) { this.setInnerField(r.REQUEST_KEY_PLATFORM_METADATA, r.REQUEST_PLATFORM_METADATA_KEY_DEVICE_VERSION, a); }, this.setFrameworkName = function (a) {
          this.setInnerField(r.REQUEST_KEY_PLATFORM_METADATA, r.REQUEST_PLATFORM_METADATA_KEY_FRAMEWORK_NAME, a),
          this.setField(r.REQUEST_PLATFORM_METADATA_KEY_FRAMEWORK_NAME, a);
        }, this.setFrameworkVersion = function (a) {
          this.setInnerField(r.REQUEST_KEY_PLATFORM_METADATA, r.REQUEST_PLATFORM_METADATA_KEY_FRAMEWORK_VERSION, a),
          this.setField(r.REQUEST_PLATFORM_METADATA_KEY_FRAMEWORK_VERSION, a);
        }, this.setOperatingSystemName = function (a) {
          this.setInnerField(r.REQUEST_KEY_PLATFORM_METADATA, r.REQUEST_PLATFORM_METADATA_KEY_OPERATING_SYSTEM_NAME, a.toUpperCase());
        }, this.setOperatingSystemVersion = function (a) { this.setInnerField(r.REQUEST_KEY_PLATFORM_METADATA, r.REQUEST_PLATFORM_METADATA_KEY_OPERATING_SYSTEM_VERSION, a); }, this.setDeviceCategory = function (a) {
          this.setInnerField(r.REQUEST_KEY_PLATFORM_METADATA, r.REQUEST_PLATFORM_METADATA_KEY_DEVICE_CATEGORY, a);
        }, this.setPlayheadTime = function (a) { this.setField(r.REQUEST_KEY_PLAYHEAD_TIME, a); }, this.setBufferLength = function (a) { this.setField(r.REQUEST_KEY_BUFFER_LENGTH, a); }, this.setStreamUrl = function (a) {
          this.setField(r.REQUEST_KEY_STREAM_URL, a);
        }, this.setStreamWidth = function (a) { this.setField(r.REQUEST_KEY_VIDEO_WIDTH, a); }, this.setStreamHeight = function (a) { this.setField(r.REQUEST_KEY_VIDEO_HEIGHT, a); }, this.setConnectionType = function (a) {
          this.setField(r.REQUEST_KEY_CONNECTION_TYPE, a);
        }, this.setLinkEncryption = function (a) { this.setField(r.REQUEST_KEY_LINK_ENCRYPTION, a); }, this.setIsAdSession = function () { this.setField(r.REQUEST_KEY_IS_AD_SESSION, !0); }, this.setSignalStrength = function (a) {
          this.setField(r.REQUEST_KEY_SIGNAL_STRENGTH, a);
        }, this.setModuleName = function (a) { this.setInnerField(r.REQUEST_PLATFORM_METADATA_KEY_CLIENT_CONFIGURATION, r.REQUEST_PLATFORM_METADATA_KEY_MODULE_NAME, a); }, this.setModuleVersion = function (a) {
          this.setInnerField(r.REQUEST_PLATFORM_METADATA_KEY_CLIENT_CONFIGURATION, r.REQUEST_PLATFORM_METADATA_KEY_MODULE_VERSION, a);
        }, this.setCDNServerIP = function (a) { this.setField(r.REQUEST_CDN_SERVER_IP, a); };
      }; var u = b.CwsSession = function (a, b, d, e, f, g, i, j, k, l, m) {
        function n(a, b, c, d, e, f, g, h, i, j, k) {
          this._eventQueue = a, this._client = b, this._clientSettings = c,
          this._logger = d, this._logger.setModuleName('CwsSession'), this._exceptionCatcher = e, this._timer = f, this._gatewayControl = g, this._cwsProtocol = h, this._time = i, this._logBuffer = j, this._clientConfig = k;
        } const o = this; o._heartbeatTimerCancel = null,
        o._startTimeMs = 0, o._heartbeatSequenceNumber = 0, o._sessionFlags = r.SessionFlags.GLOBAL, o._capabilities = r.Capabilities.INSIGHTS, o._heartbeatInfos = [], o._lastRequestSentMs = -1, o._bitrateKbps = -2, o._resource = null,
        o._playerState = h.PlayerState.NOT_MONITORED, o._encodedFrameRate = -1, o._contentLengthSec = -1, o._streamUrl = null, o._width = -1, o._height = -1, o._playerStateManager = null, o._assetName = null, o._viewerId = null, o._playerName = null,
        o._isLive = null, o._tags = {}, o._browserName = null, o._browserVersion = null, o._deviceBrand = null, o._deviceManufacturer = null, o._deviceModel = null, o._deviceType = null, o._deviceVersion = null, o._frameworkName = null, o._frameworkVersion = null,
        o._operatingSystemName = null, o._operatingSystemVersion = null, o._deviceCategory = null, o._moduleName = null, o._moduleVersion = null, o._connectionType = null, o._linkEncryption = null, o._cdnServerIP = null, o._pauseJoin = !1,
        o._hasJoined = !1, o._cleanedUp = !1, o._willEndSoon = !1, o._rfpsTimerCancel = null, o._rfpsTimerInterval = 1e3, o._playingFpsObservationCount = 0, o._playingFpsTotal = 0, n.apply(this, arguments), this.cleanup = function () {
          this._clientConfig = null,
          this._logBuffer = null, this._systemSettings = null, this._time = null, this._cwsProtocol = null, this._gatewayControl = null, this._timer = null, this._exceptionCatcher = null, this._logger = null, this._clientSettings = null,
          this._client = null, this._eventQueue.flush(), this._eventQueue = null, this._cleanedUp = !0;
        }, this.start = function (a) {
          this._session = a, this._global = this._session._global, this._logger.setSessionId(this._session._id),
          this._startTimeMs = this._time.current(), (this.isVideoSession() || this.isAdSession()) && (this._sessionFlags += u.DEFAULT_VIDEO_SESSION_FLAGS);
        }, this.willEndSoon = function () { this._willEndSoon = !0; }, this.initialize = function () {
          this._logger.debug('initialize()');
        }, this.end = function () {
          (this.isVideoSession() || this.isAdSession()) && (this._logger.debug(`end(): schedule the last hb before session cleanup${o.sessionTypeTag()}`), this.enqueueSessionEndEvent()),
          this.cancelHeartbeatTimer(), this.sendHeartbeat();
        }, this.startSendingHeartbeats = function () { this._willEndSoon || (this.sendHeartbeat(), this.resetHeartbeatTimer()); }, this.setBrowserName = function (a) {
          o._logger.debug(`setBrowserName(): ${a}`);
          const b = o._browserName; b !== a && a != null && (o._logger.debug(`Change browserName from ${b} to ${a}`), o._browserName = a);
        }, this.setBrowserVersion = function (a) {
          o._logger.debug(`setBrowserVersion(): ${a}`); const b = o._browserVersion;
          b !== a && a != null && (o._logger.debug(`Change browserVersion from ${b} to ${a}`), o._browserVersion = a);
        }, this.setDeviceBrand = function (a) {
          o._logger.debug(`setDeviceBrand(): ${a}`); const b = o._deviceBrand; b !== a && a != null && (o._logger.debug(`Change deviceBrand from ${b} to ${a}`),
          o._deviceBrand = a);
        }, this.setDeviceManufacturer = function (a) {
          o._logger.debug(`setDeviceManufacturer(): ${a}`); const b = o._deviceManufacturer; b !== a && a != null && (o._logger.debug(`Change deviceManufacturer from ${b} to ${a}`),
          o._deviceManufacturer = a);
        }, this.setDeviceModel = function (a) {
          o._logger.debug(`setDeviceModel(): ${a}`); const b = o._deviceModel; b !== a && a != null && (o._logger.debug(`Change deviceModel from ${b} to ${a}`), o._deviceModel = a);
        }, this.setDeviceType = function (a) { o._logger.debug(`setDeviceBrand(): ${a}`); const b = o._deviceType; b !== a && a != null && (o._logger.debug(`Change deviceType from ${b} to ${a}`), o._deviceType = a); }, this.setDeviceVersion = function (a) {
          o._logger.debug(`setDeviceVersion(): ${a}`); const b = o._deviceVersion; b !== a && a != null && (o._logger.debug(`Change deviceVersion from ${b} to ${a}`), o._deviceVersion = a);
        }, this.setOperatingSystemName = function (a) {
          o._logger.debug(`setOperatingSystemName(): ${a}`);
          const b = o._operatingSystemName; b !== a && a != null && (o._logger.debug(`Change operatingSystemName from ${b} to ${a}`), o._operatingSystemName = a);
        }, this.setOperatingSystemVersion = function (a) {
          o._logger.debug(`setOperatingSystemVersion(): ${a}`);
          const b = o._operatingSystemVersion; b !== a && a != null && (o._logger.debug(`Change operatingSystemVersion from ${b} to ${a}`), o._operatingSystemVersion = a);
        }, this.setDeviceCategory = function (a) {
          o._logger.debug(`setDeviceCategory(): ${a}`);
          const b = o._deviceCategory; b !== a && a != null && (o._logger.debug(`Change operatingSystemVersion from ${b} to ${a}`), o._deviceCategory = a);
        }, this.getFrameworkName = function () { return o._frameworkName; }, this.setFrameworkName = function (a) {
          o._logger.debug(`setFrameworkName(): ${a}`); const b = o._frameworkName; b !== a && a != null && (o._logger.debug(`Change frameworkName from ${b} to ${a}`), o._frameworkName = a);
        }, this.getFrameworkVersion = function () {
          return o._frameworkVersion;
        }, this.setFrameworkVersion = function (a) {
          o._logger.debug(`setFrameworkVersion(): ${a}`); const b = o._frameworkVersion; b !== a && a != null && (o._logger.debug(`Change frameworkVersion from ${b} to ${a}`), o._frameworkVersion = a);
        }, this.setStreamUrl = function (a) { o._logger.debug(`setStreamUrl(): ${a}`); const b = o._streamUrl; b !== a && a != null && (o._logger.debug(`Change stream url from ${b} to ${a}`), o._streamUrl = a); }, this.setBitrateKbps = function (a) {
          o._logger.debug(`setBitrateKbps(): ${a}`); const b = o._bitrateKbps; b !== a && a > 0 && (o._logger.info(`Change bitrate from ${b} to ${a}`), o.enqueueBitrateChangeEvent(b, a), o._bitrateKbps = a);
        }, this.setResource = function (a) {
          o._logger.debug(`setResource(): ${a}`); const b = o._resource; b !== a && a != null && (o._logger.info(`Change resource from ${b} to ${a}`), o._resource = a);
        }, this.setAssetName = function (a) {
          o._logger.debug(`setAssetName(): ${a}`),
          o._assetName !== a && a != null && (o._logger.info(`Change assetName from ${o._assetName} to ${a}`), o._assetName = a);
        }, this.setIsLive = function (a) {
          o._logger.debug(`setIsLive(): ${a}`); const b = o._isLive; b !== a && a != null && (o._logger.info(`Change isLive from ${b} to ${a}`),
          o._isLive = a);
        }, this.setViewerId = function (a) { o._logger.debug(`setViewerId(): ${a}`); const b = o._viewerId; b !== a && a != null && (o._logger.info(`Change viewerId from ${b} to ${a}`), o._viewerId = a); }, this.setPlayerName = function (a) {
          o._logger.debug(`setPlayerName(): ${a}`); const b = o._playerName; b !== a && a != null && (o._logger.info(`Change playerName from ${b} to ${a}`), o._playerName = a);
        }, this.setTags = function (a) {
          o._logger.debug('setTags()'); const b = o._tags;
          C.ObjectShallowEquals(b, a) || a == null || (o._logger.info(`Change tags from ${C.ObjectToString(b)} to ${C.ObjectToString(a)}`), o._tags = a);
        }, this.setEncodedFrameRate = function (a) {
          o._logger.debug(`setEncodedFrameRate(): ${a}`),
          o._encodedFrameRate = a;
        }, this.setContentLength = function (a) { o._logger.debug(`setContentLength(): ${a}`), o._contentLengthSec = a; }, this.setPlayerState = function (a) {
          if (o._logger.debug(`setPlayerState(): ${a}`), o._hasJoined || a != h.PlayerState.PLAYING || (o.togglePauseJoin(!1),
          o._hasJoined = !0), o._playerState !== a) { const b = o._playerState; o._logger.info(`setPlayerState(): changing player state from ${b} to ${a}`), o.enqueuePlayerStateChangeEvent(b, a), o._playerState = a; }
        }, this.setStreamResolutionWidth = function (a) {
          o._logger.debug(`setStreamResolutionWidth(): ${a}`); const b = o._width; b !== a && a > 0 && (o._logger.debug(`Change stream resolution width from ${b} to ${a}`), o.enqueueResolutionWidthChangeEvent(b, a), o._width = a);
        }, this.setStreamResolutionHeight = function (a) {
          o._logger.debug(`setStreamResolutionHeight(): ${a}`); const b = o._height; b !== a && a > 0 && (o._logger.debug(`Change stream resolution height from ${b} to ${a}`), o.enqueueResolutionHeightChangeEvent(b, a), o._height = a);
        }, this.setConnectionType = function (a) {
          o._logger.debug(`setConnectionType(): ${a}`); const b = o._connectionType; b !== a && a != null && (o._logger.debug(`Change network connection type from ${b} to ${a}`), o.enqueueConnectionTypeChangeEvent(b, a),
          o._connectionType = a);
        }, this.setLinkEncryption = function (a) {
          o._logger.debug(`setLinkEncryption(): ${a}`); const b = o._linkEncryption; b !== a && a != null && (o._logger.debug(`Change network link encryption from ${b} to ${a}`),
          o.enqueueLinkEncryptionChangeEvent(b, a), o._linkEncryption = a);
        }, this.setCDNServerIP = function (a) {
          o._logger.debug(`setCDNServerIP(): ${a}`); const b = o._cdnServerIP; b !== a && C.isValidString(a) && (o._logger.debug(`Change CDN Server IP from ${b} to ${a}`),
          o.enqueueCDNServerIPChangeEvent(b, a), o._cdnServerIP = a);
        }, this.togglePauseJoin = function (a) {
          if (o._logger.debug('togglePauseJoin()'), o._pauseJoin === a) return void o._logger.debug('togglePauseJoin(): same value, ignoring');
          const b = o._pauseJoin; const c = !o._pauseJoin; o.enqueuePauseJoinChangeEvent(b, c), o._pauseJoin = !o._pauseJoin;
        }, this.sendError = function (a, b) { o.enqueueErrorEvent(a, b); }, this.enqueueEvent = function (a, b) {
          if (b[r.REQUEST_EVENT_KEY_TYPE] = a,
          b[r.REQUEST_EVENT_KEY_SESSION_TIME] = this.getSessionTime(), b[r.REQUEST_EVENT_KEY_SEQUENCE_NUMBER] = this._eventQueue.getNumber(), this._playerStateManager) {
            const c = G.Integer(this._playerStateManager.getPHT(), 0, null, -1); const d = G.Integer(this._playerStateManager.getBufferLength(), 0, null, -1);
            c >= 0 && (b[r.REQUEST_KEY_PLAYHEAD_TIME] = c), d > 0 && (b[r.REQUEST_KEY_BUFFER_LENGTH] = d);
          } this._eventQueue.enqueue(b);
        }, this.setPlayerStateManager = function (a) {
          o._playerStateManager = a, o._playerStateManager != null ? o.startRfpsTimer() : o.cancelRfpsTimer();
        }, this.enqueueSessionEndEvent = function () { const a = this.getSessionTime(); const b = this.getNextEventNumber(); const c = this._cwsProtocol.generateSessionEndEvent(b, a); this._eventQueue.enqueue(c); }, this.enqueueErrorEvent = function (a, b) {
          const c = {}; c[r.REQUEST_ERROR_EVENT_KEY_MESSAGE] = a, c[r.REQUEST_ERROR_EVENT_KEY_FATAL] = b, this.enqueueEvent(r.EVENT_TYPE_ERROR, c);
        }, this.enqueueBitrateChangeEvent = function (a, b) {
          const c = {}; const d = {}; a > 0 && (d[r.REQUEST_KEY_BITRATE] = a),
          c[r.REQUEST_KEY_BITRATE] = b, this.declareStateChange(c, d);
        }, this.enqueueSeekEvent = function (a, b) {
          const c = {}; c[r.REQUEST_EVENT_KEY_SEEK_ACT_TYPE] = a, b >= 0 && (c[r.REQUEST_EVENT_KEY_SEEK_TO_POS] = b), this.enqueueEvent(r.EVENT_TYPE_SEEK, c);
        }, this.enqueueResolutionWidthChangeEvent = function (a, b) { const c = {}; const d = {}; a !== -1 && (d[r.REQUEST_KEY_VIDEO_WIDTH] = a), c[r.REQUEST_KEY_VIDEO_WIDTH] = b, this.declareStateChange(c, d); }, this.enqueueResolutionHeightChangeEvent = function (a, b) {
          const c = {}; const d = {}; a !== -1 && (d[r.REQUEST_KEY_VIDEO_HEIGHT] = a), c[r.REQUEST_KEY_VIDEO_HEIGHT] = b, this.declareStateChange(c, d);
        }, this.enqueueConnectionTypeChangeEvent = function (a, b) {
          const c = {}; const d = {}; a !== null && (d[r.REQUEST_KEY_CONNECTION_TYPE] = a),
          c[r.REQUEST_KEY_CONNECTION_TYPE] = b, this.declareStateChange(c, d);
        }, this.enqueueLinkEncryptionChangeEvent = function (a, b) {
          const c = {}; const d = {}; a !== null && (d[r.REQUEST_KEY_LINK_ENCRYPTION] = a), c[r.REQUEST_KEY_LINK_ENCRYPTION] = b,
          this.declareStateChange(c, d);
        }, this.enqueueCDNServerIPChangeEvent = function (a, b) {
          const c = {}; const d = {}; C.isValidString(a) && (d[r.REQUEST_CDN_SERVER_IP] = a), c[r.REQUEST_CDN_SERVER_IP] = b, this.declareStateChange(c, d);
        }, this.enqueuePauseJoinChangeEvent = function (a, b) { const c = {}; const d = {}; d[r.REQUEST_KEY_PAUSE_JOIN] = a, c[r.REQUEST_KEY_PAUSE_JOIN] = b, this.declareStateChange(c, d); }, this.enqueuePlayerStateChangeEvent = function (a, b) {
          const c = {}; const d = {}; c[r.REQUEST_KEY_PLAYER_STATE] = r.convertPlayerState(b), d[r.REQUEST_KEY_PLAYER_STATE] = r.convertPlayerState(a), this.declareStateChange(c, d);
        }, this.declareStateChange = function (a, b) {
          const c = {}; c[r.REQUEST_STATE_CHANGE_EVENT_KEY_NEW] = a,
          b != null && C.dictCount(b) > 0 && (c[r.REQUEST_STATE_CHANGE_EVENT_KEY_OLD] = b), this.enqueueEvent(r.EVENT_TYPE_STATE_CHANGE, c);
        }, this.enqueueCustomEvent = function (a, b) {
          const c = {}; c[r.REQUEST_CUSTOM_EVENT_KEY_NAME] = a,
          c[r.REQUEST_CUSTOM_EVENT_KEY_ATTRIBUTES] = b, this.enqueueEvent(r.EVENT_TYPE_CUSTOM, c);
        }, this.cancelHeartbeatTimer = function () {
          o._heartbeatTimerCancel != null && (o._heartbeatTimerCancel(), o._heartbeatTimerCancel = null);
        }, this.resetHeartbeatTimer = function () { o.cancelHeartbeatTimer(); const a = 1e3 * o._clientSettings.heartbeatInterval; o._heartbeatTimerCancel = o._timer.create(o.sendHeartbeat, a, 'Session.sendHeartbeat'); }, this.cancelRfpsTimer = function () {
          o._rfpsTimerCancel != null && (o._rfpsTimerCancel(), o._rfpsTimerCancel = null);
        }, this.startRfpsTimer = function () {
          o.cancelRfpsTimer(), o._rfpsTimerCancel = o._timer.create(o.countRfps, o._rfpsTimerInterval, 'Session.startRfpsTimer');
        }, this.createHeartbeatInfo = function () { const a = {}; a.err = 'pending', a.seq = o._heartbeatSequenceNumber - 1, a.sentAt = o.getSessionTime(), a.rtt = -1, o._heartbeatInfos.push(a); }, this.updateHeartbeatInfoOnFailure = function (a, b) {
          for (let c = 0; c < o._heartbeatInfos.length; c++) { const d = o._heartbeatInfos[c]; const e = d.seq; e === a && (d.rtt = o.getSessionTime() - d.sentAt, d.err = b); }
        }, this.updateHeartbeatInfoOnSuccess = function (a) {
          for (let b = 0; b < o._heartbeatInfos.length; b++) {
            const c = o._heartbeatInfos[b]; const d = c.seq; d === a && (c.rtt = o.getSessionTime() - c.sentAt, c.err = 'ok');
          }
        }, this.removeOutdatedHeartbeatInfos = function () {
          for (var a, b, c = o._clientConfig.get(y.CONFIG_KEY_MAX_HEARTBEAT_INFOS), d = [], e = 0; e < o._heartbeatInfos.length; e++) {
            b = o._heartbeatInfos[e],
            a = b.seq, a < o._heartbeatSequenceNumber - c && d.push(e);
          } for (var f = [], g = 0; g < o._heartbeatInfos.length; g++)b = o._heartbeatInfos[g], a = b.seq, d.indexOf(g) < 0 && f.push(o._heartbeatInfos[g]); o._heartbeatInfos = f;
        }, this.compileHeartbeatInfos = function () {
          o.removeOutdatedHeartbeatInfos(); for (var a = [], b = 0; b < o._heartbeatInfos.length; b++) {
            const c = o._heartbeatInfos[b]; const d = {}; d[r.REQUEST_HEARTBEAT_INFO_KEY_SEQUENCE_NUMBER] = c.seq, d[r.REQUEST_HEARTBEAT_INFO_KEY_ROUNDTRIP_TIME] = c.rtt,
            d[r.REQUEST_HEARTBEAT_INFO_KEY_STATUS] = c.err, a.push(d);
          } return a;
        }, this.makeHeartbeat = function () {
          const a = o._eventQueue.flush(); if (o.isGlobalSession() && a.length === 0) return null; const b = new t(); if (b.setMessageType(r.MESSAGE_TYPE_SESSION_HEARTBEAT),
          b.setCustomerKey(o._clientSettings.customerKey), b.setClientId(o._clientConfig.get(y.CONFIG_KEY_CLIENT_ID)), b.setSessionId(o._session._id), b.setSequenceNumber(o._heartbeatSequenceNumber), b.setProtocolVersion(r.version),
          b.setClientVersion(c.version), b.setInstanceId(o._client.getId()), b.setCapfield(0), o._connectionType != null && b.setConnectionType(o._connectionType), o._linkEncryption != null && b.setLinkEncryption(o._linkEncryption),
          C.isValidString(o._cdnServerIP) && b.setCDNServerIP(o._cdnServerIP), b.setPlatformMetadataSchema(r.SDK_METADATA_SCHEMA), o._browserName != null && b.setBrowserName(o._browserName), o._browserVersion != null && b.setBrowserVersion(o._browserVersion),
          o._deviceBrand != null && b.setDeviceBrand(o._deviceBrand), o._deviceManufacturer != null && b.setDeviceManufacturer(o._deviceManufacturer), o._deviceModel != null && b.setDeviceModel(o._deviceModel), o._deviceType != null && b.setDeviceType(o._deviceType),
          o._deviceVersion != null && b.setDeviceVersion(o._deviceVersion), o._frameworkName != null && b.setFrameworkName(o._frameworkName), o._frameworkVersion != null && b.setFrameworkVersion(o._frameworkVersion), o._operatingSystemName != null && b.setOperatingSystemName(o._operatingSystemName),
          o._operatingSystemVersion != null && b.setOperatingSystemVersion(o._operatingSystemVersion), o._deviceCategory != null && b.setDeviceCategory(o._deviceCategory), o._playerStateManager != null && (o._moduleName = o._playerStateManager.getModuleName(),
          o._moduleName != null && b.setModuleName(o._moduleName)), o._playerStateManager != null && (o._moduleVersion = o._playerStateManager.getModuleVersion(), o._moduleVersion != null && b.setModuleVersion(o._moduleVersion)),
          o._viewerId != null && b.setViewerId(o._viewerId), C.dictCount(o._tags) > 0 && b.setTags(o._tags), o.isVideoSession() || o.isAdSession()) {
            o.isAdSession() && b.setIsAdSession(), o._assetName != null && b.setAssetName(o._assetName),
            b.setSessionFlags(o._sessionFlags); const d = r.convertPlayerState(o._playerState); b.setPlayerState(d), b.setPauseJoin(o._pauseJoin), o._playerName != null && b.setPlayerName(o._playerName), o._isLive != null && b.setIsLive(o._isLive),
            o._contentLengthSec > 0 && b.setContentLength(o._contentLengthSec), o._bitrateKbps > 0 && b.setBitrate(o._bitrateKbps), o._resource !== null && b.setResource(o._resource), o._encodedFrameRate > 0 && b.setEncodedFrameRate(o._encodedFrameRate);
            let e; let f; let g; o._playerStateManager != null && (e = G.Integer(o._playerStateManager.getPHT(), 0, null, -1), f = G.Integer(o._playerStateManager.getBufferLength(), 0, null, -1), g = o._playerStateManager.getSignalStrength()),
            e >= 0 && b.setPlayheadTime(e), f > 0 && b.setBufferLength(f); const h = o.updateAverageFrameRate(); h > 0 && b.setAverageFrameRate(h), o._playingFpsTotal > 0 && b.setRfpsTotal(G.Integer(o._playingFpsTotal, 0, null, -1)), o._playingFpsObservationCount > 0 && b.setRfpsObservationCount(G.Integer(o._playingFpsObservationCount, 0, null, -1)),
            g <= 0 && b.setSignalStrength(g), o._streamUrl != null && b.setStreamUrl(o._streamUrl), o._width > 0 && b.setStreamWidth(o._width), o._height > 0 && b.setStreamHeight(o._height);
          } else b.setSessionFlags(r.SessionFlags.GLOBAL);
          if (a.length > 0 && b.setEvents(a), b.setCapabilities(o._capabilities), b.setIsSdkClient(!0), o._clientConfig.get(y.CONFIG_KEY_SEND_LOGS) && b.setLogs(o._logBuffer.flush()), o._clientConfig.get(y.CONFIG_KEY_MAX_HEARTBEAT_INFOS) > 0) {
            const i = o.compileHeartbeatInfos(); i.length > 0 && b.setHeartbeatInfos(i);
          } return b.setSessionStartTime(o._startTimeMs), b.setSessionTime(o.getSessionTime()), o._heartbeatSequenceNumber++, b.get();
        }, this.sendHeartbeat = function () {
          if (!o._cleanedUp) { const a = o.makeHeartbeat(); a != null && o.postHeartbeat(a); }
        }, this.countRfps = function () {
          if (o._playerState === h.PlayerState.PLAYING) {
            const a = o._playerStateManager.getRenderedFrameRate(); a >= 0 && (o._playingFpsTotal += a,
            o._playingFpsObservationCount++);
          }
        }, this.updateAverageFrameRate = function () {
          if (o._playerState === h.PlayerState.PLAYING) {
            const a = o._playerStateManager.getRenderedFrameRate(); if (a >= 0) {
              o._playingFpsTotal += a, o._playingFpsObservationCount++;
              const b = C.Int32.Cast(1 * o._playingFpsTotal / o._playingFpsObservationCount); return b;
            } return -1;
          } return -1;
        }, this.postHeartbeat = function (a) {
          o.createHeartbeatInfo(); const b = o._heartbeatSequenceNumber - 1; o._logger.info(`postHeartbeat(): Send HB[${b}]${o.sessionTypeTag()}`);
          const c = function (a, c) { o.onHeartbeatResponse(a, c, b); }; o._gatewayControl.send(a, c);
        }, this.onHeartbeatResponse = function (a, b, c) {
          o._cleanedUp || o._exceptionCatcher.runProtected('onHeartbeatResponse', () => {
            if (a) {
              const d = b; if (d != null) {
                o._logger.debug(`onHeartbeatResponse(): received valid response for HB[${c}]`), o.updateHeartbeatInfoOnSuccess(c); const e = d[r.RESPONSE_KEY_CLIENT_ID]; e != null && e != o._clientConfig.get(y.CONFIG_KEY_CLIENT_ID) && (o._logger.debug(`onHeartbeatResponse(): setting the client id to ${e} (from gateway)`),
                o._clientConfig.set(y.CONFIG_KEY_CLIENT_ID, e), o._clientConfig.save()); const f = d[r.RESPONSE_KEY_STATUS]; f != null && f != r.RESPONSE_STATUS_NO_ERRORS && o._logger.error(`onHeartbeatResponse(): error from gateway: ${f}`);
                const g = d[r.RESPONSE_KEY_CONFIG]; if (typeof g === 'object') {
                  const h = g[r.RESPONSE_CONFIG_KEY_MAX_HEARTBEAT_INFOS]; h != null && o._clientConfig.get(y.CONFIG_KEY_MAX_HEARTBEAT_INFOS) !== h && (o._logger.debug(`onHeartbeatResponse(): setting Maximum Heartbeat Infos to ${h} (from gateway)`),
                  o._clientConfig.set(y.CONFIG_KEY_MAX_HEARTBEAT_INFOS, h)); const i = g[r.RESPONSE_CONFIG_KEY_SEND_LOGS]; const j = i != null ? C.AsBoolean(i) : !1; j !== o._clientConfig.get(y.CONFIG_KEY_SEND_LOGS) && (o._logger.debug(`onHeartbeatResponse(): turning ${j ? 'on' : 'off'} sending of logs`),
                  o._clientConfig.set(y.CONFIG_KEY_SEND_LOGS, j)); const k = g[r.RESPONSE_CONFIG_KEY_HEARTBEAT_INTERVAL]; if (k != null) {
                    const l = C.Int32.Cast(k); const m = l; m != o._clientSettings.heartbeatInterval && (o._logger.debug(`onHeartbeatResponse(): received hbIntervalMs from gateway: ${m}`),
                    o._clientSettings.heartbeatInterval = m, o._heartbeatTimerCancel != null && o.resetHeartbeatTimer());
                  } const n = g[r.RESPONSE_CONFIG_KEY_GATEWAY_URL]; n != null && n != o._clientSettings.gatewayUrl && (o._logger.debug(`onHeartbeatResponse(): received gatewayUrl from gateway: ${n}`),
                  o._clientSettings.gatewayUrl = n);
                }
              } else o._logger.warning('onHeartbeatResponse(): decoded heartbeat response is null.');
            } else {
              let p = b; C.isValidString(p) ? C.stringStartsWith(p, 'HTTP timeout') ? o._logger.warning(`onHeartbeatResponse(): ${p}`) : o._logger.error(`onHeartbeatResponse(): failed to send heartbeat: ${p}`) : (p = v.DEFAULT_HEARTBEAT_ERROR_MESSAGE,
              o._logger.error(`onHeartbeatResponse(): ${p}`)), o.updateHeartbeatInfoOnFailure(c, p);
            }
          });
        }, this.getSessionTime = function () { const a = C.Int32.Cast(o._time.current() - o._startTimeMs); return a; }, this.getNextEventNumber = function () {
          return this._eventQueue.getNumber();
        }, this.sessionTypeTag = function () { return this.isGlobalSession() ? ' (global session)' : ''; }, this.isGlobalSession = function () { return this._global == u.SESSION_TYPE.GLOBAL; }, this.isVideoSession = function () {
          return this._global == u.SESSION_TYPE.VIDEO;
        }, this.isAdSession = function () { return this._global == u.SESSION_TYPE.AD; };
      }; u.DEFAULT_VIDEO_SESSION_FLAGS = r.SessionFlags.VIDEO + r.SessionFlags.QUALITY_METRICS + r.SessionFlags.BITRATE_METRICS,
      u.SESSION_TYPE = { VIDEO: 'Video', GLOBAL: 'Global', AD: 'Ad' }; var v = (b.IMonitor = function () {
        this.onPlayerStateChange = function (a) {}, this.onBitrateChange = function (a) {}, this.onSeekEvent = function (a, b) {}, this.onEncodedFrameRateChange = function (a) {},
        this.onRenderedFrameRateChange = function (a) {}, this.onContentLengthChange = function (a) {}, this.onPlayheadTimeChange = function (a) {}, this.onStreamUrlChange = function (a) {}, this.onBufferLengthChange = function (a) {},
        this.onPlayerTypeChange = function (a) {}, this.onPlayerVersionChange = function (a) {}, this.onStreamResolutionWidthChange = function (a) {}, this.onStreamResolutionHeightChange = function (a) {}, this.onConnectionTypeChange = function (a) {},
        this.onLinkEncryptionChange = function (a) {}, this.onSignalStrengthChange = function (a) {}, this.onError = function (a, b) {}, this.onRelease = function () {}, this.onCDNServerIPChange = function (a) {};
      }, b.Session = function () {
        function b(a, b, c, d, e, f, g, h) {
          this._id = a, this._global = b, this._contentMetadata = c, this._clientConfig = d, this._systemMetadata = e, this._cwsSession = f, this._exceptionCatcher = g, this._logger = h, this._logger.setModuleName('Session'),
          this._logger.setSessionId(C.ToString(this._id));
        } const c = this; c._contentMetadata = null, c._playerStateManager = null, c._id = 0, c._global = u.SESSION_TYPE.VIDEO, c._cleaningUp = !1, c._cleanedUp = !1, c._adPlaying = !1, c._adStream = null,
        c._adPlayer = null, c._adPosition = null, c._ignorePlayerState = !1, c._pooledPlayerState = h.PlayerState.NOT_MONITORED, c._ignoreBitrateAndResource = !1, c._ignorePlayheadTimeandBufferLength = !1, c._ignoreEncodedFrameRateAndDuration = !1,
        c._ignoreError = !1, b.apply(c, arguments), this.start = function () {
          if (c.isVideoSession() || c.isAdSession() ? c._logger.info(`start(): assetName=${c._contentMetadata.assetName}`) : c._logger.info(`start()${c.sessionTypeTag()}`),
          c._cwsSession.start(this), c._clientConfig.isReady())c.initiateSession(); else { const a = function () { c.initiateSession(); }; c._clientConfig.register(a); }
        }, this.cleanup = function () {
          if (c._logger.info(`cleanup()${c.sessionTypeTag()}`),
          c._cleaningUp = !0, c._cwsSession.willEndSoon(), c._clientConfig.isReady())c.endCwsSessionAndCleanupAll(); else { const a = function () { c.endCwsSessionAndCleanupAll(); }; c._clientConfig.register(a); }
        }, this.cleanupAll = function () {
          c._logger.debug(`cleanupAll()${c.sessionTypeTag()}`), (c.isVideoSession() || c.isAdSession()) && c._playerStateManager != null && c.detachPlayer(), c._contentMetadata = null, c._logger.setSessionId(null), c._logger = null,
          c._exceptionCatcher = null, c._client = null, c._clientConfig = null, c._systemMetadata = null, c._cwsSession.cleanup(), c._cwsSession = null, c._cleanedUp = !0;
        }, this.endCwsSessionAndCleanupAll = function () {
          c._cwsSession.end(),
          c.cleanupAll();
        }, this.initiateSession = function () {
          c._cwsSession.initialize(), c.setStatesFromSystemMetadata(), (c.isVideoSession() || c.isAdSession()) && (c.setStatesFromContentMetadata(), c.enqueueEventForContentMetadata()),
          c._cwsSession.startSendingHeartbeats();
        }, this.enqueueEventForContentMetadata = function () {
          const b = {}; if (c._contentMetadata.assetName != null ? b[r.REQUEST_KEY_ASSET_NAME] = c._contentMetadata.assetName : c._logger.warning('enqueueEventForContentMetadata(): assetName was not set.'),
          c._contentMetadata.applicationName != null ? (typeof b[r.REQUEST_KEY_STREAM_METADATA_CHANGE] === 'undefined' && (b[r.REQUEST_KEY_STREAM_METADATA_CHANGE] = {}), b[r.REQUEST_KEY_STREAM_METADATA_CHANGE][r.REQUEST_KEY_PLAYER_NAME] = c._contentMetadata.applicationName) : c._logger.warning('enqueueEventForContentMetadata(): applicationName was not set.'),
          c._contentMetadata.streamUrl != null ? b[r.REQUEST_KEY_STREAM_URL] = c._contentMetadata.streamUrl : c._logger.warning('enqueueEventForContentMetadata(): streamUrl was not set.'), c._contentMetadata.viewerId != null ? (typeof b[r.REQUEST_KEY_STREAM_METADATA_CHANGE] === 'undefined' && (b[r.REQUEST_KEY_STREAM_METADATA_CHANGE] = {}),
          b[r.REQUEST_KEY_STREAM_METADATA_CHANGE][r.REQUEST_KEY_VIEWER_ID] = c._contentMetadata.viewerId) : c._logger.warning('enqueueEventForContentMetadata(): viewerId was not set.'), c._contentMetadata.defaultResource != null ? b[r.REQUEST_KEY_RESOURCE] = c._contentMetadata.defaultResource : c._logger.warning('enqueueEventForContentMetadata(): defaultResource was not set.'),
          c._contentMetadata.duration > -1 ? b[r.REQUEST_KEY_CONTENT_LENGTH] = c._contentMetadata.duration : c._logger.warning('enqueueEventForContentMetadata(): duration was not set.'), c._contentMetadata.encodedFrameRate > -1 ? b[r.REQUEST_KEY_ENCODED_FRAME_RATE] = c._contentMetadata.encodedFrameRate : c._logger.warning('enqueueEventForContentMetadata(): encodedFrameRate was not set.'),
          c._contentMetadata.streamType != e.StreamType.UNKNOWN ? c._contentMetadata.streamType == a.ContentMetadata.StreamType.LIVE ? b[r.REQUEST_KEY_IS_LIVE] = !0 : b[r.REQUEST_KEY_IS_LIVE] = !1 : c._logger.warning('enqueueEventForContentMetadata(): streamType was not set.'),
          C.dictCount(c._contentMetadata.custom) > 0) { b[r.REQUEST_KEY_TAGS] = {}; for (const d in c._contentMetadata.custom)b[r.REQUEST_KEY_TAGS][d] = c._contentMetadata.custom[d]; } else c._logger.warning('enqueueEventForContentMetadata(): custom tags were not set.');
          C.dictCount(b) > 0 && c._cwsSession.declareStateChange(b, null);
        }, this.setStatesFromSystemMetadata = function () {
          c._logger.debug('setStatesFromSystemMetadata()'); const a = c._systemMetadata.get(); const b = a[K.BROWSER_NAME];
          b != null && c._cwsSession.setBrowserName(b); const d = a[K.BROWSER_VERSION]; d != null && c._cwsSession.setBrowserVersion(d); const e = a[K.DEVICE_BRAND]; e != null && c._cwsSession.setDeviceBrand(e); const f = a[K.DEVICE_MANUFACTURER];
          f != null && c._cwsSession.setDeviceManufacturer(f); const g = a[K.DEVICE_MODEL]; g != null && c._cwsSession.setDeviceModel(g); const h = a[K.DEVICE_TYPE]; h != null && c._cwsSession.setDeviceType(h); const i = a[K.DEVICE_VERSION];
          i != null && c._cwsSession.setDeviceVersion(i); const j = a[K.FRAMEWORK_NAME]; j != null && c._cwsSession.setFrameworkName(j); const k = a[K.FRAMEWORK_VERSION]; k != null && c._cwsSession.setFrameworkVersion(k); const l = a[K.OPERATING_SYSTEM_NAME];
          l != null && c._cwsSession.setOperatingSystemName(l); const m = a[K.OPERATING_SYSTEM_VERSION]; m != null && c._cwsSession.setOperatingSystemVersion(m); const n = a[K.DEVICE_CATEGORY]; n != null && c._cwsSession.setDeviceCategory(n);
        }, this.setStatesFromContentMetadata = function () {
          if (c._logger.debug('setStatesFromContentMetadata()'), c._contentMetadata.defaultResource != null && c._cwsSession.setResource(c._contentMetadata.defaultResource),
          c._contentMetadata.streamUrl != null && c._cwsSession.setStreamUrl(c._contentMetadata.streamUrl), c._contentMetadata.duration > 0 && c._cwsSession.setContentLength(c._contentMetadata.duration), c._contentMetadata.encodedFrameRate > 0 && c._cwsSession.setEncodedFrameRate(c._contentMetadata.encodedFrameRate),
          c._contentMetadata.streamType !== e.StreamType.UNKNOWN) { const a = c._contentMetadata.streamType === e.StreamType.LIVE; c._cwsSession.setIsLive(a); }c._contentMetadata.assetName != null && c._cwsSession.setAssetName(c._contentMetadata.assetName),
          c._contentMetadata.viewerId != null && c._cwsSession.setViewerId(c._contentMetadata.viewerId), c._contentMetadata.applicationName != null && c._cwsSession.setPlayerName(c._contentMetadata.applicationName), C.dictCount(c._contentMetadata.custom) > 0 && c._cwsSession.setTags(c._contentMetadata.custom);
        }, this.onPlayerStateChange = function (a) {
          return c._logger.debug(`onPlayerStateChange(): ${a}`), c._cwsSession._playerState != a ? (c._cwsSession._playerState == h.PlayerState.NOT_MONITORED && a != h.PlayerState.NOT_MONITORED && (c._pooledPlayerState = a),
          c._ignorePlayerState ? void c._logger.debug(`onPlayerStateChange(): ${a} (pooled, ${c._adPlaying ? 'ad playing' : 'preloading'})`) : void c._cwsSession.setPlayerState(a)) : void 0;
        }, this.onBitrateChange = function (a) {
          return c._logger.debug(`onBitrateChange(): ${a}`), c._ignoreBitrateAndResource ? void c._logger.info('onBitrateChange(): ignored') : void c._cwsSession.setBitrateKbps(a);
        }, this.onSeekEvent = function (a, b) {
          b == null || C.isInteger(b) || (c._logger.error(`onSeekEvent(): Ignored non-integer seekToPosition data: ${b}`),
          b = -1), c._cwsSession.enqueueSeekEvent(a, b);
        }, this.onEncodedFrameRateChange = function (a) {
          c._logger.debug(`onEncodedFrameRateChange(): ${a}`), c._contentMetadata.encodedFrameRate > 0 || (a > 0 ? c._ignoreEncodedFrameRateAndDuration ? c._logger.info(`onEncodedFrameRateChange(): Ignored encoded frame rate data: ${a} (ads)`) : (c._cwsSession.setEncodedFrameRate(a),
          c._logger.info(`onEncodedFrameRateChange(): Received encoded frame rate data: ${a}`)) : c._logger.warning(`onEncodedFrameRateChange(): Ignored invalid encoded frame rate data: ${a}`));
        }, this.onContentLengthChange = function (a) {
          c._logger.debug(`onContentLengthChange(): ${a}`), c._contentMetadata.duration > 0 || (a > 0 ? c._ignoreEncodedFrameRateAndDuration ? c._logger.info(`setContentLength(): Ignored content length data: ${a} (ads)`) : (c._cwsSession.setContentLength(a),
          c._logger.info(`setContentLength(): Received content length data: ${a}`)) : c._logger.warning(`setContentLength(): Ignored invalid content length data: ${a}`));
        }, this.onStreamUrlChange = function (a) {
          c._logger.debug(`onStreamUrlChange(): ${a}`),
          c._contentMetadata.streamUrl == null && c._cwsSession.setStreamUrl(a);
        }, this.onStreamResolutionWidthChange = function (a) {
          c._logger.debug(`onStreamResolutionWidthChange(): ${a}`), c._cwsSession.setStreamResolutionWidth(a);
        }, this.onStreamResolutionHeightChange = function (a) { c._logger.debug(`onStreamResolutionHeightChange(): ${a}`), c._cwsSession.setStreamResolutionHeight(a); }, this.onConnectionTypeChange = function (a) {
          c._logger.debug(`onConnectionTypeChange(): ${a}`),
          c._cwsSession.setConnectionType(a);
        }, this.onLinkEncryptionChange = function (a) { c._logger.debug(`onLinkEncryptionChange(): ${a}`), c._cwsSession.setLinkEncryption(a); }, this.onPlayerTypeChange = function (a) {
          c._logger.debug(`onPlayerTypeChange(): ${a}`),
          c._cwsSession.getFrameworkName() == null && c._cwsSession.setFrameworkName(a);
        }, this.onPlayerVersionChange = function (a) {
          c._logger.debug(`onPlayerVersionChange(): ${a}`), c._cwsSession.getFrameworkVersion() == null && c._cwsSession.setFrameworkVersion(a);
        }, this.onCDNServerIPChange = function (a) { c._logger.debug(`onCDNServerIPChange(): ${a}`), c._cwsSession.setCDNServerIP(a); }, this.onError = function (a, b) {
          c._logger.debug(`onError(): ${a} (${b})`), c.reportError(a, b);
        }, this.onRelease = function () { c._logger.debug('onRelease()'), c.detachPlayer(); }, this.adStart = function (b, d, e) {
          return c._logger.debug(`adStart(): adStream=${b} adPlayer=${d} adPosition=${e}`), c._adPlaying ? void c._logger.warning('adStart(): multiple adStart calls, ignoring') : (c._adPlaying = !0,
          c._adStream = b, c._adPlayer = d, c._adPosition = e, c._cwsSession._hasJoined || c._cwsSession.togglePauseJoin(!0), void (c._adStream == a.Client.AdStream.CONTENT || c._adPlayer == a.Client.AdPlayer.SEPARATE ? (c._cwsSession._playerState !== h.PlayerState.NOT_MONITORED && (c._pooledPlayerState = c._cwsSession._playerState),
          c._cwsSession.setPlayerState(h.PlayerState.NOT_MONITORED), c._ignorePlayerState = !0) : c._adStream == a.Client.AdStream.SEPARATE && c._adPlayer == a.Client.AdPlayer.CONTENT && (c._cwsSession._playerState !== h.PlayerState.NOT_MONITORED && (c._pooledPlayerState = c._cwsSession._playerState),
          c._cwsSession.setPlayerState(h.PlayerState.NOT_MONITORED), c._ignorePlayerState = !0, c._ignoreBitrateAndResource = !0, c._ignoreEncodedFrameRateAndDuration = !0, c._ignorePlayheadTimeandBufferLength = !0, c._ignoreError = !0)));
        }, this.adEnd = function () {
          return c._logger.debug('adEnd()'), c._adPlaying ? (c._cwsSession._hasJoined || c._cwsSession.togglePauseJoin(!1), c._adStream == a.Client.AdStream.CONTENT || c._adPlayer == a.Client.AdPlayer.SEPARATE ? c._preloading || (c._ignorePlayerState = !1,
          c._cwsSession.setPlayerState(c._pooledPlayerState)) : c._adStream == a.Client.AdStream.SEPARATE && c._adPlayer == a.Client.AdPlayer.CONTENT && (c._ignoreBitrateAndResource = !1, c._ignoreEncodedFrameRateAndDuration = !1,
          c._ignorePlayheadTimeandBufferLength = !1, c._ignoreError = !1, c._preloading || (c._ignorePlayerState = !1, c._cwsSession.setPlayerState(c._pooledPlayerState))), c._adPlaying = !1, void (c._adStream = c._adPlayer = c._adPosition = null)) : void c._logger.debug('adEnd(): called before adStart, ignoring');
        }, this.detachPlayer = function () {
          c._logger.debug('detachPlayer()'), c._playerStateManager !== null && (c._exceptionCatcher.runProtected('Session.detachPlayer', () => {
            c._playerStateManager.removeMonitoringNotifier();
          }), c._playerStateManager = null, c._cwsSession.setPlayerStateManager(null), c._cwsSession.setPlayerState(h.PlayerState.NOT_MONITORED));
        }, this.attachPlayer = function (a) {
          return c._logger.debug('attachPlayer()'),
          c._playerStateManager != null ? void c._logger.error('attachPlayer(): detach current PlayerStateManager first') : void c._exceptionCatcher.runProtected('Session.attachPlayer()', () => {
            a.setMonitoringNotifier(c, c._id) ? (a.pushCurrentState(),
            c._playerStateManager = a, c._cwsSession.setPlayerStateManager(c._playerStateManager)) : c._logger.error('attachPlayer(): instance of PlayerStateManager is already attached to a session');
          });
        }, this.getPlayerStateManager = function () {
          return c._logger.debug('getPlayerStateManager()'), c._playerStateManager;
        }, this.contentPreload = function () {
          return c._logger.debug('contentPreload()'), c._preloading ? void c._logger.debug('contentPreload(): called twice, ignoring') : (c._preloading = !0,
          void (c._ignorePlayerState = !0));
        }, this.contentStart = function () {
          return c._logger.debug('contentStart()'), c._preloading ? (c._preloading = !1, void (c._adPlaying || (c._ignorePlayerState = !1, c._cwsSession.setPlayerState(c._pooledPlayerState)))) : void c._logger.warning('contentStart(): called without contentPreload, ignoring');
        }, this.reportError = function (b, d) {
          if (c._logger.info(`reportError(): ${b}`), !C.isValidString(b)) return void c._logger.error(`reportError(): invalid error message string: ${b}`); if (d !== a.Client.ErrorSeverity.FATAL && d !== a.Client.ErrorSeverity.WARNING) return void c._logger.error(`reportError(): invalid error severity: ${d}`);
          if (c._ignoreError) return void c._logger.debug('reportError(): ignored'); const e = d === a.Client.ErrorSeverity.FATAL; c._cwsSession.sendError(b, e);
        }, this.updateContentMetadata = function (a) {
          c._logger.debug('updateContentMetadata(): enter'),
          c._exceptionCatcher.runProtected('Session.updateContentMetadata', () => { c.mergeContentMetadata(a), c.setStatesFromContentMetadata(); });
        }, this.sendCustomEvent = function (a, b) {
          c._logger.info(`sendEvent(): eventName=${a}${c.sessionTypeTag()}`),
          c._cwsSession.enqueueCustomEvent(a, b);
        }, this.mergeContentMetadata = function (b) {
          const d = {}; const f = {}; if (C.isValidString(b.assetName) && (c._contentMetadata.assetName != b.assetName ? (c._contentMetadata.assetName != null && (d[r.REQUEST_KEY_ASSET_NAME] = c._contentMetadata.assetName),
          f[r.REQUEST_KEY_ASSET_NAME] = b.assetName, c._contentMetadata.assetName = b.assetName) : c._logger.warning('mergeContentMetadata(): assetName was not changed.')), C.isValidString(b.applicationName) && (c._contentMetadata.applicationName != b.applicationName ? (c._contentMetadata.applicationName != null && (typeof d[r.REQUEST_KEY_STREAM_METADATA_CHANGE] === 'undefined' && (d[r.REQUEST_KEY_STREAM_METADATA_CHANGE] = {}),
          d[r.REQUEST_KEY_STREAM_METADATA_CHANGE][r.REQUEST_KEY_PLAYER_NAME] = c._contentMetadata.applicationName), typeof f[r.REQUEST_KEY_STREAM_METADATA_CHANGE] === 'undefined' && (f[r.REQUEST_KEY_STREAM_METADATA_CHANGE] = {}),
          f[r.REQUEST_KEY_STREAM_METADATA_CHANGE][r.REQUEST_KEY_PLAYER_NAME] = b.applicationName, c._contentMetadata.applicationName = b.applicationName) : c._logger.warning('mergeContentMetadata(): applicationName was not changed.')),
          C.isValidString(b.streamUrl) && (c._contentMetadata.streamUrl != b.streamUrl ? (c._contentMetadata.streamUrl != null && (d[r.REQUEST_KEY_STREAM_URL] = c._contentMetadata.streamUrl), f[r.REQUEST_KEY_STREAM_URL] = b.streamUrl,
          c._contentMetadata.streamUrl = b.streamUrl) : c._logger.warning('mergeContentMetadata(): streamUrl was not changed.')), C.isValidString(b.viewerId) && (c._contentMetadata.viewerId != b.viewerId ? (c._contentMetadata.viewerId != null && (typeof d[r.REQUEST_KEY_STREAM_METADATA_CHANGE] === 'undefined' && (d[r.REQUEST_KEY_STREAM_METADATA_CHANGE] = {}),
          d[r.REQUEST_KEY_STREAM_METADATA_CHANGE][r.REQUEST_KEY_VIEWER_ID] = c._contentMetadata.viewerId), typeof f[r.REQUEST_KEY_STREAM_METADATA_CHANGE] === 'undefined' && (f[r.REQUEST_KEY_STREAM_METADATA_CHANGE] = {}), f[r.REQUEST_KEY_STREAM_METADATA_CHANGE][r.REQUEST_KEY_VIEWER_ID] = b.viewerId,
          c._contentMetadata.viewerId = b.viewerId) : c._logger.warning('mergeContentMetadata(): viewerId was not changed.')), C.isValidString(b.defaultResource) && (c._contentMetadata.defaultResource != b.defaultResource ? (c._contentMetadata.defaultResource != null && (d[r.REQUEST_KEY_RESOURCE] = c._contentMetadata.defaultResource),
          f[r.REQUEST_KEY_RESOURCE] = b.defaultResource, c._contentMetadata.defaultResource = b.defaultResource) : c._logger.warning('mergeContentMetadata(): defaultResource was not changed.')), C.isInteger(b.duration) && b.duration > 0 && (c._contentMetadata.duration != b.duration ? (c._cwsSession._contentLengthSec > 0 && (d[r.REQUEST_KEY_CONTENT_LENGTH] = c._cwsSession._contentLengthSec),
          f[r.REQUEST_KEY_CONTENT_LENGTH] = b.duration, c._contentMetadata.duration = b.duration) : c._logger.warning('mergeContentMetadata(): duration was not changed.')), C.isInteger(b.encodedFrameRate) && b.encodedFrameRate > 0 && (c._contentMetadata.encodedFrameRate != b.encodedFrameRate ? (c._contentMetadata.encodedFrameRate > -1 && (d[r.REQUEST_KEY_ENCODED_FRAME_RATE] = c._contentMetadata.encodedFrameRate),
          f[r.REQUEST_KEY_ENCODED_FRAME_RATE] = b.encodedFrameRate, c._contentMetadata.encodedFrameRate = b.encodedFrameRate) : c._logger.warning('mergeContentMetadata(): encodedFrameRate was not changed.')), b.streamType != e.StreamType.UNKNOWN && (c._contentMetadata.streamType != b.streamType ? (c._contentMetadata.streamType != e.StreamType.UNKNOWN && (c._contentMetadata.streamType == a.ContentMetadata.StreamType.LIVE ? d[r.REQUEST_KEY_IS_LIVE] = !0 : d[r.REQUEST_KEY_IS_LIVE] = !1),
          b.streamType == a.ContentMetadata.StreamType.LIVE ? f[r.REQUEST_KEY_IS_LIVE] = !0 : f[r.REQUEST_KEY_IS_LIVE] = !1, c._contentMetadata.streamType = b.streamType) : c._logger.warning('mergeContentMetadata(): streamType was not changed.')),
          C.dictCount(b.custom) > 0) {
            const g = {}; const h = {}; for (const i in b.custom) {
              const j = c._contentMetadata.custom[i]; j != b.custom[i] ? (g[i] = b.custom[i], c._contentMetadata.custom[i] && (h[i] = c._contentMetadata.custom[i]), c._contentMetadata.custom[i] = b.custom[i]) : c._logger.info(`mergeContentMetadata(): custom.${i} was not changed.`);
            }C.dictCount(g) > 0 ? (C.dictCount(h) > 0 && (d[r.REQUEST_KEY_TAGS] = h), f[r.REQUEST_KEY_TAGS] = g) : c._logger.warning('mergeContentMetadata(): custom was not changed.');
          }C.dictCount(f) > 0 && c._cwsSession.declareStateChange(f, d);
        }, this.isGlobalSession = function () { return c._global == u.SESSION_TYPE.GLOBAL; }, this.isVideoSession = function () { return c._global == u.SESSION_TYPE.VIDEO; }, this.isAdSession = function () {
          return c._global == u.SESSION_TYPE.AD;
        }, this.sessionTypeTag = function () { return c.isGlobalSession() ? ' (global session)' : ''; };
      }); v.DEFAULT_HEARTBEAT_ERROR_MESSAGE = 'received no response (or a bad response) to heartbeat POST request'; var w = b.SessionFactory = function () {
        function a(a, c, d, e) {
          b._client = a, b._clientSettings = c, b._clientConfig = d, b._systemFactory = e, b._logger = b._systemFactory.buildLogger(), b._logger.setModuleName('SessionFactory'), b._nextSessionKey = 0, b._sessionsByKey = {};
        } var b = this; b._logger = null, b._logBuffer = null, b._nextSessionKey = 0, b._sessionsByKey = null, a.apply(b, arguments), this.cleanup = function () {
          b._logger = null, b._logBuffer = null; for (const a in b._sessionsByKey) {
            const c = b._sessionsByKey[a];
            c.cleanup();
          }b._sessionsByKey = null, b._nextSessionKey = 0;
        }, this.newSessionKey = function () { const a = b._nextSessionKey; return b._nextSessionKey++, a; }, this.makeVideoSession = function (a, c) {
          return a == null && (a = new e()),
          b.makeSession(a, c);
        }, this.makeGlobalSession = function (a) { return b.makeSession(a, u.SESSION_TYPE.GLOBAL); }, this.generateSessionId = function () { return F.integer32(); }, this.makeSession = function (a, c) {
          const d = a.clone(); const e = b.generateSessionId(); const f = b._systemFactory.buildSession(b._client, b._clientSettings, b._clientConfig, e, c, d); const g = b.newSessionKey();
          return b.addSession(g, f), f.start(), g;
        }, this.getSession = function (a) { const c = b._sessionsByKey[a]; return c == null && b._logger.error('Client: invalid sessionKey. Did you cleanup that session previously?'), c; }, this.getVideoSession = function (a) {
          let c = this.getSession(a); return c != null && (c.isVideoSession() || c.isAdSession() || (b._logger.error('Client: invalid sessionKey. Did you cleanup that session previously?'), c = null)), c;
        }, this.addSession = function (a, c) {
          b._sessionsByKey[a] = c;
        }, this.removeSession = function (a) { delete b._sessionsByKey[a]; }, this.cleanupSession = function (a) { const c = b.getSession(a); b.removeSession(a), c.cleanup(); };
      }; var x = b.CallbackWithTimeout = function (a) {
        this._timer = a, this.getWrapperCallback = function (a, b, c) {
          let d = !1; const e = function () { d || (d = !0, a(!1, `${c} (${b} ms)`)); }; this._timer.createOnce(e, b, 'CallbackWithTimeout.wrap'); const f = function (b, c) { d || (d = !0, a(b, c)); };
          return f;
        };
      }; var y = b.Config = function (a, b, c) {
        const d = this; this._logger = a, this._logger.setModuleName('Config'), this._storage = b, this._jsonInterface = c, this._defaultConfig = {
          clientId: r.DEFAULT_CLIENT_ID,
          sendLogs: !1,
          maxHbInfos: r.DEFAULT_MAX_HEARTBEAT_INFOS,
        }, this._config = C.ObjectShallowCopy(this._defaultConfig), this._loaded = !1, this._loadedEmpty = !1, this._waitingConsumers = [], this.isReady = function () {
          return this._loaded;
        }, this.load = function () {
          const a = function (a, b) {
            a ? (d._parse(b), d._logger.debug(`load(): configuration successfully loaded from local storage${d._loadedEmpty ? ' (was empty)' : ''}.`)) : d._logger.error(`load(): error loading configuration from local storage: ${b}`),
            d._loaded = !0, d._notify();
          }; this._storage.load(y.STORAGE_KEY, a);
        }, this._parse = function (a) {
          const b = this._jsonInterface.decode(a); if (b == null) return void (this._loadedEmpty = !0); const c = b[y.CONFIG_STORAGE_KEY_CLIENT_ID];
          c != null && c != r.DEFAULT_CLIENT_ID && c != '' && c != 'null' && (this._config[y.CONFIG_KEY_CLIENT_ID] = c, this._logger.debug(`parse(): loaded clientId=${c} (from local storage)`));
        }, this._marshall = function () {
          const a = {};
          return a[y.CONFIG_STORAGE_KEY_CLIENT_ID] = this._config.clientId, this._logger.debug(`_marshall(): saving clientId=${this._config.clientId} (to local storage)`), this._jsonInterface.encode(a);
        }, this.save = function () {
          const a = function (a, b) { a ? d._logger.debug('save(): configuration successfully saved to local storage.') : d._logger.error(`save(): error saving configuration to local storage: ${b}`); }; this._storage.save(y.STORAGE_KEY, this._marshall(), a);
        }, this.register = function (a) { return this.isReady() ? void a() : void this._waitingConsumers.push(a); }, this.get = function (a) { return this._loaded ? this._config[a] : null; }, this.set = function (a, b) {
          this._loaded && (this._config[a] = b);
        }, this._notify = function () { for (var a; (a = this._waitingConsumers.shift()) != null;)a(); };
      }; y.STORAGE_KEY = 'sdkConfig', y.CONFIG_STORAGE_KEY_CLIENT_ID = 'clId', y.CONFIG_KEY_CLIENT_ID = 'clientId', y.CONFIG_KEY_SEND_LOGS = 'sendLogs',
      y.CONFIG_KEY_MAX_HEARTBEAT_INFOS = 'maxHbInfos'; var z = b.EventQueue = function () {
        this._events = [], this._nextEventNumber = 0, this.enqueue = function (a) { this._events.push(a); }, this.getNumber = function () {
          const a = this._nextEventNumber;
          return this._nextEventNumber++, a;
        }, this.flush = function () { const a = this._events; return this._events = [], a; };
      }; var A = b.ExceptionCatcher = function (a, b, c) {
        this._logger = a, this._logger.setModuleName('ExceptionCatcher'),
        this._ping = b, this._systemSettings = c, this.runProtected = function (a, b, c) { try { b(); } catch (d) { if (c != null)c(d); else { if (this._systemSettings.allowUncaughtExceptions) throw d; this.onUncaughtException(a, d); } } }, this.onUncaughtException = function (a, b) {
          const c = `Uncaught exception: ${a}: ${b.toString()}`; if (this._ping != null) try { this._ping.send(c); } catch (d) { this._logger.error(`Caught exception while sending ping: ${d.toString()}`); } this._logger.error(c);
        };
      }; var B = b.GatewayControl = function (a, b, c, d) {
        const e = this; this._clientSettings = a, this._logger = b, this._logger.setModuleName('GatewayControl'), this._httpClient = c, this._jsonInterface = d, this.send = function (a, b) {
          const c = this._jsonInterface.encode(a); const d = this._clientSettings.gatewayUrl + r.gatewayPath; const f = function (a, c) {
            if (a) { const d = e._jsonInterface.decode(c); b(a, d); } else { const f = c; b(a, f); }
          }; this._httpClient.request('POST', d, c, 'application/json', f);
        };
      }; var C = b.Lang = {}; C.isMeaningfulString = function (a) {
        return C.isValidString(a) && a != null && a != 'undefined' && a != 'null';
      }, C.sanitizeStringToStringObject = function (a) { const b = {}; for (const c in a) if (C.isMeaningfulString(c)) { const d = a[c]; C.isMeaningfulString(d) && (b[c] = d); } return b; }, C.validateInterface = function (a, b, c) {
        if (a == null) throw new Error(`Expected ${c} implementation is null.`);
        for (const d in b) if (typeof a[d] !== 'function') throw new Error(`Expected method ${d} in ${c} implementation.`);
      }, C.dictCount = function (a) { let b; let c = 0; for (b in a)a.hasOwnProperty(b) && c++; return c; }, C.ToString = function (a) {
        return `${a}`;
      }, C.NumberToInt = function (a) { return Math.floor(a); }, C.NumberToUnsignedInt = function (a) { return Math.abs(C.NumberToInt(a)); }, C.AsBoolean = function (a) { return Boolean(a); }, C.isValidString = function (a) {
        return typeof a === 'string' && a !== '';
      }, C.stringStartsWith = function (a, b) { return typeof a !== 'string' || typeof b !== 'string' ? !1 : typeof a.indexOf === 'function' ? a.indexOf(b) === 0 : !1; }, C.isBoolean = function (a) { return typeof a === 'boolean'; }, C.UrlEncodeString = function (a) {
        return escape(a);
      }, C.isObject = function (a) { return typeof a === 'object'; }, C.ObjectToString = function (a) { let b = ''; for (const c in a) { const d = a[c]; const e = d; b += `${c}=${e}`; } return `Object{${b}}`; }, C.ObjectShallowCopyOmitNull = function (a) {
        const b = C.ObjectShallowCopy(a); for (const c in b)b[c] == null && delete b[c]; return b;
      }, C.ObjectShallowCopy = function (a) { const b = {}; for (const c in a)b[c] = a[c]; return b; }, C.ObjectShallowEquals = function (a, b) {
        if (typeof a !== typeof b) return !1;
        if (a instanceof Object && b instanceof Object) { if (C.dictCount(a) !== C.dictCount(b)) return !1; let c = !0; for (const d in a) if (c = a[d] === b[d], !c) return !1; return !0; } return a === b;
      }, C.isInteger = function (a) {
        if (typeof a !== 'number') return !1;
        const b = Math.round(a); return b === a;
      }, C.isArray = function (a) { return Object.prototype.toString.call(a) === '[object Array]'; }, C.ArrayEquals = function (a, b) {
        if (a === b) return !0; if (a == null || b == null) return !1; if (a.length != b.length) return !1;
        for (let c = 0; c < a.length; ++c) if (C.isArray(a[c]) && C.isArray(b[c])) { if (!C.ArrayEquals(a[c], b[c])) return !1; } else if (a[c] !== b[c]) return !1; return !0;
      }, C.isObjectDefinePropertyPresent = function () {
        return typeof Object.defineProperty !== 'undefined' && (function () {
          try { return Object.defineProperty({}, 'x', {}), !0; } catch (a) { return !1; }
        }());
      }, C.defGet = function (a, b, c) {
        if (C.isObjectDefinePropertyPresent())Object.defineProperty(a, b, { configurable: !0, enumerable: !0, get: c }); else {
          if (typeof a.__defineGetter__ === 'undefined') throw new Error('JavaScript runtime must support either Object.defineProperty or __defineGetter__'); a.__defineGetter__(b, c);
        }
      }, C.defSet = function (a, b, c) {
        if (C.isObjectDefinePropertyPresent()) {
          Object.defineProperty(a, b, { configurable: !0, set: c });
        } else { if (typeof a.__defineSetter__ === 'undefined') throw new Error('JavaScript runtime must support either Object.defineProperty or __defineSetter__'); a.__defineSetter__(b, c); }
      }, (function () {
        const a = {}; a.two32 = 4294967296, a.MaxValue = a.two32 - 1, a.MinValue = 0, C.UInt32 = a, C.UInt32.Cast = function (b) { let c = parseInt(b, 10); return c > a.MaxValue ? c %= a.two32 : c < a.MinValue && (c = -c % a.two32, c = a.two32 - c), c; }, C.UInt32.InRange = function (b) {
          const c = parseInt(b, 10); return c <= a.MaxValue && c >= a.MinValue;
        }; const b = {}; b.MaxValue = 2147483647, b.MinValue = -2147483648, C.Int32 = b, C.Int32.Cast = function (a) {
          let c = parseInt(a, 10); return c > b.MaxValue ? c %= b.MaxValue : c < b.MinValue && (c = -c % b.MaxValue,
          c = b.MaxValue - c), c;
        }, C.Int32.InRange = function (a) { const c = parseInt(a, 10); return c <= b.MaxValue && c >= b.MinValue; };
      }()); var D = b.LogBuffer = function () {
        const a = 32; this._buffer = [], this.add = function (b) {
          this._buffer.length >= a && this._buffer.shift(),
          this._buffer.push(b);
        }, this.flush = function () { const a = this._buffer; return this._buffer = [], a; };
      }; var E = b.Ping = function (a, b, d) {
        this._isSendingPing = !1, this._cachedBasePingUrl = null, this._logger = a, this._logger.setModuleName('Ping'),
        this._httpClient = b, this._clientSettings = d, this.send = function (a) {
          if (!this._isSendingPing) {
            this._isSendingPing = !0; const b = `${this.getBasePingUrl()}&d=${C.UrlEncodeString(a.toString())}`; this._logger.error(`send(): ${b}`),
            this._httpClient.request('GET', b, null, null, null), this._isSendingPing = !1;
          }
        }, this.getBasePingUrl = function () {
          if (!this._cachedBasePingUrl) {
            let a = `${E.SERVICE_URL}?comp=${E.COMPONENT_NAME}&clv=${c.version}`; if (this._clientSettings && (a += `&cid=${this._clientSettings.customerKey}`),
            a += `&sch=${r.SDK_METADATA_SCHEMA}`, !this._clientSettings) return a; this._cachedBasePingUrl = a;
          } return this._cachedBasePingUrl;
        };
      }; E.COMPONENT_NAME = 'sdkjs', E.SERVICE_URL = 'https://pings.conviva.com/ping.ping'; var F = b.Random = {};
      F.maxUInt32 = 4294967295, F.maxInt32 = 2147483647, F.minInt32 = -2147483648, F.integer32 = function () { return Math.floor(Math.random() * F.maxUInt32) + F.minInt32; }, F.uinteger32 = function () {
        return Math.floor(Math.random() * F.maxUInt32);
      }; var G = b.Sanitize = {}; G.Integer = function (a, b, c, d) { if (isNaN(a)) return d; if (typeof a !== 'number') return d; if (a == d) return d; const e = C.NumberToInt(a); return G.EnforceBoundaries(e, b, c); }, G.EnforceBoundaries = function (a, b, c) {
        return c != null && a > c ? a = c : b != null && b > a && (a = b), a;
      }, G.sanitizeSpecialIntegerValue = function (a) { return a == -1 || a == -2 ? a : a = G.Integer(a, 0, null, -1); }; var H = (b.StreamerError = function () {
        function a(a, c) {
          b.errorCode = a, b.severity = c;
        } var b = this; b.errorCode = null, b.severity = c.ErrorSeverity.FATAL, a.apply(b, arguments);
      }, b.HttpClient = function (a, b, c, d) {
        this._logger = a, this._logger.setModuleName('HttpClient'), this._httpInterface = b, this._callbackWithTimeout = c,
        this._systemSettings = d, this.request = function (a, b, c, d, e) {
          const f = 1e3 * this._systemSettings.httpTimeout; const g = this._callbackWithTimeout.getWrapperCallback(e, f, 'HTTP timeout'); this._logger.debug('request(): calling HttpInterface.makeRequest'),
          this._httpInterface.makeRequest(a, b, c, d, f, g);
        };
      }); var I = b.Logger = function (a, b, c, d, e) {
        this._loggingInterface = a, this._timeInterface = b, this._settings = c, this._logBuffer = d, this._packageName = e, this.debug = function (a) {
          this.log(a, g.LogLevel.DEBUG);
        }, this.info = function (a) { this.log(a, g.LogLevel.INFO); }, this.warning = function (a) { this.log(a, g.LogLevel.WARNING); }, this.error = function (a) { this.log(a, g.LogLevel.ERROR); }, this.log = function (a, b) {
          const c = this.formatMessage(a, b); this._logBuffer.add(c), this._settings.logLevel <= b && this._loggingInterface.consoleLog(c, b);
        }, this.prependModuleName = function (a) { let b = a; return b = `[${this._moduleName}] ${b}`; },
        this.prependPackageName = function (a) { let b = a; return b = `[${this._packageName}] ${b}`; }, this.prependConvivaNamespace = function (a) { let b = a; return b = `[Conviva] ${b}`; }, this.prependTime = function (a) {
          const b = this._timeInterface.getEpochTimeMs(); const c = (b / 1e3).toFixed(3).toString();
          return `[${c}] ${a}`;
        }, this.prependLogLevel = function (a, b) { const c = I.getLogLevelString(b); let d = a; return d = `[${c}] ${d}`; }, this.prependSessionId = function (a) {
          let b = a; return this._sessionId != null && (b = `sid=${this._sessionId} ${b}`),
          b;
        }, this.setSessionId = function (a) { this._sessionId = a; }, this.setModuleName = function (a) { this._moduleName = a; }, this.formatMessage = function (a, b) {
          return this.prependConvivaNamespace(this.prependTime(this.prependLogLevel(this.prependPackageName(this.prependModuleName(this.prependSessionId(a))), b)));
        };
      }; I.getLogLevelString = function (a) {
        let b; switch (a) { case g.LogLevel.ERROR: b = 'ERROR'; break; case g.LogLevel.WARNING: b = 'WARNING'; break; case g.LogLevel.INFO: b = 'INFO'; break; case g.LogLevel.DEBUG: b = 'DEBUG'; } return b;
      }; var J = b.Storage = function (a, b, c, d) {
        this._logger = a, this._logger.setModuleName('Storage'), this._storageInterface = b, this._callbackWithTimeout = c, this._systemSettings = d, this.load = function (a, b) {
          const c = this._callbackWithTimeout.getWrapperCallback(b, 1e3 * this._systemSettings.storageTimeout, 'storage load timeout');
          this._logger.debug('load(): calling StorageInterface.loadData'), this._storageInterface.loadData(J.STORAGE_SPACE, a, c);
        }, this.save = function (a, b, c) {
          const d = this._callbackWithTimeout.getWrapperCallback(c, 1e3 * this._systemSettings.storageTimeout, 'storage save timeout');
          this._logger.debug('save(): calling StorageInterface.saveData'), this._storageInterface.saveData(J.STORAGE_SPACE, a, b, d);
        };
      }; J.STORAGE_SPACE = 'Conviva'; var K = b.SystemMetadata = function (b, c, d) {
        this._logger = b,
        this._logger.setModuleName('SystemMetadata'), this._metadataInterface = c, this._exceptionCatcher = d, this._cachedMetadata = null, this.get = function () {
          return this._cachedMetadata || this.retrieve(), this._cachedMetadata;
        }, this.retrieve = function () {
          function b(a, b) { d._exceptionCatcher.runProtected(`Session.getSystemMetadataSchema(): ${a}`, () => { const c = b[a]; b[a] = c.call(d._metadataInterface); }); } const c = {}; c[K.BROWSER_NAME] = this._metadataInterface.getBrowserName,
          c[K.BROWSER_VERSION] = this._metadataInterface.getBrowserVersion, c[K.DEVICE_BRAND] = this._metadataInterface.getDeviceBrand, c[K.DEVICE_MANUFACTURER] = this._metadataInterface.getDeviceManufacturer, c[K.DEVICE_MODEL] = this._metadataInterface.getDeviceModel,
          c[K.DEVICE_TYPE] = this._metadataInterface.getDeviceType, c[K.DEVICE_VERSION] = this._metadataInterface.getDeviceVersion, c[K.FRAMEWORK_NAME] = this._metadataInterface.getFrameworkName, c[K.FRAMEWORK_VERSION] = this._metadataInterface.getFrameworkVersion,
          c[K.OPERATING_SYSTEM_NAME] = this._metadataInterface.getOperatingSystemName, c[K.OPERATING_SYSTEM_VERSION] = this._metadataInterface.getOperatingSystemVersion, c[K.DEVICE_CATEGORY] = this._metadataInterface.getDeviceCategory;
          var d = this; this._logger.debug('retrieve(): calling MetadataInterface methods'); for (const e in c)b(e, c), C.isValidString(c[e]) || delete c[e]; let f = !1; if (typeof c[K.DEVICE_TYPE] !== 'undefined') {
            for (const g in a.Client.DeviceType) {
              if (c[K.DEVICE_TYPE] === a.Client.DeviceType[g]) {
                f = !0; break;
              }
            }f || delete c[K.DEVICE_TYPE];
          } if (f = !1, typeof c[K.DEVICE_CATEGORY] !== 'undefined') {
            for (const h in a.Client.DeviceCategory) if (c[K.DEVICE_CATEGORY] === a.Client.DeviceCategory[h]) { f = !0; break; }f || delete c[K.DEVICE_CATEGORY];
          } this._cachedMetadata = C.ObjectShallowCopyOmitNull(c);
        };
      }; K.BROWSER_NAME = 'browserName', K.BROWSER_VERSION = 'browserVersion', K.DEVICE_BRAND = 'deviceBrand', K.DEVICE_MANUFACTURER = 'deviceManufacturer', K.DEVICE_MODEL = 'deviceModel',
      K.DEVICE_TYPE = 'deviceType', K.DEVICE_VERSION = 'deviceVersion', K.FRAMEWORK_NAME = 'frameworkName', K.FRAMEWORK_VERSION = 'frameworkVersion', K.OPERATING_SYSTEM_NAME = 'operatingSystemName', K.OPERATING_SYSTEM_VERSION = 'operatingSystemVersion',
      K.DEVICE_CATEGORY = 'deviceCategory'; var L = b.Time = function (a, b) {
        this._timeInterface = a, this._logger = b, this._logger.setModuleName('Time'), this.current = function () {
          const a = this._timeInterface.getEpochTimeMs(); return C.isInteger(a) || this._logger.error(`current(): TimeInterface.getEpochTimeMs() did not return an integer (${a})`),
          a;
        };
      }; var M = b.Timer = function (a, b, c) {
        const d = this; this._logger = a, this._logger.setModuleName('Timer'), this._timerInterface = b, this._exceptionCatcher = c, this.create = function (a, b, c) {
          const e = function () {
            d._exceptionCatcher.runProtected(c, () => {
              a();
            });
          }; const f = this.createTimer(e, b, c); return f;
        }, this.createOnce = function (a, b, c) {
          let e = { cancel: null }; const f = function () {
            d._exceptionCatcher.runProtected(c, () => {
              e && typeof e.cancel === 'function' && (e.cancel(), e.cancel = null,
              e = null), a();
            });
          }; const g = this.createTimer(f, b, c); return e.cancel = g, g;
        }, this.createTimer = function (a, b, c) {
          return this._logger.debug('createTimer(): calling TimerInterface.create'), this._timerInterface.createTimer(a, b, c);
        };
      };
    }());
  }()), a;
}));

}.call(window));
