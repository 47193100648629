import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

import Logger from '@@utils/logger/Logger';

import type VideoPlayer from '../../VideoPlayer';
import { VideoPlayerEventCallback, VideoPlayerEventType } from '../../VideoPlayerEventManager';

interface ShowAtPlayTimeProps {
  videoPlayer: VideoPlayer;

  /**
   * The content time, in seconds after which the element will be shown.
   */
  startTime: number;

  /**
   * The content time, in seconds after which the element will not be shown.
   */
  endTime: number;

  /**
   * The minimum duration, in seconds before the end time to show the content.
   */
  minDurationBeforeEndTime?: number;

  /**
   * The function to be executed when the show event is triggered.
   */
  onShow?(): void;

  /**
   * The time, in seconds that the button would auto hide after it is displayed.
   */
  autoHideDelay?: number;

  /**
   * The time, in seconds that the button would auto hide after the bitmovin ui is hidden.
   */
  autoHideAfterUiHideDelay?: number;

  /**
   * If true, hide the component when the player is paused
   */
  hideOnPause?: boolean;
}

/**
 * Generic component which will show the children element between start time and end time.
 * There are optional props to auto hide after it's shown or after the bitmovin ui is hidden.
 * @param props
 * @constructor
 */
const ShowAtPlayTime: FunctionComponent<ShowAtPlayTimeProps> = (props) => {
  const {
    videoPlayer,
    children,
    startTime,
    endTime,
    onShow,
    autoHideDelay,
    autoHideAfterUiHideDelay = 0,
    minDurationBeforeEndTime,
    hideOnPause = false,
  } = props;

  // show the skip intro button
  const [show, setShow] = useState<boolean>(false);

  // when autohide is set to true, the element will not be shown until it's set back to false
  const [autoHide, setAutoHide] = useState<boolean>(false);

  const autoHideTimeout = useRef<number>();
  const autoHideAfterUiHideTimeout = useRef<number>();

  const checkShouldShow = useCallback((event) => {
    // dont need to check anything if auto hide is true
    if (autoHide) {
      return;
    }

    const { time } = event;
    const startStreamTime = videoPlayer.streamTimeForContentTime(startTime);
    const endStreamTime = videoPlayer.streamTimeForContentTime(endTime);
    const timeLeftToEndTime = endStreamTime - time;

    let haveEnoughTimeToShow = true;
    if (!show && minDurationBeforeEndTime) {
      haveEnoughTimeToShow = minDurationBeforeEndTime <= timeLeftToEndTime;
    }

    const shouldShow = time >= startStreamTime
      && (!endTime || time <= endStreamTime)
      && haveEnoughTimeToShow;

    if (shouldShow && !show) {
      setShow(true);
    } else if (!shouldShow && show) {
      setShow(false);
    }
  }, [autoHide, videoPlayer, endTime, minDurationBeforeEndTime, show, startTime]);

  // When playing, check if it should show the element
  useEffect(() => {
    const handlePaused = () => {
      setShow(false);
    };

    videoPlayer.on(VideoPlayerEventType.TIME_CHANGED, checkShouldShow as VideoPlayerEventCallback);
    if (hideOnPause === true) {
      videoPlayer.on(VideoPlayerEventType.PAUSED, handlePaused as VideoPlayerEventCallback);
    }

    return () => {
      videoPlayer.off(VideoPlayerEventType.TIME_CHANGED, checkShouldShow as VideoPlayerEventCallback);
      if (hideOnPause === true) {
        videoPlayer.off(VideoPlayerEventType.PAUSED, handlePaused as VideoPlayerEventCallback);
      }
    };
  }, [videoPlayer, checkShouldShow, hideOnPause]);

  // when the show is set to true, create a timer to auto hide the element after a delay
  useEffect(() => {
    if (show) {
      if (onShow) {
        onShow();
      }

      if (autoHideDelay) {
        autoHideTimeout.current = setTimeout(() => {
          Logger.debug(`deb Auto hide element ${autoHideDelay}s after it's displayed.`);
          setShow(false);
          setAutoHide(true);
        }, autoHideDelay * 1000);
      }
    }

    return () => {
      if (autoHideDelay && autoHideTimeout.current) {
        clearTimeout(autoHideTimeout.current);
      }
    };
  }, [autoHideDelay, onShow, show]);

  // when the ui control is hidden, create a timer to hide the element after a delay
  useEffect(() => {
    const autoHideWhenControlIsHidden = () => {
      autoHideAfterUiHideTimeout.current = setTimeout(() => {
        Logger.debug(`Auto hide element ${autoHideAfterUiHideDelay}s after ui is hidden.`);

        setShow(false);
        setAutoHide(true);
      }, autoHideAfterUiHideDelay * 1000);
    };

    if (autoHideAfterUiHideDelay) {
      videoPlayer.on(VideoPlayerEventType.UI_CONTROLS_HIDE, autoHideWhenControlIsHidden as VideoPlayerEventCallback);
    }

    return () => {
      videoPlayer.off(VideoPlayerEventType.UI_CONTROLS_HIDE, autoHideWhenControlIsHidden as VideoPlayerEventCallback);
      clearTimeout(autoHideAfterUiHideTimeout.current);
    };
  }, [autoHideAfterUiHideDelay, videoPlayer]);

  // when the ui control is shown, cancel any timer created to hide the element after a delay
  useEffect(() => {
    function cancelAutoHideWhenControlIsHidden() {
      Logger.debug(`Cancel Auto hide element ${autoHideAfterUiHideDelay}s after ui is shown.`);
      clearTimeout(autoHideAfterUiHideTimeout.current);
      setAutoHide(false);
    }

    if (autoHideAfterUiHideDelay) {
      videoPlayer.on(VideoPlayerEventType.UI_CONTROLS_SHOW, cancelAutoHideWhenControlIsHidden as VideoPlayerEventCallback);
    }

    return () => {
      videoPlayer.off(VideoPlayerEventType.UI_CONTROLS_SHOW, cancelAutoHideWhenControlIsHidden as VideoPlayerEventCallback);
      clearTimeout(autoHideAfterUiHideTimeout.current);
    };
  }, [autoHideAfterUiHideDelay, videoPlayer]);

  // When 'Play' ie: from resume, clear auto hide
  useEffect(() => {
    const clearAutoHide = () => {
      setAutoHide(false);
    };

    videoPlayer.on(VideoPlayerEventType.PLAY, clearAutoHide as VideoPlayerEventCallback);
    return () => {
      videoPlayer.off(VideoPlayerEventType.PLAY, clearAutoHide as VideoPlayerEventCallback);
    };
  }, [videoPlayer]);

  if (!show) {
    return null;
  }

  return <>{children}</>;
};

export default ShowAtPlayTime;
