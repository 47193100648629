import { Grid, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PlayIcon from '@@icons/Play';
import Button from '@@src/components/Inputs/Button';
import TimedButton from '@@src/components/Inputs/TimedButton';

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      bottom: 240,
      right: 38,
      [theme.breakpoints.down('sm')]: {
        bottom: 120,
        right: 18,
      },
    },
  });
});

export interface AutoPlayNextEpisodeButtonsProps {
  onActionTimeout?(): void;
  onActionClick?(): void;
  onCancelClick?(): void;
  nextEpisodeId: string;
  delay: number;
}

const AutoplayNextEpisodeButtons: FunctionComponent<AutoPlayNextEpisodeButtonsProps> = (props) => {
  const {
    onActionTimeout,
    onActionClick,
    onCancelClick,
    nextEpisodeId,
    delay,
  } = props;

  const classes = useStyles(props);
  const { t } = useTranslation('common');

  const theme = useTheme();
  const mediaSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  // when user click 'keep watching', we'll hide the buttons forever
  const [cancelled, setCancelled] = useState(false);

  useEffect(() => {
    // reset cancel state if the next episode path has changed
    setCancelled(false);
  }, [nextEpisodeId]);

  const dispatchVideoChange = useCallback(() => {
    const event = new CustomEvent('OdPlayerVideoChange', {
      detail: {
        videoId: nextEpisodeId,
        allowResume: false,
      },
    });
    document.dispatchEvent(event);
  }, [nextEpisodeId]);

  const handleActionClick = useCallback(() => {
    dispatchVideoChange();

    if (onActionClick) {
      onActionClick();
    }
    setCancelled(true);
  }, [dispatchVideoChange, onActionClick]);

  const handleActionTimeout = useCallback(() => {
    dispatchVideoChange();

    if (onActionTimeout) {
      onActionTimeout();
    }
  }, [dispatchVideoChange, onActionTimeout]);

  const handleCancelClick = useCallback(() => {
    setCancelled(true);

    if (onCancelClick) {
      onCancelClick();
    }
  }, [onCancelClick]);

  if (cancelled) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={1} alignItems="stretch">
        <Grid item>
          <TimedButton
            fullWidth
            buttonType="primary"
            size={mediaSmDown ? 'small' : 'large'}
            onTimeout={handleActionTimeout}
            delay={delay}
            onClick={handleActionClick}
            startIcon={<PlayIcon/>}
          >
            {t('videoPlayer.playNextEpisode')}
          </TimedButton>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            buttonType="secondary"
            size={mediaSmDown ? 'small' : 'large'}
            onClick={handleCancelClick}
          >
            {t('videoPlayer.keepWatching')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AutoplayNextEpisodeButtons;
