import { Button, PlayerUtils, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { ButtonConfig } from '@sbs/bitmovin-player-ui/src/ts/components/button';
import { PlayerAPI, PlayerEvent } from 'bitmovin-player';

class FastForwardButton extends Button<ButtonConfig> {
  private pausedTimeshiftUpdater;

  constructor(config: ButtonConfig = {}) {
    super(config);

    this.config = this.mergeConfig(config, {
      ariaLabel: 'Fast Forward',
      cssClass: 'ui-fastforwardbutton',
    } as ButtonConfig, this.config);
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);

    const checkTimeShift = () => {
      if (!player.isLive()) {
        return;
      }

      const buttonElement = this.getDomElement();
      const isAtLivePosition = player.isLive() && player.getTimeShift() === 0;

      if (!PlayerUtils.isTimeShiftAvailable(player) || isAtLivePosition) {
        buttonElement.addClass('disabled');
      } else {
        buttonElement.removeClass('disabled');
      }
    };

    player.on(PlayerEvent.Paused, () => {
      if (player.isLive() && player.getMaxTimeShift() < 0) {
        this.pausedTimeshiftUpdater = setInterval(checkTimeShift, 1000);
      }
    });
    player.on(PlayerEvent.Play, () => {
      clearInterval(this.pausedTimeshiftUpdater);
      checkTimeShift();
    });
    player.on(PlayerEvent.Ready, checkTimeShift);
    player.on(PlayerEvent.TimeShifted, checkTimeShift);
  }
}

export default FastForwardButton;
