import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';

import Button, { ButtonProps } from './Button';

const useStyles = makeStyles<Theme, TimedButtonProps>(() => {
  return {
    root: {
      backgroundSize: '200% 100%',
      backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.25) 50%)',
      backgroundPosition: '0 0',
      '&.active': {
        backgroundPosition: '-100% 0',
        transition({ delay }) {
          return `background-position ${delay}s linear`;
        },
      },
      '&.clicked': {
        backgroundPosition: '-100% 0',
      },
    },
  };
});

// ignoreFunctionalComponents does not work for forwardRef https://github.com/yannickcr/eslint-plugin-react/issues/2856
/* eslint-disable react/require-default-props */
export interface TimedButtonProps extends ButtonProps {
  /** The function to be executed when the timer expires. */
  onTimeout(): void;
  /** The time, in seconds that the timer should wait before the onTimeout function is executed */
  delay?: number;
}
/* eslint-enable react/require-default-props */

/**
 * Button that has a timer. The `onTimeout` function will be executed when the timer expires.
 * If the button is clicked, the timer will be cancelled and the `onTimeout` will not be executed.
 * Any other props supplied will be provided to the root element (Button).
 */
const TimedButton = forwardRef<HTMLButtonElement, TimedButtonProps>((props, ref) => {
  const {
    delay = 10,
    onTimeout,
    onClick,
    ...other
  } = props;

  const classes = useStyles({ ...props, delay });

  const [active, setActive] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);

  const history = useHistory();

  const handleTimeout = useCallback(() => {
    // if 'to' is set and is a string, go to that path
    if ('to' in other && typeof other.to === 'string' && other.to) {
      history.push(other.to);
    }

    if (onTimeout) {
      onTimeout();
    }
  }, [history, onTimeout, other]);

  const handleClick = useCallback((e) => {
    setActive(false);
    setClicked(true);
    if (onClick) {
      onClick(e);
    }
  }, [onClick]);

  // reset the states if delay changes
  useEffect(() => {
    // set the active state asynchronously so that the css class would be set in the next tick
    const timeoutHandler = setTimeout(() => {
      setActive(false);
      setClicked(false);
    });

    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
  }, [delay]);

  useEffect(() => {
    let timeoutHandler;

    if (!active) {
      // set the active state asynchronously so that the css class would be set in the next tick
      timeoutHandler = setTimeout(() => {
        setActive(true);
      });
    }

    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
  }, [active]);

  useEffect(() => {
    let timeoutHandler;

    if (!clicked) {
      // call the onTimeout after delay
      timeoutHandler = setTimeout(handleTimeout, delay * 1000);
    }

    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
  }, [delay, handleTimeout, clicked]);

  return (
    <Button
      ref={ref}
      className={clsx(classes.root, { active, clicked })}
      onClick={handleClick}
      {...other}
    />
  );
});

export default TimedButton;
