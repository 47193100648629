import * as ls from 'local-storage';
import { pick } from 'lodash';
import { FunctionComponent, useEffect, useCallback } from 'react';

import { SubtitleSwitchHandler } from '../../BitmovinPlayerUI/utils/subtitleutils';
import {
  VideoEventHandler,
  VideoPlayerEventCallback,
  VideoPlayerEventType,
} from '../../VideoPlayerEventManager';
import { VideoPlayerPluginProps } from '../VideoPlayerPlugin';

export interface UserPreferences {
  volume: number;
  muted: boolean;
  stLang: string;
  audioLanguage: string;
  audioLabel: string;
}

const defaultPreferences = {
  volume: 75,
  muted: false,
  stLang: '',
  audioLanguage: '',
  audioLabel: '',
};

const lsKey = 'od.player.userPrefs';

export function getUserPreferences(): UserPreferences {
  ls.backend(localStorage);
  return pick({
    ...defaultPreferences,
    ...ls.get<UserPreferences>(lsKey),
  }, ['volume', 'muted', 'stLang', 'audioLanguage', 'audioLabel']);
}

export function saveUserPreference<T extends keyof UserPreferences>(key: T, value: UserPreferences[T]) {
  const currentPreferences = getUserPreferences();
  ls.backend(localStorage);
  ls.set<UserPreferences>(lsKey, {
    ...currentPreferences,
    [key]: value,
  });
}

const SaveUserPreferences: FunctionComponent<VideoPlayerPluginProps> = (props) => {
  const { videoPlayer } = props;

  const onVolumeChanged = useCallback<VideoEventHandler<VideoPlayerEventType.VOLUME_CHANGED>>((event) => {
    const { targetVolume } = event;
    saveUserPreference('volume', targetVolume);
  }, []);

  const onMuted = useCallback(() => {
    saveUserPreference('muted', true);
  }, []);

  const onUnmuted = useCallback(() => {
    saveUserPreference('muted', false);
  }, []);

  const onSubtitleEnable = useCallback<VideoEventHandler<VideoPlayerEventType.SUBTITLE_ENABLE>>((event) => {
    if (event.subtitle.label === SubtitleSwitchHandler.FORCED_SUBTITLES_LABEL) {
      saveUserPreference('stLang', '');
    } else {
      saveUserPreference('stLang', event.subtitle.lang);
    }
  }, []);

  const onSubtitleDisable = useCallback(() => {
    saveUserPreference('stLang', '');
  }, []);

  const onAudioChanged = useCallback<VideoEventHandler<VideoPlayerEventType.AUDIO_CHANGED>>((event) => {
    const { sourceAudio, targetAudio } = event;

    // Not saving the current audio track if it's the initial audio changed event as this would come from Bitmovin loading
    // the default audio track.
    if (sourceAudio !== null && targetAudio) {
      saveUserPreference('audioLanguage', targetAudio.lang);
      saveUserPreference('audioLabel', targetAudio.label);
    }
  }, []);

  const onReady = useCallback<VideoEventHandler<VideoPlayerEventType.READY>>(() => {
    const userPrefs = getUserPreferences();

    /** Loading preferred subtitles */
    if (userPrefs?.stLang) {
      const matchedSubtitle = videoPlayer.getAvailableSubtitles().filter((subtitle) => {
        return subtitle.lang === userPrefs.stLang;
      }).pop();

      if (matchedSubtitle) {
        videoPlayer.enableSubtitles(matchedSubtitle.id);
      }
    }

    /** Loading preferred audio track */
    let matchedAudioTrack;

    // Match by label
    if (userPrefs?.audioLabel) {
      matchedAudioTrack = videoPlayer.getAvailableAudio().filter((audioTrack) => {
        return userPrefs.audioLabel === audioTrack.label;
      }).pop();
    }

    // Fallback to matching by language code
    if (!matchedAudioTrack && userPrefs?.audioLanguage) {
      matchedAudioTrack = videoPlayer.getAvailableAudio().filter((audioTrack) => {
        return userPrefs.audioLanguage === audioTrack.lang;
      }).shift();
    }

    if (matchedAudioTrack) {
      videoPlayer.setAudio(matchedAudioTrack.id);
    }
  }, [videoPlayer]);

  useEffect(() => {
    videoPlayer.on(VideoPlayerEventType.VOLUME_CHANGED, onVolumeChanged as VideoPlayerEventCallback);
    videoPlayer.on(VideoPlayerEventType.MUTED, onMuted);
    videoPlayer.on(VideoPlayerEventType.UNMUTED, onUnmuted);
    videoPlayer.on(VideoPlayerEventType.SUBTITLE_ENABLE, onSubtitleEnable as VideoPlayerEventCallback);
    videoPlayer.on(VideoPlayerEventType.SUBTITLE_DISABLE, onSubtitleDisable);
    videoPlayer.on(VideoPlayerEventType.AUDIO_CHANGED, onAudioChanged as VideoPlayerEventCallback);
    videoPlayer.on(VideoPlayerEventType.READY, onReady as VideoPlayerEventCallback);

    return () => {
      videoPlayer.off(VideoPlayerEventType.READY, onReady as VideoPlayerEventCallback);
      videoPlayer.off(VideoPlayerEventType.VOLUME_CHANGED, onVolumeChanged as VideoPlayerEventCallback);
      videoPlayer.off(VideoPlayerEventType.MUTED, onMuted);
      videoPlayer.off(VideoPlayerEventType.UNMUTED, onUnmuted);
      videoPlayer.off(VideoPlayerEventType.SUBTITLE_ENABLE, onSubtitleEnable as VideoPlayerEventCallback);
      videoPlayer.off(VideoPlayerEventType.SUBTITLE_DISABLE, onSubtitleDisable);
      videoPlayer.off(VideoPlayerEventType.AUDIO_CHANGED, onAudioChanged as VideoPlayerEventCallback);
    };
  }, [videoPlayer, onVolumeChanged, onMuted, onUnmuted, onSubtitleEnable, onSubtitleDisable, onAudioChanged, onReady]);

  // This plugin does not have a UI
  return null;
};

export default SaveUserPreferences;
