import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { useAppDispatch } from '@@src/hooks/store';
import VideoPlayer from '@@src/lib/VideoPlayerV2/VideoPlayer';
import { setProgress } from '@@stores/ProgressStore';
import OnDemand from '@@types/OnDemand';
import { PlaybackStreamData } from '@@types/PlaybackStreamData';
import DataLayer from '@@utils/DataLayer';

import { VideoPlayerEventType } from '../../../VideoPlayerEventManager';
import NextEpisodeCard from './NextEpisodeCard';

interface PlaybackNextEpisodeEndCardProps {
  videoPlayer: VideoPlayer
  nextEpisode: OnDemand.SlimEpisode;
  playbackStreamData: PlaybackStreamData
}

const PlaybackNextEpisodeEndCard: FunctionComponent<PlaybackNextEpisodeEndCardProps> = (props) => {
  const {
    videoPlayer,
    nextEpisode,
    playbackStreamData,
  } = props;

  const [show, setShow] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const showCard = useCallback(() => {
    setShow(true);
    DataLayer.events.playNextEpisode(
      'promoCardDisplay',
      { ...nextEpisode, entityType: playbackStreamData.entityType } as OnDemand.Episode,
      videoPlayer.getPlayerMetadata(),
      {
        videoNavigationEvent: {
          playNextEpisode: 'promoCardDisplay',
        },
      },
    );
  }, [nextEpisode, playbackStreamData.entityType, videoPlayer]);

  useEffect(() => {
    videoPlayer.on(VideoPlayerEventType.PLAYBACK_FINISHED, showCard);

    return () => {
      videoPlayer.off(VideoPlayerEventType.PLAYBACK_FINISHED, showCard);
    };
  }, [showCard, videoPlayer]);

  // reset progress so that the video is played from the beginning
  const resetProgress = useCallback((videoId) => {
    dispatch(setProgress({
      videoId,
      seconds: 0,
      percent: 0,
    }));
  }, [dispatch]);

  const handleClickPlay = useCallback(() => {
    resetProgress(nextEpisode.id);

    setShow(false);

    DataLayer.events.playNextEpisode(
      'promoCardTap',
      { ...nextEpisode, entityType: playbackStreamData.entityType } as OnDemand.Episode,
      videoPlayer.getPlayerMetadata(),
      {
        videoNavigationEvent: {
          playNextEpisode: 'promoCardTap',
        },
      },
    );
  }, [nextEpisode, playbackStreamData.entityType, resetProgress, videoPlayer]);

  if (!show) {
    return null;
  }

  return (
    <NextEpisodeCard episode={nextEpisode} onClickPlay={handleClickPlay}/>
  );
};

export default PlaybackNextEpisodeEndCard;
