import { get } from 'lodash';
import { isSafari } from 'react-device-detect';

import { OzTAMServiceConstructor } from '@@src/lib/VideoPlayerV2/plugins/OztamTracking/OztamTracking';
import Logger from '@@utils/logger/Logger';

import VideoStream from '../../@types/VideoStream';
import BitmovinClient from './BitmovinClient';
import PlayerEvents from './PlayerEvents';

declare global {
  interface Window {
    OzTAMService: OzTAMServiceConstructor;
  }
}

interface OztamTrackingOptions extends VideoStream.OztamMetadata {
  userId?: string;
  publisherId: string;
  vendorVersion: string;
  streamMetadata: VideoStream.DaiStream;
  videoStreamType: string;
  environment?: string;
}

class VideoOztamTracking {
  public OzTAMServiceInstance: any = null;
  public eventInitialized: boolean = false;

  private options: OztamTrackingOptions = null;
  private bitmovinClientInstance: BitmovinClient;
  private playerEvents: PlayerEvents;
  private streamMetadata: any;
  private currentTimeOverride: number = null;
  private contentHasStarted: boolean = false;
  private isLive: boolean = false;
  private playbackStartTimestamp: number = 0;
  private adPodStartTime: number = null;
  private castInitPosition: number = null;
  private castInitTime: number = null;
  private castPendingTime: number = null;
  private isCasting: boolean = false;
  private isSnapBack: boolean = false;

  public init(
    bitmovinClientInstance: BitmovinClient,
    options: OztamTrackingOptions,
  ) {
    this.streamMetadata = options.streamMetadata;
    this.bitmovinClientInstance = bitmovinClientInstance;
    this.options = {
      ...options,
    };

    this.isCasting = false;
    this.castInitPosition = null;
    this.castInitTime = null;
    this.castPendingTime = null;
    this.adPodStartTime = null;
    this.playbackStartTimestamp = null;
    this.contentHasStarted = false;
    this.currentTimeOverride = null;

    const env = get(options, 'environment', 'test');

    if (typeof window !== 'undefined' && !this.OzTAMServiceInstance) {
      this.OzTAMServiceInstance = new window.OzTAMService(
        options.publisherId,
        options.vendorVersion,
        env === 'prod', // Is production instance?
        env !== 'prod', // Enable debug?
        true, // Use HTTPS?
      );
    }

    this.isLive = this.streamMetadata.videoStreamType === 'live';
    this.OzTAMServiceInstance.generateNextSessionID();
  }

  public initPlayerEvents() {
    this.playerEvents = this.bitmovinClientInstance.playerEvents;

    this.playerEvents.on('SourceLoaded', this.onSourceLoaded);
    this.playerEvents.on('ContentStarted', this.onContentStarted);
    this.playerEvents.on('AdBreakStarted', this.onAdBreakStarted);
    this.playerEvents.on('AdStarted', this.onAdStarted);
    this.playerEvents.on('AdFinished', this.onAdFinished);
    this.playerEvents.on('AdBreakFinished', this.onAdBreakFinished);
    this.playerEvents.on('PlayFinished', this.onPlayFinished);
    this.playerEvents.on('Paused', this.onPaused);
    this.playerEvents.on('Playing', this.onPlaying);
    this.playerEvents.on('BufferingStarted', this.onBufferingStarted);
    this.playerEvents.on('BufferingFinished', this.onBufferingFinished);
    this.playerEvents.on('SeekStarted', this.onSeekStarted);
    this.playerEvents.on('SeekFinished', this.onSeekFinished);
    this.playerEvents.on('CastInit', this.onCastInit);
    this.playerEvents.on('CastPending', this.onCastPending);
    this.playerEvents.on('CastStarted', this.onCastStarted);
    this.playerEvents.on('CastFinished', this.onCastFinished);
    this.playerEvents.on('SnapBackStarted', this.onSnapBackStarted);
    this.playerEvents.on('SnapBackFinished', this.onSnapBackFinished);

    this.eventInitialized = true;
  }

  public getSessionId() {
    return this.OzTAMServiceInstance.sessionId || this.OzTAMServiceInstance.nextSessionId;
  }

  public unregisterEvents() {
    if (this.playerEvents) {
      this.playerEvents.off('SourceLoaded', this.onSourceLoaded);
      this.playerEvents.off('ContentStarted', this.onContentStarted);
      this.playerEvents.off('AdBreakStarted', this.onAdBreakStarted);
      this.playerEvents.off('AdStarted', this.onAdStarted);
      this.playerEvents.off('AdFinished', this.onAdFinished);
      this.playerEvents.off('AdBreakFinished', this.onAdBreakFinished);
      this.playerEvents.off('PlayFinished', this.onPlayFinished);
      this.playerEvents.off('Paused', this.onPaused);
      this.playerEvents.off('BufferingStarted', this.onBufferingStarted);
      this.playerEvents.off('BufferingFinished', this.onBufferingFinished);
      this.playerEvents.off('SeekStarted', this.onSeekStarted);
      this.playerEvents.off('SeekFinished', this.onSeekFinished);
      this.playerEvents.off('CastInit', this.onCastInit);
      this.playerEvents.off('CastPending', this.onCastPending);
      this.playerEvents.off('CastFinished', this.onCastFinished);
    }
  }

  public destroy() {
    Logger.info('Destroying VideoOztamTracking plugin');
    if (this.OzTAMServiceInstance) {
      this.OzTAMServiceInstance.haltProgress();
      this.OzTAMServiceInstance.stop();
    }

    this.options = null;
    this.streamMetadata = null;
    this.currentTimeOverride = null;
    this.contentHasStarted = false;

    this.unregisterEvents();
  }

  public unload = () => {
    this.OzTAMServiceInstance.stop();
    this.OzTAMServiceInstance.sessionId = null;
    this.OzTAMServiceInstance.generateNextSessionID();

    this.castPendingTime = null;
    this.castInitPosition = null;
    this.castInitTime = null;
    this.isCasting = false;
  };

  private getCurrentTime = () => {
    let streamTime;
    let currentTime;

    // Workaround for sending partial progress upon starting a cast session
    // When the user selects a cast device on the browser, Bitmovin pauses the video
    // and the current position is reset to 0 and will update to the current position on the cast device.
    // This causes Oztam SDK to not be able to calculate the proper partial progress and it will see it as invalid.
    // The workaround here was to record the video position and clock time when the user has clicked on the cast icon (playback still going),
    // then record the clock time when the cast is in pending mode (waiting for the device to respond, and playback is paused).
    // When the video is paused, the Oztam SDK will send a partial progress where we here below override the current time.
    if (this.castInitPosition && this.castInitTime && this.castPendingTime) {
      return this.castInitPosition + (this.castPendingTime - this.castInitTime);
    }

    // If it's an ad event, lets use the current stream time of the first ad event in the series
    // the next progress event will work out the rest and catchup when main content resumes.
    // If an ad snapback is occurring, freeze time and use currentTimeOverride to report time at which the snapback occured
    if (this.adPodStartTime !== null || this.isSnapBack) {
      if (this.currentTimeOverride !== null) {
        streamTime = this.currentTimeOverride;
        currentTime = this.bitmovinClientInstance.contentTimeForStreamTime(streamTime);
      } else {
        streamTime = this.adPodStartTime;
        currentTime = this.bitmovinClientInstance.contentTimeForStreamTime(streamTime);
      }
    } else {
      if (this.currentTimeOverride !== null) {
        streamTime = this.currentTimeOverride;
      } else {
        streamTime = this.bitmovinClientInstance.getStreamTime();
      }

      currentTime = this.bitmovinClientInstance.contentTimeForStreamTime(streamTime);

      this.currentTimeOverride = null;

      if (this.isLive && isSafari) {
        currentTime = (this.playbackStartTimestamp / 1000) + currentTime;
      }
    }

    return Math.round(currentTime);
  };

  private generateMediaProperties = () => {
    const mediaProperties = {};
    mediaProperties[this.OzTAMServiceInstance.PROP_ALT_MEDIA_ID] = this.streamMetadata.video.id;

    const channel = get(this.options, 'channel', null);
    if (channel) {
      mediaProperties[this.OzTAMServiceInstance.PROP_CHANNEL] = channel;
    }

    const userId = get(this.options, 'userId', null);
    if (userId) {
      mediaProperties[this.OzTAMServiceInstance.PROP_DEMO1] = userId;
    }

    const classification = get(this.options, 'mediaProperties.classification', null);
    if (classification) {
      mediaProperties[this.OzTAMServiceInstance.PROP_CLASSIFICATION] = classification.toLowerCase();
    }

    const genres = get(this.streamMetadata, 'video.genres', null);
    if (genres) {
      [mediaProperties[this.OzTAMServiceInstance.PROP_GENRE]] = genres;
    }

    mediaProperties[this.OzTAMServiceInstance.PROP_CONNECTION_TYPE] = get(window, 'navigator.connection.type', '');

    return mediaProperties;
  };

  private onSourceLoaded = () => {
    this.startSession();
  };

  private onContentStarted = () => {
    if (this.contentHasStarted === false) {
      if (this.isLive && this.playbackStartTimestamp === 0) {
        this.playbackStartTimestamp = new Date().getTime();
      }

      this.contentHasStarted = true;
      const mediaProperties = this.generateMediaProperties();

      this.OzTAMServiceInstance.beginPlayback(
        this.options.videoId,
        this.options.videoUrl,
        this.options.duration,
        this.getCurrentTime,
        mediaProperties,
        this.streamMetadata.videoStreamType,
      );
    }
  };

  private onAdBreakStarted = () => {
    if (!this.isLive) {
      this.adPodStartTime = this.bitmovinClientInstance.getStreamTime();
      this.OzTAMServiceInstance.haltProgress();
    }
  };

  private onAdStarted = () => {
    if (!this.isLive) {
      if (!this.adPodStartTime) {
        this.adPodStartTime = this.bitmovinClientInstance.getStreamTime();
      }
      this.OzTAMServiceInstance.adBegin();
    }
  };

  private onAdFinished = () => {
    if (!this.isLive) {
      this.OzTAMServiceInstance.adComplete();
    }
  };

  private onAdBreakFinished = () => {
    if (!this.isLive) {
      this.OzTAMServiceInstance.adComplete();
      this.OzTAMServiceInstance.resumeProgress();

      this.adPodStartTime = null;
    }
  };

  private onPlayFinished = () => {
    this.OzTAMServiceInstance.complete();
  };

  private onPaused = () => {
    if (this.castInitTime) {
      this.castPendingTime = new Date().getTime() / 1000;
    }

    if (this.playerEvents.state.isAd === false) {
      this.OzTAMServiceInstance.haltProgress();
    }
  };

  private onPlaying = () => {
    if (
      this.isCasting === false
      && this.playerEvents.state.isAd === false
      && this.OzTAMServiceInstance.state !== this.OzTAMServiceInstance.STATE_PLAYING
    ) {
      if (this.OzTAMServiceInstance.state === this.OzTAMServiceInstance.STATE_SEEKING) {
        this.OzTAMServiceInstance.seekComplete();
      }

      this.OzTAMServiceInstance.resumeProgress();
    }
  };

  private onSeekStarted = (event: any) => {
    // Because Bitmovin implements the player differently in Safari, player.getCurrentTime() will not report
    // the time that we need when doing a seek. So for this event, we will get the position from the event object instead.
    // More details in ODW-498
    this.currentTimeOverride = event.position;
    this.OzTAMServiceInstance.seekBegin();
  };

  private onSeekFinished = () => {
    this.OzTAMServiceInstance.seekComplete();
  };

  private onSnapBackStarted = (event: any) => {
    this.isSnapBack = true;
    this.currentTimeOverride = event.position;
  };

  private onSnapBackFinished = () => {
    this.isSnapBack = false;
    this.currentTimeOverride = null;
  };

  private onCastInit = () => {
    this.castInitPosition = this.getCurrentTime();
    this.castInitTime = new Date().getTime() / 1000;
  };

  private onCastPending = () => {
    this.castPendingTime = new Date().getTime() / 1000;
    this.OzTAMServiceInstance.dispatchMeterEvents(this.OzTAMServiceInstance.EVENT_TYPE_PROGRESS);
    this.OzTAMServiceInstance.endSession();
  };

  private onCastStarted = () => {
    this.isCasting = true;
  };

  private onCastFinished = () => {
    this.unload();
    this.OzTAMServiceInstance.generateNextSessionID();
    this.OzTAMServiceInstance.startSession(
      this.options.videoId,
      this.options.videoUrl,
      get(this.streamMetadata.video, 'duration', 0),
      this.streamMetadata.videoStreamType,
    );

    const mediaProperties = this.generateMediaProperties();
    this.OzTAMServiceInstance.beginPlayback(
      this.options.videoId,
      this.options.videoUrl,
      this.options.duration,
      this.getCurrentTime,
      mediaProperties,
      this.streamMetadata.videoStreamType,
    );
  };

  private onBufferingStarted = () => {
    if (this.playerEvents.state.isAd === false) {
      this.OzTAMServiceInstance.haltProgress();
    }
  };

  private onBufferingFinished = () => {
    if (this.playerEvents.state.isAd === false) {
      this.OzTAMServiceInstance.resumeProgress();
    }
  };

  public startSession = () => {
    this.OzTAMServiceInstance.startSession(
      this.options.videoId,
      this.options.videoUrl,
      get(this.streamMetadata.video, 'duration', 0),
      this.streamMetadata.videoStreamType,
    );
  };
}

export default VideoOztamTracking;
