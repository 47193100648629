import { get } from 'lodash';

import { httpClientWithAuth } from '@@utils/HttpClient';
import { VIDEO_RELATED_PATH } from '@@utils/constants';

import OnDemand from '../@types/OnDemand';
import { transformToVideoItem } from '../transformers/FeedTransformer';

export function getSingleRecommendation(videoId: string): Promise<OnDemand.Video> {
  return httpClientWithAuth.get(
    `${VIDEO_RELATED_PATH}`,
    {
      params: {
        id: videoId,
        range: '1-1',
      },
    },
  ).then(({ data }) => {
    const firstRecommendation = get(data, 'itemListElement[0]', null);
    if (firstRecommendation) {
      const recommendedVideo = transformToVideoItem(firstRecommendation);
      if ('duration' in recommendedVideo && recommendedVideo.duration) {
        return recommendedVideo;
      }
    }

    return null;
  });
}

export default {
  getSingleRecommendation,
};
