import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, useState, useEffect } from 'react';

import { VideoPlayerEventType } from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';
import { VideoPlayerPluginProps } from '@@src/lib/VideoPlayerV2/plugins/VideoPlayerPlugin';

const useStyles = makeStyles<Theme>(() => {
  return createStyles({
    '@global': {
      // This plugin replaces Bitmovin's buffering overlay
      '.bitmovinplayer-container .bmpui-ui-buffering-overlay': {
        display: 'none',
      },
    },
    root: {
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      top: 'calc(50% - 30px)',
      zIndex: 9999,
    },
  });
});

const LoadingSpinner: FunctionComponent<VideoPlayerPluginProps> = (props) => {
  const { videoPlayer, video } = props;
  const [shouldShowLoader, setShouldShowLoader] = useState<boolean>(true);
  const classes = useStyles(props);

  useEffect(() => {
    const hideSpinner = () => {
      setShouldShowLoader(false);
    };

    const showSpinner = () => {
      setShouldShowLoader(true);
    };

    const handleSourceLoaded = () => {
      const isAutoplayOn = videoPlayer.getAutoplayConfig();

      // If autoplay is OFF, hide spinner. Otherwise, let PLAYBACK_STARTED do it.
      // This handles when we disable autoplay after stop casting.
      if (!isAutoplayOn) {
        hideSpinner();
      }
    };

    videoPlayer.on(VideoPlayerEventType.SOURCE_LOADED, handleSourceLoaded);
    videoPlayer.on(VideoPlayerEventType.PLAYBACK_STARTED, hideSpinner);
    videoPlayer.on(VideoPlayerEventType.BUFFERING_STARTED, showSpinner);
    videoPlayer.on(VideoPlayerEventType.BUFFERING_FINISHED, hideSpinner);
    videoPlayer.on(VideoPlayerEventType.SEEK_STARTED, showSpinner);
    videoPlayer.on(VideoPlayerEventType.SEEK_FINISHED, hideSpinner);
    videoPlayer.on(VideoPlayerEventType.PLAYER_ERROR, hideSpinner);
    videoPlayer.on(VideoPlayerEventType.PAUSED, hideSpinner);

    return () => {
      videoPlayer.off(VideoPlayerEventType.PLAYBACK_STARTED, hideSpinner);
      videoPlayer.off(VideoPlayerEventType.BUFFERING_STARTED, showSpinner);
      videoPlayer.off(VideoPlayerEventType.BUFFERING_FINISHED, hideSpinner);
      videoPlayer.off(VideoPlayerEventType.SEEK_STARTED, showSpinner);
      videoPlayer.off(VideoPlayerEventType.SEEK_FINISHED, hideSpinner);
      videoPlayer.off(VideoPlayerEventType.PLAYER_ERROR, hideSpinner);
      videoPlayer.off(VideoPlayerEventType.PAUSED, hideSpinner);
      videoPlayer.off(VideoPlayerEventType.SOURCE_LOADED, handleSourceLoaded);
    };
  }, [video, videoPlayer]);

  if (!shouldShowLoader) {
    return null;
  }

  return <div className={classes.root}><CircularProgress color="secondary" size={60}/></div>;
};

export default LoadingSpinner;
