/**
 * Custom audio track list, see OdAudioTrackUtils.ts for the main code
 */
import { ListBox, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { ListSelector, ListSelectorConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/listselector';
import { EventListener } from '@sbs/bitmovin-player-ui/src/ts/eventdispatcher';
import { PlayerAPI } from 'bitmovin-player';

import { OdAudioTrackSwitchHandler } from '../utils/audiotrackutils';

/**
 * A element that is similar to a select box where the user can select a subtitle
 */
export default class AudioTrackListBox extends ListBox {
  private odAudioTrackSwitchHandler: OdAudioTrackSwitchHandler | undefined;
  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    this.onItemRemoved.subscribe(this.handleItemRemoved);

    super.configure(player, uimanager);
    // eslint-disable-next-line no-new, @typescript-eslint/no-unused-vars
    this.odAudioTrackSwitchHandler = new OdAudioTrackSwitchHandler(player, this, uimanager);
  }

  private handleItemRemoved: EventListener<ListSelector<ListSelectorConfig>, string> = (_, key) => {
    // if the item removed is in selected item, remove it
    if (key === this.selectedItem) {
      // @ts-ignore null is fine
      this.selectedItem = null;
    }
  };
}
