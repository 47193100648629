import VideoPlayer from '@@src/lib/VideoPlayerV2/VideoPlayer';
import {
  VideoPlayerCustomEvent,
  VideoPlayerEventCallback,
  VideoPlayerEventType,
} from '@@src/lib/VideoPlayerV2/VideoPlayerEventManager';

interface PlaybackEvents {
  type: VideoPlayerEventType;
  handler: VideoPlayerEventCallback;
}

class CastIntegrationCore {
  private readonly uiControlsHideCallback: VideoPlayerEventCallback;
  private readonly uiControlsShowCallback: VideoPlayerEventCallback;
  private videoPlayer: VideoPlayer;
  private events: PlaybackEvents[] = [];

  public constructor(
    videoPlayer: VideoPlayer,
    callbacks: {
      uiControlsHideCallback: VideoPlayerEventCallback
      uiControlsShowCallback: VideoPlayerEventCallback
    },
  ) {
    this.videoPlayer = videoPlayer;

    this.uiControlsHideCallback = callbacks.uiControlsHideCallback;
    this.uiControlsShowCallback = callbacks.uiControlsShowCallback;

    this.events = [
      { type: VideoPlayerEventType.CUSTOM_EVENT, handler: this.recommendedEndCardDisplayed },
      { type: VideoPlayerEventType.UI_CONTROLS_HIDE, handler: this.uiControlsHideCallback },
      { type: VideoPlayerEventType.UI_CONTROLS_SHOW, handler: this.uiControlsShowCallback },
    ];
    this.registerEvents();
  }

  private recommendedEndCardDisplayed = (event: VideoPlayerCustomEvent) => {
    if (event.eventName === 'recommendedEndCard_display') {
      this.uiControlsHideCallback(event);
    }
  };

  private registerEvents = () => {
    this.events.forEach((event) => {
      this.videoPlayer.on(event.type, event.handler);
    });
  };

  private unRegisterEvents = () => {
    this.events.forEach((event) => {
      this.videoPlayer.off(event.type, event.handler);
    });
  };

  public destroy = () => {
    this.unRegisterEvents();
  };
}

export default CastIntegrationCore;
