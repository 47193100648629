import { IconButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Gpt from '@@src/components/Advert/Gpt';
import { useAppSelector } from '@@src/hooks/store';
import { ReactComponent as CloseIcon } from '@@src/images/icons/close.svg';
import { getUserId } from '@@stores/UserStore';
import blue from '@@styles/colors/blue';
import OnDemand from '@@types/OnDemand';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      opacity: 0,
      transition: 'opacity 0.2s',
    },
    adContainer: {
      position: 'fixed',
      bottom: 202,
      right: 0,
      zIndex: 1,
    },
    show: {
      opacity: 1,
      transition: 'opacity 3s',
    },
    dismissButton: {
      position: 'absolute',
      top: 0,
      right: 30,
      padding: 0,
      fontSize: '2.5rem',
      '&:hover': {
        background: 'none',
      },
      '& .close_svg__circle': {
        fill: 'transparent',
      },
      '&[data-focus-visible-added]': {
        outline: `3px solid ${blue.navy}`,
      },
    },
    dismissBackground: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      border: 0,
      top: 0,
      left: 0,
      background: 'linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))',
    },
    '@global': {
      // hide the subtitle overlay when the ad is showing
      '.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay': (prop: { show: boolean }) => {
        const { show } = prop;
        return {
          opacity: show ? 0 : 1,
          transition: 'opacity 3s !important',
        };
      },
    },
  });
});

interface AdOnPauseOverlayProps {
  video: OnDemand.Video;
  onDismissed?(resume?: boolean): void;
}

const AdOverlay: FunctionComponent<AdOnPauseOverlayProps> = ({ video, onDismissed }) => {
  const adUnitPath = '/4117/video.web.sbs.com.au/webpause';
  const slotSize = [[1, 1], [960, 540]];

  const [show, setShow] = useState<boolean>(false);
  const [dismissed, setDismissed] = useState<boolean>(false);
  const userId = useAppSelector(getUserId) || '';

  const classes = useStyles({ show });

  const { t } = useTranslation('common');

  const onSlotRenderEnded = useCallback((event) => {
    // we only want to show the ad if it's returning the correct size
    if (!event.isEmpty && isEqual(event.size, [960, 540])) {
      setShow(true);
    } else {
      setDismissed(true);
    }
  }, []);

  const handleClickDismiss = useCallback(() => {
    setShow(false);
    setTimeout(() => {
      setDismissed(true);
      if (onDismissed) {
        onDismissed(false);
      }
    }, 250);
  }, [onDismissed]);

  const handleClickDismissAndResume = useCallback(() => {
    setShow(false);
    setTimeout(() => {
      setDismissed(true);
      if (onDismissed) {
        onDismissed(true);
      }
    }, 250);
  }, [onDismissed]);

  const targeting = useMemo<Record<string, string>>(() => {
    const _targeting: Record<string, string> = {
      uid: userId,
      genre: video.genres.join(','),
      vid: video.id,
      programname: video.type === 'Episode' && video.episodeData ? video.episodeData.programName : video.title,
      ratings: video.classification,
      collections: video.collections.join(','),
      ifaoptout: '0',
    };

    if (video.type === 'Episode' && video.episodeData?.seasonNumber) {
      _targeting.season = video.episodeData.seasonNumber.toString();
    }

    return _targeting;
  }, [video, userId]);

  if (dismissed) {
    return null;
  }

  return (
    <div className={clsx(classes.root, { [classes.show]: show })}>
      <button
        type="button"
        className={classes.dismissBackground}
        onClick={handleClickDismissAndResume}
        aria-label={t('button.dismissAd')}
        tabIndex={-1}
      />
      <div className={classes.adContainer}>
        <IconButton
          className={classes.dismissButton}
          onClick={handleClickDismiss}
          title={t('button.dismissAd')}
          aria-label={t('button.dismissAd')}
        >
          <CloseIcon/>
        </IconButton>
        <Gpt
          slotSize={slotSize}
          adUnitPath={adUnitPath}
          enableSingleRequest
          onSlotRenderEnded={onSlotRenderEnded}
          targeting={targeting}
          ppid={userId.replace(/-/g, '').toUpperCase()}
        />
      </div>
    </div>
  );
};

export default AdOverlay;
