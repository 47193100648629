import { httpClientWithAuth } from '@@utils/HttpClient';
import { VIDEO_STREAM_PATH } from '@@utils/constants';

import VideoStream from '../@types/VideoStream';

interface Term {
  type: 'Term';
  name: string;
}

export interface VideoTaxonomy {
  section: Term[];
  useType?: string;
  genre?: Term[];
  subGenre?: Term[];
  channel?: Term[];
  grouping?: Term[];
}

export interface StreamProviderConfig {
  type: string;
}

export interface GoogleDAIProviderConfig extends StreamProviderConfig {
  providerAccountId: string;
  providerName: string;
  type: 'GoogleDAIProvider';
  videoBitrate: string;
  videoEncoding: string;
  videoId: string;
  videoStreamType: 'vod' | 'live';
}

export interface AkamaiHLSProviderConfig extends StreamProviderConfig {
  contentUrl: string;
  providerName: string;
  type: 'AkamaiHLSProvider';
  videoBitrate: string;
  videoEncoding: string;
  videoId: string;
  videoStreamType: string;
  advertisement?: {
    type: 'preroll' | string;
    playlistUrl: string;
  };
  textTracks?: {
    name: string;
    contentUrl: string;
    language: string;
    format: string;
    characteristics: string[];
    attributes: string[];
  }[];
}

export declare namespace VideoStreamApi {
  interface Response {
    '@context': any;
    type: string;
    name: string;
    adTag?: Record<string, unknown> | null;
    streamProviders: (GoogleDAIProviderConfig | AkamaiHLSProviderConfig)[];
    oztam: VideoStream.OztamMetadata;
    videoItem: any;
    nextVideoItem?: any;
    chromecast: any;
    videoProgress: any;
    nextVideo?: any;
    trackingEvents: {
      event_type: string;
      event_seconds: number;
    }[];
    conviva: any;
    recommendedContent: string;
    error?: string;
    bidding?: any;
  }
}

async function fetch(videoId: string, params = {}): Promise<VideoStreamApi.Response> {
  const { data } = await httpClientWithAuth.get<VideoStreamApi.Response>(
    VIDEO_STREAM_PATH,
    {
      params: {
        id: videoId,
        ...params,
      },
    },
  );

  return data;
}

export default fetch;
