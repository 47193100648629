import { useEffect, useState } from 'react';

import { useAppSelector } from '@@src/hooks/store';
import VideoPlayer, { VideoPlayerOptions } from '@@src/lib/VideoPlayerV2/VideoPlayer';
import { getUserPreferences } from '@@src/lib/VideoPlayerV2/plugins/SaveUserPreferences/SaveUserPreferences';
import { getSetting } from '@@stores/SettingsStore';

const useVideoPlayer = (
  videoPlayerContainer?: HTMLDivElement,
  videoElement?: HTMLVideoElement,
  uiContainerElement?: HTMLDivElement,
  adClickElementId?: string,
  autoPlay?: boolean,
) => {
  const [videoPlayer, setVideoPlayer] = useState<VideoPlayer>();
  const adBlockerDetected = useAppSelector((state) => {
    return getSetting(state, 'adBlockDetected');
  });

  useEffect(() => {
    let _videoPlayer;
    if (
      adBlockerDetected !== undefined
      && videoPlayerContainer
      && videoElement
      && uiContainerElement
    ) {
      const userPrefs = getUserPreferences();
      const options: VideoPlayerOptions = {
        volume: userPrefs.volume,
        muted: userPrefs.muted,
        uiContainer: uiContainerElement,
        adClickElementId,
        adBlockerDetected,
        autoPlay,
      };

      _videoPlayer = new VideoPlayer(videoPlayerContainer, videoElement, options);
      setVideoPlayer(_videoPlayer);
    }

    return () => {
      if (_videoPlayer) {
        _videoPlayer.unload();
      }
    };
  }, [videoElement, videoPlayerContainer, uiContainerElement, adClickElementId, adBlockerDetected, autoPlay]);

  return videoPlayer;
};

export default useVideoPlayer;
