import {
  FormControl,
  ListItemIcon,
  MenuItem,
  Select as MuiSelect,
  SelectProps as SelectPropsType,
  MenuProps as MenuPropsType,
} from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';

import blue from '@@styles/colors/blue';
import grey from '@@styles/colors/grey';
import orange from '@@styles/colors/orange';
import fontFamily from '@@styles/typography/fontFamily';

import { ReactComponent as TickIcon } from '../../images/icons/tick.svg';
import { ReactComponent as UpChevronIcon } from '../../images/icons/up-chevron.svg';

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    formControl: {
      '& .MuiInputBase-root': {
        fontFamily: fontFamily.secondary,
        fontSize: '0.875rem',
        fontWeight: 700,
      },
      '& .MuiFilledInput-root': {
        borderRadius: '50rem',
        backgroundColor: grey.darkBlackPearl,
        transition: 'background-color 0.2s ease-in-out',
      },
      '& .MuiFilledInput-root:hover': {
        backgroundColor: grey.codgrey,
      },
      '& .MuiSelect-filled': {
        padding: theme.spacing(1, 5, 1, 2),
      },
      '& .MuiSelect-filled.Mui-disabled': {
        padding: theme.spacing(1, 2, 1, 2),
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
      '& .MuiSelect-root.focus-visible': {
        outline: `3px solid ${blue.navy}`,
        borderRadius: '50rem',
      },
      '& $itemListIcon': {
        // don't show the check icon on the form control
        display: 'none',
      },
      '& .MuiInputBase-root.Mui-disabled': {
        color: grey.white,
        backgroundColor: alpha(grey.darkBlackPearl, 0.5),
      },
      '& input, & svg': {
        pointerEvents: 'none',
        cursor: 'pointer',
        '& path': {
          pointerEvents: 'none',
        },
      },
    },
    selectIcon: {
      transform: 'rotate(180deg)',
      top: 'auto',
      fontSize: '0.7rem',
      right: 14,
      '&.MuiSelect-iconOpen': {
        transform: 'rotate(0)',
      },
      '&.Mui-disabled': {
        display: 'none',
      },
    },
    popoverRoot: {
      '& .MuiList-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiPaper-root': {
        borderRadius: 8,
        backgroundColor: grey.darkBlackPearl,
        marginTop: 4,
      },
      '& .MuiMenuItem-root': {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingRight: theme.spacing(5),
        fontFamily: fontFamily.secondary,
        fontSize: '0.875rem',
      },
      '& .MuiListItem-root.Mui-selected, & .MuiListItem-root.Mui-selected:hover': {
        backgroundColor: 'transparent',
        color: orange.darkTangerine,
        fontWeight: 500,
      },
      '& .MuiListItem-root.focus-visible': {
        outline: 'none',
      },
      '& .MuiListItem-button:hover': {
        backgroundColor: grey.codgrey,
      },
    },
    itemListIcon: {
      minWidth: 30,
      color: orange.darkTangerine,
    },
  });
});

export interface SelectProps {
  /**
   * The items to display in the select menu.
   */
  items: Record<string, string>;
  /**
   * The key of the selected item. If passed in, the item selected will always be the one with this key.
   */
  selectedKey?: string;
  /**
   * The callback function when the selected item changes.
   */
  onChange?: SelectInputProps['onChange'];
  SelectProps?: Partial<SelectPropsType>;
  MenuProps?: Partial<MenuPropsType>;
  SelectIconComponent?: ReactElement;
}

const Select: FunctionComponent<SelectProps> = (props) => {
  const {
    items,
    selectedKey: selectedItemKeyProp,
    onChange,
    SelectProps = {},
    MenuProps = {},
    SelectIconComponent,
  } = props;

  const classes = useStyles(props);

  const [selectedItemKey, setSelectedItemKey] = useState<string>(selectedItemKeyProp || '');

  const _SelectIconComponent = useCallback(({ className }) => {
    return SelectIconComponent || <UpChevronIcon className={clsx(className, classes.selectIcon)}/>;
  }, [SelectIconComponent, classes.selectIcon]);

  useEffect(() => {
    setSelectedItemKey(selectedItemKeyProp || '');
  }, [selectedItemKeyProp]);

  const handleChange = useCallback((event, child) => {
    if (onChange) {
      onChange(event, child);
    }

    if (selectedItemKeyProp === undefined) {
      setSelectedItemKey(event.target.value);
    }
  }, [onChange, selectedItemKeyProp]);

  return (
    <FormControl className={classes.formControl}>
      <MuiSelect
        value={selectedItemKey}
        variant="filled"
        disableUnderline
        displayEmpty
        IconComponent={_SelectIconComponent}
        onChange={handleChange}
        MenuProps={{
          elevation: 0,
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          PopoverClasses: {
            root: classes.popoverRoot,
          },
          ...MenuProps,
        }}
        {...SelectProps}
      >
        {
          Object.entries(items).map(([key, value]) => {
            return (
              <MenuItem key={key} value={key}>
                {
                  key === selectedItemKey && key !== '' ? (
                    <ListItemIcon classes={{ root: classes.itemListIcon }}>
                      <TickIcon/>
                    </ListItemIcon>
                  ) : (
                    <div className={classes.itemListIcon}/>
                  )
                }
                <span>{value}</span>
              </MenuItem>
            );
          })
        }
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
