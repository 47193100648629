import { get } from 'lodash';

import VideoPlayerPluginV2WithReactPortal from '../VideoPlayerPluginV2WithReactPortal';
import ConsumerAdvicePluginComponent from './ConsumerAdvicePluginComponent';

class ConsumerAdvicePluginV2 extends VideoPlayerPluginV2WithReactPortal {
  protected videoCardPosition: 'bottom' = 'bottom';

  public shouldLoad(): boolean {
    const video = this.videoPlayer.getVideo();
    // it should load if video has a classification
    return !!video.classification;
  }

  protected component() {
    const video = this.videoPlayer.getVideo();

    const { sbsSubCertification, classification } = video;
    const consumerAdvice = get(sbsSubCertification, 'consumerAdvice', []);
    const consumerWarnings = get(sbsSubCertification, 'consumerWarnings', []);

    if (!this.bitmovinClient || !this.bitmovinClient.playerEvents) {
      return null;
    }

    return (
      <ConsumerAdvicePluginComponent
        classification={classification}
        consumerAdvice={consumerAdvice}
        consumerWarnings={consumerWarnings}
        bitmovinClient={this.bitmovinClient}
      />
    );
  }
}

export default ConsumerAdvicePluginV2;
