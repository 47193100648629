// eslint-disable-next-line max-classes-per-file
import { PlayerEvent } from 'bitmovin-player';
import { get, throttle } from 'lodash';

import Logger from '@@utils/logger/Logger';

import BitmovinClient, { ChapterData } from './BitmovinClient';

export interface AdMetadata {
  id: string;
  duration: number;
  name: string;
  position: number;
  description: string;
  totalAds: number;
  creativeId: string;
  orderId: string;
  podIndex: number;
}

export interface AdPlayingEvent {
  adProgressMetadata: DaiAdProgressData,
  currentChapterData?: ChapterData,
}

export interface CustomAdEvent extends DaiStreamEvent {
  adMetadata?: AdMetadata,
}

/**
 * Event handler lists for PlayerEvents
 */
class PlayerEventsHandlers {
  public SourceLoaded: (() => void)[] = [];
  public Seek: (() => void)[] = [];
  public SeekStarted: (() => void)[] = [];
  public SeekFinished: (() => void)[] = [];
  public Paused: (() => void)[] = [];
  public SeekPaused: (() => void)[] = [];
  public Ready: (() => void)[] = [];
  public AdBreakStarted: (() => void)[] = [];
  public AdStarted: (() => void)[] = [];
  public AdPlaying: (() => void)[] = [];
  public AdFinished: (() => void)[] = [];
  public AdBreakFinished: (() => void)[] = [];
  public PlayStarted: (() => void)[] = [];
  public Play: (() => void)[] = [];
  public Playing: (() => void)[] = [];
  public PlayFinished: (() => void)[] = [];
  public PlayerResized: (() => void)[] = [];
  public Progress: (() => void)[] = [];
  public AudioChanged: (() => void)[] = [];
  public SubtitleEnabled: (() => void)[] = [];
  public SubtitleDisabled: (() => void)[] = [];
  public Milestone: (() => void)[] = [];
  public ContentStarted: (() => void)[] = [];
  public BufferingStarted: (() => void)[] = [];
  public BufferingFinished: (() => void)[] = [];
  public Warning: (() => void)[] = [];
  public CuePointsChanged: (() => void)[] = [];
  public StreamError: (() => void)[] = [];
  public Error: (() => void)[] = [];
  public VideoTagError: (() => void)[] = [];
  public VolumeChanged: (() => void)[] = [];
  public Muted: (() => void)[] = [];
  public Unmuted: (() => void)[] = [];
  public ViewModeChanged: (() => void)[] = [];
  public CastAvailable: (() => void)[] = [];
  public CastInit: (() => void)[] = [];
  public CastPending: (() => void)[] = [];
  public CastStarted: (() => void)[] = [];
  public CastFinished: (() => void)[] = [];
  public SnapBackStarted: (() => void)[] = [];
  public SnapBackFinished: (() => void)[] = [];

  public destroy() {
    this.SourceLoaded = [];
    this.Seek = [];
    this.SeekStarted = [];
    this.SeekFinished = [];
    this.Paused = [];
    this.SeekPaused = [];
    this.Ready = [];
    this.AdBreakStarted = [];
    this.AdStarted = [];
    this.AdPlaying = [];
    this.AdFinished = [];
    this.AdBreakFinished = [];
    this.PlayStarted = [];
    this.Playing = [];
    this.Play = [];
    this.PlayFinished = [];
    this.PlayerResized = [];
    this.Progress = [];
    this.AudioChanged = [];
    this.SubtitleEnabled = [];
    this.SubtitleDisabled = [];
    this.Milestone = [];
    this.ContentStarted = [];
    this.BufferingStarted = [];
    this.BufferingFinished = [];
    this.Warning = [];
    this.CuePointsChanged = [];
    this.StreamError = [];
    this.Error = [];
    this.VideoTagError = [];
    this.VolumeChanged = [];
    this.Muted = [];
    this.Unmuted = [];
    this.ViewModeChanged = [];
    this.CastAvailable = [];
    this.CastInit = [];
    this.CastPending = [];
    this.CastStarted = [];
    this.CastFinished = [];
    this.SnapBackStarted = [];
    this.SnapBackFinished = [];
  }
}

export interface PlayerEventsOptions {
  milestones: number[];
  duration: number;
  resumePosition?: number;
  progressFrequency?: number;
  debugMode?: boolean;
}

interface PlayerEventsState {
  isReadyDispatched: boolean;
  isAdBreak: boolean;
  isAd: boolean;
  currentAdMetadata: AdMetadata;
  currentAdDuration: number;
  contentPlayStarted: boolean;
  previousChapterHash: string;
  milestoneReached: number;
  selectedAudioTrack: string;
  selectedAudioTrackLabel: string;
  selectedSubtitleLanguage: string;
  subtitleLanguageLabel: string;
  isSeeking: boolean;
  seekIssuer: string;
  isPlaying: boolean;
  isContentCompleted: boolean;
  previousTimeChangedCheck: number;
  previousProgressPosition: number;
  seekTargetTime: number;
  positionAtSeek: number;
  furthestPosition: number;
  lastContentTime: number;
  cuepoints: any[];
}

const initialState: PlayerEventsState = {
  isReadyDispatched: false,
  isAdBreak: false,
  isAd: false,
  currentAdMetadata: undefined,
  currentAdDuration: 0,
  contentPlayStarted: false,
  previousChapterHash: '',
  milestoneReached: 0,
  selectedAudioTrack: '',
  selectedAudioTrackLabel: '',
  selectedSubtitleLanguage: 'off',
  subtitleLanguageLabel: '',
  isSeeking: false,
  seekIssuer: '',
  isPlaying: false,
  isContentCompleted: false,
  previousTimeChangedCheck: 0,
  previousProgressPosition: 0,
  seekTargetTime: 0,
  positionAtSeek: 0,
  furthestPosition: 0,
  lastContentTime: 0,
  cuepoints: [],
};

/**
 * Abstracting Bitmovin events
 */
class PlayerEvents {
  public state: PlayerEventsState = { ...initialState };

  private handlers: PlayerEventsHandlers;
  private milestones: number[];
  private bitmovinClientInstance: any;
  private googleDaiInstance: any;
  private duration: number;
  private progressFrequency: number;
  private debugMode: boolean;

  /**
   * Class constructor
   * @param bitmovinClientInstance
   * @param options
   */
  public constructor(bitmovinClientInstance: BitmovinClient, options: PlayerEventsOptions) {
    const { player, googleDai } = bitmovinClientInstance;
    this.googleDaiInstance = googleDai;
    this.bitmovinClientInstance = bitmovinClientInstance;
    this.handlers = new PlayerEventsHandlers();
    this.parseOptions(options);
    this.overrideBitmovinFunctions();

    // Ready is called multiple times in a playback session.
    // It is called when the player has enough data to start playback.
    player.on(PlayerEvent.Ready, this.onReady);
    player.on(PlayerEvent.TimeChanged, this.onTimeChanged);
    player.on(PlayerEvent.Play, this.onPlay);
    player.on(PlayerEvent.Playing, this.onPlaying);

    if (!this.bitmovinClientInstance.state.isLivestream) {
      player.on(PlayerEvent.Seek, this.onSeek);
      player.on(PlayerEvent.Seeked, this.onSeeked);
    } else {
      player.on(PlayerEvent.TimeShift, this.onSeek);
      player.on(PlayerEvent.TimeShifted, this.onSeeked);
    }

    player.on(PlayerEvent.Paused, this.onPaused);
    player.on(PlayerEvent.AudioChanged, this.onAudioChanged);
    player.on(PlayerEvent.SubtitleEnabled, this.onSubtitleEnabled);
    player.on(PlayerEvent.SubtitleDisabled, this.onSubtitleDisabled);
    player.on(PlayerEvent.StallStarted, this.onStallStarted);
    player.on(PlayerEvent.StallEnded, this.onStallEnded);
    player.on(PlayerEvent.Warning, this.onWarning);
    player.on(PlayerEvent.Error, this.onError);
    player.on(PlayerEvent.VolumeChanged, this.onVolumeChanged);
    player.on(PlayerEvent.Muted, this.onMuted);
    player.on(PlayerEvent.Unmuted, this.onUnmuted);
    player.on(PlayerEvent.ViewModeChanged, this.onViewModeChanged);
    player.on(PlayerEvent.PlaybackFinished, this.onPlaybackFinished);
    player.on(PlayerEvent.CastAvailable, this.onCastAvailable);
    player.on(PlayerEvent.CastStart, this.onCastStart);
    player.on(PlayerEvent.CastStarted, this.onCastStarted);
    player.on(PlayerEvent.CastStopped, this.onCastStopped);
    player.on(PlayerEvent.CastWaitingForDevice, this.onCastWaitingForDevice);

    const throttlePlayerResized = throttle(this.onPlayerResized, 1000);
    player.on(PlayerEvent.PlayerResized, throttlePlayerResized);

    // Some events are on DAI side
    if (this.bitmovinClientInstance.state.isStreamDai) {
      this.enableDaiEvents();
    } else {
      this.enableBitmovinAdEvents();
    }
  }

  private parseOptions = (options: PlayerEventsOptions) => {
    this.milestones = get(options, 'milestones', []);
    this.duration = get(options, 'duration', null);
    this.debugMode = get(options, 'debugMode', false);
    this.state.previousProgressPosition = get(options, 'resumePosition', 0);

    this.progressFrequency = get(options, 'progressFrequency', 5);
    if (this.progressFrequency < 1) {
      this.progressFrequency = 1;
    }
  };

  public reset = (bitmovinClientInstance, options: PlayerEventsOptions) => {
    const { googleDai } = bitmovinClientInstance;
    this.googleDaiInstance = googleDai;
    this.parseOptions(options);

    if (this.progressFrequency < 1) {
      this.progressFrequency = 1;
    }

    this.state = { ...initialState };
    this.disableBitmovinAdEvents();
    this.disableDaiEvents();

    if (this.bitmovinClientInstance.state.isStreamDai) {
      this.enableDaiEvents();
    } else {
      this.enableBitmovinAdEvents();
    }
  };

  public enableDaiEvents = () => {
    this.googleDaiInstance.streamManager.addEventListener(
      [
        window.google.ima.dai.api.StreamEvent.Type.LOADED,
        window.google.ima.dai.api.StreamEvent.Type.ERROR,
        window.google.ima.dai.api.StreamEvent.Type.CUEPOINTS_CHANGED,
        window.google.ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED,
        window.google.ima.dai.api.StreamEvent.Type.AD_PROGRESS,
        window.google.ima.dai.api.StreamEvent.Type.AD_BREAK_ENDED,
        window.google.ima.dai.api.StreamEvent.Type.STARTED,
        window.google.ima.dai.api.StreamEvent.Type.COMPLETE,
      ],
      this.onGoogleDaiStreamEvent,
      false,
    );
  };

  public disableDaiEvents = () => {
    this.googleDaiInstance.streamManager.removeEventListener(
      [
        window.google.ima.dai.api.StreamEvent.Type.LOADED,
        window.google.ima.dai.api.StreamEvent.Type.ERROR,
        window.google.ima.dai.api.StreamEvent.Type.CUEPOINTS_CHANGED,
        window.google.ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED,
        window.google.ima.dai.api.StreamEvent.Type.AD_PROGRESS,
        window.google.ima.dai.api.StreamEvent.Type.AD_BREAK_ENDED,
        window.google.ima.dai.api.StreamEvent.Type.STARTED,
        window.google.ima.dai.api.StreamEvent.Type.COMPLETE,
      ],
      this.onGoogleDaiStreamEvent,
      false,
    );
  };

  public enableBitmovinAdEvents = () => {
    const { player } = this.bitmovinClientInstance;

    player.on(PlayerEvent.SourceLoaded, this.onSourceLoaded);
    player.on(PlayerEvent.AdBreakStarted, this.onAdBreakStarted);
    player.on(PlayerEvent.AdStarted, this.onBitmovinAdStarted);
    player.on(PlayerEvent.AdFinished, this.onAdFinished);
    player.on(PlayerEvent.AdBreakFinished, this.onAdBreakFinished);
  };

  public disableBitmovinAdEvents = () => {
    const { player } = this.bitmovinClientInstance;

    if (player) {
      player.off(PlayerEvent.SourceLoaded, this.onSourceLoaded);
      player.off(PlayerEvent.AdBreakStarted, this.onAdBreakStarted);
      player.off(PlayerEvent.AdStarted, this.onBitmovinAdStarted);
      player.off(PlayerEvent.AdFinished, this.onAdFinished);
      player.off(PlayerEvent.AdBreakFinished, this.onAdBreakFinished);
    }
  };

  public destroy = () => {
    const { player } = this.bitmovinClientInstance;

    if (player) {
      // Ready is called multiple times in a playback session.
      // It is called when the player has enough data to start playback.
      player.off(PlayerEvent.Ready, this.onReady);
      player.off(PlayerEvent.TimeChanged, this.onTimeChanged);
      player.off(PlayerEvent.Play, this.onPlay);
      player.off(PlayerEvent.Playing, this.onPlaying);

      if (!this.bitmovinClientInstance.state.isLivestream) {
        player.off(PlayerEvent.Seek, this.onSeek);
        player.off(PlayerEvent.Seeked, this.onSeeked);
      } else {
        player.off(PlayerEvent.TimeShift, this.onSeek);
        player.off(PlayerEvent.TimeShifted, this.onSeeked);
      }

      player.off(PlayerEvent.Paused, this.onPaused);
      player.off(PlayerEvent.AudioChanged, this.onAudioChanged);
      player.off(PlayerEvent.SubtitleEnabled, this.onSubtitleEnabled);
      player.off(PlayerEvent.SubtitleDisabled, this.onSubtitleDisabled);
      player.off(PlayerEvent.StallStarted, this.onStallStarted);
      player.off(PlayerEvent.StallEnded, this.onStallEnded);
      player.off(PlayerEvent.Warning, this.onWarning);
      player.off(PlayerEvent.Error, this.onError);
      player.off(PlayerEvent.VolumeChanged, this.onVolumeChanged);
      player.off(PlayerEvent.Muted, this.onMuted);
      player.off(PlayerEvent.Unmuted, this.onUnmuted);
      player.off(PlayerEvent.ViewModeChanged, this.onViewModeChanged);
      player.off(PlayerEvent.PlaybackFinished, this.onPlaybackFinished);
      player.off(PlayerEvent.CastAvailable, this.onCastAvailable);
      player.off(PlayerEvent.CastStart, this.onCastStart);
      player.off(PlayerEvent.CastStarted, this.onCastStarted);
      player.off(PlayerEvent.CastStopped, this.onCastStopped);
      player.off(PlayerEvent.CastWaitingForDevice, this.onCastWaitingForDevice);
      player.off(PlayerEvent.PlayerResized, this.onPlayerResized);

      this.bitmovinClientInstance.getVideoElement().removeEventListener('error', this.onVideoTagError);
    }

    // Some events are on DAI side
    if (this.bitmovinClientInstance.state.isStreamDai) {
      this.disableDaiEvents();
    } else {
      this.disableBitmovinAdEvents();
    }

    this.handlers.destroy();
  };

  public cuepointAtStreamTime = (streamTime) => {
    for (let ci = 0; ci < this.state.cuepoints.length; ci += 1) {
      const cuepoint = this.state.cuepoints[ci];
      if (cuepoint.start <= streamTime && streamTime < cuepoint.end) {
        return cuepoint;
      }
    }

    return null;
  };

  public resetSeekTargetTime = () => {
    this.state.seekTargetTime = 0;
  };

  private overrideBitmovinFunctions = () => {
    const playerSeek = this.bitmovinClientInstance.player.seek.bind(this.bitmovinClientInstance.player);
    this.bitmovinClientInstance.player.seek = (time, issuer) => {
      let seekTime = time;

      if (issuer === 'ui' || issuer === 'api') {
        // if seeking into the middle of an ad, change seekTime to the end of the ads
        const cuepoint = this.cuepointAtStreamTime(seekTime);
        if (cuepoint !== null) {
          seekTime = cuepoint.end + 0.2;
          Logger.info(`Seeking into an ad, changing seek target to ${seekTime}`);
        }
      }

      return playerSeek(seekTime, issuer);
    };
  };

  private onReady = (event: any) => {
    this.bitmovinClientInstance.getVideoElement().addEventListener('error', this.onVideoTagError);
    this.dispatchEvent('Ready', event);
    this.state.isReadyDispatched = true;
  };

  private onPaused = (event: any) => {
    this.state.isPlaying = false;

    if (event.issuer === 'ui-seek') {
      this.dispatchEvent('SeekPaused', event);
    } else {
      this.dispatchEvent('Paused', event);
    }
  };

  private onAudioChanged = (event: any) => {
    this.state.selectedAudioTrack = event.targetAudio.lang;
    this.state.selectedAudioTrackLabel = event.targetAudio.label;

    this.dispatchEvent('AudioChanged', event);
  };

  private onSubtitleEnabled = (event: any) => {
    this.state.selectedSubtitleLanguage = event.subtitle.lang;
    this.state.subtitleLanguageLabel = event.subtitle.label;

    this.dispatchEvent('SubtitleEnabled', event);
  };

  private onSubtitleDisabled = (event: any) => {
    this.state.selectedSubtitleLanguage = event.subtitle.lang;
    this.state.subtitleLanguageLabel = event.subtitle.label;

    this.dispatchEvent('SubtitleDisabled', event);
  };

  private onStallStarted = (event: any) => {
    this.state.isPlaying = false;
    this.dispatchEvent('BufferingStarted', event);
  };

  private onStallEnded = (event: any) => {
    this.dispatchEvent('BufferingFinished', event);
  };

  private onWarning = (event: any) => {
    this.dispatchEvent('Warning', event);
  };

  private onVideoTagError = (event: any) => {
    this.state.isContentCompleted = false;
    this.state.contentPlayStarted = false;
    this.state.isPlaying = false;
    this.dispatchEvent('VideoTagError', event);
  };

  private onError = (event: any) => {
    this.state.isContentCompleted = false;
    this.state.contentPlayStarted = false;
    this.state.isPlaying = false;
    this.dispatchEvent('Error', event);
  };

  private onVolumeChanged = (event: any) => {
    this.dispatchEvent('VolumeChanged', event);
  };

  private onMuted = (event: any) => {
    this.dispatchEvent('Muted', event);
  };

  private onUnmuted = (event: any) => {
    this.dispatchEvent('Unmuted', event);
  };

  private onViewModeChanged = (event: any) => {
    this.dispatchEvent('ViewModeChanged', event);
  };

  private onSourceLoaded = (event: any) => {
    this.state.isContentCompleted = false;
    this.state.contentPlayStarted = false;
    this.state.isPlaying = false;
    this.dispatchEvent('SourceLoaded', event);
  };

  private onAdBreakStarted = (event: any) => {
    // https://github.com/bitmovin/sbs-au-sync/issues/41
    if (this.state.isReadyDispatched === false) {
      this.dispatchEvent('Ready', {});
      this.state.isReadyDispatched = true;
    }

    this.state.isAd = true;
    this.dispatchEvent('AdBreakStarted', event);
  };

  private onAdFinished = (event: any) => {
    this.state.isAd = false;
    this.state.currentAdMetadata = undefined;
    this.dispatchEvent('AdFinished', event);
  };

  private onAdBreakFinished = (event: any) => {
    this.state.isAd = false;
    this.state.currentAdMetadata = undefined;
    this.dispatchEvent('AdBreakFinished', event);
  };

  private onSeek = (event: any) => {
    this.state.isPlaying = false;
    this.state.seekIssuer = event.issuer;
    this.state.seekTargetTime = event.seekTarget;
    this.state.positionAtSeek = event.position;

    const issuerIgnoreList = ['snapBack', 'snapForward'];
    if (issuerIgnoreList.indexOf(event.issuer) === -1) {
      if (this.state.isSeeking === false) {
        this.dispatchEvent('SeekStarted', event);
      } else {
        this.dispatchEvent('Seek', event);
      }
    }

    this.state.isSeeking = true;
  };

  private onSeeked = (event: any) => {
    const issuerIgnoreList = ['snapBack', 'snapForward'];
    if (issuerIgnoreList.indexOf(event.issuer) === -1) {
      const customEvent = event;
      customEvent.issuer = this.state.seekIssuer;
      this.dispatchEvent('SeekFinished', customEvent);
    }

    this.state.isSeeking = false;
    this.state.seekIssuer = '';
  };

  private onGoogleDaiStreamEvent = (event: DaiStreamEvent) => {
    switch (event.type) {
      case window.google.ima.dai.api.StreamEvent.Type.CUEPOINTS_CHANGED: {
        const streamData = event.getStreamData();
        this.state.cuepoints = get(streamData, 'cuepoints', []);
        this.dispatchEvent('CuePointsChanged', event);
        break;
      }

      case window.google.ima.dai.api.StreamEvent.Type.LOADED: {
        this.state.isContentCompleted = false;
        this.state.contentPlayStarted = false;
        this.state.isPlaying = false;
        this.dispatchEvent('SourceLoaded', event);
        break;
      }

      case window.google.ima.dai.api.StreamEvent.Type.ERROR: {
        this.dispatchEvent('StreamError', event);
        break;
      }

      case window.google.ima.dai.api.StreamEvent.Type.STARTED: {
        this.state.isAd = true;

        const customEvent: CustomAdEvent = event;
        customEvent.adMetadata = PlayerEvents.daiGetAdMetadata(event);
        this.state.currentAdMetadata = customEvent.adMetadata;

        this.dispatchEvent('AdStarted', customEvent);

        break;
      }

      case window.google.ima.dai.api.StreamEvent.Type.COMPLETE: {
        const customEvent: CustomAdEvent = event;
        customEvent.adMetadata = PlayerEvents.daiGetAdMetadata(event);
        this.state.isAd = false;
        this.state.currentAdMetadata = undefined;

        this.dispatchEvent('AdFinished', customEvent);
        break;
      }

      case window.google.ima.dai.api.StreamEvent.Type.AD_PROGRESS: {
        const adPlayingEvent: AdPlayingEvent = {
          adProgressMetadata: { ...event.getStreamData().adProgressData },
        };

        this.dispatchEvent('AdPlaying', adPlayingEvent);
        break;
      }

      case window.google.ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED: {
        this.state.isAdBreak = true;
        this.dispatchEvent('AdBreakStarted', event);
        break;
      }

      case window.google.ima.dai.api.StreamEvent.Type.AD_BREAK_ENDED: {
        this.state.isAdBreak = false;
        this.state.isAd = false;
        this.state.currentAdMetadata = undefined;
        this.dispatchEvent('AdBreakFinished', event);
        break;
      }

      default: {
        break;
      }
    }
  };

  public static daiGetAdMetadata = (event: DaiStreamEvent): AdMetadata => {
    let adInfo: AdMetadata = null;
    const ad = event.getAd();

    if (ad) {
      const podInfo = ad.getAdPodInfo();
      adInfo = {
        id: ad.getAdId(),
        duration: ad.getDuration(),
        name: ad.getTitle(),
        position: podInfo.getAdPosition(),
        description: ad.getDescription(),
        totalAds: podInfo.getTotalAds(),
        creativeId: ad.getCreativeId(),
        podIndex: podInfo.getPodIndex(),
        orderId: null, // N/A
      };
    }

    return adInfo;
  };

  // When scrubbing past midrolls, Bitmovin does not send the player.Playing event when content resumes.
  private dispatchPlayStarted = (event) => {
    if (this.state.isPlaying === false) {
      this.state.isPlaying = true;

      this.dispatchEvent('PlayStarted', event);

      const position = this.bitmovinClientInstance.getCurrentContentPosition();
      Logger.info(`Playback started at content time: ${position.toFixed(1)}`);
    }
  };

  private getMilestoneReached = (contentTime) => {
    const videoDuration = this.duration;

    if (contentTime >= videoDuration) {
      return 100;
    }

    let milestoneReached = 0;

    this.milestones.forEach((milestone) => {
      const milestoneInSeconds = (milestone / 100) * videoDuration;

      // Allows 5 seconds error margin based off the milestone in seconds
      // Especially because video completion is sometimes happening slightly before 100%.
      // Also prevents triggering previous milestone event if scrubbing/seeking in between milestones
      if (Math.abs(milestoneInSeconds - contentTime) <= 5) {
        milestoneReached = milestone;
      }
    });

    return milestoneReached;
  };

  private onPlaybackFinished = (event) => {
    this.checkMilestone(event);
    this.dispatchEvent('PlayFinished', event);
  };

  private onPlayerResized = (event) => {
    this.dispatchEvent('PlayerResized', event);
  };

  private onCastAvailable = (event) => {
    this.dispatchEvent('CastAvailable', event);
  };

  private onCastStart = (event) => {
    this.dispatchEvent('CastInit', event);
  };

  private onCastWaitingForDevice = (event) => {
    this.dispatchEvent('CastPending', event);
  };

  private onCastStarted = (event) => {
    this.dispatchEvent('CastStarted', event);
  };

  private onCastStopped = (event) => {
    this.dispatchEvent('CastFinished', event);
  };

  private dispatchMilestone = (event, newMilestoneReached, shouldDispatchEvent = true) => {
    if (newMilestoneReached > this.state.milestoneReached) {
      this.state.milestoneReached = newMilestoneReached;

      if (shouldDispatchEvent) {
        this.dispatchEvent('Milestone', {
          ...event,
          milestone: newMilestoneReached,
        });
      }
    }
  };

  private dispatchContentStarted = () => {
    if (this.state.contentPlayStarted === false) {
      const currentChapterData = this.bitmovinClientInstance.getChapterData();
      this.state.previousChapterHash = JSON.stringify(currentChapterData);
      this.state.contentPlayStarted = true;
      this.state.isContentCompleted = false;

      // https://github.com/bitmovin/sbs-au-sync/issues/41
      if (this.state.isReadyDispatched === false) {
        this.dispatchEvent('Ready', {});
        this.state.isReadyDispatched = true;
      }
      this.dispatchEvent('ContentStarted', {});
    }
  };

  private dispatchProgress = (event, contentTime) => {
    if (
      this.state.isAd === false
      && this.bitmovinClientInstance.state.isSnapback === false
      && this.bitmovinClientInstance.state.snapForwardTime === 0
    ) {
      const progressRecordingTimeDelta = Math.abs(contentTime - this.state.previousProgressPosition);
      if ((progressRecordingTimeDelta > this.progressFrequency)) {
        this.state.previousProgressPosition = contentTime;
        this.dispatchEvent('Progress', event);
      }
    }
  };

  private onPlay = (event) => {
    this.dispatchEvent('Play', event);
  };

  private onPlaying = (event) => {
    this.dispatchPlayStarted(event);
  };

  private checkMilestone = (event) => {
    const { isPlaying } = this.state;
    const contentTime = this.bitmovinClientInstance.getCurrentContentPosition();
    const streamTime = this.bitmovinClientInstance.getStreamTime();
    const streamTimeDelta = Math.abs(streamTime - this.state.previousTimeChangedCheck);

    if (streamTimeDelta > 5) {
      const newMilestoneReached = this.getMilestoneReached(contentTime);

      this.dispatchMilestone(event, newMilestoneReached, isPlaying);

      this.state.previousTimeChangedCheck = streamTime;
    }
  };

  private onTimeChanged = (event) => {
    const { isSeeking } = this.state;
    if (isSeeking === true) {
      return;
    }

    if (this.state.isAd && !this.googleDaiInstance) {
      this.dispatchEvent('AdPlaying', { adProgressMetadata: { adBreakDuration: this.state.currentAdDuration } });
    }

    const contentTime = this.bitmovinClientInstance.getCurrentContentPosition();
    this.state.lastContentTime = contentTime;

    // There was cases where 'Play' event wasn't received from Bitmovin, so we send PlayStarted here if needed.
    this.dispatchPlayStarted(event);

    this.dispatchProgress(event, contentTime);

    if (this.state.isAd === false) {
      if (event.time > this.state.furthestPosition) {
        this.state.furthestPosition = event.time;
      }
      this.checkMilestone(event);
      this.dispatchContentStarted();
    }

    this.dispatchEvent('Playing', event);
  };

  private onBitmovinAdStarted = (event) => {
    this.state.isAd = true;

    const { ad } = event;
    this.state.currentAdDuration = ad.duration;
    const adInfo: AdMetadata = {
      id: ad.id,
      duration: ad.duration,
      name: ad.data.adTitle,
      position: null, // N/A
      description: ad.data.adDescription,
      totalAds: null, // N/A
      creativeId: ad.data.creative.id,
      orderId: null, // N/A
      podIndex: null, // @TODO, to be implemented when we will support midroll on livestream
    };
    this.state.currentAdMetadata = adInfo;

    const customEvent = event;
    customEvent.adMetadata = adInfo;
    this.dispatchEvent('AdStarted', customEvent);
  };

  /**
   * Calling all handlers that have registered to the event
   * @param eventName
   * @param event
   */
  public dispatchEvent = (eventName: string, event: any) => {
    if (this.handlers[eventName].length > 0) {
      if (
        eventName.indexOf('Progress') === -1
        && eventName.indexOf('Playing') === -1
      ) {
        Logger.info(`PlayerEvents: dispatching ${eventName} event${event.issuer ? ` issued by ${event.issuer}` : ''}`, event);
      }

      const customEvent = event || {};
      customEvent.currentChapterData = this.bitmovinClientInstance.getChapterData();

      for (let hi = 0; hi < this.handlers[eventName].length; hi += 1) {
        const eventHandler = this.handlers[eventName][hi];
        if (typeof eventHandler === 'function') {
          eventHandler(customEvent);
        }
      }

      this.handlers[eventName] = this.handlers[eventName].filter((handler) => {
        return handler !== null;
      });
    }
  };

  /**
   * Registering handler
   * @param eventName
   * @param handlerFunction
   */
  public on = <T>(eventName: string | string[], handlerFunction: (args?: T) => void): void => {
    let eventNames = [];
    if (typeof eventName === 'string') {
      eventNames.push(eventName);
    } else {
      eventNames = [...eventName];
    }

    for (let ei = 0; ei < eventNames.length; ei += 1) {
      const event = eventNames[ei];

      if (Object.prototype.hasOwnProperty.call(this.handlers, event)) {
        this.handlers[event].push(handlerFunction);
      } else {
        Logger.warn(`PlayerEvents: unknown event ${event}`);
      }
    }
  };

  /**
   * Unregistering handler
   * @param eventName
   * @param handlerFunction
   */
  public off = (eventName: string, handlerFunction: <T = unknown>(args?: T) => void) => {
    if (this.handlers) {
      for (let hi = 0; hi < this.handlers[eventName].length; hi += 1) {
        const handler = this.handlers[eventName][hi];
        const matching = handler === handlerFunction;
        if (matching) {
          // Setting to null instead of deleting the element from the array
          // as this can break the loop from dispatchEvent() above
          this.handlers[eventName][hi] = null;
        }
      }
    }
  };
}

export default PlayerEvents;
