import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PlaybackStreamNotFoundError, PlaybackStreamUnauthorisedError } from '@@src/apis/PlaybackApi';
import GoBackButton from '@@src/components/Buttons/GoBackButton';
import ChromeCastRemotePlayer from '@@src/components/ChromeCast/ChromeCastRemotePlayer';
import { NotFoundError, VideoLoadingError } from '@@src/components/Error/Error';
import { getImageUrl, getResizedUrl } from '@@src/components/Html/Image';
import { dispatchOpenSignIn } from '@@src/components/Login/LoginForm';
import { useAppSelector } from '@@src/hooks/store';
import useGetVideoData from '@@src/hooks/useGetVideoData';
import VideoPlayerContainer from '@@src/pages/VideoPlayerContainer';
import Logger from '@@src/utils/logger/Logger';
import { getUserId } from '@@stores/UserStore';
import { getCasting } from '@@stores/chromeCastStore';
import grey from '@@styles/colors/grey';
import { SSRFunctionComponent } from '@@types/ssr';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      backgroundColor: `${grey.black}`,
      position: 'fixed',
      right: 0,
      bottom: 0,
      top: 0,
      left: 0,
      fontSize: '1rem',
      '&.debug::before': {
        content: '"v2"',
        position: 'absolute',
        zIndex: 9,
        top: 10,
        right: 10,
        textShadow: '0px 0px 10px #ffffff',
      },
    },
    playerBackgroundImage: {
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    castOverlay: {
      background: 'rgba(0,0,0,0.3)',
      height: '100%',
      width: '100%',
      zIndex: -1,
    },
  });
});

interface WatchPageV2Props {
  onLoadSuccess?: (title: string) => void,
  onLoadError?: (errorCode: string, errorMessage: string) => void,
}

const WatchPageV2: SSRFunctionComponent<WatchPageV2Props> = (props) => {
  const { onLoadSuccess = () => {}, onLoadError = () => {} } = props;
  const { id } = useParams<{ id: string }>();

  const [error, setError] = useState<Error | null>(null);
  const [videoId, setVideoId] = useState<string>(id);
  const [autoPlay, setAutoPlay] = useState<boolean | undefined>(undefined);

  const { i18n: { language } } = useTranslation();
  const classes = useStyles(props);
  const userId = useAppSelector(getUserId);
  const isCasting = useAppSelector(getCasting);

  const {
    playbackStreamData,
    ozTamSessionId,
    video,
    resumePosition,
    closePath,
    error: videoDataError,
  } = useGetVideoData(videoId, language, userId);

  /**
   * When stop casting, do not autoplay the current video after it's being re-loaded into the player
   */
  useEffect(() => {
    if (!isCasting && autoPlay === true) {
      setAutoPlay(false);
    }

    if (isCasting) {
      setAutoPlay(true);
    }
  }, [isCasting, autoPlay]);

  useEffect(() => {
    setVideoId(id);
  }, [id]);

  useEffect(() => {
    if (playbackStreamData) {
      // After each video change, allow autoplay's default behaviour
      setAutoPlay(undefined);
      onLoadSuccess(playbackStreamData.title);
    }
  }, [playbackStreamData, onLoadSuccess]);

  useEffect(() => {
    if (videoDataError) {
      setError(videoDataError);
    }
  }, [videoDataError]);

  useEffect(() => {
    if (videoId && !/^\d+$/.exec(videoId)) {
      onLoadError('404', 'Page Not Found');
    } else if (error instanceof PlaybackStreamNotFoundError) {
      onLoadError('404', 'Page Not Found');
    } else if (error instanceof PlaybackStreamUnauthorisedError) {
      onLoadError('403', 'Unauthorised');
    } else if (error) {
      onLoadError('500', error.message);
    }
  }, [error, onLoadError, videoId]);

  if (!/^\d+$/.exec(videoId)) {
    return <NotFoundError/>;
  }

  if (error instanceof PlaybackStreamNotFoundError) {
    return <NotFoundError/>;
  }

  if (error instanceof PlaybackStreamUnauthorisedError) {
    Logger.debug('This video requires sign-in, opening sign-in form');
    dispatchOpenSignIn();
    setError(null);
  } else if (error) {
    return <VideoLoadingError/>;
  }

  const backgroundImageId = video && 'odImageId' in video ? video.odImageId : null;

  const ogImageUrl = video && 'ogImageId' in video && video.ogImageId ? getResizedUrl(
    { imageId: video.ogImageId },
    { width: 640, height: 360 },
  ) : null;

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"/>
        <title>{playbackStreamData?.title}</title>
        {ogImageUrl && <meta property="og:image" content={ogImageUrl}/>}
      </Helmet>
      <div
        className={clsx(classes.root, backgroundImageId && classes.playerBackgroundImage, { debug: process.env.BVAR_PLAYER_DEBUG === 'true' })}
        style={{
          ...(backgroundImageId && { backgroundImage: `url(${getImageUrl(backgroundImageId)})` }),
        }}
      >

        {!isCasting ? (
          <VideoPlayerContainer
            playbackStreamData={playbackStreamData}
            resumePosition={resumePosition}
            setError={setError}
            video={video}
            setVideoId={setVideoId}
            ozTamSessionId={ozTamSessionId}
            userId={userId}
            closePath={closePath}
            adClickElementId="adclick"
            autoPlay={autoPlay}
          />
        ) : (
          <>
            <GoBackButton backUrl={closePath}/>
            <div className={clsx(classes.castOverlay)}/>
          </>
        )}
      </div>
      <ChromeCastRemotePlayer/>
    </>
  );
};

export default WatchPageV2;
