import { Box, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { FunctionComponent, useState } from 'react';

import Button from '../../../Inputs/Button';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      bottom: 174,
      right: 57,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      '.isMobileOnly &': {
        display: 'none',
      },
    },
  });
});

export interface SeekButtonProps {
  /**
   * The seek function
   */
  seekFn(seekTo: number): void;

  /**
   * The content time to where the player should seek to
   */
  seekTo: number;

  onClick?(): void;
}

/**
 * A button that will perform a seek and hide itself when clicked.
 * Positioned at the bottom right corner.
 * @param props
 * @constructor
 */
const SeekButton: FunctionComponent<SeekButtonProps> = (props) => {
  const classes = useStyles({});
  const {
    seekFn, seekTo, onClick, children,
  } = props;

  const [show, setShow] = useState<boolean>(true);

  const handleClick = () => {
    setShow(false);
    seekFn(seekTo);
    if (onClick) {
      onClick();
    }
  };

  if (!show) return null;

  return (
    <div className={classes.root}>
      <Box mb={2}>
        <Button buttonType="secondary" size="large" onClick={handleClick}>
          {children}
        </Button>
      </Box>
    </div>
  );
};

export default SeekButton;
