import { isMobile } from 'react-device-detect';

import VideoPlayerPluginV2WithReactPortal from '../VideoPlayerPluginV2WithReactPortal';
import RecommendationEndCardPluginComponent from './RecommendationEndCardPluginComponent';

class RecommendationEndCardPluginV2 extends VideoPlayerPluginV2WithReactPortal {
  public shouldLoad(): boolean {
    return !isMobile && !!this.videoPlayer.getRecommendationEndpoint();
  }

  protected component(): React.ReactNode {
    let displayTime = null;
    const creditsBeginTime = this.videoPlayer.getTrackingTime('credits_begin');
    if (creditsBeginTime) {
      // display time is 3 seconds after the credit time
      displayTime = creditsBeginTime + 3;
    }

    return (
      <RecommendationEndCardPluginComponent
        displayTime={displayTime}
        bitmovinClient={this.bitmovinClient}
        video={this.videoPlayer.getVideo()}
        playerMetadata={this.videoPlayer.getPlayerMetadata()}
      />
    );
  }
}

export default RecommendationEndCardPluginV2;
