/**
 * Based on: https://github.com/bitmovin/bitmovin-player-web-analytics-conviva/blob/v4.0.3/src/ts/ContentMetadataBuilder.ts
 */
import Conviva, { ConvivaMetadata } from '@convivainc/conviva-js-coresdk';
import { get } from 'lodash';

import Logger from '@@utils/logger/Logger';

import ContentMetadata, { CustomContentMetadata } from './ContentMetadata';

export type StreamType = 'LIVE' | 'VOD' | 'UNKNOWN';

export interface Metadata {
  // Can only be set once
  assetName?: string;

  // Can only be set before playback starteasd
  viewerId?: string;
  streamType?: StreamType;
  applicationName?: string;
  duration?: number;

  // Dynamic
  streamUrl?: string;

  custom?: CustomContentMetadata;
}

export class ContentMetadataBuilder {
  // The actual content metadata sent to Conviva
  // It's build from metadataOverrides and metadata below
  private contentMetadata: ContentMetadata;

  // internal metadata fields to enable merging / overriding
  private metadata: Metadata = {};
  private metadataOverrides: Metadata = {}; // Used to update metadata when more info is available after session started
  private playbackStarted: boolean = false;

  constructor() {
    this.contentMetadata = new ContentMetadata();
  }

  /**
   * This method is used for custom content metadata updates during / before a session.
   * @param newValue
   */
  setOverrides(newValue: Metadata) {
    if (this.playbackStarted) {
      Logger.warn('Conviva Analytics: Playback has started. Only some metadata attributes will be updated');
    }

    this.metadataOverrides = { ...this.metadataOverrides, ...newValue };
  }

  getOverrides(): Metadata {
    return this.metadataOverrides;
  }

  setPlaybackStarted(value: boolean) {
    this.playbackStarted = value;
  }

  build(): ConvivaMetadata {
    if (!this.playbackStarted) {
      // Asset name is only allowed to be set once
      if (!this.contentMetadata.assetName) {
        this.contentMetadata.assetName = this.assetName;
      }

      this.contentMetadata.streamType = get(
        this.metadataOverrides,
        'streamType',
        get(
          this.metadata,
          'streamType',
          'VOD',
        ),
      );
      this.contentMetadata.applicationName = get(this.metadataOverrides, 'applicationName', get(this.metadata, 'applicationName', ''));
      this.contentMetadata.custom = this.custom;
    }

    this.contentMetadata.streamUrl = get(this.metadataOverrides, 'streamUrl', get(this.metadata, 'streamUrl', ''));
    this.contentMetadata.viewerId = get(this.metadataOverrides, 'viewerId', get(this.metadata, 'viewerId', ''));

    const convivaContentInfo = {} as ConvivaMetadata;
    convivaContentInfo[Conviva.Constants.ASSET_NAME] = this.contentMetadata.assetName;
    convivaContentInfo[Conviva.Constants.STREAM_URL] = this.contentMetadata.streamUrl;
    convivaContentInfo[Conviva.Constants.IS_LIVE] = this.contentMetadata.streamType;
    convivaContentInfo[Conviva.Constants.PLAYER_NAME] = this.contentMetadata.applicationName;
    convivaContentInfo[Conviva.Constants.VIEWER_ID] = this.contentMetadata.viewerId;

    return {
      ...convivaContentInfo,
      ...this.contentMetadata.custom,
    };
  }

  // These methods should be treated as package private
  set assetName(newValue: string) {
    this.metadata.assetName = newValue;
  }

  get assetName(): string {
    return get(this.metadataOverrides, 'assetName', get(this.metadata, 'assetName', ''));
  }

  set viewerId(newValue: string) {
    this.metadata.viewerId = newValue;
  }

  get viewerId(): string {
    return get(this.metadataOverrides, 'viewerId', get(this.metadata, 'viewerId', ''));
  }

  set streamType(newValue: StreamType) {
    this.metadata.streamType = newValue;
  }

  get streamType(): StreamType {
    return get(this.metadataOverrides, 'streamType', get(this.metadata, 'streamType', 'VOD'));
  }

  set applicationName(newValue: string | undefined) {
    this.metadata.applicationName = newValue;
  }

  get applicationName(): string | undefined {
    return this.metadata.applicationName;
  }

  set custom(newValue: CustomContentMetadata) {
    this.metadata.custom = newValue;
  }

  get custom(): CustomContentMetadata {
    return {
      ...this.metadataOverrides.custom,
      ...this.metadata.custom, // Keep our custom tags in case someone tries to override them
    };
  }

  set duration(newValue: number | undefined) {
    this.metadata.duration = newValue;
  }

  get duration(): number | undefined {
    return this.metadata.duration;
  }

  set streamUrl(newValue: string | undefined) {
    this.metadata.streamUrl = newValue;
  }

  get streamUrl(): string | undefined {
    return this.metadata.streamUrl;
  }

  public reset(): void {
    this.metadataOverrides = {};
    this.metadata = {};
    this.playbackStarted = false;
    this.contentMetadata = new ContentMetadata();
  }
}
