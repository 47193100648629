import { ClickOverlay, ControlBar, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { PlayerAPI } from 'bitmovin-player';
import { find } from 'lodash';

/**
 * Click overlay to toggle the display of the control bar on tap for mobile/tablets
 */
export default class ControlsToggleOverlay extends ClickOverlay {
  constructor(config = {}) {
    super(config);

    this.config = this.mergeConfig(config, {
      cssClasses: ['ui-controlstoggleoverlay'],
    }, this.config);
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    this.onClick.subscribe(() => {
      const uiComponents = uimanager.getUI().getComponents();

      const controlBar = find(uiComponents, (component) => {
        return component instanceof ControlBar;
      });

      if (controlBar) {
        if (controlBar.isShown()) {
          uimanager.onControlsHide.dispatch(uimanager.getUI());
        } else {
          uimanager.onControlsShow.dispatch(uimanager.getUI());
        }
      }
    });
  }
}
