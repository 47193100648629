import { UIConditionContext, UIContainer, UIManager } from '@sbs/bitmovin-player-ui';
import { PlayerModule as AnalyticsModule } from 'bitmovin-analytics';
import {
  AudioChangedEvent,
  HttpRequestType,
  PlaybackEvent,
  Player,
  PlayerAPI,
  PlayerEvent,
  PlayerEventBase,
  PlayerEventCallback,
  PlayerType,
  SeekEvent,
  SourceConfig,
  StreamType,
  SubtitleEvent,
  TimeMode,
  VideoPlaybackQualityChangedEvent,
  VolumeChangedEvent,
} from 'bitmovin-player';
import { ErrorEvent, MetadataEvent, UserInteractionEvent } from 'bitmovin-player/types/core/Events';
import { HttpRequest, HttpResponse, HttpResponseBody } from 'bitmovin-player/types/core/NetworkAPI';
import * as ls from 'local-storage';
import { find, get } from 'lodash';
import { DateTime } from 'luxon';
import { isIOS, isMobile, isSafari } from 'react-device-detect';

import UIFactory from '@@src/lib/VideoPlayerV2/BitmovinPlayerUI/UIFactory';
import SbsControlBar from '@@src/lib/VideoPlayerV2/BitmovinPlayerUI/components/SbsControlBar';
import { PlaybackStreamData } from '@@types/PlaybackStreamData';
import { PlayerUserSettings } from '@@utils/DataLayer';
import { BITMOVIN_ANALYTICS_LICENSE_KEY } from '@@utils/constants';
import Logger from '@@utils/logger/Logger';

import { SbsUIContainer, SbsUIVariant } from './BitmovinPlayerUI/components/SbsUIContainer';
import SeekBackButton from './BitmovinPlayerUI/components/SeekBackButton';
import SeekForwardButton from './BitmovinPlayerUI/components/SeekForwardButton';
import GoogleImaDaiApiLoadError from './StreamHandlers/GoogleImaDaiApiLoadError';
import { CuePoints, StreamData, StreamHandler } from './StreamHandlers/StreamHandler';
import StreamHandlerFactory from './StreamHandlers/StreamHandlerFactory';
import VideoPlayerEventManager, {
  EventHandlerPayloadMap,
  VideoPlayerEventBase,
  VideoPlayerEventType,
  VideoPlayerEvent, VideoPlayerEventCallback, VideoEventHandler, VideoPlayerCustomEvent,
} from './VideoPlayerEventManager';
import PlayerKeyboardControl from './plugins/PlayerKeyboardControls/PlayerKeyboardControls';

import './BitmovinPlayerUI/scss/player-ui.scss';

export { TimeMode };
export type { SourceConfig };

Player.addModule(AnalyticsModule);

export interface ChapterData {
  current: number;
  total: number;
}

interface LoadOptions {
  resumePosition?: number;
  backUrl?: string;
  pauseOnLoad?: boolean;
}

export interface VideoPlayerOptions {
  volume?: number;
  muted?: boolean;
  uiContainer?: HTMLElement;
  adClickElementId?: string;
  adBlockerDetected?: boolean;
  autoPlay?: boolean;
}

export interface PlayerInfo {
  name: string;
  sdk: string;
  placement: string;
}

interface PlayerSession {
  videoId: string;
  timestamp: number;
}

interface BitmovinEventToHandle {
  type: PlayerEvent;
  handler: PlayerEventCallback;
}

interface VideoPlayerEventToHandle {
  type: VideoPlayerEventType;
  handler: VideoEventHandler<VideoPlayerEventType>;
}

export default class VideoPlayer {
  private readonly player: PlayerAPI;
  private readonly eventManager: VideoPlayerEventManager;
  private uiManager: UIManager | undefined;
  private readonly videoElement: HTMLVideoElement;
  private readonly uiContainer?: HTMLElement;
  private streamHandler!: StreamHandler;
  private isSeeking: boolean = false;
  private isSourceLoaded: boolean = false;
  private streamErrorReason: string | undefined = undefined;
  private readonly maxHttpRequestRetryCount: number = 3;
  private httpRequestRetryCount: number = 0;
  private playerKeyboardControl: PlayerKeyboardControl | undefined = undefined;
  private loaded: boolean = false;
  private uiReady: boolean = false;
  private readonly adHolidayDuration: number = 60;
  private readonly adHolidayRecoveryDuration: number = 120;
  private adHolidayTimeoutHandler: ReturnType<typeof setTimeout> | undefined = undefined;
  private recordPlayerSessionInterval: ReturnType<typeof setInterval> | undefined = undefined;
  private readonly recordPlayerSessionFrequency = 10;
  private videoId: string | undefined = undefined;
  private readonly adClickElementId: string | undefined = undefined;
  private readonly bitmovinEventsToHandle: BitmovinEventToHandle[] = [];
  private readonly videoPlayerEventsToHandle: VideoPlayerEventToHandle[] = [];
  private readonly adBlockerDetected: boolean = false;
  private canLoad: boolean = true;

  constructor(containerElement: HTMLElement, videoElement: HTMLVideoElement, options: VideoPlayerOptions = {}) {
    this.videoElement = videoElement;

    const {
      volume,
      muted,
      uiContainer,
      adClickElementId,
      adBlockerDetected = false,
      autoPlay = undefined,
    } = options;

    this.adClickElementId = adClickElementId;
    this.adBlockerDetected = adBlockerDetected;

    this.player = new Player(containerElement, {
      key: process.env.BVAR_BITMOVIN_LICENSE_KEY || '',
      playback: {
        // Set autoplay to true only if it was explicitly allowed
        // or if not Mobile and not Safari
        autoplay: autoPlay || (autoPlay === undefined && !isMobile && !isSafari),
        preferredTech: isSafari ? [{ player: PlayerType.Native, streaming: StreamType.Hls }] : [{ player: PlayerType.Html5, streaming: StreamType.Hls }],
        ...(volume ? { volume } : null),
        ...(muted ? { muted } : null),
      },
      ui: false,
      advertising: {},
      ...((isSafari || isIOS) && {
        // This config is required for preprocessHttpResponse() below to work with Safari.
        tweaks: {
          native_hls_parsing: true,
        },
      }),
      network: {
        // @ts-ignore: that's how Bitmovin advised us to do
        retryHttpRequest: this.bitmovinRetryHttpRequest,
        preprocessHttpResponse: this.bitmovinPreprocessHttpResponse,
      },
      ...(BITMOVIN_ANALYTICS_LICENSE_KEY && {
        analytics: {
          key: BITMOVIN_ANALYTICS_LICENSE_KEY,
        },
      }),
    });

    if (process.env.BVAR_PLAYER_DEBUG === 'true') {
      window.player = this.player;
    }

    this.player.setVideoElement(this.videoElement);

    this.eventManager = new VideoPlayerEventManager();

    if (uiContainer) {
      this.uiContainer = uiContainer;
    }

    this.bitmovinEventsToHandle = [
      { type: PlayerEvent.Ready, handler: this.onReady },
      { type: PlayerEvent.SourceLoaded, handler: this.onSourceLoaded },
      { type: PlayerEvent.SourceUnloaded, handler: this.onSourceUnloaded },

      { type: PlayerEvent.Play, handler: this.onPlay },
      { type: PlayerEvent.Playing, handler: this.onPlaying as PlayerEventCallback },
      { type: PlayerEvent.Paused, handler: this.onPaused as PlayerEventCallback },
      { type: PlayerEvent.VideoPlaybackQualityChanged, handler: this.onVideoPlaybackQualityChanged as PlayerEventCallback },

      { type: PlayerEvent.TimeChanged, handler: this.onTimeChanged as PlayerEventCallback },

      // scrubbing events on vod
      { type: PlayerEvent.Seek, handler: this.onSeek as PlayerEventCallback },
      { type: PlayerEvent.Seeked, handler: this.onSeeked },

      // scrubbing events on live stream
      { type: PlayerEvent.TimeShift, handler: this.onSeek as PlayerEventCallback },
      { type: PlayerEvent.TimeShifted, handler: this.onSeeked },

      { type: PlayerEvent.PlaybackFinished, handler: this.onPlaybackFinished },

      { type: PlayerEvent.StallStarted, handler: this.onStallStarted },
      { type: PlayerEvent.StallEnded, handler: this.onStallEnded },

      { type: PlayerEvent.VolumeChanged, handler: this.onVolumeChanged as PlayerEventCallback },
      { type: PlayerEvent.Muted, handler: this.onMuted },
      { type: PlayerEvent.Unmuted, handler: this.onUnmuted },

      { type: PlayerEvent.SubtitleEnable, handler: this.onSubtitleEnable as PlayerEventCallback },
      { type: PlayerEvent.SubtitleDisable, handler: this.onSubtitleDisable as PlayerEventCallback },
      { type: PlayerEvent.AudioChanged, handler: this.onAudioChanged as PlayerEventCallback },

      { type: PlayerEvent.PlayerResized, handler: this.onPlayerResized },
      { type: PlayerEvent.ViewModeChanged, handler: this.onViewModeChanged },

      { type: PlayerEvent.Error, handler: this.onError as PlayerEventCallback },
      { type: PlayerEvent.Warning, handler: this.onWarning as PlayerEventCallback },

      { type: PlayerEvent.Metadata, handler: this.onMetadata as PlayerEventCallback },
    ];

    this.videoPlayerEventsToHandle = [
      { type: VideoPlayerEventType.TIME_CHANGED, handler: this.dispatchContentStarted },
      { type: VideoPlayerEventType.AD_FINISHED, handler: this.onAdFinished as VideoPlayerEventCallback },

      // Listen to the following events to trigger UI variant resolution
      { type: VideoPlayerEventType.PLAYER_RESIZED, handler: this.resolveUiVariant },
      { type: VideoPlayerEventType.CONTENT_STARTED, handler: this.resolveUiVariant },
      { type: VideoPlayerEventType.AD_BREAK_STARTED, handler: this.resolveUiVariant },
      { type: VideoPlayerEventType.AD_BREAK_FINISHED, handler: this.resolveUiVariant },
      { type: VideoPlayerEventType.VIEW_MODE_CHANGED, handler: this.resolveUiVariant },
      { type: VideoPlayerEventType.PLAYER_ERROR, handler: this.onPlayerError },
      { type: VideoPlayerEventType.READY, handler: this.resolveUiVariant },

      // Listen to the following events to show timeline markers,
      // make sure that the resolveUiVariant is called before the showTimelineMarkers
      { type: VideoPlayerEventType.READY, handler: this.showTimelineMarkers },
      { type: VideoPlayerEventType.CONTENT_STARTED, handler: this.showTimelineMarkers },
      { type: VideoPlayerEventType.AD_BREAK_FINISHED, handler: this.showTimelineMarkers },

      { type: VideoPlayerEventType.CUSTOM_EVENT, handler: this.onCustomEvent as VideoPlayerEventCallback },
    ];
  }

  get version() {
    return this.player.version;
  }

  get playerName() {
    return `ondemand:web:web ${isMobile ? 'mobile' : 'desktop'}:main:v5`;
  }

  private readonly bitmovinRetryHttpRequest = <T extends HttpResponseBody>(type: HttpRequestType, response: HttpResponse<T>): Promise<HttpRequest> | null => {
    // Retry failed video segment requests
    if (type === HttpRequestType.MEDIA_VIDEO || type === HttpRequestType.MANIFEST_HLS_VARIANT) {
      this.httpRequestRetryCount += 1;

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const retryLimitReached = this.httpRequestRetryCount >= this.maxHttpRequestRetryCount;

          if (retryLimitReached) {
            this.streamErrorReason = 'media-fetch-retry-maxed';
            this.eventManager.dispatch(VideoPlayerEventType.PLAYER_ERROR, {
              timestamp: this.getTimestamp(),
              name: 'mediaRequestTimeout',
              code: 'SE-001',
              message: `Media request timeout, re-attempt #${this.httpRequestRetryCount}`,
              data: {
                httpRequestRetryCount: this.httpRequestRetryCount,
                reason: 'media-fetch-retry-maxed',
              },
            });
            reject(new Error('Media request timed out after 3 retries'));
          } else {
            this.eventManager.dispatch(VideoPlayerEventType.PLAYER_WARNING, {
              timestamp: this.getTimestamp(),
              name: 'mediaRequestTimeout',
              code: 'SW-003',
              message: `Media request timeout, re-attempt #${this.httpRequestRetryCount}`,
              data: {
                httpRequestRetryCount: this.httpRequestRetryCount,
                reason: 'media-fetch-retry',
              },
            });
            resolve(response.request);
          }
        }, 1500);
      });
    }

    // on Safari, Bitmovin does not trigger an error failure loading master.m3u8
    // so we handle it ourselves.
    if ((isSafari || isIOS) && type === HttpRequestType.MANIFEST_HLS_MASTER) {
      return new Promise((_resolve, reject) => {
        this.eventManager.dispatch(VideoPlayerEventType.PLAYER_ERROR, {
          timestamp: this.getTimestamp(),
          name: 'manifestRequestFailed',
          code: 'SE-002',
          message: 'Failed loading HLS master manifest',
          data: {
            reason: this.streamErrorReason || response.statusText,
          },
        });
        reject(new Error('Manifest request failed'));
      });
    }

    return null;
  };

  private readonly bitmovinPreprocessHttpResponse = <T extends HttpResponseBody>(type: string, response: HttpResponse<T>): Promise<HttpResponse<T>> => {
    return new Promise((resolve) => {
      // Extract Akamai HTTP X-Error-Reason Header
      if (type === HttpRequestType.MANIFEST_HLS_MASTER) {
        Object.keys(response.headers).forEach((header) => {
          if (header === 'x-error-reason') {
            this.streamErrorReason = response.headers[header];
          }
        });
      }
      resolve(response);
    });
  };

  private readonly generateUiVariants = (): Record<string, SbsUIVariant> => {
    const smallScreenSwitchWidth = 1280;
    /* istanbul ignore next */
    return {
      error: {
        ui: UIFactory.sbsError(),
        condition: (context) => {
          // @ts-ignore: need to augment this type
          return context.hasError;
        },
      },
      loadingSmall: {
        ui: UIFactory.sbsMinimalSmallUI(isMobile),
        condition: (context) => {
          // @ts-ignore: need to augment this type
          return !context.hasPlaybackStarted
            && context.documentWidth < smallScreenSwitchWidth;
        },
      },
      loading: {
        ui: UIFactory.sbsMinimalUI(),
        condition: (context) => {
          // @ts-ignore: need to augment this type
          return !context.hasPlaybackStarted;
        },
      },
      adsSmall: {
        ui: UIFactory.sbsMinimalSmallUI(isMobile),
        condition: (context) => {
          return context.isAd
            // @ts-ignore: need to augment this type
            && !context.isAdHoliday
            && context.documentWidth < smallScreenSwitchWidth;
        },
      },
      ads: {
        ui: UIFactory.sbsMinimalUI(),
        condition: (context) => {
          return context.isAd
            // @ts-ignore: need to augment this type
            && !context.isAdHoliday;
        },
      },
      small: {
        ui: UIFactory.sbsSmallUI(isMobile),
        condition: (context) => {
          return (
            !context.isAd
            // @ts-ignore: need to augment this type
            || context.isAdHoliday
          )
          && context.documentWidth < smallScreenSwitchWidth;
        },
      },
      default: {
        ui: UIFactory.sbsUI(),
      },
    };
  };

  private loadUi() {
    if (this.uiManager instanceof UIManager) {
      return;
    }

    try {
      const uiVariants = this.generateUiVariants();
      this.uiManager = new UIManager(this.player, Object.values(uiVariants), {
        autoUiVariantResolve: false,
        disableAutoHideWhenHovered: true,
        playbackSpeedSelectionEnabled: false,
        seekbarSnappingRange: 0,
        enableSeekPreview: false,
        ...(this.uiContainer ? { container: this.uiContainer } : null),
      });

      this.uiManager.onUiVariantResolve.subscribe(this.onUiVariantResolve);
      this.uiManager.onActiveUiChanged.subscribe(this.onActiveUiChanged);

      this.uiManager.getUiVariants().forEach((uiVariant) => {
        const { ui: uiContainer } = uiVariant;

        // SbsUIContainer allow us to do more things
        if (uiContainer instanceof SbsUIContainer) {
          uiContainer.onControlsShow.subscribe(this.onUIControlsShow);
          uiContainer.onControlsHide.subscribe(this.onUIControlsHide);

          // find all seek forward buttons and subscribe to it so we can dispatch UI_SEEK_FORWARD event
          const seekForwardButtons = uiContainer.findComponents(SeekForwardButton);
          seekForwardButtons.forEach((button) => {
            button.onClick.subscribe(this.onUISeekForwardButtonClick);
          });

          // find all seek back buttons and subscribe to it so we can dispatch UI_SEEK_BACK event
          const seekBackButtons = uiContainer.findComponents(SeekBackButton);
          seekBackButtons.forEach((button) => {
            button.onClick.subscribe(this.onUISeekBackButtonClick);
          });
        }
      });

      this.playerKeyboardControl = new PlayerKeyboardControl(this.player, this.uiManager, this.eventManager);
      this.eventManager.dispatch(VideoPlayerEventType.UI_LOADED, {
        timestamp: new Date().getTime(),
        ui: this.uiManager.activeUi,
      });

      Logger.debug('Video player ui is loaded');
    } catch (error) {
      Logger.error('Error loading video player ui', {
        error,
      });
    }
  }

  /**
   * Load the stream handler from the first provider in the list. If it fails, it will try the next one.
   * @param videoId
   * @param streamProviderConfigs
   */
  public async loadStreamHandler(videoId: string, streamProviderConfigs: PlaybackStreamData['streamHandlerConfigs']): Promise<StreamData | null> {
    this.canLoad = true;
    if (streamProviderConfigs.length === 0) {
      throw new Error('Unable to load stream, no valid stream providers.');
    }

    const streamProviderConfig = streamProviderConfigs[0];
    const { type: streamProviderType } = streamProviderConfig;

    // In case of an error, we need to load the player UI and make sure it loads the Error UI variant.
    const resolveUiVariantPayload = {
      isAd: false,
      hasPlaybackStarted: false,
      hasContentStarted: false,
      isAdHoliday: false,
      timestamp: new Date().getTime(),
      timelineMarkers: [],
    };

    try {
      this.streamHandler = await StreamHandlerFactory.create(
        streamProviderConfig,
        this.eventManager,
        this.player,
        this.videoElement,
        this.adClickElementId,
      );

      this.eventManager.dispatch(VideoPlayerEventType.STREAM_HANDLER_INITIALISED, { timestamp: this.getTimestamp() });
    } catch (error) {
      // if the error is caused by google ima dai api load error
      if (error instanceof GoogleImaDaiApiLoadError) {
        // Now that loadStreamHandler() is called outside of this class (in VideoPlayerContainer.tsx),
        // we need to manually load the player UI if there is an load error at this stage\
        // and make sure we resolve the UI variant to use the error UI.
        this.streamErrorReason = 'daiload-failed';
        this.loadUi();
        this.resolveUiVariant(resolveUiVariantPayload);

        this.player.unload();
        this.eventManager.dispatch(VideoPlayerEventType.PLAYER_ERROR, {
          timestamp: new Date().getTime(),
          name: 'daiLoadFailed',
          code: 'SE-003',
          message: 'Failed loading DAI',
          data: { reason: this.streamErrorReason },
        });

        return null;
      }

      Logger.error('Error when creating a stream handler', {
        streamProvider: streamProviderType,
        error,
      });
    }

    if (this.streamHandler) {
      return this.streamHandler.load()
        .catch((error) => {
          Logger.error('Error when loading stream', { error });

          // We did detect an adblocker during page load
          if (
            error?.message?.includes('HTTP status code: 0')
            || this.adBlockerDetected
            || window.od_abd === true
          ) {
            // See the comment about loadUI() in the daiload-failed block above
            this.streamErrorReason = 'adblocker-detected';
            this.loadUi();
            this.resolveUiVariant(resolveUiVariantPayload);

            this.player.unload();
            this.eventManager.dispatch(VideoPlayerEventType.PLAYER_ERROR, {
              timestamp: new Date().getTime(),
              name: 'adblockerDetected',
              code: 'SE-004',
              message: 'Ad blocker detected',
              data: { reason: this.streamErrorReason },
            });

            return null;
          }

          // load the next stream provider
          return this.loadStreamHandler(videoId, streamProviderConfigs.slice(1));
        });
    }

    // load the next stream provider
    return this.loadStreamHandler(videoId, streamProviderConfigs.slice(1));
  }

  /**
   * Load the video. It will load a stream handler and then load the player with the stream url that comes back from the stream handler.
   * @param videoStreamData
   * @param loadOptions
   // * @param convivaOptions An object required to instantiate conviva or null to disable conviva
   */
  public async load(
    videoStreamData: StreamData & { title: string, mpxId: string, userId?: string },
    loadOptions: Partial<LoadOptions> = {},
  ): Promise<void> {
    Logger.debug('Video player is loading');

    // Cancel the load process if the player has been closed before the stream handler request async operation has completed
    if (!this.canLoad) {
      return;
    }

    this.loadUi();

    this.videoId = videoStreamData.mpxId;
    this.registerPlayerEvents();

    let resumePosition = 0;

    if (this.streamHandler) {
      const contentResumePosition = loadOptions?.resumePosition ?? 0;
      resumePosition = contentResumePosition > 0 ? this.streamHandler.streamTimeForContentTime(contentResumePosition) : 0;
    }

    if (resumePosition > 0) {
      Logger.debug(`Resuming to ${resumePosition}`);
    }

    try {
      await this.player.load({
        title: videoStreamData.title,
        hls: videoStreamData.hlsUrl,
        ...(!!resumePosition && {
          options: {
            startOffset: resumePosition,
          },
        }),
        metadata: {
          backUrl: loadOptions?.backUrl ?? '',
        },
        ...(BITMOVIN_ANALYTICS_LICENSE_KEY && {
          analytics: {
            videoId: videoStreamData.mpxId,
            title: videoStreamData.title,
            userId: videoStreamData.userId || undefined,
          },
        }),
      });

      // Cancel the load process if the player has been closed before the Bitmovin load async operation has completed
      if (!this.canLoad) {
        this.player.pause();
        await this.player.unload();
      }

      if (videoStreamData.textTracks) {
        videoStreamData.textTracks.forEach((testTrack, index) => {
          const subtitle = {
            id: `sub${index + 1}`,
            lang: testTrack.language,
            label: testTrack.label,
            url: testTrack.url,
            kind: 'subtitle',
          };

          this.player.subtitles.add(subtitle);
        });
      }

      this.loaded = true;
      Logger.debug('Video player is loaded');
    } catch (error) {
      // raise error loading stream
      Logger.error('Bitmovin failed loading the stream', { error });
    }
  }

  private pendingUnloadPromise: Promise<void> | undefined;
  private resolveUnload: (() => void) | undefined;

  public getStreamTime = (): number => {
    return this.player.getCurrentTime();
  };

  public getCurrentContentTime = (): number => {
    const streamTime = this.player.getCurrentTime();
    return this.streamHandler.contentTimeForStreamTime(streamTime);
  };

  public getCurrentChapterData = (streamTime?: number): ChapterData => {
    // When you start the video from the beginning, it's chapter 1
    let currentChapter = 1;
    const currentTime = streamTime ?? this.getStreamTime();
    const cuePoints = this.streamHandler.getCuePoints();

    for (let ci = 0; ci < cuePoints.length; ci += 1) {
      const marker = cuePoints[ci];

      if (currentTime >= marker.start) {
        // marker at index 0 is chapter 1
        currentChapter = ci + 1;

        // if the first marker has a time greater than 0,
        // this means from beginning to timelineMarkers[0] is chapter 1
        // between timelineMarkers[0] and timelineMarkers[1] is chapter 2
        if (cuePoints[0].start > 0) {
          currentChapter += 1;
        }
      }
    }

    return {
      current: currentChapter,
      total: cuePoints.length > 0 ? cuePoints.length : 1,
    };
  };

  public getCurrentTime = (mode: TimeMode): number => {
    return this.player.getCurrentTime(mode);
  };

  public cancelLoad = () => {
    this.canLoad = false;
  };

  public isLoadCancelled = () => {
    return this.canLoad === false;
  };

  /**
   * Unload the player
   */
  public async unload(): Promise<void> {
    Logger.debug('Video player is unloading');

    if (this.playerKeyboardControl) {
      this.playerKeyboardControl.destroy();
    }

    if (this.uiManager) {
      this.uiManager.onUiVariantResolve.unsubscribe(this.onUiVariantResolve);
      this.uiManager.onActiveUiChanged.unsubscribe(this.onActiveUiChanged);
      // this will remove all the event listeners from the uiManager and all ui instance managers
      this.uiManager.release();
      this.uiManager = undefined;
      Logger.debug('Video player ui is unloaded');
    }

    await this.player.unload();

    // unloading streamhandler here because BM's SourceUnloaded is triggering the preroll to play again and in turn
    // triggers streamhandler ad start events.
    this.streamHandler?.unload();

    // If source has not been loaded, we can deregister player events and clear the event manager handlers here
    if (!this.isSourceLoaded) {
      this.deregisterPlayerEvents();
      Logger.debug('Video player is unloaded');
      return undefined;
    }

    this.loaded = false;

    /**
     * If source has been loaded...
     * The bitmovin player unload would return before the PlayerEvent.SourceUnloaded is raised
     * in order to make sure that we clean up the events on unload,
     * we would return a promise which will be resolved once the PlayerEvent.SourceUnloaded is raised
     */
    if (typeof this.pendingUnloadPromise === 'undefined') {
      this.pendingUnloadPromise = new Promise((resolve) => {
        this.resolveUnload = resolve;
      });
    }

    return this.pendingUnloadPromise;
  }

  /**
   * Adds an event handler to a video player event
   * @param eventType
   * @param callback
   */
  public on(eventType: VideoPlayerEventType, callback: (event: VideoPlayerEventBase) => void) {
    this.eventManager.addEventHandler(eventType, callback);
  }

  /**
   * Removes an event handler from a video player event
   * @param eventType
   * @param callback
   */
  public off(eventType: VideoPlayerEventType, callback: (event: VideoPlayerEventBase) => void) {
    this.eventManager.removeEventHandler(eventType, callback);
  }

  /**
   * Register general player events
   * Any events that are specific to the stream handler should be implemented in the stream handler, eg: ad events
   * @private
   */
  private registerPlayerEvents(): void {
    this.bitmovinEventsToHandle.forEach((event) => {
      this.player.on(event.type, event.handler);
    });

    this.videoPlayerEventsToHandle.forEach((event) => {
      this.eventManager.addEventHandler(event.type, event.handler);
    });
  }

  private deregisterPlayerEvents(): void {
    this.bitmovinEventsToHandle.forEach((event) => {
      this.player.off(event.type, event.handler);
    });

    this.videoPlayerEventsToHandle.forEach((event) => {
      this.eventManager.removeEventHandler(event.type, event.handler);
    });
  }

  private readonly onReady = (event: PlayerEventBase) => {
    this.eventManager.dispatch(VideoPlayerEventType.READY, { timestamp: event.timestamp });
  };

  private readonly onSourceLoaded = (event: PlayerEventBase) => {
    this.eventManager.dispatch(VideoPlayerEventType.SOURCE_LOADED, { timestamp: event.timestamp });
    this.isSourceLoaded = true;
    this.checkPreviousPlayerSession();
  };

  private readonly onSourceUnloaded = (event: PlayerEventBase) => {
    this.eventManager.dispatch(VideoPlayerEventType.SOURCE_UNLOADED, { timestamp: event.timestamp });

    this.deregisterPlayerEvents();

    // once the events are all deregistered, we will resolve the unload promise
    if (this.resolveUnload) {
      Logger.debug('Video player is unloaded');
      this.resolveUnload();
    }

    this.pendingUnloadPromise = undefined;
    this.resolveUnload = undefined;
    this.isSourceLoaded = false;
    clearInterval(this.recordPlayerSessionInterval);
    clearTimeout(this.adHolidayTimeoutHandler);
    this.eventManager.updateState('isAdHoliday', undefined);
    this.eventManager.updateState('hasPlaybackStarted', false);
    this.eventManager.updateState('hasContentStarted', false);
  };

  private readonly onPlay = (event: PlayerEventBase) => {
    this.eventManager.dispatch(VideoPlayerEventType.PLAY, { timestamp: event.timestamp });
  };

  private readonly onPlaying = (event: PlaybackEvent) => {
    this.eventManager.dispatch(VideoPlayerEventType.PLAYING, { time: event.time, timestamp: event.timestamp });
  };

  private readonly onVideoPlaybackQualityChanged = (event: VideoPlaybackQualityChangedEvent) => {
    this.eventManager.dispatch(VideoPlayerEventType.VIDEO_PLAYBACK_QUALITY_CHANGED, { timestamp: event.timestamp, bitrate: get(event.targetQuality, 'bitrate') });
  };

  private readonly onTimeChanged = (event: PlaybackEvent) => {
    this.eventManager.dispatch(VideoPlayerEventType.TIME_CHANGED, { time: event.time, timestamp: event.timestamp });
  };

  private readonly onAdFinished = (event: VideoPlayerEvent<VideoPlayerEventType.AD_FINISHED>) => {
    const { adMetadata } = event;

    // We are checking the AD_FINISHED event for starting the ad holiday on the last ad instead of using AD_BREAK_FINISHED
    // because there are cases where AD_BREAK_STARTED and AD_BREAK_FINISHED are triggered with no ad inside, so using AD_BREAK_FINISHED
    // can lead to ad holiday being offered while no ad were playing.
    if (
      adMetadata
      // if it's the last ad in the pod
      && adMetadata.position === adMetadata.totalAds
    ) {
      // ad holiday has not started yet during this session
      if (event.isAdHoliday === undefined) {
        this.startAdHoliday();
      }
    }
  };

  private readonly dispatchContentStarted = (event: VideoPlayerEventBase) => {
    if (!event.hasPlaybackStarted) {
      this.eventManager.updateState('hasPlaybackStarted', true);
      this.eventManager.dispatch(VideoPlayerEventType.PLAYBACK_STARTED, { timestamp: event.timestamp });
    }

    if (!event.hasContentStarted && !event.isAd) {
      this.eventManager.updateState('hasContentStarted', true);
      this.eventManager.dispatch(VideoPlayerEventType.CONTENT_STARTED, { timestamp: event.timestamp });

      if (!this.recordPlayerSessionInterval) {
        this.recordPlayerSession();
        this.recordPlayerSessionInterval = setInterval(() => {
          this.recordPlayerSession();
        }, this.recordPlayerSessionFrequency * 1000);
      }
    }
  };

  private readonly onPaused = (event: PlaybackEvent) => {
    const { timestamp, time, issuer } = event;
    this.eventManager.dispatch(VideoPlayerEventType.PAUSED, { timestamp, time, issuer });
  };

  private readonly onSeek = (event: SeekEvent) => {
    const {
      timestamp, position, seekTarget, issuer,
    } = event;
    const positionContentTime = this.streamHandler.contentTimeForStreamTime(position);

    if (this.isSeeking) {
      this.eventManager.dispatch(VideoPlayerEventType.SEEK, { timestamp });
    } else {
      this.eventManager.dispatch(VideoPlayerEventType.SEEK_STARTED, {
        timestamp, position, positionContentTime, seekTarget, issuer,
      });
    }

    this.isSeeking = true;
  };

  private readonly onSeeked = (event: UserInteractionEvent) => {
    const { issuer } = event;
    this.isSeeking = false;
    this.eventManager.dispatch(VideoPlayerEventType.SEEK_FINISHED, { timestamp: event.timestamp, issuer });
  };

  private readonly onPlaybackFinished = (event: PlayerEventBase) => {
    clearTimeout(this.adHolidayTimeoutHandler);
    clearInterval(this.recordPlayerSessionInterval);
    this.eventManager.dispatch(VideoPlayerEventType.PLAYBACK_FINISHED, { timestamp: event.timestamp });
  };

  private readonly onStallStarted = (event: PlayerEventBase) => {
    this.eventManager.dispatch(VideoPlayerEventType.BUFFERING_STARTED, { timestamp: event.timestamp });
  };

  private readonly onStallEnded = (event: PlayerEventBase) => {
    this.eventManager.dispatch(VideoPlayerEventType.BUFFERING_FINISHED, { timestamp: event.timestamp });
  };

  private readonly onVolumeChanged = (event: VolumeChangedEvent) => {
    this.eventManager.dispatch(VideoPlayerEventType.VOLUME_CHANGED, { timestamp: event.timestamp, targetVolume: event.targetVolume });
  };

  private readonly onMuted = (event: PlayerEventBase) => {
    this.eventManager.dispatch(VideoPlayerEventType.MUTED, { timestamp: event.timestamp });
  };

  private readonly onUnmuted = (event: PlayerEventBase) => {
    this.eventManager.dispatch(VideoPlayerEventType.UNMUTED, { timestamp: event.timestamp });
  };

  private readonly onSubtitleEnable = (event: SubtitleEvent) => {
    this.eventManager.dispatch(VideoPlayerEventType.SUBTITLE_ENABLE, { timestamp: event.timestamp, subtitle: event.subtitle });
  };

  private readonly onSubtitleDisable = (event: SubtitleEvent) => {
    this.eventManager.dispatch(VideoPlayerEventType.SUBTITLE_DISABLE, { timestamp: event.timestamp, subtitle: event.subtitle });
  };

  private readonly onAudioChanged = (event: AudioChangedEvent) => {
    this.eventManager.dispatch(VideoPlayerEventType.AUDIO_CHANGED, { timestamp: event.timestamp, sourceAudio: event.sourceAudio, targetAudio: event.targetAudio });
  };

  private readonly onPlayerResized = (event: PlayerEventBase) => {
    this.eventManager.dispatch(VideoPlayerEventType.PLAYER_RESIZED, { timestamp: event.timestamp });
  };

  private readonly onViewModeChanged = (event: PlayerEventBase) => {
    this.eventManager.dispatch(VideoPlayerEventType.VIEW_MODE_CHANGED, { timestamp: event.timestamp });
  };

  private readonly onError = (event: ErrorEvent) => {
    const {
      name, code, message, data, timestamp,
    } = event;

    if (!this.streamErrorReason) {
      this.streamErrorReason = `${event.name}: ${event.message}`;
    }

    this.eventManager.dispatch(VideoPlayerEventType.PLAYER_ERROR, {
      timestamp,
      name,
      code: code.toString(),
      message: message || '',
      data: {
        ...data,
        reason: this.streamErrorReason,
      },
    });
  };

  private readonly onCustomEvent = (event: VideoPlayerCustomEvent) => {
    const { eventName } = event;

    if (eventName === 'adBlockerDetected') {
      this.streamErrorReason = 'adblocker-detected';
      this.resolveUiVariant(event);
      this.player.unload();
      this.eventManager.dispatch(VideoPlayerEventType.PLAYER_ERROR, {
        timestamp: new Date().getTime(),
        name: 'adblockerDetected',
        code: 'SE-005',
        message: 'Ad blocker detected',
        data: { reason: this.streamErrorReason },
      });
    }
  };

  private readonly onPlayerError = (event: VideoPlayerEventBase) => {
    this.player.unload().then(() => {
      this.resolveUiVariant(event);
    });
  };

  private readonly onWarning = (event: ErrorEvent) => {
    const {
      name, code, message, data,
    } = event;

    this.eventManager.dispatch(VideoPlayerEventType.PLAYER_WARNING, {
      timestamp: event.timestamp,
      name,
      code: code.toString(),
      message: message || '',
      data,
    });
  };

  private readonly onMetadata = (event: MetadataEvent) => {
    const {
      timestamp, metadataType, start, end,
    } = event;
    this.eventManager.dispatch(VideoPlayerEventType.METADATA, {
      timestamp, metadataType, metadata: event.metadata as Record<string, unknown>, start, end,
    });
  };

  private getTimestamp() {
    return DateTime.now().toMillis();
  }

  private readonly showTimelineMarkers = (event: VideoPlayerEventBase) => {
    const { timelineMarkers } = event;

    if (this.uiManager) {
      const { uiManager } = this;

      // clear current markers
      const currentMarkers = uiManager.getTimelineMarkers();
      [...currentMarkers].forEach((marker) => {
        uiManager.removeTimelineMarker(marker);
      });

      timelineMarkers.forEach((time) => {
        uiManager.addTimelineMarker({
          time,
        });
      });
    }
  };

  private readonly resolveUiVariant = (event: VideoPlayerEventBase) => {
    const {
      isAd, isAdHoliday, hasPlaybackStarted,
    } = event;

    if (this.uiManager) {
      const context: Partial<UIConditionContext> = {
        isAd,
        isAdHoliday,
        hasPlaybackStarted,
        adRequiresUi: isAd,
        documentWidth: document.body.clientWidth,
        hasError: this.hasError(),
      };
      this.uiManager.resolveUiVariant(context);
    }
  };

  private readonly onUIControlsShow = (sender: UIContainer) => {
    const isActiveUi = sender === this.uiManager?.activeUi.getUI();
    if (isActiveUi) {
      this.eventManager.dispatch(VideoPlayerEventType.UI_CONTROLS_SHOW, { timestamp: this.getTimestamp() });
    }
  };

  private readonly onUIControlsHide = (sender: UIContainer) => {
    const isActiveUi = sender === this.uiManager?.activeUi.getUI();
    if (isActiveUi) {
      this.eventManager.dispatch(VideoPlayerEventType.UI_CONTROLS_HIDE, { timestamp: this.getTimestamp() });
    }
  };

  private readonly onUiVariantResolve = (event: UIManager) => {
    this.eventManager.dispatch(VideoPlayerEventType.UI_VARIANT_RESOLVE, {
      timestamp: this.getTimestamp(),
      ui: event.activeUi,
    });
  };

  private readonly onActiveUiChanged = (event: UIManager) => {
    this.eventManager.dispatch(VideoPlayerEventType.UI_VARIANT_CHANGED, {
      timestamp: this.getTimestamp(),
      ui: event.activeUi,
    });

    // When starting a content without preroll (resuming for example),
    // we need to force show the UI on the first variant change.
    if (!this.uiReady) {
      event.activeUi.getUI().showUi();
      this.uiReady = true;
    }
  };

  private readonly onUISeekForwardButtonClick = () => {
    this.eventManager.dispatch(VideoPlayerEventType.UI_SEEK_FORWARD, { timestamp: DateTime.now().toMillis() });
  };

  private readonly onUISeekBackButtonClick = () => {
    this.eventManager.dispatch(VideoPlayerEventType.UI_SEEK_BACK, { timestamp: DateTime.now().toMillis() });
  };

  public getNextCuePointForStreamTime = (streamTime: number): CuePoints | null => {
    const cuePoints = this.streamHandler.getCuePoints();
    const cuePoint = cuePoints.find((c) => {
      return c.start >= streamTime;
    });

    return cuePoint || null;
  };

  public getPreviousCuePointForStreamTime = (streamTime: number): CuePoints | null => {
    return this.streamHandler.getPreviousCuePointForStreamTime(streamTime);
  };

  public dispatchCustomEvent = (name: string, payload: EventHandlerPayloadMap[VideoPlayerEventType.CUSTOM_EVENT]) => {
    this.eventManager.dispatchCustomEvent(name, payload);
  };

  public streamTimeForContentTime = (contentTime: number) => {
    return this.streamHandler.streamTimeForContentTime(contentTime);
  };

  public contentTimeForStreamTime = (streamTime: number) => {
    return this.streamHandler.contentTimeForStreamTime(streamTime);
  };

  public seek = (contentTime: number) => {
    this.player.seek(this.streamHandler.streamTimeForContentTime(contentTime));
  };

  public seekToStreamTime = (streamTime: number, issuer?: string) => {
    this.player.seek(streamTime, issuer);
  };

  public getPlayerMetadata = (): PlayerInfo => {
    return {
      name: this.playerName,
      sdk: `bitmovin:${this.version}`,
      placement: 'main',
    };
  };

  public getVideoElement = (): HTMLVideoElement => {
    return this.videoElement;
  };

  public play = (): Promise<void> => {
    return this.player.play();
  };

  public pause = (issuer?: string): void => {
    this.player.pause(issuer);
  };

  public getSource = () => {
    return this.player.getSource();
  };

  public getStreamType = () => {
    return this.player.getStreamType();
  };

  public getPlayerType = () => {
    return this.player.getPlayerType();
  };

  public getDuration = () => {
    return this.player.getDuration();
  };

  public getAvailableAudio = () => {
    return this.player.getAvailableAudio();
  };

  public setAudio = (trackId: string) => {
    return this.player.setAudio(trackId);
  };

  public getCurrentBitrate = () => {
    const playbackVideoData = this.player.getPlaybackVideoData();
    if (playbackVideoData) {
      return playbackVideoData.bitrate;
    }

    return undefined;
  };

  public getAutoplayConfig = (): boolean => {
    const playerConfig = this.player.getConfig();

    if (playerConfig.playback?.autoplay) {
      return playerConfig.playback.autoplay;
    }

    return false;
  };

  public getPreloadConfig = (): boolean => {
    const playerConfig = this.player.getConfig();

    if (isMobile) {
      if (playerConfig.adaptation?.mobile?.preload) {
        return playerConfig.adaptation.mobile.preload;
      }
    } else if (playerConfig.adaptation?.desktop?.preload) {
      return playerConfig.adaptation.desktop.preload;
    }

    if (playerConfig.adaptation?.preload) {
      return playerConfig.adaptation.preload;
    }

    return !this.player.isLive();
  };

  private readonly getSelectedSubtitles = () => {
    if (this.player.subtitles) {
      return this.player.subtitles.list().filter((subtitle) => { return subtitle.enabled; }).pop();
    }

    return null;
  };

  public getAvailableSubtitles = () => {
    return this.player.subtitles.list();
  };

  public enableSubtitles = (trackId: string) => {
    this.player.subtitles.enable(trackId, true);
  };

  public getUserSettings = (): PlayerUserSettings => {
    const selectedSubtitles = this.getSelectedSubtitles();
    const selectedAudio = this.player.getAudio();

    return {
      subtitleLanguage: get(selectedSubtitles, 'lang', 'off'),
      subtitleLabel: get(selectedSubtitles, 'label', ''),
      audioLanguage: get(selectedAudio, 'lang', ''),
      audioLabel: get(selectedAudio, 'label', ''),
    };
  };

  public getStreamHandler = (): StreamHandler => {
    return this.streamHandler;
  };

  private readonly hasError = () => {
    return this.streamErrorReason !== undefined;
  };

  public isLoaded = () => {
    return this.loaded;
  };

  private readonly startAdHoliday = () => {
    if (this.player.isLive()) {
      Logger.info('VideoPlayer: No ad holiday for livestreams');
    } else {
      Logger.info(`VideoPlayer: Ad holiday started for ${this.adHolidayDuration} seconds`);
      this.eventManager.updateState('isAdHoliday', true);

      this.adHolidayTimeoutHandler = setTimeout(() => {
        Logger.info('VideoPlayer: Ad holiday ended');
        this.eventManager.updateState('isAdHoliday', false);
        this.eventManager.dispatch(VideoPlayerEventType.AD_HOLIDAY_FINISHED, { timestamp: new Date().getTime() });
      }, this.adHolidayDuration * 1000);

      this.eventManager.dispatch(VideoPlayerEventType.AD_HOLIDAY_STARTED, { timestamp: new Date().getTime() });
    }
  };

  private readonly checkPreviousPlayerSession = () => {
    const playerSession: PlayerSession = ls.get('od.player.session');

    if (playerSession) {
      const { timestamp: sessionTimestamp, videoId: sessionVideoId } = playerSession;
      const previousSessionLastTimestamp = sessionTimestamp;
      const now = new Date().getTime();
      const previousSessionDeltaTime = (now - previousSessionLastTimestamp) / 1000;

      if (
        this.videoId === sessionVideoId
        && previousSessionDeltaTime <= this.adHolidayRecoveryDuration
      ) {
        Logger.info('VideoPlayer: Found a recent session for this video');
        this.startAdHoliday();
      }
    }
  };

  private readonly recordPlayerSession = () => {
    Logger.debug('Recording data for player session');
    ls.set('od.player.session', { videoId: this.videoId, timestamp: new Date().getTime() });
  };

  public isPlaying = () => {
    return this.player.isPlaying();
  };

  public getCurrentAudioTrack = () => {
    return this.player?.getAudio();
  };

  public getCurrentControlBar = (): SbsControlBar | undefined => {
    if (this.uiManager) {
      const uiComponents = this.uiManager.activeUi.getUI().getComponents();
      return find(uiComponents, (component) => {
        return component instanceof SbsControlBar;
      }) as SbsControlBar;
    }

    return undefined;
  };

  public getCurrentTextTrack = () => {
    return this.player.subtitles.list().filter((subtitle) => { return subtitle.enabled === true; })?.pop();
  };
}
