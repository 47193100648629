import { Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { recommendedEndCard } from '@@src/apis/RecommendationApi';
import { getSingleRecommendation } from '@@src/apis/VideoRecommendationsApi';
import { useAppDispatch } from '@@src/hooks/store';
import { AdSnapBackEventType } from '@@src/lib/VideoPlayerV2/plugins/AdSnapBack/AdSnapBackCore';
import { VideoPlayerPluginProps } from '@@src/lib/VideoPlayerV2/plugins/VideoPlayerPlugin';
import { canUseSmartRecommendation } from '@@src/utils/helpers';
import Browser from '@@src/utils/newrelicAgent/Browser';
import { setProgress } from '@@stores/ProgressStore';
import { setRecommendation } from '@@stores/RecommendationStore';
import OnDemand from '@@types/OnDemand';
import DataLayer from '@@utils/DataLayer';

import {
  VideoPlayerCustomEvent,
  VideoPlayerEventCallback,
  VideoPlayerEventType,
} from '../../../VideoPlayerEventManager';
import RecommendationCard from './RecommendationCard';

const minWidthToDisplayOnCredits = 900;

const useStyles = makeStyles<Theme>((theme) => {
  return {
    '@global': {
      '#video-player': {
        transition: 'all .3s ease-out',
        '&.endcardFloat': {
          position: 'absolute',
          width: 'calc(100vw * 0.2898)',
          bottom: 'calc(33% - 4vw - 3vh)',
          height: 'calc(100vw * 0.2898 * 0.5625)',
          right: 'calc(100vw * 0.1234)',
          left: 'auto',
          top: 'auto',
          zIndex: 9999,
          transform: 'translate3d(0, 0, 0)',
          border: '2px solid rgba(0, 0, 0, 0)',
          '&:hover': {
            border: '2px solid white',
          },
          [theme.breakpoints.down('sm')]: {
            left: 600,
            width: 'calc(100vw - 640px)',
            height: 'calc((100vw - 680px) * 0.5625)',
          },
          [theme.breakpoints.down('xs')]: {
            left: 490,
            width: 'calc(100vw - 530px)',
            height: 'calc((100vw - 570px) * 0.5625)',
          },
          [`@media only screen and (max-width: ${minWidthToDisplayOnCredits - 1}px)`]: {
            display: 'none',
          },
          '@media only screen and (max-height: 600px)': {
            bottom: 80,
          },
          '@media only screen and (max-height: 400px)': {
            bottom: 20,
          },
        },
        '&.endcardFloatEnd': {
          right: 0,
          bottom: 0,
          left: 'auto',
          top: 'auto',
          zIndex: 9999,
          transform: 'translate3d(0, 0, 0)',
        },
      },
    },
  };
});

const PlaybackRecEndCard: FunctionComponent<VideoPlayerPluginProps> = (props) => {
  const {
    videoPlayer,
    video,
    playbackStreamData,
  } = props;

  useStyles(props);

  // display time is 3 seconds after the credits begin
  const displayTime = playbackStreamData.playbackEvents.closingCreditsBegin;

  // show the recommendation card after displayTime
  const [show, setShow] = useState<boolean>(false);

  const [enabled, setEnabled] = useState<boolean>(true);

  // the rec end card is dismissed
  const [dismissed, setDismissed] = useState<boolean>(false);

  const [recommendedVideo, setRecommendedVideo] = useState<OnDemand.Video>();

  const displayOnCredits = useMediaQuery(`(min-width:${minWidthToDisplayOnCredits}px)`);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // reset state when video is changed
    setRecommendedVideo(undefined);
    setDismissed(false);
    setShow(false);

    const retrieveRecommendedVideo = (_video: OnDemand.Video, useSmartRecommendation: boolean) => {
      if (useSmartRecommendation) {
        let recommendationSourceId = _video.catalogueId;
        if (_video.type === 'Episode' && _video?.episodeData) {
          recommendationSourceId = _video.episodeData.seriesId;
        }

        recommendedEndCard(recommendationSourceId).then((_recommendedVideo) => {
          if (_recommendedVideo && (_recommendedVideo.entityType === 'MOVIE' || _recommendedVideo.entityType === 'TV_PROGRAM' || _recommendedVideo.entityType === 'TV_EPISODE')) {
            setRecommendedVideo(_recommendedVideo);
          }
        });
      } else {
        getSingleRecommendation(_video.id).then((_recommendedVideo) => {
          if (_recommendedVideo) {
            setRecommendedVideo(_recommendedVideo);
          }
        });
      }
    };

    if (video) {
      const useSmartRecommendation = canUseSmartRecommendation(video.entityType);
      Browser.addPageAction('od-feature-personalised', {
        recName: 'Rec End Card',
        displayed: useSmartRecommendation ? 'yes' : 'no',
      });

      retrieveRecommendedVideo(video, useSmartRecommendation);
    }
  }, [video]);

  const shrinkPlayer = useCallback(() => {
    const videoElement = videoPlayer.getVideoElement();
    // show the mini player
    videoElement.classList.add('endcardFloat');

    const videoOnClick = () => {
      videoElement.classList.remove('endcardFloat');

      // We need this for the player to grow back to its previous position
      // without it, it will grow from top/right corner instead of reversing the animation
      videoElement.classList.add('endcardFloatEnd');

      setTimeout(() => {
        if (videoElement.classList.contains('endcardFloatEnd')) {
          videoElement.classList.remove('endcardFloatEnd');
        }
      }, 350);

      // set dismiss state when the mini player is clicked
      setDismissed(true);

      if (video && recommendedVideo) {
        DataLayer.events.recEndCard(
          'recommendedEndCard_exit',
          video,
          recommendedVideo,
          videoPlayer.getPlayerMetadata(),
          {
            videoNavigationEvent: {
              recommendedEndCard: 'exit',
            },
          },
        );
      }

      videoElement.removeEventListener('click', videoOnClick);
    };

    videoElement.addEventListener('click', videoOnClick);
  }, [videoPlayer, recommendedVideo, video]);

  const growPlayer = useCallback((dismiss: boolean = true) => {
    const videoElement = videoPlayer.getVideoElement();
    videoElement.classList.remove('endcardFloat');

    // We need this for the player to grow back to its previous position
    // without it, it will grow from top/right corner instead of reversing the animation
    videoElement.classList.add('endcardFloatEnd');

    setTimeout(() => {
      if (videoElement.classList.contains('endcardFloatEnd')) {
        videoElement.classList.remove('endcardFloatEnd');
      }
    }, 350);

    if (dismiss) {
      // set dismiss state when the mini player is clicked
      setDismissed(true);
    }
  }, [videoPlayer]);

  const hideMiniPlayer = useCallback(() => {
    const videoElement = videoPlayer.getVideoElement();
    videoElement.style.display = 'none';
    videoElement.classList.remove('endcardFloat');
  }, [videoPlayer]);

  const displayRecommendation = useCallback(() => {
    if (video && recommendedVideo) {
      setShow(true);

      DataLayer.events.recEndCard(
        'recommendedEndCard_display',
        video,
        recommendedVideo,
        videoPlayer.getPlayerMetadata(),
        {
          videoNavigationEvent: {
            recommendedEndCard: 'display',
          },
        },
      );

      videoPlayer.dispatchCustomEvent('recommendedEndCard_display', {
        timestamp: new Date().getTime(),
      });
    }
  }, [recommendedVideo, video, videoPlayer]);

  const onCustomEvent = (event: VideoPlayerCustomEvent) => {
    const { eventName } = event;

    if (eventName === AdSnapBackEventType.AD_SNAP_BACK_STARTED) {
      setEnabled(false);
    } else if (eventName === AdSnapBackEventType.AD_SNAP_BACK_FINISHED) {
      setEnabled(true);
    }
  };

  const checkShouldDisplayRecommendation = useCallback((event) => {
    const { time } = event;

    if (displayTime) {
      const displayStreamTime = videoPlayer.streamTimeForContentTime(displayTime + 3);

      if (enabled && !show && time >= displayStreamTime) {
        displayRecommendation();
        shrinkPlayer();
      }
    }
  }, [displayTime, videoPlayer, enabled, show, displayRecommendation, shrinkPlayer]);

  // when playing, check if we should display the recommendation
  useEffect(() => {
    // we don't want to display the recommendation on the credits time on small screen
    // because there isn't enough room to display the shrunk player
    if (recommendedVideo && displayOnCredits) {
      videoPlayer.on(VideoPlayerEventType.TIME_CHANGED, checkShouldDisplayRecommendation);
    }

    videoPlayer.on(VideoPlayerEventType.CUSTOM_EVENT, onCustomEvent as VideoPlayerEventCallback);

    return () => {
      videoPlayer.off(VideoPlayerEventType.CUSTOM_EVENT, onCustomEvent as VideoPlayerEventCallback);
      videoPlayer.off(VideoPlayerEventType.TIME_CHANGED, checkShouldDisplayRecommendation);
    };
  }, [videoPlayer, checkShouldDisplayRecommendation, displayOnCredits, recommendedVideo]);

  const displayRecommendationOnPlayFinished = useCallback(() => {
    setDismissed(false);
    hideMiniPlayer();
    displayRecommendation();
  }, [displayRecommendation, hideMiniPlayer]);

  // when content is finished, display the recommendation without the mini player
  useEffect(() => {
    if (recommendedVideo) {
      videoPlayer.on(VideoPlayerEventType.PLAYBACK_FINISHED, displayRecommendationOnPlayFinished);
    }

    return () => {
      videoPlayer.off(VideoPlayerEventType.PLAYBACK_FINISHED, displayRecommendationOnPlayFinished);
    };
  }, [videoPlayer, displayRecommendationOnPlayFinished, show, recommendedVideo]);

  // reset progress so that the video is played from the beginning
  const resetProgress = useCallback((videoId) => {
    dispatch(setProgress({
      videoId,
      seconds: 0,
      percent: 0,
    }));
  }, [dispatch]);

  const handleClickPlay = useCallback(() => {
    if (recommendedVideo?.recommendationId) {
      dispatch(setRecommendation({
        recommendationId: recommendedVideo.recommendationId,
        recommendationVariantName: recommendedVideo.recommendationVariant,
        target: {
          entityType: recommendedVideo.type === 'Episode' ? 'Series' : recommendedVideo.entityType,
          id: recommendedVideo.type === 'Episode' ? recommendedVideo.episodeData?.seriesId : recommendedVideo.catalogueId,
        },
      }));
    }

    if (video && recommendedVideo) {
      resetProgress(recommendedVideo.id);

      DataLayer.events.recEndCard(
        'recommendedEndCard_tap',
        video,
        recommendedVideo,
        videoPlayer.getPlayerMetadata(),
        {
          videoNavigationEvent: {
            recommendedEndCard: 'tap',
          },
        },
      );
    }

    hideMiniPlayer();

    // re-grow the player in the background in preparation for next video
    growPlayer();
  }, [recommendedVideo, video, hideMiniPlayer, growPlayer, dispatch, resetProgress, videoPlayer]);

  const handleClickMoreInfo = useCallback(() => {
    if (recommendedVideo?.recommendationId) {
      dispatch(setRecommendation({
        recommendationId: recommendedVideo.recommendationId,
        recommendationVariantName: recommendedVideo.recommendationVariant,
        target: {
          entityType: recommendedVideo.type === 'Episode' ? 'Series' : recommendedVideo.entityType,
          id: recommendedVideo.type === 'Episode' ? recommendedVideo.episodeData?.seriesId : recommendedVideo.catalogueId,
        },
      }));
    }

    if (video && recommendedVideo) {
      DataLayer.events.recEndCard(
        'recommendedEndCard_info',
        video,
        recommendedVideo,
        videoPlayer.getPlayerMetadata(),
        {
          videoNavigationEvent: {
            recommendedEndCard: 'info',
          },
        },
      );
    }
  }, [dispatch, recommendedVideo, video, videoPlayer]);

  const handleFavourited = useCallback(() => {
    if (video) {
      DataLayer.events.addToFavourites(video, 'recEndCard', {
        recommendationId: recommendedVideo?.recommendationId || '',
        recommendationVariantName: recommendedVideo?.recommendationVariant || '',
      });
    }
  }, [recommendedVideo, video]);

  const handleUnfavourited = useCallback(() => {
    if (video) {
      DataLayer.events.removeFromFavourites(video, 'recEndCard', {
        recommendationId: recommendedVideo?.recommendationId || '',
        recommendationVariantName: recommendedVideo?.recommendationVariant || '',
      });
    }
  }, [recommendedVideo, video]);

  if (!enabled || dismissed || !show || !recommendedVideo) {
    return null;
  }

  return (
    <RecommendationCard
      video={recommendedVideo}
      onClickPlay={handleClickPlay}
      onClickMoreInfo={handleClickMoreInfo}
      onFavourited={handleFavourited}
      onUnfavourited={handleUnfavourited}
    />
  );
};

export default PlaybackRecEndCard;
