import { SettingsPanel, SettingsPanelPage } from '@sbs/bitmovin-player-ui';
import { ComponentConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/component';

import VideoQualitySettingsPanelItem from './VideoQualitySettingsPanelItem';

export default class VideoQualitySettingsPanel extends SettingsPanel {
  constructor(config: ComponentConfig = {}) {
    super({
      ...config,
      hidden: true,
      hideDelay: -1,
      cssClasses: ['video-quality'],
      components: [
        new SettingsPanelPage({
          autoFocusOn: 'label',
          components: [
            new VideoQualitySettingsPanelItem(),
          ],
        }),
      ],
    });
  }
}
