import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useState, useRef, useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useUnmount } from 'react-use';

import OnDemand from '@@types/OnDemand';

import PlayerEpisodePickerShelf from '../../../PlayerEpisodePicker/PlayerEpisodePickerShelf';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      width: '100%',
      bottom: 100,
      padding: '0 86px',
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0.2s, opacity 0.2s ease-in-out',
      '&:focus-visible': {
        outline: 'unset',
      },
      [theme.breakpoints.up('md')]: {
        '&.show': {
          visibility: 'visible',
          opacity: 1,
        },
      },
      '.bmpui-controls-hidden &': {
        display: 'none',
      },
    },
    pickerRoot: {
      width: '100%',
    },
  });
});

export interface VideoEpisodePickerV2ComponentProps {
  videoPlayerContainer: HTMLElement,
  video: OnDemand.Episode;
}

let mouseOutHideTimeoutHandler;

const EpisodePickerPluginComponent: FunctionComponent<VideoEpisodePickerV2ComponentProps> = (props) => {
  const { videoPlayerContainer, video } = props;
  const classes = useStyles(props);
  const cardRef = useRef(null);
  const [show, setShow] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [buttonElement, setButtonElement] = useState<any>(undefined);

  const dispatchShownEvent = useCallback(() => {
    const shownEvent = new CustomEvent('OdPlayerMoreEpisodesShown', {
      detail: { target: cardRef.current },
    });
    videoPlayerContainer.dispatchEvent(shownEvent);
  }, [videoPlayerContainer]);

  const dispatchHiddenEvent = useCallback(() => {
    const hiddenEvent = new CustomEvent('OdPlayerMoreEpisodesHidden');
    videoPlayerContainer.dispatchEvent(hiddenEvent);
  }, [videoPlayerContainer]);

  const selfHide = useCallback(() => {
    setShow(false);
    dispatchHiddenEvent();
  }, [dispatchHiddenEvent, setShow]);

  useEffect(() => {
    // hide the more episode button when video id changes
    const moreEpisodesButton = document.getElementById('bmpui-ui-moreepisodesbutton');
    if (moreEpisodesButton) {
      moreEpisodesButton.classList.add('hidden');
    }
  }, [video.id]);

  useUnmount(() => {
    // hide the more episode button when unmounted
    const moreEpisodesButton = document.getElementById('bmpui-ui-moreepisodesbutton');
    if (moreEpisodesButton) {
      moreEpisodesButton.classList.add('hidden');
    }
  });

  const toggleVisibility = useCallback(() => {
    setShow((isShow) => {
      const willBeShown = !isShow;

      if (!willBeShown) {
        dispatchHiddenEvent();
      } else {
        dispatchShownEvent();
      }
      return willBeShown;
    });
  }, [dispatchShownEvent, dispatchHiddenEvent, setShow]);

  const handleHideRequestEvent = useCallback((event) => {
    const { detail: { reason } } = event;
    let shouldHide;
    let button;

    switch (reason) {
      case 'buttonMouseOut':
        ({ detail: { button } } = event);
        if ((!button || !button.hovered) && !isHovered) {
          shouldHide = true;
        }
        setButtonElement(button);
        break;

      case 'settingsMenuShow':
      case 'panelHide':
        shouldHide = true;
        break;

      default:
        shouldHide = false;
        break;
    }

    if (shouldHide) {
      selfHide();
    }
  }, [isHovered, selfHide]);

  const handleShowRequestEvent = useCallback(() => {
    setShow(true);
    dispatchShownEvent();
  }, [dispatchShownEvent, setShow]);

  useEffect(() => {
    const handleButtonClick = () => {
      clearTimeout(mouseOutHideTimeoutHandler);

      // toggle show
      toggleVisibility();
    };

    videoPlayerContainer.addEventListener('OdPlayerMoreEpisodesButtonClick', handleButtonClick);
    videoPlayerContainer.addEventListener('OdPlayerMoreEpisodesHide', handleHideRequestEvent);
    videoPlayerContainer.addEventListener('OdPlayerMoreEpisodesShow', handleShowRequestEvent);

    return (() => {
      videoPlayerContainer.removeEventListener('OdPlayerMoreEpisodesButtonClick', handleButtonClick);
      videoPlayerContainer.removeEventListener('OdPlayerMoreEpisodesHide', handleHideRequestEvent);
      videoPlayerContainer.addEventListener('OdPlayerMoreEpisodesShow', handleShowRequestEvent);
    });
  }, [videoPlayerContainer, handleHideRequestEvent, toggleVisibility, handleShowRequestEvent]);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        cardRef.current.focus();
      }, 250);
    }
  }, [show]);

  const handleMouseEnter = useCallback(() => {
    clearTimeout(mouseOutHideTimeoutHandler);
    setIsHovered(true);
    const event = new CustomEvent('OdPlayerMoreEpisodesHoverChanged', { detail: { hovered: true } });
    videoPlayerContainer.dispatchEvent(event);
  }, [videoPlayerContainer]);

  const handleMouseLeave = useCallback((e) => {
    const { relatedTarget } = e;

    if (!(relatedTarget instanceof Window)) {
      mouseOutHideTimeoutHandler = setTimeout(() => {
        setIsHovered(false);
        const event = new CustomEvent('OdPlayerMoreEpisodesHoverChanged', { detail: { hovered: false } });
        videoPlayerContainer.dispatchEvent(event);

        if (buttonElement && !buttonElement.hovered) {
          selfHide();
        }
      }, 200);
    }
  }, [buttonElement, selfHide, videoPlayerContainer]);

  const handleOnIsAvailable = useCallback(() => {
    // Only display button and allow episode picker to be displayed if there are other episodes in the series
    const moreEpisodesButton = document.getElementById('bmpui-ui-moreepisodesbutton');
    if (moreEpisodesButton) {
      moreEpisodesButton.classList.remove('hidden');
    }
  }, []);

  if (isMobileOnly) {
    return null;
  }

  return (
    <div
      className={clsx(classes.root, { show })}
      id="episode-picker"
      data-testid="episode-picker"
      ref={cardRef}
      tabIndex={-1}
    >
      <PlayerEpisodePickerShelf
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onIsAvailable={handleOnIsAvailable}
        mpxId={video.id}
        classes={{ root: classes.pickerRoot }}
      />
    </div>
  );
};

export default EpisodePickerPluginComponent;
