import { FunctionComponent } from 'react';

import VideoPlayer from '@@src/lib/VideoPlayerV2/VideoPlayer';
import OnDemand from '@@types/OnDemand';
import { PlaybackStreamData } from '@@types/PlaybackStreamData';
import { getNextEpisodeInSeries } from '@@utils/helpers';

import PlaybackNextEpisodeEndCard from './PlaybackNextEpisodeEndCard/PlaybackNextEpisodeEndCard';
import PlaybackRecEndCard from './PlaybackRecEndCard/PlaybackRecEndCard';

interface PlaybackEndCardProps {
  videoPlayer: VideoPlayer;
  video: OnDemand.Video;
  playbackStreamData: PlaybackStreamData;
  series?: OnDemand.TvSeries | null;
}

const PlaybackEndCard: FunctionComponent<PlaybackEndCardProps> = (props) => {
  const {
    videoPlayer,
    video,
    playbackStreamData,
    series,
  } = props;

  if (series) {
    const nextEpisode = getNextEpisodeInSeries(video.id, series);

    if (nextEpisode) {
      return <PlaybackNextEpisodeEndCard videoPlayer={videoPlayer} nextEpisode={nextEpisode} playbackStreamData={playbackStreamData}/>;
    }
  }

  return <PlaybackRecEndCard videoPlayer={videoPlayer} video={video} playbackStreamData={playbackStreamData}/>;
};

export default PlaybackEndCard;
