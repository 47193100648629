import { PlayerAPI } from 'bitmovin-player';
import { DateTime } from 'luxon';

import Logger from '@@utils/logger/Logger';

import VideoPlayerEventManager, {
  VideoPlayerEvent,
  VideoPlayerEventCallback,
  VideoPlayerEventType,
} from '../VideoPlayerEventManager';
import type { StreamData, StreamHandler } from './StreamHandler';
import { CuePoints } from './StreamHandler';

export interface GoogleDaiStreamHandlerConfig {
  type: 'GoogleDAI';
  videoId?: string;
  assetKey?: string;
  contentSourceId: string;
  adTagParameters: Record<string, unknown>;
}

export interface AdMetadata {
  id: string;
  adSystem: string;
  apiFramework: string;
  url: string | null;
  duration: number;
  name: string;
  position: number;
  description: string;
  totalAds: number;
  creativeId: string;
  podIndex: number;
}

export default class GoogleDaiStreamHandler implements StreamHandler {
  private config: GoogleDaiStreamHandlerConfig;
  private eventManager: VideoPlayerEventManager;
  private player: PlayerAPI;
  private imaDaiApi: typeof google.ima.dai.api;
  private videoElement: HTMLVideoElement;

  private liveStreamAdStartTime: number | undefined = undefined;

  public streamManager: google.ima.dai.api.StreamManager;
  private StreamEventType: typeof google.ima.dai.api.StreamEvent.Type;

  private pendingLoadPromise: Promise<StreamData> | undefined;
  private resolveLoad: ((streamData: StreamData) => void) | undefined;
  private rejectLoad: ((error: Error) => void) | undefined;

  private streamUrl: string | null = null;
  private streamId: string | null = null;
  private cuepoints: CuePoints[] = [];

  constructor(
    config: GoogleDaiStreamHandlerConfig,
    eventManager: VideoPlayerEventManager,
    player: PlayerAPI,
    videoElement: HTMLVideoElement,
    imaDaiApi: typeof google.ima.dai.api,
    adClickElementId?: string,
  ) {
    this.config = config;
    this.eventManager = eventManager;
    this.player = player;
    this.videoElement = videoElement;
    this.imaDaiApi = imaDaiApi;
    const { StreamManager, StreamEvent } = imaDaiApi;
    this.streamManager = new StreamManager(this.videoElement);
    if (adClickElementId) {
      const adClickElement = document.getElementById(adClickElementId);
      if (adClickElement) {
        this.streamManager.setClickElement(adClickElement);
      }
    }

    this.StreamEventType = StreamEvent.Type;

    this.registerPlayerEvents();
  }

  private resetLoadPromise() {
    this.pendingLoadPromise = undefined;
    this.resolveLoad = undefined;
    this.rejectLoad = undefined;
  }

  protected getAdTagParameters(): Record<string, unknown> {
    const adTagParameters = { ...this.config.adTagParameters };

    // convert cust_params object to key value pairs
    const custParamsObject: Record<string, string> = this.config.adTagParameters.cust_params as Record<string, string> || {};
    delete adTagParameters.cust_params;

    const custParams = Object.keys(custParamsObject).map((key) => {
      return `${key}=${custParamsObject[key]}`;
    });

    if (custParams.length > 0) {
      adTagParameters.cust_params = custParams.join('&');
    }

    return adTagParameters;
  }

  // Livestreams HLS contains live events that are collected and emitted by Bitmovin SDK
  // We need to pass this on to the stream manager so it knows when ads should be inserted.
  private onMetadata = (data: VideoPlayerEvent<VideoPlayerEventType.METADATA>) => {
    if (this.streamManager && data?.metadataType === 'ID3') {
      this.streamManager.onTimedMetadata(data.metadata);
    }
  };

  public load() {
    Logger.debug('GoogleDaiStreamHandler: load', {
      videoId: this.config.videoId || this.config.assetKey,
    });

    if (typeof this.pendingLoadPromise !== 'undefined') {
      return this.pendingLoadPromise;
    }

    this.pendingLoadPromise = new Promise((resolve, reject) => {
      this.resolveLoad = resolve;
      this.rejectLoad = reject;
    });

    let streamRequest: google.ima.dai.api.VODStreamRequest | google.ima.dai.api.LiveStreamRequest;

    if (this.config.videoId) { // vod
      streamRequest = new this.imaDaiApi.VODStreamRequest();
      streamRequest.contentSourceId = this.config.contentSourceId;
      streamRequest.videoId = this.config.videoId;
    } else if (this.config.assetKey) { // live
      streamRequest = new this.imaDaiApi.LiveStreamRequest();
      streamRequest.assetKey = this.config.assetKey;

      this.eventManager.addEventHandler(VideoPlayerEventType.METADATA, this.onMetadata as VideoPlayerEventCallback);
    } else {
      throw new Error('Neither videoID nor assetKey is defined');
    }

    streamRequest.format = 'hls';

    streamRequest.adTagParameters = this.getAdTagParameters();

    this.streamManager.requestStream(streamRequest);

    return this.pendingLoadPromise;
  }

  public getStreamId = () => {
    return this.streamId;
  };

  public getStreamTime = () => {
    return this.player.getCurrentTime();
  };

  public streamTimeForContentTime = (contentTime: number): number => {
    return this.streamManager.streamTimeForContentTime(contentTime);
  };

  public contentTimeForStreamTime = (streamTime: number): number => {
    return this.streamManager.contentTimeForStreamTime(streamTime);
  };

  public getStreamManager() {
    return this.streamManager;
  }

  public setClickElement = (element: HTMLElement) => {
    this.streamManager.setClickElement(element);
  };

  private getTimestamp = () => {
    return DateTime.now().toMillis();
  };

  private registerPlayerEvents = () => {
    // Map dai stream events to our own video player events
    this.streamManager.addEventListener([this.StreamEventType.LOADED], this.onLoaded, false);
    this.streamManager.addEventListener([this.StreamEventType.ERROR], this.onError, false);
    this.streamManager.addEventListener([this.StreamEventType.AD_BREAK_STARTED], this.onAdBreakStarted, false);
    this.streamManager.addEventListener([this.StreamEventType.AD_BREAK_ENDED], this.onAdBreakEnded, false);
    this.streamManager.addEventListener([this.StreamEventType.AD_PROGRESS], this.onAdProgress, false);
    this.streamManager.addEventListener([this.StreamEventType.STARTED], this.onAdStarted as google.ima.dai.api.StreamManagerEventCallback, false);
    this.streamManager.addEventListener([this.StreamEventType.COMPLETE], this.onAdComplete as google.ima.dai.api.StreamManagerEventCallback, false);
    this.streamManager.addEventListener([this.StreamEventType.CUEPOINTS_CHANGED], this.onCuepointsChanged, false);
  };

  private deregisterPlayerEvents = () => {
    this.eventManager.removeEventHandler(VideoPlayerEventType.METADATA, this.onMetadata as VideoPlayerEventCallback);
  };

  public getPreviousCuePointForStreamTime = (streamTime: number): CuePoints | null => {
    return this.streamManager.previousCuePointForStreamTime(streamTime) as CuePoints;
  };

  public unload() {
    this.deregisterPlayerEvents();
    this.streamManager.reset();
  }

  private onLoaded = (event: google.ima.dai.api.StreamEvent) => {
    const streamData = event.getStreamData();
    this.streamUrl = streamData.url;
    this.streamId = streamData.streamId;

    Logger.debug(`GoogleDaiStreamHandler: Event ${event.type}`, streamData);
    if (this.resolveLoad) {
      if (streamData.manifestFormat === 'hls' && streamData.url) {
        this.resolveLoad({
          hlsUrl: streamData.url,
        });
      } else if (this.rejectLoad) {
        this.rejectLoad(new Error(`Unsupported manifest format ${streamData.manifestFormat}`));
      }
    }
    this.resetLoadPromise();
  };

  private onError = (event: google.ima.dai.api.StreamEvent) => {
    const streamData = event.getStreamData();
    Logger.debug(`GoogleDaiStreamHandler: Event ${event.type}`, streamData);
    if (this.rejectLoad && streamData.errorMessage) {
      this.rejectLoad(new Error(streamData.errorMessage));
    }
    this.resetLoadPromise();
  };

  private onAdBreakStarted = () => {
    this.eventManager.updateState('isAd', true);
    this.eventManager.dispatch(VideoPlayerEventType.AD_BREAK_STARTED, { timestamp: this.getTimestamp() });
  };

  private onAdBreakEnded = () => {
    this.eventManager.updateState('isAd', false);
    this.eventManager.dispatch(VideoPlayerEventType.AD_BREAK_FINISHED, { timestamp: this.getTimestamp() });
  };

  private onAdProgress = (event: google.ima.dai.api.StreamEvent) => {
    const streamData = event.getStreamData();

    const adBreakDuration = streamData.adProgressData ? streamData.adProgressData.adBreakDuration : 0;
    const streamTime = this.player.getCurrentTime();
    const previousCuePoint = this.streamManager.previousCuePointForStreamTime(streamTime);

    // calculate remaining time
    let remainingTime = null;

    if (this.config.videoId) { // vod
      if (previousCuePoint) {
        const adLapsedTime = streamTime - previousCuePoint.start;
        remainingTime = Math.round(Math.max(0, adBreakDuration - adLapsedTime));
      }
    } else if (this.liveStreamAdStartTime === undefined) {
      // With Livestreams we don't have timeline markers to tell us what the ad pod start time is.
      // So we have to calculate and store it for future use.
      const currentAdTime = streamData.adProgressData ? streamData.adProgressData.currentTime : 0;
      this.liveStreamAdStartTime = streamTime - currentAdTime;
    }

    if (this.liveStreamAdStartTime !== undefined) {
      const adPodCurrentTime = streamTime - this.liveStreamAdStartTime;
      remainingTime = Math.round(adBreakDuration - adPodCurrentTime);
    }

    this.eventManager.dispatch(VideoPlayerEventType.AD_PROGRESS, {
      timestamp: this.getTimestamp(),
      remainingTime,
    });
  };

  private onAdStarted = (event: google.ima.dai.api.AdStreamEvent) => {
    this.eventManager.updateState('isAd', true);
    this.eventManager.dispatch(VideoPlayerEventType.AD_STARTED, { timestamp: this.getTimestamp(), adMetadata: this.getAdMetadata(event) });
  };

  private onAdComplete = (event: google.ima.dai.api.AdStreamEvent) => {
    this.eventManager.dispatch(VideoPlayerEventType.AD_FINISHED, { timestamp: this.getTimestamp(), adMetadata: this.getAdMetadata(event) });
  };

  private onCuepointsChanged = (event: google.ima.dai.api.StreamEvent) => {
    const streamData = event.getStreamData();

    this.cuepoints = streamData.cuepoints || [];
    const timelineMarkers: number[] = [];

    this.cuepoints.forEach((cuepoint) => {
      if (cuepoint.start > 0) {
        timelineMarkers.push(cuepoint.start);
      }
    });

    this.eventManager.updateState('timelineMarkers', timelineMarkers);
  };

  private getAdMetadata = (event: google.ima.dai.api.AdStreamEvent): AdMetadata => {
    const ad = event.getAd();
    const podInfo = ad.getAdPodInfo();
    return {
      id: ad.getAdId(),
      adSystem: ad.getAdSystem(),
      apiFramework: ad.getApiFramework(),
      url: this.streamUrl,
      duration: ad.getDuration(),
      name: ad.getTitle(),
      description: ad.getDescription(),
      creativeId: ad.getCreativeId(),
      position: podInfo.getAdPosition(),
      totalAds: podInfo.getTotalAds(),
      podIndex: podInfo.getPodIndex(),
    };
  };

  public getCuePoints = () => {
    return this.cuepoints;
  };
}
