import { loadScript } from '@@utils/helpers';

import GoogleImaDaiApiLoadError from './GoogleImaDaiApiLoadError';

export interface ImaDaiWindow {
  google?: {
    ima: {
      dai: {
        api: typeof google.ima.dai.api;
      };
    };
  };
}

const imaDaiSdkSrc = 'https://imasdk.googleapis.com/js/sdkloader/ima3_dai.js';
let pendingPromise: Promise<typeof google.ima.dai.api> | null = null;

export default function loadImaDaiSdk(): Promise<typeof google.ima.dai.api> {
  const w = window as ImaDaiWindow;
  if (w.google && w.google.ima && w.google.ima.dai && w.google.ima.dai.api) {
    return Promise.resolve(w.google.ima.dai.api);
  }

  if (pendingPromise) {
    return pendingPromise;
  }

  pendingPromise = loadScript(imaDaiSdkSrc).then(() => {
    if (w.google && w.google.ima && w.google.ima.dai && w.google.ima.dai.api) {
      return w.google.ima.dai.api;
    }
    throw new GoogleImaDaiApiLoadError();
  }).catch(() => {
    throw new GoogleImaDaiApiLoadError();
  });

  return pendingPromise;
}

export function resetLoadImaDaiSdkPromise() {
  pendingPromise = null;
}
