import { isIOS, isMobileOnly } from 'react-device-detect';

import VideoPlayerPluginV2WithReactPortal from '../VideoPlayerPluginV2WithReactPortal';
import AutoplayNextEpisodeComponent from './AutoplayNextEpisodeComponent';

class AutoplayNextEpisodePluginV2 extends VideoPlayerPluginV2WithReactPortal {
  protected videoCardPosition = 'top' as const;

  public shouldLoad(): boolean {
    // it should load if next video and offer_next_episode tracking time are available
    return !isIOS // this plugin doesn't work well with ios native player when playing full screen
      && !isMobileOnly // the button position is not great on mobile
      && !!this.videoPlayer.getNextVideo() && !!this.videoPlayer.getTrackingTime('offer_next_episode');
  }

  protected component() {
    return (
      <AutoplayNextEpisodeComponent
        bitmovinClient={this.bitmovinClient}
        video={this.videoPlayer.getVideo()}
        nextEpisode={this.videoPlayer.getNextVideo()}
        displayTime={this.videoPlayer.getTrackingTime('offer_next_episode')}
        playerMetadata={this.videoPlayer.getPlayerMetadata()}
      />
    );
  }
}

export default AutoplayNextEpisodePluginV2;
