import { PlaybackToggleButton, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { Container, ContainerConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/container';
import { PlayerAPI, PlayerEvent } from 'bitmovin-player';

import SeekBackButton from './SeekBackButton';
import SeekForwardButton from './SeekForwardButton';

export default class PlaybackButtonGroup extends Container<ContainerConfig> {
  constructor(config: ContainerConfig = {}) {
    super({
      ...config,
      cssClasses: ['playbackgroup'],
      hidden: true,
      components: [
        new SeekBackButton(),
        new PlaybackToggleButton({
          cssClasses: ['ui-playbacktogglebutton', 'ui-button'],
        }),
        new SeekForwardButton(),
      ],
    });
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);

    uimanager.onControlsShow.subscribe(() => {
      if (player.getSource()?.hls || player.isPlaying() || player.isPaused()) {
        this.show();
      }
    });

    uimanager.onControlsHide.subscribe(() => {
      this.hide();
    });

    player.on(PlayerEvent.SourceLoaded, () => {
      this.show();
    });

    player.on(PlayerEvent.SourceUnloaded, () => {
      this.hide();
    });
  }
}
