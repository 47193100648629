import { Container, Label, UIInstanceManager } from '@sbs/bitmovin-player-ui';
import { ContainerConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/container';
import { LabelConfig } from '@sbs/bitmovin-player-ui/dist/js/framework/components/label';
import { CastStartedEvent, CastWaitingForDeviceEvent, PlayerAPI } from 'bitmovin-player';

import i18n from '../../../../i18n';

export default class OdCastStatusOverlay extends Container<ContainerConfig> {
  private statusLabel: Label<LabelConfig>;
  private deviceName: string;
  private castPlayer: any;
  private castPlayerController: any;
  private player: PlayerAPI;
  private contentTitle: string = '';

  constructor(config: ContainerConfig = {}) {
    super(config);

    this.statusLabel = new Label<LabelConfig>({ cssClass: 'ui-cast-status-label' });

    this.config = this.mergeConfig(config, {
      cssClass: 'ui-cast-status-overlay',
      components: [this.statusLabel],
      hidden: true,
    }, this.config);
  }

  configure(player: PlayerAPI, uimanager: UIInstanceManager): void {
    super.configure(player, uimanager);
    this.player = player;

    player.on(player.exports.PlayerEvent.CastAvailable, this.onCastAvailable);
    player.on(player.exports.PlayerEvent.CastWaitingForDevice, this.onCastWaitingForDevice);
    player.on(player.exports.PlayerEvent.CastStarted, this.onCastStarted);
    player.on(player.exports.PlayerEvent.CastStopped, this.onCastStopped);
  }

  onCastAvailable = (): void => {
    this.castPlayer = new window.cast.framework.RemotePlayer();
    this.castPlayerController = new window.cast.framework.RemotePlayerController(this.castPlayer);

    this.castPlayerController.addEventListener(window.cast.framework.RemotePlayerEventType.DISPLAY_STATUS_CHANGED, this.onCastDisplayStatusChanged);
    this.castPlayerController.addEventListener(window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED, this.onCastCurrentTimeChanged);
  };

  onCastWaitingForDevice = (event: CastWaitingForDeviceEvent): void => {
    this.show();
    this.deviceName = event.castPayload.deviceName;
    this.statusLabel.setText(i18n.t('common:videoPlayer.chromecastConnectingTo', { device: this.deviceName }));
  };

  onCastStarted = (event: CastStartedEvent): void => {
    this.show();
    this.deviceName = event.deviceName;
    this.statusLabel.setText(i18n.t('common:videoPlayer.chromecastConnectingTo', { device: this.deviceName }));
  };

  onCastStopped = (): void => {
    this.hide();
  };

  onCastDisplayStatusChanged = (event): void => {
    const { value } = event;
    this.show();

    if (value.indexOf('Casting') === 0) {
      const [, title] = value.split(': ');
      this.contentTitle = title;
      this.statusLabel.setText(i18n.t('common:videoPlayer.chromecastConnectingTo', { device: this.deviceName }));
    } else if (this.contentTitle === value) {
      this.statusLabel.setText(i18n.t('common:videoPlayer.chromecastPlayingOn', { device: this.deviceName }));
    }
  };

  onCastCurrentTimeChanged = (): void => {
    this.statusLabel.setText(i18n.t('common:videoPlayer.chromecastPlayingOn', { device: this.deviceName }));
  };

  release(): void {
    if (this.player) {
      this.player.off(this.player.exports.PlayerEvent.CastAvailable, this.onCastAvailable);
      this.player.off(this.player.exports.PlayerEvent.CastWaitingForDevice, this.onCastWaitingForDevice);
      this.player.off(this.player.exports.PlayerEvent.CastStarted, this.onCastStarted);
      this.player.off(this.player.exports.PlayerEvent.CastStopped, this.onCastStopped);
      delete this.castPlayer;
    }

    if (this.castPlayerController) {
      this.castPlayerController.removeEventListener(window.cast.framework.RemotePlayerEventType.DISPLAY_STATUS_CHANGED, this.onCastDisplayStatusChanged);
      this.castPlayerController.removeEventListener(window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED, this.onCastCurrentTimeChanged);
      delete this.castPlayerController;
    }
  }
}
